import React, {FunctionComponent, useState} from 'react'
import {IClientSchedule} from "../../../core/models/clientSchedules";
import {Button, Icon} from "semantic-ui-react";
import UserIdentity from "../../users/commom/UserIdentity";
import ShowMoreButton from "../../commom/buttons/ShowMoreButton";
import ClientScheduleForm from "../form/ClientScheduleForm";
import {toast} from "react-toastify";

interface OwnProps {
    clientSchedule: IClientSchedule
}

type Props = OwnProps

const ClientScheduleIndebtedEmployeeIdentity: FunctionComponent<Props> = ({clientSchedule}) => {

    const [editMode, setEditMode] = useState(false)
    const [showEditIcon, setShowEditIcon] = useState(false)

    if (editMode) {
        return <div>

            <ClientScheduleForm
                partialForm
                partialFormFields={{
                    indebtedEmployeeId: true
                }}
                clientScheduleId={clientSchedule.id}
                onSubmitSuccess={() => {
                    setEditMode(false)
                    toast.success('Plan uspešno izmenjen')
                }}
                onCancel={() => setEditMode(false)}
            />

            <Button
                content='Exit edit mode'
                onClick={() => setEditMode(false)}
            />
        </div>
    }

    return clientSchedule.indebtedEmployee
        ? <div
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
        >
            <UserIdentity user={clientSchedule.indebtedEmployee}/>
            {showEditIcon &&
            <Icon
                style={{marginLeft: 5, cursor: 'pointer'}}
                name='pencil'
                color='orange'
                onClick={() => setEditMode(true)}
            />}
        </div>
        : <div>
            <ShowMoreButton
                content='Dodaj zaduženog'
                onClick={() => setEditMode(true)}
            />
        </div>

}

export default ClientScheduleIndebtedEmployeeIdentity
