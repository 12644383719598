import {FilterProps} from "react-table";
import React from "react";
import {Dropdown, Input} from "semantic-ui-react";
import LoadingComponent from "../../../../../layout/LoadingComponent";
import {IBrand} from "../../../../../models/brands";
import {IProductsFilter, ProductsFilter} from "../../../../../models/products";
import CompactFilterLayout from "../../common/CompactFilterLayout";

export const ProductsDetailedFilter = ({
                                           column: {
                                               filterValue,
                                               preFilteredRows,
                                               filteredRows,
                                               setFilter,
                                               filter
                                           }
                                       }: FilterProps<IProductsFilter>,
                                       brands: IBrand[],
) => {
    
    const renderLabel = (label: any) => ({
        color: 'blue',
        content: label.text,
        size: 'mini'
    })


    if (brands === null) return <LoadingComponent/>

    return <CompactFilterLayout
        filterValue={filterValue}
        onClearFilterClick={() => setFilter(new ProductsFilter())}
    >
        <Input
            fluid
            value={filterValue.sku}
            onChange={(event, data) => setFilter({...filterValue, sku: data.value})}
            placeholder='Šifra'
        />

        <Input
            fluid
            value={filterValue.name}
            onChange={(event, data) => setFilter({...filterValue, name: data.value})}
            placeholder='Naziv'
        />

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={brands.map((brand: IBrand) => ({
                key: brand.id,
                text: brand.name,
                value: brand.id
            }))}
            value={filterValue.brands}
            renderLabel={renderLabel}
            placeholder='Brend'
            onChange={(event, data) => setFilter({...filterValue, brands: data.value ?? []})}
        />
    </CompactFilterLayout>

}