import React, {FunctionComponent} from 'react';
import {RouteComponentProps} from "react-router";
import {Grid, Header} from "semantic-ui-react";
import ClientScheduleForm from "./ClientScheduleForm";

interface DetailParams {
    id: string;
}

const ClientScheduleFormRouteWrapper: FunctionComponent<RouteComponentProps<DetailParams>> = ({
                                                                                                  match,
                                                                                                  history,
                                                                                              }) => {
    return <Grid centered>
        <Grid.Column computer={8} mobile={16} tablet={16}>
            <Header
                as='h2'
                color='blue'
                content={match.params.id ? 'Izmena plana prometa' : 'Dodavanje plana prometa'}
            />

            <ClientScheduleForm clientScheduleId={match.params.id}/>
        </Grid.Column>
    </Grid>
}

export default ClientScheduleFormRouteWrapper