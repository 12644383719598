import React, {FunctionComponent} from 'react';
import {FieldRenderProps} from "react-final-form";
import {FormFieldProps, Form, Label, Input, InputProps} from "semantic-ui-react";

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {
    suggestions?: string[],
    labelTop?: boolean,
    icon?: string
}

const TextInput: FunctionComponent<IProps> = ({
                                                  input,
                                                  width,
                                                  type,
                                                  placeholder,
                                                  label,
                                                  labelPosition,
                                                  meta: {error, touched},
                                                  suggestions,
                                                  labelTop,
                                                  icon,
                                                  ...rest
                                              }) => {
    return (
        <Form.Field error={touched && !!error} type={type} width={width}>
            {labelTop &&
            <Label content={label}/>}
            <Input
                icon={icon}
                label={labelTop ? null : label} labelPosition={labelPosition} autoComplete='off'
                list={`suggestions-${input.name}`} 
                {...input}
                placeholder={placeholder}
                {...rest as InputProps}
            />
            {suggestions &&
            <datalist id={`suggestions-${input.name}`}>
                {suggestions.map((suggestion, index) => (
                    <option key={index} value={suggestion}>{suggestion}</option>
                ))}
            </datalist>
            }
            {touched && error && (
                <Label basic color='red'>
                    {error}
                </Label>
            )}
        </Form.Field>
    );
};

export default TextInput;
