import React, {FunctionComponent, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import {Form, Input, Item} from "semantic-ui-react";

interface IProps {
}

const BillCartManualDifferenceInPrice: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {manualBillBasePrice, setManualBillBasePrice} = rootStore.billsCartStore

    return (<Item>
        <Item.Header content={'Nabavna cena'}/>
        <Item.Content>
            <Input
                name='manualBillDifferenceInPriceGratisBase'
                as={Form.Field}
                type='number'
                fluid
                labelPosition='right'
                label={{basic: true, content: 'RSD'}}
                value={manualBillBasePrice}
                onChange={(event, data) => setManualBillBasePrice(Number(data.value))}/>
        </Item.Content>
    </Item>)
}

export default observer(BillCartManualDifferenceInPrice)
