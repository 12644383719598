import {IProduct} from "./products";
import moment from "moment";
import { v4 as uuid } from 'uuid'
import {IBrand} from "./brands";

export interface IProductConsumptionItem {
    id: string
    productConsumptionId: string
    productConsumptionDate: Date
    productConsumptionCategory: string
    product: IProduct
    eurExchangeRateOnDay: number 
    count: number
    singleEurValue: number 
    singleRsdValue: number 
    summaryEurValue: number 
    summaryRsdValue: number 
}

export class ProductConsumptionItem implements IProductConsumptionItem {
    count: number = 1
    eurExchangeRateOnDay: number = 0
    productConsumptionDate: Date = new Date()
    productConsumptionCategory: string = ''
    id: string = uuid()
    product: IProduct;
    productConsumptionId = uuid()
    singleEurValue = 0
    singleRsdValue = 0 
    summaryEurValue = 0 
    summaryRsdValue = 0 
    
    constructor(product: IProduct, count: number = 1) {
        this.count = count
        this.product = product
    }
    
}


export interface IProductConsumption {
    id: string
    category: string
    eurExchangeRateOnDay: number
    items: IProductConsumptionItem[]
    date: Date
    value: number
    note: string
}

export interface IProductConsumptionFormValues {
    id: string
    category: string
    items: IProductConsumptionItem[]
    date: string
    value: string
    note: string
}

export class ProductConsumptionFormValues implements IProductConsumptionFormValues {
    date = moment().format('YYYY-MM-DD')
    category = ''
    id = ''
    items: IProductConsumptionItem[] = []
    note = ''
    value = ''

    constructor(productConsumption?: IProductConsumption) {
        if (productConsumption) {
            this.date = moment(productConsumption.date).format('YYYY-MM-DD')
            this.id = productConsumption.id
            this.items = productConsumption.items
            this.note = productConsumption.note 
            this.value = productConsumption.value.toString()
            this.category = productConsumption.category
        }
    }

}

export interface IProductConsumptionAnalyseEntity {
    count: number
    value: number
    brand: IBrand | null
    product: IProduct | null
}