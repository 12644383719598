import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, reaction, runInAction} from "mobx";
import {IPayment, PaymentFormValues} from "../models/payments";
import agent from "../api/agent";
import {history} from "../../index";
import { v4 as uuid } from 'uuid'

export default class PaymentsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

        reaction(() => this.payments.values(),
            () => {
                this.rootStore.clientsStore.updateFinancialState()
            })
    }

    @observable payments = new Map<string, IPayment>()
    @observable loading = false
    @observable deleting = false
    @observable deletingId: string | null = null
    @observable submitting = false
    @observable payment: IPayment | null = null
    @observable predicate = new Map()
    @observable lastRequestId = ''

    
    @computed get axiosParams () {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }
    
    @computed get paymentsRegistrySize() {
        return this.payments.size
    }
    
    @computed get paymentsArray() {
        return Array.from(this.payments.values())
    }

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }
    
    @action loadPayments = async () => {
        this.loading = true
        const requestId = uuid()
        this.lastRequestId = requestId
        try {
            const payments = await agent.Payments.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.payments.clear()
                    payments.forEach(payment => {
                        this.payments.set(payment.id, payment)
                    })   
                }
            })
        } catch (error) {
            console.log(error)
            this.payments.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loading = false   
                }
            })
        }
    }

    @action deletePayment = async (id: string) => {
        this.deleting = true
        this.deletingId = id
        try {
            await agent.Payments.delete(id)
            runInAction(() => {
                this.payments.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingId = null
            })
        }
    }
    
    @action createPayment = async (payment: PaymentFormValues) => {
        this.submitting = true
        try {
            await agent.Payments.create(payment)
            const paymentFromServer = await agent.Payments.details(payment.id)
            runInAction(() => {
                this.payments.set(paymentFromServer.id, paymentFromServer)                
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/payments`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editPayment = async (payment: PaymentFormValues) => {
        this.submitting = true
        try {
            await agent.Payments.edit(payment)
            const paymentFromServer = await agent.Payments.details(payment.id)
            runInAction(() => {
                this.payments.set(paymentFromServer.id, paymentFromServer)                
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/payments`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action getPaymentFromLocalOrNull = (id: string) => {
        return this.payments.get(id) ?? null
    }
    
    @action loadPayment = async (id: string) => {
        let payment: any = this.getPaymentFromLocalOrNull(id)
        if (payment === null) {
            this.loading = true
            try {
                payment = await agent.Payments.details(id)
                runInAction(() => {
                    this.payment = payment                    
                })
            } catch (error) {
                console.log(error)
            } finally {
                runInAction(() => {
                    this.loading = false
                })
            }
            
        }
        
        return payment
    }

    @action loadPaymentsToRegistry = (payments: IPayment[]) => {
        this.payments.clear()
        payments.forEach(payment => {
            this.payments.set(payment.id, payment)
        })
    }
}