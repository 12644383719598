import {IClient} from "./clients";
import moment from "moment";
import {IUser} from "./user";

export interface IClientActivityParticipant {
    id: string
    user: IUser
    isMain: boolean
}

export interface IClientActivity {
    id: string
    client: IClient
    date: Date
    type: string
    content: string
    contentUpdatedAt: Date
    hasDocument: boolean
    observation: string
    observationUpdatedAt: Date
    cost: number
    participants: IClientActivityParticipant[]
}

export interface IClientActivityFormValues {
    id: string
    clientId: string
    date: string
    type: string
    content: string
    hasDocument: boolean
    observation: string
    cost: number
    editedDocument: string
    participants: string[]
    mainParticipant: string | null
}

export class ClientActivityFormValues implements IClientActivityFormValues {
    clientId = ''
    content = ''
    cost = 0
    date = moment().format('YYYY-MM-DD')
    hasDocument = false
    id = ''
    observation = ''
    type = ''
    editedDocument = ''
    participants: string[] = []
    mainParticipant: string | null = null
    
    constructor(clientActivity?: IClientActivity) {
        if (clientActivity) {
            this.clientId = clientActivity.client.id!
            this.content = clientActivity.content    
            this.cost = clientActivity.cost
            this.date = moment(clientActivity.date).format('YYYY-MM-DD')
            this.hasDocument = clientActivity.hasDocument
            this.id = clientActivity.id
            this.observation = clientActivity.observation
            this.type = clientActivity.type
            this.participants = clientActivity.participants.map(p => p.user.id)
            this.mainParticipant = clientActivity.participants.filter(p => p.isMain)[0]?.user.id ?? null
        }
    }
}