import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {RouteComponentProps} from "react-router";
import LoadingComponent from "../../../core/layout/LoadingComponent";
import {Button, Checkbox, Grid, Header, Icon, Label, Message, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {convertStringToState} from "../../../core/common/util/draft-js";
import {Editor} from 'draft-js';
import BillPopup from "../../bills/components/BillPopup";
import ClientTableBasicCell from "../../clients/list/components/ClientTableBasicCell";
import {isClientOrderComplete} from "../../../core/models/clientsOrders";
import DateTimePickerWithShortcuts from "../../commom/date/DateTimePickerWithShortcuts";
import moment from "moment";
import ClientOrderBillDocumentDownloadButton from "../commom/ClientOrderBillDocumentDownloadButton";
import ClientOrderContentDocumentDownloadButton from "../commom/ClientOrderContentDocumentDownloadButton";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import styled from "styled-components";

interface DetailParams {
    id: string
}

const ClientOrdersDetails: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clientOrder,
        loadClientOrder,
        loading,
        editingId,
        setDelivered,
        setBillCreated,
        setCorrection,
        setPostExpress,
        setUrgent
    } = rootStore.clientOrdersStore
    const {currentTraffic} = rootStore.trafficsStore

    useEffect(() => {
        loadClientOrder(match.params.id)
    }, [
        loadClientOrder,
        match.params.id
    ])

    if (clientOrder === null || loading) return <LoadingComponent content='Učitavanje narudžbine...'/>

    return (<Grid centered>

            <Grid.Row>
                <Grid.Column width={16}>
                    <BreadcrumbNavigation items={[
                        {
                            text: 'Porudžbine',
                            linkWithoutCurrentTrafficId: 'clientOrders'
                        },
                        {
                            text: clientOrder.orderNumber,
                            active: true
                        }
                    ]}/>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column computer={12} mobile={16} tablet={16}>
                    <Wrapper $urgent={clientOrder.urgent}>
                        <Segment.Group>
                            <Segment>
                                <Label basic style={{border: '0px'}} size='big' attached='top right'>
                                    {clientOrder.correction &&
                                        <Icon size='big' color='red' name='pencil'/>}
                                    {clientOrder.postExpress &&
                                        <Icon size='big' color='yellow' name='mail'/>}
                                    {isClientOrderComplete(clientOrder) &&
                                        <Icon size='big' color='green' name='check'/>}
                                </Label>
                                <Header icon='users' as='h2' textAlign='center'>
                                    <Header.Content content={clientOrder.orderNumber}/>
                                    <Header.Subheader>
                                        <ClientTableBasicCell client={clientOrder.client}/>

                                        {clientOrder.bill &&
                                            <>Račun: <BillPopup
                                                labelProps={{
                                                    // basic: true,
                                                    color: 'blue',
                                                }}
                                                bill={clientOrder.bill}/></>}

                                        {clientOrder.hasBillDocument &&
                                            <ClientOrderBillDocumentDownloadButton clientOrder={clientOrder}/>}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                            <Segment clearing>
                                <Editor onChange={() => {
                                }} editorState={convertStringToState(clientOrder.text)} readOnly={true}/>

                                {clientOrder.hasBillDocument &&
                                    <ClientOrderContentDocumentDownloadButton clientOrder={clientOrder}/>}

                            </Segment>
                            {clientOrder.note !== '' &&
                                <Segment>
                                    <Message info>
                                        <Message.Header>Napomena</Message.Header>
                                        <p>{clientOrder.note}</p>
                                    </Message>
                                </Segment>}
                            <Segment>

                                <Grid>
                                    <Grid.Column width={16}>
                                        <label>Otpremljeno: </label>
                                        <DateTimePickerWithShortcuts
                                            placeholder='Datum otpreme'
                                            shortcuts={{
                                                today: true,
                                                tomorrow: true,
                                                yesterday: true
                                            }}
                                            date={clientOrder.deliveredDate}
                                            disabled={clientOrder.id === editingId}
                                            onChange={date => setDelivered(clientOrder?.id, date ? moment(date).format('YYYY-MM-DD') : '')}
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={16}>

                                        <Checkbox
                                            style={{margin: 5}}
                                            label='Napravljen račun'
                                            disabled={clientOrder.id === editingId}
                                            toggle
                                            checked={clientOrder.billCreated}
                                            onChange={(event, data) => setBillCreated(clientOrder.id, data.checked!)}
                                        />

                                        <Checkbox
                                            style={{margin: 5}}
                                            label='Korekcija'
                                            disabled={clientOrder.id === editingId}
                                            toggle
                                            checked={clientOrder.correction}
                                            onChange={(event, data) => setCorrection(clientOrder.id, data.checked!)}
                                        />

                                        {clientOrder.deliveredDate && <Checkbox
                                            style={{margin: 5}}
                                            label='Post express'
                                            disabled={clientOrder.id === editingId}
                                            toggle
                                            checked={clientOrder.postExpress}
                                            onChange={(event, data) => setPostExpress(clientOrder.id, data.checked!)}
                                        />}

                                        <Checkbox
                                            style={{margin: 5}}
                                            label='Hitno'
                                            disabled={clientOrder.id === editingId}
                                            toggle
                                            checked={clientOrder.urgent}
                                            onChange={(event, data) => setUrgent(clientOrder.id, data.checked!)}
                                        />
                                    </Grid.Column>
                                </Grid>

                            </Segment>
                            <Segment clearing>
                                <Button
                                    as={Link}
                                    icon='edit'
                                    to={`/${currentTraffic?.id}/clientOrders/manage/${clientOrder.id}/edit`}
                                    floated='right'
                                    content='Izmeni'
                                    color='orange'
                                />
                            </Segment>
                        </Segment.Group>
                    </Wrapper>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export const Wrapper = styled.div<{ $urgent?: boolean }>`
  border: ${(props) => props.$urgent ? "3px solid red" : undefined};
  border-radius: ${(props) => props.$urgent ? "5px" : undefined};
`;

export default observer(ClientOrdersDetails)
