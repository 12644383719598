import React, { FunctionComponent } from 'react';
import {IClient} from "../../../../core/models/clients";

interface IProps {
    client: IClient
}

const ClientTableContactCell: FunctionComponent<IProps> = ({ client: { contactInfo, email, telNumber } }) => {
  
  return (
      <div>
          <div>{contactInfo}</div>
          {email &&
          <a href={`mailto:${email}`}>{email}</a>}
          <div>{telNumber}</div>
      </div>
  );
};

export default ClientTableContactCell;
