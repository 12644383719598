import React, {FunctionComponent} from 'react';
import {Header} from "semantic-ui-react";
import {getNiceColorFromStingAsciiSum} from "../../../../core/common/util/colors";

interface IProps {
    brandName: string
    productName: string
    productSku: string,
    brandColor?: string
}

const BillCartOrdersListProductInfo: FunctionComponent<IProps> = ({
                                                                      brandName,
                                                                      productName,
                                                                      productSku,
                                                                      brandColor
                                                                  }) => {

    return (
        <Header style={{color: brandColor ?? getNiceColorFromStingAsciiSum(brandName)}}  as='h4'>
            <Header.Subheader>
                {productSku}
            </Header.Subheader>
            {brandName}
            <Header.Subheader>
                <Header as='h4'>
                    {productName}
                </Header>
            </Header.Subheader>
        </Header>
    );
};

export default BillCartOrdersListProductInfo;
