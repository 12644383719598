import React, {FunctionComponent, useContext} from 'react';
import {IUser} from "../../../core/models/user";
import {Checkbox, List} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";

interface IProps {
    user: IUser
}

const UsersTableRolesCell: FunctionComponent<IProps> = ({user}) => {
    const rootStore = useContext(RootStoreContext)
    const { roles, addRole, removeRole } = rootStore.usersStore
    const { user: currentUser } = rootStore.userStore
    
    return (<List>
            {roles!.map(role => (<List.Item key={role}>
                    <Checkbox
                        disabled={currentUser?.id === user.id && role === 'SuperAdministrator'}
                        toggle
                        label={role}
                        checked={user.roles.includes(role)}
                        onChange={((event, data) => data.checked ? addRole(user.id, role) : removeRole(user.id, role) )}
                    />
                    
                </List.Item>
            ))}
        </List>

    );
};

export default observer(UsersTableRolesCell)
