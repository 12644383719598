import React, {FunctionComponent, useContext, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import Table from "../../../core/common/tables/Table";
import {ITraffic} from "../../../core/models/traffics";
import {Cell} from "react-table";
import TrafficBasicCell from "./TrafficBasicCell";
import {Button, Header, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";

interface IProps {
}

const TrafficsList: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {trafficsArray: traffics, currentTraffic, deletingId, deleteTraffic, defaulting, makeDefault} = rootStore.trafficsStore
    const { openModal, closeModal } = rootStore.modalStore

    const columns = useMemo(() => [
        {
            Header: 'Naziv',
            accessor: 'name',
            Cell: ({row: { original: traffic }}: Cell<ITraffic>) => <TrafficBasicCell traffic={traffic} />
        },
        {
            Header: 'Prefiks šifre računa',
            accessor: 'billIdStringPrefix'
        },
        {
            Header: 'Podrazumevani',
            // accessor: 'isDefault',
            Cell: ({row: { original: traffic }}: Cell<ITraffic>) => traffic.isDefault ?
                <></> :
                <Button 
                disabled={defaulting} 
                content='Postavi za podrazumevani'
                onClick={() => makeDefault(traffic.id)}  />
        },
        {
            Header: 'Kontrole',
            Cell: ({row: {original: traffic}}: Cell<ITraffic>) => (
                <Button.Group>
                    <Button as={Link} to={`/${currentTraffic?.id}/traffics/manage/${traffic.id}/edit`} icon='edit'
                            color='yellow'/>
                    <Button disabled={traffic.isDefault} onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje prometa
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš promet <strong>{traffic.name} ?</strong>
                            </p>
                            <p style={{textAlign: 'center'}}>
                                <strong>Brisanjem prometa biće obrisani svi povezani računi</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any) => {
                                event.target.className += ' loading'
                                deleteTraffic(traffic.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={deletingId === traffic.id}/>
                </Button.Group>)
        }
        
    ], [
        openModal, closeModal, deleteTraffic, deletingId, currentTraffic, defaulting, makeDefault
    ])
    
    return (<Table
        data={traffics}
        columns={columns}/>)
}

export default observer(TrafficsList)
