import React, {FunctionComponent, useContext, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import Table from "../../../core/common/tables/Table";
import {Cell} from "react-table";
import {Button, Header, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {IRegion} from "../../../core/models/region";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";

interface IProps {
}

const RegionsList: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {regionsArray: regions, deletingId, deleteRegion} = rootStore.regionsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {currentTraffic} = rootStore.trafficsStore
    const { isSuperAdmin } = rootStore.userStore

    const columns = useMemo(() => [
        {
            Header: 'Naziv',
            accessor: 'name',
            Cell: ({row: {original: region}}: Cell<IRegion>) => <Header as={Link}
                                                                        to={`/${currentTraffic?.id}/regions/${region.id}`}
                                                                        content={region.name}/>
        },
        {
            Header: 'Broj klijenata',
            accessor: 'clientsCount',
            Filter: NumberFilter,
            filter: 'numberFilter'
        },
        {
            id: 'controls',
            hide: !isSuperAdmin,
            Header: 'Kontrole',
            Cell: ({row: {original: region}}: Cell<IRegion>) => (
                <Button.Group>
                    <Button as={Link} to={`/${currentTraffic?.id}/regions/manage/${region.id}/edit`} icon='edit'
                            color='yellow'/>
                    <Button
                        disabled={region.isDefault}
                        onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje regiona
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš region <strong>{region.name} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any) => {
                                    event.target.className += ' loading'
                                    deleteRegion(region.id!)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={deletingId === region.id}/>
                </Button.Group>)
        }
    ], [
        openModal,
        closeModal,
        currentTraffic,
        deleteRegion,
        deletingId,
        isSuperAdmin
    ])

    return (
        <Table
            data={regions}
            columns={columns}
        />
    )
}

export default observer(RegionsList)
