import React, {FunctionComponent, useContext} from 'react';
import {Checkbox, Dropdown, Label} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import moment from "moment";
import {DatePicker} from "antd";
import styled from "styled-components";

interface IProps {
}

const ProfitabilityFilters: FunctionComponent<IProps> = () => {
    const rootStore = useContext(RootStoreContext)
    const {
        startDateFilter,
        endDateFilter,
        manualBillsFilter, setManualBillsFilterAndUpdate,
        trafficFilter, setTrafficFilterAndUpdate,
        setDateRangeFilter
    } = rootStore.profitabilityStore
    const {trafficsArray: traffics} = rootStore.trafficsStore

    return (<Flex>
        <Left>
            <DatePicker.RangePicker
                // disabled={allLoading}
                placeholder={['Početni datum', 'Krajni datum']}
                format='DD.MM.YYYY.'
                onChange={(date, dateString) =>
                    setDateRangeFilter(date && date[0] ? moment(date[0]).format('YYYY-MM-DD') : '',
                        date && date[1] ? moment(date[1]).format('YYYY-MM-DD') : '',
                        true)}
                value={[startDateFilter ? moment(startDateFilter) : null, endDateFilter ? moment(endDateFilter) : null]}
            />
        </Left>

        <Left>
            <Checkbox
                toggle
                label='Ručni računi'
                checked={manualBillsFilter}
                onChange={((event, data) => setManualBillsFilterAndUpdate(data.checked!))}
            />
        </Left>

        <Left>
            <Dropdown
                // disabled={allLoading}
                clearable
                placeholder='Promet'
                multiple
                selection
                value={trafficFilter}
                onChange={(event, data) => setTrafficFilterAndUpdate(data.value as number[])}
                renderLabel={item => <Label content={item.text} color={item.isExpense ? 'red' : 'green'}/>}
                options={traffics?.map(traffic => ({
                    key: traffic.id!,
                    value: traffic.id!,
                    text: traffic.name,
                    isExpense: traffic.isExpense
                }))}/>
        </Left>
    </Flex>)


    // return <>
    //     <Responsive.NotMobile>
    //         {menu()}
    //     </Responsive.NotMobile>
    //     <Responsive.Mobile>
    //         {menu(true)}
    //     </Responsive.Mobile>
    // </>
};

export default observer(ProfitabilityFilters)

const Flex = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex-grow: 0;
  align-self: center;
`;

// const Right = styled.div`
//   margin-left: auto;
//   align-self: center;
// `;