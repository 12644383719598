import React, {FunctionComponent, useContext} from 'react';
import {Menu} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";

interface IProps {
}

const ClientsDashboardMenu: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const { currentTraffic } = rootStore.trafficsStore

    return (<Menu>
        <Menu.Item
            as={NavLink}
            exact
            to={`/${currentTraffic?.id}/clients`}>
            Klijenti
        </Menu.Item>

        <Menu.Item
            as={NavLink}
            exact
            to={`/${currentTraffic?.id}/clients/employees`}>
            Zaposleni
        </Menu.Item>

    </Menu>);
};

export default observer(ClientsDashboardMenu)
