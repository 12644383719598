import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import Table from "../../../core/common/tables/Table";
import {Cell, FilterProps} from "react-table";
import ProductsTableBasicInfoCell from "../../products/list/ProductsTableBasicInfoCell";
import {IOnHoldProduct} from "../../../core/models/onHoldProduct";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {Button, Header, Icon, Message, Segment, Statistic} from "semantic-ui-react";
import ClientTableBasicCell from "../../clients/list/components/ClientTableBasicCell";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import moment from "moment";
import ClientDetailedFilter from "../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import {DateFilter} from "../../../core/common/tables/filters/date/DateFilter";
import {ProductsDetailedFilter} from "../../../core/common/tables/filters/products/detailed/ProductsDetailedFilter";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";

interface IProps {
    hideClient?: boolean,
    trackingId?: string
}

const OnHoldProductsList: FunctionComponent<IProps> = ({hideClient, trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        onHoldProductsArray: onHoldProducts,
        deletingId,
        deleting,
        deleteOnHoldProduct
    } = rootStore.onHoldProductsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {closeModal, openModal} = rootStore.modalStore
    const {brandsArray: brands, loadBrandsIfNull} = rootStore.brandsStore
    const { isEmployee } = rootStore.userStore

    useEffect(() => {
        loadBrandsIfNull()
    }, [
        loadBrandsIfNull
    ])


    const columns = useMemo(() => [
        {
            id: 'product',
            Header: 'Proizvod',
            accessor: 'name',
            Cell: ({row: {original: onHoldProduct}}: Cell<IOnHoldProduct>) => onHoldProduct.isOther ?
                <Header>
                    <Header.Subheader>
                        Nestandardni proizvod
                    </Header.Subheader>
                    <Header.Content>
                        {onHoldProduct.otherContent}    
                    </Header.Content>
                    </Header> :
                (<ProductsTableBasicInfoCell product={onHoldProduct.product!}/>),
            Filter: (d: FilterProps<any>) => ProductsDetailedFilter(d, brands),
            filter: 'productsDetailedFilter'
        },
        {
            Header: 'Klijent',
            Cell: ({row: {original: onHoldProduct}}: Cell<IOnHoldProduct>) => (
                <ClientTableBasicCell client={onHoldProduct.client}/>),
            accessor: 'client',
            hide: hideClient,
            Filter: ClientDetailedFilter,
            filter: 'clientDetailedFilter'
        },
        {
            Header: 'Količina',
            accessor: (d: IOnHoldProduct) => d.count,
            Cell: ({row: {original: onHoldProduct}}: Cell<IOnHoldProduct>) => (
                <Statistic content={onHoldProduct.count}/>),
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            id: 'date',
            Header: 'Datum',
            accessor: (d: IOnHoldProduct) => d.date,
            Cell: ({row: {original: onHoldProduct}}: Cell<IOnHoldProduct>) => (<span
                    style={{
                        color: moment(onHoldProduct.date).isBefore(new Date(), 'day') ?
                                'red' :
                                moment(onHoldProduct.date).isSame(new Date(), 'day') ?
                                    'orange' : undefined
                    }}
                >
                {moment(onHoldProduct.date).format('l')}</span>
        ),
            Filter: DateFilter,
            filter: 'dateFilter'
        },
        {
            Header: 'Napomena',
            accessor: 'note'
        },
        {
            id: 'controls',
            hide: !isEmployee,
            Header: 'Kontrole',
            Cell: ({row: {original: onHoldProduct}}: Cell<IOnHoldProduct>) => (
                <Button.Group>
                    <Button as={Link} to={`/${currentTraffic?.id}/onHoldProducts/manage/${onHoldProduct.id}/edit`}
                            icon='edit' color='yellow'/>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje proizvoda na čekanju
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš proizvod na
                                čeknaju <strong>{onHoldProduct.product!.name} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any, data) => {
                                event.target.className += ' loading'
                                deleteOnHoldProduct(onHoldProduct.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={(deletingId === onHoldProduct.id) && deleting}/>
                </Button.Group>)
        }

    ], [
        openModal,
        closeModal,
        deleteOnHoldProduct,
        deleting,
        deletingId,
        currentTraffic,
        hideClient,
        brands,
        isEmployee
    ])

    if (onHoldProducts.length === 0) return <div style={{marginTop: 50}}>
        <Message warning>
            <Message.Header>Ne postoje proizvodi na čekanju po zadatom kriterijumu</Message.Header>
            <p>Pokušaj da osvežiš stranicu.</p>
        </Message>
    </div>

    return (<Table
        trackingId={trackingId}
        columns={columns}
        data={onHoldProducts}/>)
}

export default observer(OnHoldProductsList)
