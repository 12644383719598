import moment from "moment";

export interface IGeneralExpense {
    id: string
    name: string
    category: string
    note: string
    value: number
    date: Date
}

export interface IGeneralExpenseFormValues {
    id: string
    name: string
    category: string
    note: string
    value: string
    date: string
}

export class GeneralExpense implements IGeneralExpense {
    category: string = ''
    date: Date = new Date()
    id: string = ''
    name: string = ''
    note: string = ''
    value: number = NaN
    
    constructor(generalExpenseFormValues?: IGeneralExpenseFormValues) {
        if (generalExpenseFormValues) {
            Object.assign(this, generalExpenseFormValues)
            this.date = new Date(generalExpenseFormValues.date)
            
        }
    }
}

export class GeneralExpenseFormValues implements IGeneralExpenseFormValues {
    category: string = ''
    date: string = moment().format('YYYY-MM-DD')
    id: string = ''
    name: string = ''
    note: string = ''
    value: string = ''
    
    constructor(generalExpense?: IGeneralExpense) {
        if (generalExpense) {
            this.category = generalExpense.category
            this.date = moment(generalExpense.date).format('YYYY-MM-DD')
            this.id = generalExpense.id
            this.name = generalExpense.name
            this.note = generalExpense.note
            this.value = generalExpense.value.toString()
        }
    }
}