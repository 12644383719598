import React, {useContext, useEffect, useState} from 'react';
import ClientsReceivablesList from "../../clients/list/components/ClientsReceivablesList";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";
import {Grid, Segment} from "semantic-ui-react";
import moment from "moment";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {ReceivablesHeader} from "./components/ReceivablesHeader";

export const RECEIVABLES_TRACKING_ID = 'receivablesList'

export const receivablesDefaultDateRange = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end: moment().endOf('year').format('YYYY-MM-DD')
}


const ReceivablesDashboard = () => {
    const rootStore = useContext(RootStoreContext)
    const {
        clearPredicate,
        setPredicate,
        loadClients,
        loading,
    } = rootStore.clientsStore
    const {removeFromRegistry} = rootStore.tablesStore
    const [withInitialState, setWithInitialState] = useState(true)

    useNavbarSelectedItem('receivables')

    useEffect(() => {
        clearPredicate()
        setPredicate('withFinancialState', 'true')
        setPredicate('startDate', receivablesDefaultDateRange.start)
        setPredicate('endDate', receivablesDefaultDateRange.end)
        loadClients()


        return () => {
            clearPredicate()
            removeFromRegistry(RECEIVABLES_TRACKING_ID)
        }
    }, [clearPredicate, setPredicate, removeFromRegistry, loadClients])


    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Potraživanja',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Segment.Group>
                    <ReceivablesHeader withInitialState={withInitialState} setWithInitialState={setWithInitialState}/>
                    <Segment attached='bottom' loading={loading}>
                        <ClientsReceivablesList withInitialState={withInitialState} trackingId={RECEIVABLES_TRACKING_ID}/>
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid.Row>
    </Grid>);
};

export default observer(ReceivablesDashboard)
