import React, {FunctionComponent} from 'react';
import GlobalSettingsList from "../list/GlobalSettingsList";
import {Grid, Segment} from "semantic-ui-react";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface IProps {
}

const GlobalSettingsDashboard: FunctionComponent<IProps> = (props) => {

    return (<Grid centered>
        <Grid.Column width={16}>
            <BreadcrumbNavigation
                items={[
                    {
                        text: 'Globalna podešavanja',
                        active: true
                    }
                ]}
            />
        </Grid.Column>
        <Grid.Column computer={8} tablet={10} mobile={16}>
            <Segment>
                <GlobalSettingsList/>
            </Segment>
        </Grid.Column>
    </Grid>);
};

export default GlobalSettingsDashboard;
