import { FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive'


interface IProps {
    children: any
}

export const useTablet = () => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })

    return isTablet
}

const Tablet: FunctionComponent<IProps> = ({children}) => {
    const isTablet = useTablet()
    
    return isTablet ? children : null
};

export default Tablet;
