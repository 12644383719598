import React, {FunctionComponent, useContext, useEffect} from 'react';
import ClientsList from './../list/ClientsList'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {Button, Grid, Icon, Segment, Select} from "semantic-ui-react";
import {generateYearsOptions} from "../../../core/common/util/options";
import {Link} from "react-router-dom";
import autoTable from "jspdf-autotable";
import {IClient} from "../../../core/models/clients";
import {formatRsd} from "../../../core/common/util/format";
import {PdfService} from "../../../core/common/pdf/PdfService";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

const TRACKING_ID = 'clientsList'

interface IProps {
}

const ClientsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clearPredicate,
        setPredicate,
        loadClients,
        setYear, year,
        loading: loadingClients,
    } = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    useNavbarSelectedItem('clients')

    useEffect(() => {
        clearPredicate()
        setPredicate('withFinancialState', 'true')
        setPredicate('year', year)
        loadClients()

        return () => {
            clearPredicate()
            removeFromRegistry(TRACKING_ID)
        }

    }, [clearPredicate, setPredicate, loadClients, year, removeFromRegistry])

    const handleYearChange = (event: any, data: any) => {
        const {value} = data
        setYear(value)
        setPredicate('year', value)
        loadClients()
    }

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        const clientPropertiesCell = (client: IClient) => {
            let text = `${client.name}, ${client.category},${client.type}, ${client.regionName}`

            if (client.type === 'Aktivan') {
                text = `${text}, ${client.status}`
            }

            return text
        }

        doc.text(`Tabela klijenata sa saldom i ostvarenjem za ${year}. godinu`, 25, 50)
        doc.setFont('DVSans')
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            // willDrawCell: (data => {
            //     // if (data.row.section === 'body' && data.column.dataKey === 1) {
            //         // something
            //     // }
            // }),
            // didDrawCell: data => {
            //     // something
            // },
            columnStyles: {
                1: {cellWidth: 200},
                4: {cellWidth: 100},
                5: {cellWidth: 100}
            },
            startY: 75,
            head: [
                ['#', 'Naziv', 'Agent', 'Napomena', 'Saldo', 'Ostvarenje']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((client: IClient, index) => ([
                index + 1,
                clientPropertiesCell(client),
                client.agent?.displayName ?? 'N/A',
                client.privateNote,
                formatRsd(client.financialState?.balance!),
                `${formatRsd(client.financialState?.placedBaseValue!)} / ${formatRsd(client.financialState?.scheduleValue!)}`
            ])) ?? []
        })

        doc.save("Klijenti.pdf")
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <BreadcrumbNavigation
                        items={[
                            {
                                text: 'Klijenti',
                                active: true
                            }
                        ]}
                    />

                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/clients/create`}
                        floated='right'
                        color='blue'
                        icon
                        labelPosition='left'>
                        <Icon name='add'/>
                        Dodaj klijenta
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment.Group>
                        {/*<Segment style={{padding: 0}} attached='top' color='blue'>*/}
                        {/*    <ClientsDashboardMenu/>*/}
                        {/*</Segment>*/}
                        <Segment attached clearing>
                            <Select
                                name='year'
                                placeholder='Izaberi godinu'
                                options={generateYearsOptions(2010, 2050)}
                                onChange={handleYearChange}
                                value={year}
                            />

                            <Button
                                disabled={loadingClients}
                                icon
                                labelPosition='left'
                                floated='right'
                                color='teal'
                                onClick={() => exportPdf()}>
                                <Icon name='file pdf'/>
                                PDF
                            </Button>

                        </Segment>
                        <Segment attached='bottom' loading={loadingClients}>
                            <ClientsList trackingId={TRACKING_ID}/>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default observer(ClientsDashboard)
