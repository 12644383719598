import React, {FunctionComponent, useContext} from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router-dom';
import {RootStoreContext} from "../../stores/rootStore";
import {observer} from "mobx-react-lite";

interface IProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>>
}

const AnyNonClientRoleRoute: FunctionComponent<IProps> = ({component: Component, ...rest}) => {
    const rootStore = useContext(RootStoreContext)
    const { isLoggedIn, isEmployee, isTrafficManager, isSuperAdmin } = rootStore.userStore
        
  return (
      <Route 
          { ...rest }
          render={(props) => isLoggedIn && (isEmployee || isTrafficManager || isSuperAdmin) ? <Component { ...props } /> : <Redirect to='/' />}
      />
  )
}

export default observer(AnyNonClientRoleRoute)
