import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Button, Grid, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import PricelistsList from "../list/PricelistsList";
import {Link} from "react-router-dom";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface IProps {
}

const PricelistsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loadPricelists, loading} = rootStore.pricelistsStore
    const {currentTraffic} = rootStore.trafficsStore

    useNavbarSelectedItem('pricelists')

    useEffect(() => {
        loadPricelists()
    }, [loadPricelists])

    return <Grid>
        <Grid.Row>
            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Cenovnici',
                            active: true
                        }
                    ]}
                />
                <Button
                    floated='right'
                    color='teal'
                    as={Link}
                    icon='add'
                    content='Dodaj cenovnik'
                    to={`/${currentTraffic?.id}/pricelists/create`}/>
            </Grid.Column>
        </Grid.Row>
        
        <Grid.Row>
            <Grid.Column width={16}>
                <Segment
                    clearing
                    loading={loading}>
                    <PricelistsList/>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>


};

export default observer(PricelistsDashboard)
