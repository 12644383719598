import {IUser} from "./user";
import {IClient} from "./clients";
import moment from "moment"
import {ICloudFile} from "./cloudFiles";

export enum ClientGoalStatus {
    inProgress = 0,
    partlyMet = 1,
    met = 2,
    failed = 4
}

export enum ClientGoalType {
    classic = 0,
    payment = 1,
    clientRequest = 2
}

export interface IClientGoalCategoryWithProperties {
    name: string,
    type: ClientGoalType,
    status: ClientGoalStatus
}

export const clientGoalStatusToString = (clientGoal: IClientGoal) => {
    switch (clientGoal.status) {
        case ClientGoalStatus.inProgress:
            return 'Aktivan'
        case ClientGoalStatus.partlyMet:
            return 'Delimično ispunjen'
        case ClientGoalStatus.met:
            return 'Ispunjen'
        case ClientGoalStatus.failed:
            return 'Neispunjen'
    }
}

export interface IClientGoalParticipant {
    id: string
    user: IUser
    isMain: boolean
}

export interface IClientGoalComment {
    id: string
    clientGoalId: string
    clientGoalName: string
    clientGoalColor: 'red' | 'orange' | 'blue'
    clientGoalType: ClientGoalType
    client: IClient
    content: string
    user: IUser
    createdAt: Date
    updatedAt: Date
}

export interface IClientGoalDocument {
    id: string
    clientGoalId: string
    name: string
    file?: ICloudFile
}

export interface IClientGoalDocumentFormValues {
    id: string
    clientGoalId: string
    name: string
    file: Blob | null
}

export interface IClientGoal {
    id: string
    type: ClientGoalType
    client: IClient
    category: string
    name: string
    status: ClientGoalStatus
    color: 'red' | 'blue' | 'orange'
    statusNote: string
    date: Date | null
    completedDate: Date | null
    comments: IClientGoalComment[]
    participants: IClientGoalParticipant[]
    documents: IClientGoalDocument[]
    createdAt: Date
    updatedAt: Date

    flags?: {
        updating: boolean,
        uploading: boolean,
        deleting: boolean
    }
}

export class ClientGoal implements IClientGoal {
    category: string;
    type: ClientGoalType
    client: IClient;
    color: "red" | "blue" | "orange"
    status: ClientGoalStatus
    statusNote: string
    comments: IClientGoalComment[];
    completedDate: Date | null;
    createdAt: Date;
    date: Date | null;
    documents: IClientGoalDocument[];
    flags?: { updating: boolean; uploading: boolean; deleting: boolean };
    id: string;
    name: string;
    participants: IClientGoalParticipant[];
    updatedAt: Date;

    constructor(clientGoal: IClientGoal) {
        this.id = clientGoal.id
        this.type = clientGoal.type
        this.name = clientGoal.name
        this.category = clientGoal.category
        this.color = clientGoal.color
        this.statusNote = clientGoal.statusNote ?? ''
        this.date = clientGoal.date
        this.client = clientGoal.client
        this.completedDate = clientGoal.completedDate
        this.createdAt = clientGoal.createdAt
        this.updatedAt = clientGoal.updatedAt
        this.status = clientGoal.status
        if (clientGoal.flags) {
            this.flags = {
                deleting: clientGoal.flags.deleting,
                updating: clientGoal.flags.updating,
                uploading: clientGoal.flags.uploading
            }
        } else {
            this.flags = {
                deleting: false,
                updating: false,
                uploading: false
            }
        }


        this.comments = clientGoal.comments
        this.documents = clientGoal.documents
        this.participants = clientGoal.participants
    }
}

export interface IClientGoalFormValues {
    id: string
    type: ClientGoalType
    clientId: string
    category: string
    name: string
    color: 'red' | 'blue' | 'orange'
    statusNote: string
    date: string | null
    completedDate: string | null

    participants: string[]
    mainParticipant: string | null

    initialCommentContent: string
}

export class ClientGoalFormValues implements IClientGoalFormValues {
    clientId = ''
    type: ClientGoalType = ClientGoalType.classic
    category = ''
    color: 'red' | 'blue' | 'orange' = 'blue'
    statusNote: string = ''
    date = moment().format('YYYY-MM-DD')
    id = ''
    name = ''
    completedDate = ''

    participants: string[] = []
    mainParticipant: string | null = null

    initialCommentContent = ''

    constructor(clientGoal?: IClientGoal) {
        if (clientGoal) {
            this.type = clientGoal.type
            this.clientId = clientGoal.client.id!
            this.category = clientGoal.category
            this.color = clientGoal.color
            this.statusNote = clientGoal.statusNote ?? ''
            this.date = clientGoal.date ? moment(clientGoal.date).format('YYYY-MM-DD') : ''
            this.id = clientGoal.id
            this.name = clientGoal.name
            this.completedDate = clientGoal.completedDate ? moment(clientGoal.completedDate).format('YYYY-MM-DD') : ''
            this.participants = clientGoal.participants.map(p => p.user.id)
            this.mainParticipant = clientGoal.participants.filter(p => p.isMain)[0]?.user.id ?? null
        }
    }

}

export interface IClientGoalFilter {
    name: string,
    colors: string[],
    categories: string[],
    // met: 'all' | 'met' | 'notMetYet' | 'expired'
}

export class ClientGoalFilter implements IClientGoalFilter {
    // met: "all" | "met" | "notMetYet" | "expired" = 'all'
    categories: string[] = []
    colors: string[] = []
    name: string = ''

}

export const getClientGoalColorSerbianText = (color: 'red' | 'blue' | 'orange') => {
    switch (color) {
        case 'red':
            return 'Prioritetan'
        case 'orange':
            return 'U planu'
        case 'blue':
            return 'Zakazano/Poslato'

    }
}