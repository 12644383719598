import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import {Button, Header, Icon, Item, Message, Segment} from "semantic-ui-react";
import Table from "../../../core/common/tables/Table";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IClientActivity} from "../../../core/models/clientActivities";
import {Cell, FilterProps} from "react-table";
import {replaceLatinCharacters, stringPreview} from "../../../core/common/util/string";
import {convertStringToPlainText} from "../../../core/common/util/draft-js";
import moment from "moment";
import {formatRsd} from "../../../core/common/util/format";
import {Link} from "react-router-dom";
import ClientDetailedCell from "../../clients/list/components/ClientDetailedCell";
import ClientDetailedFilter from "../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import {DateFilter, IDateFilter} from "../../../core/common/tables/filters/date/DateFilter";
import SumTableHeader from "../../../core/common/tables/headers/SumTableHeader";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";
import MultiselectFilter from "../../../core/common/tables/filters/multiselect/MultiselectFilter";
import {clientActivitiesOptions} from "../../../core/common/options/clientActivities";
import ClientActivityParticipantsIdentity from "../commom/ClientActivityParticipantsIdentity";

interface IProps {
    hideClient?: boolean,
    trackingId?: string
}

const ClientActivitiesList: FunctionComponent<IProps> = ({ hideClient, trackingId }) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clientActivitiesArray: clientActivities,
        loadingId,
        loadClientActivityDoc,
        deletingId,
        deleteClientActivity
    } = rootStore.clientActivitiesStore
    const {currentTraffic} = rootStore.trafficsStore
    const {openModal, closeModal} = rootStore.modalStore
    const { isEmployee } = rootStore.userStore
    const { loadUsers, employeesArray: employees } = rootStore.usersStore

    useEffect(() => {
        loadUsers(false)
    }, [
        loadUsers
    ])

    const columns = useMemo(() => [
        {
                Header: 'Tip',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) =>
                    <Item.Group>
                        <Item>
                            <Item.Image
                                size='tiny'
                                avatar
                                src={`/assets/clientActivitiesTypesImages/${replaceLatinCharacters(clientActivity.type)}_m.jpg`} />
                            <Item.Content verticalAlign='middle'> 
                                <Header 
                                    as={Link}
                                    to={`/${currentTraffic?.id}/clientActivities/${clientActivity.id}`}
                                    content={clientActivity.type} />
                            </Item.Content>
                        </Item>
                    </Item.Group>,
                accessor: 'type',
            Filter: (d: FilterProps<any>) => MultiselectFilter(d, clientActivitiesOptions.map(option => ({key: option.key, value: option.value, text: option.text})), 'Tip'),
            filter: 'multiselectFilter'
            },
            {
                accessor: (d: IClientActivity) => d.participants.map(participant => participant.user.id),
                Header: 'Učesnici',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) =>
                    <ClientActivityParticipantsIdentity clientActivity={clientActivity}/>,
                Filter: (d: FilterProps<any>) => MultiselectFilter(d, employees.map(user => ({
                    key: user.id,
                    value: user.id,
                    text: `${user.displayName} [${user.email}]`
                })), 'Učesnik'),
                filter: 'multiselectFilter',
                disableSortBy: true
            },        
            {
                id: 'date',
                Header: 'Datum',
                accessor: 'date',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) => <>{moment(clientActivity.date).format('l')}</>,
                Filter: (d: FilterProps<IDateFilter>) =>  DateFilter(d, '2021-01-01', '2021-12-31'),
                filter: 'dateFilter'
            },
            {
                id: 'client',
                Header: 'Klijent',
                accessor: (d: IClientActivity) => d.client.name,
                hide: hideClient,
                Filter: ClientDetailedFilter,
                filter: 'clientDetailedFilter',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) => <ClientDetailedCell withRealization client={clientActivity.client}/>                
            },
            {
                Header: 'Sadržaj',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) =>
                    <span>{stringPreview(convertStringToPlainText(clientActivity.content), 20)}</span>
            },
            {
                Header: 'Zapažanje',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) =>
                    <span>{stringPreview(clientActivity.observation, 20)}</span>,
                accessor: 'observation'
            },
            {
                Header: (row: any) => <SumTableHeader row={row} type='money' name='Trošak' accessor='cost' />,
                accessor: 'cost',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) =>
                    <>{formatRsd(clientActivity.cost)}</>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                Header: 'Dokument',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) => <>
                    {clientActivity.hasDocument &&
                    <Button
                        icon
                        labelPosition='left'
                        loading={loadingId === clientActivity.id}
                        color='blue'
                        onClick={() => loadClientActivityDoc(clientActivity.id)}
                    >
                        <Icon name='file'/>
                        Dokument
                    </Button>}
                </>
            },
            {
                id: 'controls',
                hide: !isEmployee,
                Header: 'Kontrola',
                Cell: ({row: {original: clientActivity}}: Cell<IClientActivity>) => (
                    <Button.Group fluid>
                        <Button
                            color='blue'
                            icon as={Link}
                            to={`/${currentTraffic?.id}/clientActivities/${clientActivity.id}`}>
                            <Icon name='eye'/>
                        </Button>
                        <Button as={Link} to={`/${currentTraffic?.id}/clientActivities/manage/${clientActivity.id}/edit`}
                                color='teal'
                                icon>
                            <Icon name='edit'/>
                        </Button>
                        <Button onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje aktivnosti
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš aktivnost
                                    klijenta <strong>{clientActivity.client.name} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any) => {
                                    event.target.className += ' loading'
                                    deleteClientActivity(clientActivity.id)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={deletingId === clientActivity.id}/>
                    </Button.Group>
                )
            }

        ],
        [
            loadClientActivityDoc, 
            loadingId, 
            deletingId, 
            deleteClientActivity, 
            closeModal, 
            currentTraffic, 
            openModal,
            hideClient,
            isEmployee,
            employees
        ])

    if (clientActivities.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje aktivnosti po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>
    
    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={clientActivities}
        />
    )
}

export default observer(ClientActivitiesList)
