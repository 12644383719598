import React, {FunctionComponent, useContext} from 'react';
import MyPieChart from "../../../../../core/common/charts/MyPieChart";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../core/stores/rootStore";
import {formatRsd} from "../../../../../core/common/util/format";
import {summator} from "../../../../../core/common/util/array";
import {PROFITABILITY_EXPENSE_TRACKING_ID} from "../../expense/ProfitabilityDashboardExpenseSection";


interface IProps {
}

const ProfitabilityDashboardExpenseDiagram: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {SHIT_getFilteredRowsById} = rootStore.tablesStore

    const filteredRows = SHIT_getFilteredRowsById(PROFITABILITY_EXPENSE_TRACKING_ID) ?? []
    
        
    return (<>
        <MyPieChart
            data={filteredRows}
            colors={filteredRows.map(row => row.color)}
            xAccessor='name'
            yAccessor='cost'
            title={[
                'Rashodi',
                formatRsd(summator(filteredRows, 'cost'))]}/>
    </>);
};

export default observer(ProfitabilityDashboardExpenseDiagram)
