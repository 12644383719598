import React, {FunctionComponent, useContext} from 'react';
import {IClient} from "../../../../core/models/clients";
import {Header} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../core/stores/rootStore";

interface IProps {
    client: IClient,
    hideRegion?: boolean
}

const ClientTableLocationCell: FunctionComponent<IProps> = ({ 
                                                                client: { 
                                                                    address, 
                                                                    city, 
                                                                    postalCode, 
                                                                    regionName, 
                                                                    regionId 
                                                                }, hideRegion }) => {
  
    const rootStore = useContext(RootStoreContext)
    const { currentTraffic } = rootStore.trafficsStore
    
  return (
      <Header as='h4' image>
          <Header.Content>
              {city}{!hideRegion && <span>, <Link to={`/${currentTraffic?.id}/regions/${regionId}`}>{regionName}</Link></span>}  
              <Header.Subheader>{postalCode}</Header.Subheader>
              <Header.Subheader>{address}</Header.Subheader>
          </Header.Content>
      </Header>
  );
};

export default observer(ClientTableLocationCell)
