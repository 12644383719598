import React, {FC} from "react";
import PeriodProfitabilityList from "../../lists/PeriodProfitabilityList";
import {Header, Label} from "semantic-ui-react";
import {ProfitabilityPeriodTimeDiagram} from "../diagrams/periods/ProfitabilityPeriodTimeDiagram";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useProfitabilityPeriods} from "../hooks/useProfitabilityPeriods";

export type LabelType = 'profit' | 'expense' | 'income' | 'ruc'
export const profitabilityPeriodsTrackingId = 'profitabilityPeriodsTrackingId'

export interface IProfitabilityLabels {
    profit: boolean,
    expense: boolean,
    income: boolean,
    ruc: boolean
}

const ProfitabilityPeriods: FC = () => {
    const {entries, labels, activeLabels, setActiveLabels} = useProfitabilityPeriods();

    const toggleLabel = (type: LabelType) => {
        setActiveLabels(prevState => ({...prevState, [type]: !prevState[type]}))
    }


    return <>
        <Header as='h2'>Periodi</Header>
        <DiagramWrapper>
            {labels.map(label => <LabelWrapper
                content={label.title}
                color={activeLabels[label.id] ? label.color : 'grey'}
                basic={!activeLabels[label.id]}
                onClick={() => toggleLabel(label.id)}/>)}
            <ProfitabilityPeriodTimeDiagram
                entries={entries}
            />
        </DiagramWrapper>
        <PeriodProfitabilityList trackingId={profitabilityPeriodsTrackingId}/>
    </>
}

export default observer(ProfitabilityPeriods)

const DiagramWrapper = styled.div`
  //padding: 10px 100px 10px 100px;
`;

const LabelWrapper = styled(Label)`
  cursor: pointer;
`;
