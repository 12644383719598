import React, { Fragment, useState, useEffect } from 'react';
import { Header, Grid, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import PhotoWidgetDropzone from './PhotoWidgetDropzone';
import PhotoWidgetCropper from './PhotoWidgetCropper';

interface IProps {
    loading: boolean;
    uploadPhoto: (file: Blob) => void;
}

const PhotoUploadWidget: React.FC<IProps> = ({ loading, uploadPhoto }) => {
    const [files, setFiles] = useState<any[]>([]);
    const [image, setImage] = useState<Blob | null>(null);

    useEffect(() => {
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        };
    });

    return (
        <Fragment>
            <Grid>
                <Grid.Column computer={4} tablet={4} mobile={15}>
                    <Header color='teal' sub content='Korak 1 - Dodaj Fotografiju' />
                    <PhotoWidgetDropzone setFiles={setFiles} />
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column computer={4} tablet={4} mobile={15}>
                    <Header sub color='teal' content='Step 2 - Iseci Fotografiju' />
                    {files.length > 0 && (
                        <PhotoWidgetCropper
                            setImage={setImage}
                            imagePreview={files[0].preview}
                        />
                    )}
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column computer={4} tablet={4} mobile={15}>
                    <Header sub color='teal' content='Step 3 - Prikaz & Optremanje' />
                    {files.length > 0 && (
                        <Fragment>
                            <div
                                className='img-preview'
                                style={{ minHeight: '200px', overflow: 'hidden' }}
                            />
                            <Button.Group widths={2}>
                                <Button
                                    positive
                                    icon='check'
                                    loading={loading}
                                    onClick={() => uploadPhoto(image!)}
                                />
                                <Button
                                    icon='close'
                                    disabled={loading}
                                    onClick={() => setFiles([])}
                                />
                            </Button.Group>
                        </Fragment>
                    )}
                </Grid.Column>
            </Grid>
        </Fragment>
    );
};

export default observer(PhotoUploadWidget);