import {IClient} from "./clients";
import {IProduct} from "./products";

export interface IBrand {
    id: number
    // nameId: string
    name: string
    color: string
    clients: IClient[] | null
    products: IProduct[] | null
    used: boolean,
    value: number | null
}

export class Brand implements IBrand {
    clients: IClient[] | null = null
    color: string;
    id: number;
    name: string;
    products: IProduct[] | null = null
    used: boolean = false
    value: number | null = null
    
    constructor(brandFormValues: IBrandFormValues) {
        this.color = brandFormValues.color
        this.id = brandFormValues.id!
        this.name = brandFormValues.name
    }
    
    
}

export interface IBrandFormValues {
    id: number | null
    name: string
    color: string
}

export class BrandFormValues implements IBrandFormValues {
    id: number | null = null
    color = ''
    name = ''
    
    constructor(brand?: IBrand) {
        
        if (brand) {
            this.color = brand.color
            this.name = brand.name
            this.id = brand.id
        }
    }
}