import React, {FunctionComponent, useContext, useState} from 'react'
import {Form, Grid, Header, Icon, Item, Label, Popup, Segment, Select} from "semantic-ui-react";
import {Link} from 'react-router-dom'
import {RootStoreContext} from "../../../core/stores/rootStore";
import {
    EmployeeGoalFormValues,
    EmployeeGoalStatus, getEmployeeGoalColorSerbianText,
    IEmployeeGoal,
    IEmployeeGoalFormValues
} from "../../../core/models/employeeGoals";
import {stringToColor} from "../../../core/common/util/semanticUi";
import moment from "moment";
import {observer} from "mobx-react-lite";
import {employeeGoalsColorsOptions} from "../../../core/common/options/employeeGoals";
import EmployeeGoalMet from "./EmployeeGoalMet";

interface OwnProps {
    employeeGoal: IEmployeeGoal
}

type Props = OwnProps

const EmployeeGoalIdentity: FunctionComponent<Props> = ({employeeGoal}) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {editEmployeeGoal} = rootStore.employeeGoalsStore

    const [editMode, setEditMode] = useState(false)
    const [showEditIcon, setShowEditIcon] = useState(false)

    const handleColorChange = (color: 'red' | 'blue' | 'orange') => {
        const employeeGoalFormValues: IEmployeeGoalFormValues = {
            ...new EmployeeGoalFormValues(employeeGoal),
            color,
            date: employeeGoal.date ? moment(employeeGoal.date).format('YYYY-MM-DD') : null,
            completedDate: employeeGoal.completedDate ? moment(employeeGoal.completedDate).format('YYYY-MM-DD') : null
        }

        return editEmployeeGoal(employeeGoalFormValues)
            // .then(() => setEditMode(false))
            .catch(error => {
                console.log(error)
            })
    }

    return <Segment.Group>
        <Segment
            clearing
            attached='top'
            inverted={!editMode}
            color={employeeGoal.color}
            style={{padding: editMode ? undefined : 5}}
            onMouseEnter={() => !editMode && setShowEditIcon(true)}
            onMouseLeave={() => !editMode && setShowEditIcon(false)}
        >
            {editMode 
                ? <Form as={Segment}>

                    <Label
                        basic
                        style={{border: 0}}
                        attached='top right'>
                        <Icon
                            style={{cursor: 'pointer'}}
                            name='x'
                            circular
                            size='small'
                            onClick={() => setEditMode(false)}
                        />
                    </Label>
                             
                    <Form.Field>
                        <Select
                            loading={employeeGoal.flags?.updating ?? false}
                            disabled={employeeGoal.flags?.updating ?? false}
                            placeholder='Boja'
                            value={employeeGoal.color}
                            options={employeeGoalsColorsOptions}
                            onChange={((event, data) => handleColorChange(data.value as 'red' | 'orange' | 'blue'))}
                        />
                    </Form.Field>

                    <Form.Field>
                        <EmployeeGoalMet
                            employeeGoal={employeeGoal}
                            fluid
                        />
                    </Form.Field>
                </Form> 
                : <Grid>
                    <Grid.Column width={12}>
                        {getEmployeeGoalColorSerbianText(employeeGoal)}
                    </Grid.Column>
                    <Grid.Column
                        width={4}
                        textAlign='right'>
                        <Icon
                            inverted
                            style={{visibility: showEditIcon ? 'visible' : 'hidden', cursor: 'pointer'}}
                            name='pencil'
                            onClick={() => setEditMode(true)}
                            // color='orange'
                        />
                    </Grid.Column>
                </Grid>}
        </Segment>

        <Segment
            attached='bottom'
            style={{margin: 0}}
        >
            {/*{(employeeGoal.isMet || employeeGoal.isPartlyMet) &&*/}
            <Item.Header
                as={Header}
                color={stringToColor(employeeGoal.color)}>
                <Label
                    attached='top right'
                    basic
                    style={{border: 0, backgroundColor: 'transparent'}}>

                    {/*TODO {isCancel  && <Icon size='big' color='red' name='x'/>}*/}

                    {employeeGoal.status === EmployeeGoalStatus.met ?
                        <Icon size='big' color={'green'} name='check'/> :
                        employeeGoal.status === EmployeeGoalStatus.partlyMet ?
                            <Popup
                                header='Obrazloženje'
                                content={employeeGoal.statusNote}
                                trigger={
                                    <Icon size='big' color={'grey'} name='minus'/>}/> :
                            employeeGoal.status === EmployeeGoalStatus.failed ?
                                <Popup
                                    header='Obrazloženje'
                                    content={employeeGoal.statusNote}
                                    trigger={<Icon size='big' color='red' name='x'/>}/> : null
                    }
                    
                </Label>
            </Item.Header>
            {/*}*/}
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Link
                            to={`/${currentTraffic?.id}/employeeGoals/${employeeGoal.id}`}>
                            <Header
                                color={stringToColor(employeeGoal.color)}>
                                {employeeGoal.name}
                            </Header>
                        </Link>
                        <Item.Meta>{employeeGoal.category}</Item.Meta>
                        <Item.Extra as='small'>Kreiran: {moment(employeeGoal.createdAt).format('l')}</Item.Extra>
                    </Item.Content>
                </Item>
            </Item.Group>
        </Segment>
    </Segment.Group>
    
}

export default observer(EmployeeGoalIdentity)
