import React, {ChangeEvent, FunctionComponent, useContext} from 'react';
import {Form as FinalForm, Field} from 'react-final-form'
import {Button, Form, Header, InputOnChangeData} from "semantic-ui-react";
import TextInput from "../../core/common/form/TextInput";
import {RootStoreContext} from "../../core/stores/rootStore";
import {IUserFormValues} from "../../core/models/user";
import {FORM_ERROR} from "final-form";
import {combineValidators, composeValidators, isRequired} from "revalidate";
import ErrorMessage from "../../core/common/form/ErrorMessage";
import {isValidEmail} from "../../core/common/validators/general";

interface IProps {
}

const validate = combineValidators({
    email: composeValidators(
        isRequired({message: 'E-mail je obavezan'}),
        isValidEmail()
    )(),
    username: isRequired({message: 'Korisničko ime je obavezno'}),
    displayName: isRequired({message: 'Ime za prikaz je obavezno'}),
    password: isRequired({message: 'Lozinka je obavezna'})
})

const RegisterForm: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {register} = rootStore.userStore

    return (
        <FinalForm
            onSubmit={(values: IUserFormValues) => register(values).catch(error => ({
                [FORM_ERROR]: error
            }))}
            validate={validate}
            render={({
                         handleSubmit,
                         submitting,
                         form,
                         submitError,
                         invalid,
                         pristine,
                         dirtySinceLastSubmit,

                     }) => (
                <Form onSubmit={handleSubmit} error>
                    <Header as='h2' content='Registracija na Aspective.' color='blue'/>
                    <Field
                        name='email'
                        component={TextInput}
                        placeholder='Email'
                        onChange={(event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                            form.change('email', data.value.trim())
                        }}
                    />
                    <Field name='displayName' component={TextInput} placeholder='Ime za prikaz'/>
                    <Field name='username' component={TextInput} placeholder='Korisničko ime'/>
                    <Field name='password' component={TextInput} placeholder='Lozinka' type='password'/>
                    {submitError && !dirtySinceLastSubmit &&
                    <ErrorMessage error={submitError}/>}
                    <Button disabled={(invalid && !dirtySinceLastSubmit) || pristine} color='blue'
                            content='Registruj se'
                            loading={submitting} fluid/>
                </Form>
            )}
        />
    );
};

export default RegisterForm;
