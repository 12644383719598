import React, {Fragment, FunctionComponent, useState} from 'react'
import {Button, Icon, Item, Segment} from "semantic-ui-react";
import {FcPlus} from "react-icons/all";
import {IEmployeeGoal} from "../../../core/models/employeeGoals";
import EmployeeGoalDocumentForm from "../form/EmployeeGoalDocumentForm";
import EmployeeGoalSingleDocument from "./EmployeeGoalSingleDocument";
import {toast} from "react-toastify";
import moment from "moment";

interface OwnProps {
    employeeGoal: IEmployeeGoal
}

type Props = OwnProps

const EmployeeGoalDocuments: FunctionComponent<Props> = ({employeeGoal}) => {
    
    const [addingDocumentMode, setAddingDocumentMode] = useState(false)
    
    return <Fragment>
        <Segment
            textAlign='center'
            style={{border: 'none'}}
            attached='top'
            secondary
            inverted
            color='teal'
        >
            Dokumenta
        </Segment>

        <Segment attached>
            {addingDocumentMode ?
                <EmployeeGoalDocumentForm 
                    employeeGoalId={employeeGoal.id}
                    onCancel={() => setAddingDocumentMode(false)}
                    onUploadSuccess={() => {
                        setAddingDocumentMode(false)
                        toast.success('Fajl uspešno otpremljen')
                    }}
                />
                : <Button
                    basic
                    icon
                    onClick={() => setAddingDocumentMode(true)}>
                    <Icon>
                        <FcPlus/>
                    </Icon>
                    Dodaj dokument
                </Button>}
            <Item.Group divided>
                {employeeGoal.documents
                    .sort((a, b) => moment(a.file?.updatedAt).isBefore(b.file?.updatedAt) ? 1 : -1)
                    .map(document => <EmployeeGoalSingleDocument key={document.id} employeeGoalDocument={document} />)}
            </Item.Group>
        </Segment>
    </Fragment>
}

export default EmployeeGoalDocuments
