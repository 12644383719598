import React, {FunctionComponent, useContext, useEffect} from 'react';
import ScheduleSectionLayout from "../../scheduleSection/layout/ScheduleSectionLayout";
import ClientSchedulesList from "../list/ClientSchedulesList";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {Button, Grid, Icon, Segment, Select} from "semantic-ui-react";
import {Link} from "react-router-dom";
import moment from "moment";
import {PdfService} from "../../../core/common/pdf/PdfService";
import autoTable from "jspdf-autotable";
import {formatRsd} from "../../../core/common/util/format";
import {IClientSchedule} from "../../../core/models/clientSchedules";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import {calculatePercentage} from "../../../core/common/util/myMath";
import {numberToStringToFixedOrInt} from "../../../core/common/util/string";
import {sumatorIntegrantTotalityWithPercentage} from "../../../core/common/util/array";

const TRACKING_ID = 'clientSchedules'

interface IProps {
}

const ClientSchedulesDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loadClientSchedules, loading, setYear, loadYearsWithAny, yearsWithAny, year, loadingYearsWithAny} = rootStore.clientScheduleStore
    const { currentTraffic } = rootStore.trafficsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore
   
    useNavbarSelectedItem('clientSchedules')
    
    useEffect(() => () => {
        removeFromRegistry(TRACKING_ID)
    }, [
        removeFromRegistry,
    ])

    useEffect(() => {
        const currentYear = moment().format('YYYY')
        loadYearsWithAny()
            .then(loadYearsWithAny => {
                if (loadYearsWithAny?.includes(currentYear)) {
                    setYear(currentYear)
                } else {
                    setYear('all')
                }
            })
        
        return () => {
            setYear('')
        }
    }, [
        loadClientSchedules,
        setYear,
        loadYearsWithAny,
    ])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc
        
        let title = 'Tabela plana prometa'
        if (year !== 'all') {
            title += ` za ${year}. godinu`
        }
                
        const sumator = sumatorIntegrantTotalityWithPercentage(SHIT_getFilteredRowsById(TRACKING_ID)!,
            'realizationValue',
            'value',
            'average',
            true)
        
        doc.text(title, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                [
                    '#', 
                    'Klijent', 
                    'Zadužen', 
                    'Plan/Ostvarenje [%]', 
                    'Godina', 
                    'Napomena'
                ],
                [
                    '',
                    '',
                    '',
                    `${formatRsd(sumator.integrant)} / ${formatRsd(sumator.totality)} [${numberToStringToFixedOrInt(sumator.percentage, 2)}%]`,
                    '',
                    ''
                ]
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((clientSchedule: IClientSchedule, index) => ([
                index + 1,
                clientSchedule.client.name,
                clientSchedule.indebtedEmployee?.displayName ?? '-',
                `${formatRsd(clientSchedule.realizationValue)} / ${formatRsd(clientSchedule.value)} [${numberToStringToFixedOrInt(calculatePercentage(clientSchedule.realizationValue, clientSchedule.value, true), 2)}%]`,
                clientSchedule.year,
                clientSchedule.note
            ])) ?? []
        })

        doc.save("PlanPrometa.pdf")
    }


    return (<ScheduleSectionLayout
        // contentLoading={loading || loadingYearsWithAny}
    >
        <Grid as={Segment} style={{marginTop: 0}} loading={loading || loadingYearsWithAny}>
            <Grid.Row>
                <Grid.Column>
                    {yearsWithAny &&
                    <Select
                        loading={loadingYearsWithAny}
                        options={[
                            {key: 'all', value: 'all', text: 'Sve'},
                            ...yearsWithAny.map(year => ({key: year, value: year, text: year}))
                        ]}
                        value={year}
                        onChange={(event, data) => setYear(data.value as string)}
                    />}
                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/clientSchedules/create`}
                        floated='right'
                        color='blue'
                        icon
                        labelPosition='left'>
                        <Icon name='add' />
                        Dodaj plan prometa
                    </Button>
                    <Button
                        disabled={loading}
                        icon
                        labelPosition='left'
                        floated='right'
                        color='teal'
                        onClick={() => exportPdf()}>
                        <Icon name='file pdf'/>
                        PDF
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <ClientSchedulesList trackingId={TRACKING_ID}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </ScheduleSectionLayout>);
};

export default observer(ClientSchedulesDashboard)
