import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {GeneralExpense, IGeneralExpense, IGeneralExpenseFormValues} from "../models/generalExpenses";
import agent from "../api/agent";
import {history} from "../../index";
import {groupBy} from "../common/util/array";
import {getNiceColorFromStingAsciiSum} from "../common/util/colors";
import { v4 as uuid } from 'uuid'

export default class ExpensesStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable submittingGeneralExpenses = false
    @observable loadingGeneralExpenses = false
    @observable generalExpensesRegistry = new Map<string, IGeneralExpense>()
    @observable generalExpense: IGeneralExpense | null = null
    @observable generalExpenseDeletingId: string | null = null
    @observable generalExpensesPredicate = new Map<string, string>()
    @observable generalExpensesCategories: string[] | null = null
    @observable loadingGeneralExpensesCategories = false
    @observable trackingId = ''
    @observable lastRequestId = ''

    @computed get generalExpensesAxiosParams() {
        const params = new URLSearchParams()
        this.generalExpensesPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get generalExpensesArray() {
        return Array.from(this.generalExpensesRegistry.values())
    }

    @computed get filteredGroupByCategory() {
        return groupBy(this.rootStore.tablesStore.SHIT_getFilteredRowsById(this.trackingId) ?? [], g => g.category)
            .map(p => p[1]).map((group: IGeneralExpense[]) =>
                group.reduce((previousValue: { value: number, category: string, color: string }, currentValue, index) => {
                        if (index > 0) {
                            return {
                                ...previousValue,
                                value: previousValue.value + currentValue.value
                            }
                        } else {
                            return previousValue
                        }
                    },
                    {
                        value: group[0].value,
                        category: group[0].category,
                        color: getNiceColorFromStingAsciiSum(group[0].category)
                    }
                ))
    }

    @action setGeneralExpensesPredicate = (key: string, value: string) => {
        this.generalExpensesPredicate.set(key, value)
    }

    @action clearGeneralExpensesPredicate = () => {
        this.generalExpensesPredicate.clear()
    }

    @action setTrackingId = (trackingId: string) => this.trackingId = trackingId

    @action createGeneralExpense = async (generalExpenseFormValues: IGeneralExpenseFormValues) => {
        this.submittingGeneralExpenses = true
        try {
            await agent.GeneralExpenses.create(generalExpenseFormValues)
            runInAction(() => {
                this.generalExpensesRegistry.set(generalExpenseFormValues.id, new GeneralExpense(generalExpenseFormValues))
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/generalExpenses`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submittingGeneralExpenses = false
            })
        }
    }

    @action editGeneralExpense = async (generalExpenseFormValues: IGeneralExpenseFormValues) => {
        this.submittingGeneralExpenses = true
        try {
            await agent.GeneralExpenses.edit(generalExpenseFormValues)
            runInAction(() => {
                this.generalExpense = new GeneralExpense(generalExpenseFormValues)
                this.generalExpensesRegistry.set(generalExpenseFormValues.id, new GeneralExpense(generalExpenseFormValues))
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/generalExpenses`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submittingGeneralExpenses = false
            })
        }
    }

    @action deleteGeneralExpense = async (id: string) => {
        this.generalExpenseDeletingId = id
        try {
            await agent.GeneralExpenses.delete(id)
            runInAction(() => {
                if (this.generalExpense?.id === id) {
                    this.generalExpense = null
                }
                this.generalExpensesRegistry.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.generalExpenseDeletingId = null
            })
        }
    }

    @action loadGeneralExpense = async (id: string) => {
        this.loadingGeneralExpenses = true
        try {
            const generalExpense = await agent.GeneralExpenses.details(id)
            runInAction(() => {
                this.generalExpense = generalExpense
            })
            return generalExpense
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingGeneralExpenses = false
            })
        }
    }

    @action loadGeneralExpenses = async () => {
        this.loadingGeneralExpenses = true
        const requestId = uuid()
        this.lastRequestId = requestId
        try {
            const generalExpenses = await agent.GeneralExpenses.list(this.generalExpensesAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.generalExpensesRegistry.clear()
                    generalExpenses.forEach(generalExpense => {
                        this.generalExpensesRegistry.set(generalExpense.id, generalExpense)
                    })   
                }
            })
        } catch (error) {
            console.log(error)
            this.generalExpensesRegistry.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loadingGeneralExpenses = false   
                }
            })
        }
    }

    @action loadGeneralExpensesCategories = async (local: boolean = false) => {
        this.loadingGeneralExpensesCategories = true
        try {
            let categories = (!local || !this.generalExpensesCategories) ?
                await agent.GeneralExpenses.categories() :
                this.generalExpensesCategories

            runInAction(() => {
                this.generalExpensesCategories = categories
            })

            return categories
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingGeneralExpensesCategories = false
            })
        }
    }
    
}