import React, {FunctionComponent, useState} from 'react';
import {VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryTheme, VictoryTooltip} from "victory";
import {formatRsd} from "../util/format";
import {semanticColorToHash} from "../util/semanticUi";
import {getNiceColorFromStingAsciiSum} from "../util/colors";

interface IProps {
    data: any[],
    // colors: string[],
    xAccessor: ((row: any) => any) | string,
    yAccessor: ((row: any) => any) | string,
    type?: 'money' | 'count',
    title: string | string[]
    titleColor?: string,
    sortKey?: string,
    sortOrder?: 'ascending' | 'descending'
}

const MyPieChart: FunctionComponent<IProps> = ({
                                                   data,
                                                   // colors,
                                                   xAccessor,
                                                   yAccessor,
                                                   type = 'money',
                                                   title,
                                                   titleColor = semanticColorToHash('teal'),
                                                   sortKey,
                                                   sortOrder
                                               }) => {

    const [centerLabel, setCenterLabel] = useState<string[] | null>(null)

    const labara = ({datum, data}: any) => {
        const total = data.reduce((total: number, current: any) => total + current._y, 0)

        const value = type === 'money' ? formatRsd(datum._y) : datum._y

        return [
            datum.xName,
            value.toString(),
            `(${(datum._y / total * 100).toFixed(2)}%)`
        ]
    }

    return (
        <VictoryChart
            animate={{
                duration: 500,
                onLoad: {duration: 200}
            }}
            domainPadding={{x: 30, y: 5 }}
            theme={VictoryTheme.material}
            height={200}
        >
            <VictoryAxis dependentAxis style={{tickLabels: { fontSize: 5 } }} />
            <VictoryBar
                // theme={VictoryTheme.material}
                barRatio={0.8}
                cornerRadius={0} // Having this be a non-zero number looks good when it isn't transitioning, but looks like garbage when it is....
                style={{data: {width: 10, fill: (d) => getNiceColorFromStingAsciiSum(d.datum.category)}}}
                alignment="middle"
                labels={d => null}
                data={data}
                x={xAccessor}
                y={yAccessor}
                sortKey={sortKey}
                sortOrder={sortOrder}
                labelComponent={<VictoryTooltip
                    flyoutComponent={<></>}
                    labelComponent={<VictoryLabel
                    textAnchor="middle"
                    style={{fontSize: 7, fontWeight: 'bold', fill: titleColor}}
                    // x={50} y={165}
                    text={centerLabel!}
                />} />}
                
                events={[{
                    target: "data",
                    eventHandlers: {
                        onMouseOver: (a) => {

                            return [{
                                target: 'data',
                                mutation: (props) => {

                                    setCenterLabel(labara(props))

                                    return {
                                        style: Object.assign({},
                                            props.style,
                                            {
                                                // fill: "tomato",
                                                fillOpacity: 0.5

                                            }),
                                    }
                                }
                            },
                                {
                                    target: "labels",
                                    mutation: () => ({active: true})
                                }
                            ];
                        },
                        onMouseOut: () => {
                            setCenterLabel(null)

                            return [
                                {
                                    target: "data",
                                    mutation: () => {
                                    }
                                }, {
                                    target: "labels",
                                    mutation: () => ({active: false})
                                }
                            ];
                        }
                    }
                }]}
            />
        
        </VictoryChart>
    )
}

export default MyPieChart