import {IClient} from "./clients";
import {IProduct} from "./products";
import moment from "moment";

export interface IOnHoldProduct {
    id: string
    client: IClient
    product?: IProduct
    count: number
    date: Date
    isOther: boolean
    otherContent: string
    note: string
}

export interface IOnHoldProductFormValues {
    id: string
    clientId: string
    productId?: string
    count: number
    date: string
    isOther: boolean
    otherContent: string
    note: string
}

export class OnHoldProductFormValues implements IOnHoldProductFormValues {
    clientId = ''
    count = 1
    id = ''
    productId = ''
    date = moment(new Date()).format('YYYY-MM-DD')
    isOther = false
    otherContent = ''
    note = ''
    
    constructor(onHoldProduct?: IOnHoldProduct) {
        if (onHoldProduct) {
            this.id = onHoldProduct.id
            this.clientId = onHoldProduct.client.id!
            this.count = onHoldProduct.count
            this.date = moment(onHoldProduct.date).format('YYYY-MM-DD')
            this.isOther = onHoldProduct.isOther
            this.otherContent = onHoldProduct.otherContent
            this.note = onHoldProduct.note
            
            if (!onHoldProduct.isOther) {
                this.productId = onHoldProduct.product!.id
            }
        }
    }
}