import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import agent from "../api/agent";
import {history} from "../../index";
import {IOnHoldProduct, IOnHoldProductFormValues} from "../models/onHoldProduct";
import {groupBy} from "../common/util/array";
import moment from "moment";
import { v4 as uuid } from 'uuid'

export default class OnHoldProductsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }

    @observable onHoldProductsRegistry = new Map<string, IOnHoldProduct>()
    @observable loading = false
    @observable submitting = false
    @observable onHoldProduct: IOnHoldProduct | null = null
    @observable deleting = false
    @observable deletingId: string | null = null
    @observable predicate = new Map<string, string>()
    @observable lastRequestId = ''

    @computed get onHoldProductsArray() {
        return Array.from(this.onHoldProductsRegistry.values())
    }

    @computed get onHoldProductsRegistrySize() {
        return this.onHoldProductsRegistry.size
    }

    @computed get onHoldProductsGroupedByProduct() {
        const aa = groupBy(this.onHoldProductsArray, (onHoldProduct: IOnHoldProduct) => onHoldProduct.product!.id)

        return aa
            .map(pair => pair[1])
            .map((group: IOnHoldProduct[]) => {

                if (group.length === 1) {
                    return group[0]
                } else {
                    return group.reduce((previousValue, currentValue) => {
                        return {
                            ...previousValue,
                            count: previousValue.count + currentValue.count,
                            date: moment(currentValue.date).isBefore(previousValue.date) ? currentValue.date : previousValue.date

                        }
                    }, {...group[0], count: 0, date: new Date('9999-12-31')} as IOnHoldProduct)
                }
            })
    }

    @computed get axiosParams() {
        const params = new URLSearchParams()

        this.predicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }
    
    @action setPredicate = (predicate: 'clientId', value: string) => {
        this.predicate.set(predicate, value)
    }

    @action clearPredicates = () => this.predicate.clear()
    
    @action loadOnHoldProducts = async () => {
        const lastRequestId = uuid()
        this.lastRequestId = lastRequestId
        this.loading = true
        try {
            const onHoldProducts = await agent.OnHoldProducts.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === lastRequestId) {
                    this.onHoldProductsRegistry.clear()
                    onHoldProducts.forEach(onHoldProduct => {
                        this.onHoldProductsRegistry.set(onHoldProduct.id,
                            onHoldProduct.isOther ? // Little hack here, other product acting real product :)
                                {
                                    ...onHoldProduct,
                                    product: {
                                        id: `OTHER_PRODUCT_${onHoldProduct.otherContent}`,
                                        name: onHoldProduct.otherContent,
                                        brandId: 0,
                                        brandName: 'OTHER_PRODUCT',
                                        sku: `OTHER_PRODUCT_${onHoldProduct.otherContent}`,
                                        brandColor: 'black',
                                        prices: [],
                                        description: ''
                                    }
                                } :
                                onHoldProduct)
                    })   
                }
            })
        } catch (error) {
            console.log(error)
            this.onHoldProductsRegistry.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === lastRequestId) {
                    this.loading = false   
                }
            })
        }
    }

    @action createOnHoldProduct = async (onHoldProductFormValues: IOnHoldProductFormValues) => {
        this.submitting = true
        try {
            const id = await agent.OnHoldProducts.create(onHoldProductFormValues)
            const onHoldProduct = await agent.OnHoldProducts.details(id)
            runInAction(() => {
                this.onHoldProductsRegistry.set(id, onHoldProduct)

                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/onHoldProducts`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editOnHoldProduct = async (onHoldProductFormValues: IOnHoldProductFormValues) => {
        this.submitting = true
        try {
            await agent.OnHoldProducts.edit(onHoldProductFormValues)
            // const onHoldProduct = await agent.OnHoldProducts.details(onHoldProductFormValues.id)  // It's works without this (probably everywhere!!!) because push to page include loading
            runInAction(() => {
                // this.onHoldProductsRegistry.set(onHoldProductFormValues.id, onHoldProduct)
                // this.onHoldProduct = onHoldProduct
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/onHoldProducts`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action deleteOnHoldProduct = async (id: string, byProduct: boolean = false) => {
        this.deleting = true
        this.deletingId = id
        try {
            const params = new URLSearchParams()
            if (byProduct) {
                params.append('byProduct', 'true')
            }
            await agent.OnHoldProducts.delete(id, params)
            runInAction(() => {
                if (byProduct) {
                    this.onHoldProductsArray.forEach(onHoldProduct => {
                        if (onHoldProduct.product!.id === id) {
                            this.onHoldProductsRegistry.delete(onHoldProduct.id)
                        }
                    })
                } else {
                    this.onHoldProduct = null
                    this.onHoldProductsRegistry.delete(id)
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingId = null
            })
        }
    }

    @action loadOnHoldProduct = async (id: string) => {
        this.loading = true
        try {
            const onHoldProduct: IOnHoldProduct = await agent.OnHoldProducts.details(id)
            runInAction(() => {
                this.onHoldProduct = onHoldProduct
            })

            return onHoldProduct
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action loadOnHoldProductsToRegistry = (onHoldProducts: IOnHoldProduct[]) => {
        this.onHoldProductsRegistry.clear()
        onHoldProducts.forEach(onHoldProduct => {
            this.onHoldProductsRegistry.set(onHoldProduct.id, onHoldProduct)
        })
    }
}