import React, {FunctionComponent, useContext} from 'react';
import {Segment, Header, Comment, Divider} from 'semantic-ui-react';
import {RootStoreContext} from '../../../core/stores/rootStore';
import {observer} from 'mobx-react-lite';
import {IEmployeeGoal} from "../../../core/models/employeeGoals";
import EmployeeGoalCommentForm from "../form/EmployeeGoalCommentForm";
import EmployeeGoalSingleComment from "./EmployeeGoalSingleComment";

interface IProps {
    employeeGoal: IEmployeeGoal
}

const EmployeeGoalDetailedChat: FunctionComponent<IProps> = ({employeeGoal}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        sortedComments,
    } = rootStore.employeeGoalsStore
    

    return (
        <div>
            <Segment
                textAlign='center'
                attached='top'
                inverted
                color='teal'
                style={{border: 'none'}}
            >
                <Header>Komentari</Header>
            </Segment>
            <Segment>
                <Comment.Group>

                    <EmployeeGoalCommentForm employeeGoalId={employeeGoal.id}/>

                    <Divider hidden/>

                    {sortedComments && sortedComments.map((comment) => (
                        <EmployeeGoalSingleComment key={comment.id} comment={comment}/>
                    ))}
                </Comment.Group>
            </Segment>
        </div>
    );
};

export default observer(EmployeeGoalDetailedChat);