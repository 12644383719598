import React, {FC, useContext, useMemo} from "react";
import Table from "../../../core/common/tables/Table";
import {Cell} from "react-table";
import {IPeriodAnalyzeEntity, MONTH_YEAR_FORMAT} from "../../../core/models/billOrders";
import SumTableHeader from "../../../core/common/tables/headers/SumTableHeader";
import {formatRsd} from "../../../core/common/util/format";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";
import moment from "moment";
import {getNiceColorFromStingAsciiSum} from "../../../core/common/util/colors";
import styled from "styled-components";
import {numberToStringToFixedOrInt} from "../../../core/common/util/string";
import {Divider} from "semantic-ui-react";

interface IProps {
    trackingId?: string,
    // view: 'years' | 'months'
}

const PeriodProfitabilityList: FC<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {periodsArray} = rootStore.profitabilityStore

    const columns = useMemo(() => [
        {
            Header: 'Mesec',
            accessor: 'monthYearString',
            Cell: ({row: {original: analyseEntity}}: Cell<IPeriodAnalyzeEntity>) =>
                <div>{moment(analyseEntity.monthYearString, MONTH_YEAR_FORMAT).format('MMMM YYYY').toUpperCase()}</div>,
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type='money' name='Prodajna'
                                                  accessor='summaryPriceWithDiscountWithBillDiscount'/>,
            accessor: 'summaryPriceWithDiscountWithBillDiscount',
            Cell: ({row: {original: analyseEntity}}: Cell<IPeriodAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.summaryPriceWithDiscountWithBillDiscount)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: 'Po kat klijenata %',
            accessor: 'clientCategoriesShare',
            Cell: ({row: {original: analyseEntity}}: Cell<IPeriodAnalyzeEntity>) => <ul>
                {analyseEntity.clientCategoriesShare.map((entity, index) => <li>
                    <Category style={{color: getNiceColorFromStingAsciiSum(entity.category)}}>{entity.category}</Category>{' '}
                    <Value>{formatRsd(entity.value)}</Value>{' '}
                    <Percent>{numberToStringToFixedOrInt(entity.percentage, 2)}%</Percent>
                    {(index !== analyseEntity.clientCategoriesShare.length - 1) && <Divider fitted />}
                </li>)}
            </ul>,
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'RUC'}
                                                  accessor={'differenceInPrice'}/>,
            accessor: 'differenceInPrice',
            Cell: ({row: {original: analyseEntity}}: Cell<IPeriodAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.differenceInPrice)}</span>,
            id: 'differenceInPrice',
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader
                row={row}
                type='money'
                name='Troškovi'
                accessor='expense'/>,
            accessor: 'expense',
            Cell: ({row: {original: analyseEntity}}: Cell<IPeriodAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.expense)}</span>,
        },
        {
            Header: (row: any) => <SumTableHeader
                row={row}
                type='money'
                name='Profit'
                accessor='profit' />,
            accessor: 'profit',
            Cell: ({row: {original: analyseEntity}}: Cell<IPeriodAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.profit)}</span>,
        }
    ], [])

    return <Table
        columns={columns}
        data={periodsArray}
        trackingId={trackingId}
    />
}

export default observer(PeriodProfitabilityList)

const Category = styled.span`
  font-weight: bold;
`;

const Value = styled.span`
  font-weight: bolder;
  color: #292929;
`;

const Percent = styled.span`
  color: #444444;
`;
