import React, {FunctionComponent, useContext} from 'react';
import {Grid} from "semantic-ui-react";
import ProfitabilityDashboardDiagram from "./ProfitabilityDashboardDiagram";
import ProfitabilityIncomeDiagrams from "./income/ProfitabilityIncomeDiagrams";
import ProfitabilityExpenseDiagrams from "./expense/ProfitabilityExpenseDiagrams";
import {RootStoreContext} from "../../../../core/stores/rootStore";

interface IProps {}

const ProfitabilityDiagrams: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const { showDiagramsExtensions } = rootStore.profitabilityStore
    
    const align = showDiagramsExtensions ? 'middle' : 'bottom'
    
  return (<Grid>
      <Grid.Column computer={5} tablet={5} mobile={16} verticalAlign={align}>
          <ProfitabilityIncomeDiagrams/>
      </Grid.Column>

      <Grid.Column computer={6} tablet={6} mobile={16} verticalAlign={align}>
          <ProfitabilityDashboardDiagram/>
      </Grid.Column>

      <Grid.Column computer={5} tablet={5} mobile={16} verticalAlign={align}>
          <ProfitabilityExpenseDiagrams/>
      </Grid.Column>
  </Grid>)
}

export default ProfitabilityDiagrams;
