import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react'
import {Cell, FilterProps} from "react-table";
import {Button, Header, Icon, Message, Segment, Statistic} from "semantic-ui-react";
import ProductsTableBasicInfoCell from "../../products/list/ProductsTableBasicInfoCell";
import {ProductsDetailedFilter} from "../../../core/common/tables/filters/products/detailed/ProductsDetailedFilter";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";
import moment from "moment";
import {DateFilter} from "../../../core/common/tables/filters/date/DateFilter";
import {Link} from "react-router-dom";
import Table from "../../../core/common/tables/Table";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {IRequiredProduct} from "../../../core/models/requiredProducts";
import {observer} from "mobx-react-lite";

interface OwnProps {
}

type Props = OwnProps

const RequiredProductsList: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {requiredProductsArray: requiredProducts, deleteRequiredProduct, deletingId} = rootStore.requiredProductsStore
    const { loadBrandsIfNull, brandsArray: brands } = rootStore.brandsStore
    const { openModal, closeModal } = rootStore.modalStore
    const { currentTraffic } = rootStore.trafficsStore
    
    
    useEffect(() => {
        loadBrandsIfNull()
    }, [
        loadBrandsIfNull
    ])

    const columns = useMemo(() => [
        {
            id: 'product',
            Header: 'Proizvod',
            accessor: 'name',
            Cell: ({row: {original: requiredProduct}}: Cell<IRequiredProduct>) => requiredProduct.isOther ?
                <Header>
                    <Header.Subheader>
                        Nestandardni proizvod
                    </Header.Subheader>
                    <Header.Content>
                        {requiredProduct.otherContent}
                    </Header.Content>
                </Header> :
                (<ProductsTableBasicInfoCell product={requiredProduct.product!}/>),
            Filter: (d: FilterProps<any>) => ProductsDetailedFilter(d, brands),
            filter: 'productsDetailedFilter'
        },
        {
            Header: 'Količina',
            accessor: (d: IRequiredProduct) => d.count,
            cellWidth: 2,
            Cell: ({row: {original: requiredProduct}}: Cell<IRequiredProduct>) => (
                <Statistic content={requiredProduct.count}/>),
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            id: 'date',
            Header: 'Datum',
            cellWidth: 2,
            accessor: (d: IRequiredProduct) => d.date,
            Cell: ({row: {original: requiredProduct}}: Cell<IRequiredProduct>) => (<span
                    style={{
                        color: moment(requiredProduct.date).isBefore(new Date(), 'day') ?
                            'red' :
                            moment(requiredProduct.date).isSame(new Date(), 'day') ?
                                'orange' : undefined
                    }}
                >
                {moment(requiredProduct.date).format('l')}</span>
            ),
            Filter: DateFilter,
            filter: 'dateFilter'
        },
        {
            Header: 'Napomena',
            accessor: 'note'
        },
        {
            id: 'controls',
            // hide: !isEmployee,
            Header: 'Kontrole',
            cellWidth: 2,
            Cell: ({row: {original: requiredProduct}}: Cell<IRequiredProduct>) => (
                <Button.Group>
                    <Button as={Link} to={`/${currentTraffic?.id}/requiredProducts/manage/${requiredProduct.id}/edit`}
                            icon='edit' color='yellow'/>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje teaženog proizvoda
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš teaženi
                                proizvod <strong>{requiredProduct.product!.name} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any, data) => {
                                event.target.className += ' loading'
                                deleteRequiredProduct(requiredProduct.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={deletingId === requiredProduct.id}/>
                </Button.Group>)
        }

    ], [
        brands,
        closeModal,
        currentTraffic,
        deleteRequiredProduct,
        deletingId,
        openModal
    ])

    if (requiredProducts.length === 0) return <div style={{marginTop: 50}}>
        <Message warning>
            <Message.Header>Ne postoje traženi proizvodi po zadatom kriterijumu</Message.Header>
            <p>Pokušaj da osvežiš stranicu.</p>
        </Message>
    </div>

    return (<Table
        // trackingId={trackingId}
        columns={columns}
        data={requiredProducts}/>)
}

export default observer(RequiredProductsList)
