import { FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive'


interface IProps {
    children: any
}

export const useMobile = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 })

    return isMobile
}

const Mobile: FunctionComponent<IProps> = ({children}) => {
    const isMobile = useMobile()
    
    return isMobile ? children : null
};

export default Mobile
