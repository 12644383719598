import React, {useContext, useMemo} from "react";
import {Cell, FilterProps} from "react-table";
import {ClientGoalStatus, ClientGoalType, IClientGoal} from "../../../core/models/clientGoals";
import ClientGoalIdentity from "../commom/ClientGoalIdentity";
import {ClientGoalFilter} from "../../../core/common/tables/filters/clientGoals/ClientGoalFilter";
import ClientDetailedCell from "../../clients/list/components/ClientDetailedCell";
import ClientDetailedFilter from "../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import ClientGoalParticipantsIdentity from "../commom/ClientGoalParticipantsIdentity";
import MultiselectFilter from "../../../core/common/tables/filters/multiselect/MultiselectFilter";
import ClientGoalDatesCell from "./components/ClientGoalDatesCell";
import ClientGoalCommentCell from "./components/ClientGoalCommentCell";
import ClientGoalDocumentsCell from "./components/ClientGoalDocumentsCell";
import {Button, Header, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {RootStoreContext} from "../../../core/stores/rootStore";

interface IUseClientGoalsColumnsProps {
    type: ClientGoalType,
    status?: ClientGoalStatus,
    hideClient: boolean,
}

export const useClientGoalsColumns = ({hideClient, type, status}: IUseClientGoalsColumnsProps) => {
    const rootStore = useContext(RootStoreContext)
    const {
        deleteClientGoal,
        clientGoalsCategories,
    } = rootStore.clientGoalsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {isEmployee} = rootStore.userStore
    const {employeesArray: employees} = rootStore.usersStore

    const columns = useMemo(() => [
        {
            id: 'clientGoal',
            Header: 'Cilj',
            accessor: 'name',
            Cell: ({row: {original: clientGoal}}: Cell<IClientGoal>) => <ClientGoalIdentity clientGoal={clientGoal}/>,
            filter: 'clientGoalFilter',
            Filter: (d: any) => ClientGoalFilter(d, Array.from(new Set((clientGoalsCategories ?? [])
                .filter(x => x.type === type)
                .filter(x => status === undefined ? true : x.status === status)
                .map(x => x.name)))
            ),
        },
        {
            id: 'client',
            Header: 'Klijent',
            accessor: 'client.name',
            hide: hideClient,
            Cell: ({row: {original: clientGoal}}: Cell<IClientGoal>) => <ClientDetailedCell
                client={clientGoal.client}/>,
            Filter: ClientDetailedFilter,
            filter: 'clientDetailedFilter',
        },
        {
            accessor: (d: IClientGoal) => d.participants.map(participant => participant.user.id),
            Header: 'Učesnici',
            Cell: ({row: {original: clientGoal}}: Cell<IClientGoal>) =>
                <ClientGoalParticipantsIdentity clientGoal={clientGoal}/>,
            Filter: (d: FilterProps<any>) => MultiselectFilter(d, employees.map(user => ({
                key: user.id,
                value: user.id,
                text: `${user.displayName} [${user.email}]`
            })), 'Učesnik'),
            filter: 'multiselectFilter',
            disableSortBy: true
        },
        {
            id: 'dates',
            Header: 'Datumi',
            // accessor: 'date',
            Cell: ({row: {original: clientGoal}}: Cell<IClientGoal>) => <ClientGoalDatesCell clientGoal={clientGoal}/>,
            // Filter: DateFilter,
            // filter: 'dateFilter',
        },
        {
            accessor: 'id',
            Header: 'Komentari',
            Cell: ({row: {original: clientGoal}}: Cell<IClientGoal>) => <ClientGoalCommentCell
                clientGoal={clientGoal}/>,
            sortType: 'clientGoalsLastComment'
        },
        {
            Header: 'Poslednji dokument',
            Cell: ({row: {original: clientGoal}}: Cell<IClientGoal>) => <ClientGoalDocumentsCell
                clientGoal={clientGoal}/>
        },
        {
            Header: 'Kontrole',
            id: 'controls',
            hide: !isEmployee,
            Cell: ({row: {original: clientGoal}}: Cell<IClientGoal>) => (
                <Button.Group>
                    <Button as={Link} to={`/${currentTraffic?.id}/clientGoals/manage/${clientGoal.id}/edit`} icon='edit'
                            color='yellow'/>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje {deletingText[clientGoal.type][0]}
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš
                                {deletingText[clientGoal.type][1]}
                                <strong>{clientGoal.name}</strong> klijenta <strong>{clientGoal.client.name} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any, data) => {
                                event.target.className += ' loading'
                                deleteClientGoal(clientGoal.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={clientGoal.flags?.deleting}/>
                </Button.Group>)
        }
    ], [
        closeModal,
        openModal,
        currentTraffic,
        deleteClientGoal,
        hideClient,
        // hideMetFilter, 
        isEmployee,
        clientGoalsCategories,
        employees,
        type,
        status
    ])

    return {columns}
}

const deletingText: Record<ClientGoalType, [string, string]> = {
    [ClientGoalType.payment]: [
        'naplate',
        'naplatu'
    ],
    [ClientGoalType.clientRequest]: [
        'zaheva klijenta',
        'zahtev klijenta'
    ],
    [ClientGoalType.classic]:
        [
            'cilja',
            'cilj'
        ]
}
