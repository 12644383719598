import {IBillOrder} from "./billOrders";
import {IBillInstallment} from "./billInstallments";
import {IClient} from "./clients";
import {ITraffic} from "./traffics";
import {IBrand} from "./brands";
import {IUser} from "./user";

export enum BillCreationType {
    Cart,
    Manual
}

export enum BillType {
    Specification,
    Invoice
}

export interface IBill {
    id: string,
    idString: string
    client: IClient
    type: number
    isGratis: boolean
    isOnSale: boolean
    onSaleNote: string
    traffic: ITraffic
    creationType: BillCreationType
    brand: IBrand
    value: number
    baseValue: number
    discountRate: number
    note: string
    date: Date
    payday: Date
    hirePurchase: boolean
    installmentsCount: number
    billOrders: IBillOrder[],
    billInstallments: IBillInstallment[]
    euroExchangeRateAtInvoicing: number
    manualBillBasePrice: number
    user: IUser
}

export interface IBillFormValues {
    id: string,
    idString: string
    clientId: string
    type: number
    isGratis: boolean
    isOnSale: boolean
    onSaleNote: string
    trafficId: number
    creationType: number
    brandId: number
    value: number
    discountRate: number
    note: string
    date: Date
    payday: Date
    hirePurchase: boolean
    billOrders: IBillOrder[],
    billInstallments: IBillInstallment[]
    waitingFileUpload: boolean,
    manualFileChanged: boolean,
    manualBillValue: number
    manualBillBasePrice: number
}

export interface IBillIdStringSuggestion {
    trafficId: number
    specification: string
    specificationGratis: string
    invoice: string
    invoiceGratis: string
}
