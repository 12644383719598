import React, {FunctionComponent} from 'react'
import {IEmployeeGoal} from "../../../core/models/employeeGoals";
import {Grid} from "semantic-ui-react";
import EmployeeGoalDatesCellPlannedDate from "./EmployeeGoalDatesCellPlannedDate";
import EmployeeGoalDatesCellCompletedDate from "./EmployeeGoalDatesCellCompletedDate";

interface OwnProps {
    employeeGoal: IEmployeeGoal
}

type Props = OwnProps

const EmployeeGoalDatesCell: FunctionComponent<Props> = ({employeeGoal}) => {

    return <Grid>
        <Grid.Column width={16} style={{ margin: 0, paddingBottom: 3 }}>
            <EmployeeGoalDatesCellPlannedDate employeeGoal={employeeGoal}/>
        </Grid.Column>
        <Grid.Column width={16} style={{margin: 0, paddingTop: 3 }}>
            <EmployeeGoalDatesCellCompletedDate employeeGoal={employeeGoal}/>
        </Grid.Column>
    </Grid>
}

export default EmployeeGoalDatesCell
