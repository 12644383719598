import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import agent from "../api/agent";
import {IRegion, IRegionFormValues} from "../models/region";
import {toast} from "react-toastify";

export default class RegionsStore {
    rootStore: RootStore
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }
    
    @observable regionsRegistry = new Map<number, IRegion>()
    @observable region: IRegion | null = null
    @observable loading = false
    @observable submitting = false
    @observable deletingId: number | null = null
    
    @computed get regionsNames () {
        // if (this.regions !== null) {
        //     return this.regions.map(region => region.name)
        // } else {
        //     return null
        // }
        return  this.regionsArray.map(region => region.name)
    }
    
    @computed get regionsArray() {
        return Array.from(this.regionsRegistry.values())
    }
    
    @action loadRegionsIfNull = async () => {
        if (this.regionsRegistry.size === 0) {
            await this.loadRegions()
        }
    }
    
    @action loadRegions = async () => {
        this.regionsRegistry.clear()
        this.loading = true
        try {
            const regions = await agent.Regions.getAll()
            runInAction(() => {
                regions.forEach(region => {
                    this.regionsRegistry.set(region.id!, region)
                })
            })
        } catch (error) {
            console.log(error)
            toast.error('Greška prilikom pribavljanja regiona')
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action loadRegion = async (id: number) => {
        this.region = null
        this.loading = true
        try {
            const region = await agent.Regions.details(id)
            runInAction(() => {
                this.region = region
                this.rootStore.clientsStore.loadToRegistry(region.clients!)
            })
            
            return region
        } catch (error) {
            console.log(error)
            toast.error('Greška prilikom pribavljanja regiona')
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action createRegion = async (regionFormValues: IRegionFormValues) => {
        this.submitting = true
        try {   
            const id = await agent.Regions.create(regionFormValues)
            runInAction(() => {
                this.regionsRegistry.set(id, { 
                    id,
                    name: regionFormValues.name,
                    clients: [],
                    isDefault: false,
                    latitude: regionFormValues.latitude,
                    longitude: regionFormValues.longitude,
                    clientsCount: 0
                })
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false    
            })
        }
    }

    @action editRegion = async (regionFormValues: IRegionFormValues) => {
        this.submitting = true
        try {
            await agent.Regions.edit(regionFormValues)
            runInAction(() => {
                this.regionsRegistry.set(regionFormValues.id, {
                    id: regionFormValues.id,
                    name: regionFormValues.name,
                    clients: [],
                    isDefault: false,
                    latitude: regionFormValues.latitude,
                    longitude: regionFormValues.longitude,
                    clientsCount: 0
                })
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action deleteRegion = async (id: number) => {
        this.deletingId = id
        try {   
            await agent.Regions.delete(id)
            runInAction(() => {
                const deletedRegionClientsCount = this.regionsRegistry.get(id)?.clientsCount ?? 0
                this.regionsRegistry.delete(id)
                const defaultRegion = this.regionsArray.filter(r => r.isDefault)[0]
                if (defaultRegion) {
                    this.regionsRegistry.set(defaultRegion.id!, {...defaultRegion, clientsCount: defaultRegion.clientsCount + deletedRegionClientsCount})
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deletingId = null
            })
        }
    }
    
}

