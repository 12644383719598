import React, {FunctionComponent, useState} from 'react'
import {Comment, Grid} from "semantic-ui-react";
import ClientGoalSingleComment from "../../details/ClientGoalSingleComment";
import {IClientGoal} from "../../../../core/models/clientGoals";
import ClientGoalCommentForm from "../../form/ClientGoalCommentForm";
import ShowMoreButton from "../../../commom/buttons/ShowMoreButton";

interface OwnProps {
    clientGoal: IClientGoal
}

type Props = OwnProps

const ClientGoalCommentCell: FunctionComponent<Props> = ({clientGoal}) => {
    const [addCommentMode, setAddCommentMode] = useState(false)

    const lastComment = (clientGoal.comments.length > 0) ?
        clientGoal.comments[clientGoal.comments.length - 1] : null


    return <Grid centered verticalAlign='middle'>
        {/*     Last comment     */}
        {lastComment && <Grid.Row>
            <Grid.Column>
                <Comment.Group>
                    <ClientGoalSingleComment compact comment={lastComment}/>
                </Comment.Group>
            </Grid.Column>
        </Grid.Row>}

        <Grid.Row>
            <Grid.Column textAlign='center'>
                {/*     Add comment     */}
                {addCommentMode ?
                    <ClientGoalCommentForm
                        small
                        clientGoalId={clientGoal.id}
                        onSubmitSuccess={() => setAddCommentMode(false)}
                        onCancel={() => setAddCommentMode(false)}
                    /> :
                    <ShowMoreButton
                        content='Dodaj komentar'
                        onClick={() => setAddCommentMode(true)}/>}
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default ClientGoalCommentCell
