import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction, toJS} from "mobx";
import {IClientInitialState, IClientInitialStateFormValues} from "../models/clientInitialStates";
import agent from "../api/agent";
import moment from "moment";
import {history} from "../../index";
import {stringNumberSort} from "../common/util/string";

export default class ClientInitialStatesStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }
    
    @observable clientInitialStatesRegistry = new Map<string, IClientInitialState>()
    @observable clientInitialState: IClientInitialState | null = null
    @observable loading = false
    @observable yearsWithAny: string[] | null = null
    @observable loadingYearsWithAny = false
    @observable filterYear = moment().format('YYYY')
    @observable generating = false
    @observable submitting = false
    @observable deleting = false
    @observable deletingId: string | null = null
    
    @computed get clientInitialStatesArray() {
        return Array.from(this.clientInitialStatesRegistry.values())
    }
        
    @computed get clientInitialStatesArrayFilteredByYear() {
        if (this.filterYear === 'all') {
            return this.clientInitialStatesArray
        } else {
            return this.clientInitialStatesArray.filter(clientInitialState => clientInitialState.year === this.filterYear)   
        }
    }
    
    @action setFilterYear = (year: string) => {
        if ((Number(year) >= 1990 && Number(year) <= 2100) || year === 'all') {
            this.filterYear = year
        }
    }
    
    @action loadClientInitialStates = async () => {
        this.loading = true
        this.clientInitialStatesRegistry.clear()
        try {
            const clientInitialStates = await agent.ClientInitialStates.list()
            runInAction(() => {
                clientInitialStates.forEach(clientInitialState => {
                    this.clientInitialStatesRegistry.set(clientInitialState.id, clientInitialState)
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action loadYearsWithAny = async () => {
        this.loadingYearsWithAny = true
        try {
            const yearsWithAny = await agent.ClientInitialStates.yearsWithAny()
            runInAction(() => {
              this.yearsWithAny = stringNumberSort(yearsWithAny)
            })
            return toJS(this.yearsWithAny)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingYearsWithAny = false
            })
        }
    }
    
    @action generateClientInitialState = async (year: string) => {
        this.generating = true
        try {   
            await agent.ClientInitialStates.generate(year)
            runInAction(() => {
                if (!this.yearsWithAny?.filter(y => y === year)) {
                    this.yearsWithAny?.push(year)
                }
                this.filterYear = year
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientInitialStates`)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.generating = false   
            })
        }
        
    }
    
    @action loadClientInitialState = async (id: string) => {
        this.loading = true
        try {
            const clientInitialState = await agent.ClientInitialStates.details(id)
            runInAction(() => {
                this.clientInitialState = clientInitialState
            })
            return clientInitialState
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action createClientInitialState = async (clientInitialStateFormValues: IClientInitialStateFormValues) => {
        this.submitting = true
        try {   
            await agent.ClientInitialStates.create(clientInitialStateFormValues)
            runInAction(() => {
                const newClientInitialState: IClientInitialState = {
                    id: clientInitialStateFormValues.id,
                    year: clientInitialStateFormValues.year,
                    value: Number(clientInitialStateFormValues.value),
                    client: this.rootStore.clientsStore.clientsRegistry.get(clientInitialStateFormValues.clientId)!
                }
                this.filterYear = clientInitialStateFormValues.year
                this.clientInitialStatesRegistry.set(newClientInitialState.id, newClientInitialState)
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientInitialStates`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editClientInitialState = async (clientInitialStateFormValues: IClientInitialStateFormValues) => {
        this.submitting = true
        try {
            await agent.ClientInitialStates.edit(clientInitialStateFormValues)
            runInAction(() => {
                const editClientInitialState: IClientInitialState = {
                    id: clientInitialStateFormValues.id,
                    year: clientInitialStateFormValues.year,
                    value: Number(clientInitialStateFormValues.value),
                    client: this.rootStore.clientsStore.clientsRegistry.get(clientInitialStateFormValues.clientId)!
                }
                this.clientInitialState = editClientInitialState
                this.clientInitialStatesRegistry.set(editClientInitialState.id, editClientInitialState)
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientInitialStates`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action deleteClientInitialState = async (id: string) => {
        this.deleting = true
        this.deletingId = id
        try {   
            await agent.ClientInitialStates.delete(id)
            runInAction(() => {
                this.clientInitialState = null 
                this.clientInitialStatesRegistry.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingId = null    
            })
        }
    }
}