import {ICloudFile} from "./cloudFiles";

export interface IPostExpressDocument {
    id: string
    date: Date
    name: string
    file: ICloudFile
}

export interface IPostExpressDocumentFormValues {
    id: string
    name: string
    date: string
    file: Blob | null
}

export class PostExpressDocument implements IPostExpressDocument {
    date: Date;
    file: ICloudFile;
    id: string;
    name: string;
    
    constructor(postExpressDocumentFormValues: IPostExpressDocumentFormValues) {
        this.id = postExpressDocumentFormValues.id
        this.file = {
            id: '',
            contentType: 'N/A',
            createdAt: new Date(),
            extension: 'N/A',
            path: '',
            updatedAt: new Date()
        }
        this.date = new Date(postExpressDocumentFormValues.date)
        this.name = postExpressDocumentFormValues.name
    }
    
}
    