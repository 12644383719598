import React from 'react';
import ReactDOM from 'react-dom';
import './core/layout/styles.css';
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import App from './App';
import {Router} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import * as serviceWorker from './serviceWorker';
import ScrollToTopOnMount from './core/layout/ScrollToTopOnMount'
import 'moment/locale/sr'
import 'antd/dist/antd.css';
import {ConfigProvider} from "antd";
import locale from 'antd/lib/locale/sr_RS'


export const history = createBrowserHistory()


ReactDOM.render(
    // <React.StrictMode>
        <Router history={history}>
            <ScrollToTopOnMount>
                <ConfigProvider locale={locale}>
                    <App/>
                </ConfigProvider>
            </ScrollToTopOnMount>
        </Router>
    // </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
