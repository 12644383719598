import moment from "moment";
import {IDateFilter} from "./DateFilter";

export const dateFilter = (rows: any, id: any, filterValue: IDateFilter) => {
    return rows.filter((row: any) => {
        const rowDateWithoutTime = moment(row.original[id]).startOf('day')
        
        if (filterValue.start && !moment(rowDateWithoutTime).isSameOrAfter(filterValue.start)) return false

        if (filterValue.end && !moment(rowDateWithoutTime).isSameOrBefore(filterValue.end)) return false

        return true
    })
}