import React, {FunctionComponent, useState} from 'react'
import {Button, Grid, Popup} from "semantic-ui-react";
import {filterCounter} from "../index";

interface OwnProps {
    initialShowValue?: boolean,
    filterValue: any,
    onClearFilterClick: () => void
}

type Props = OwnProps

const CompactFilterLayout: FunctionComponent<Props> = ({
                                                           filterValue,
                                                           initialShowValue,
                                                           onClearFilterClick,
                                                           children
                                                       }) => {

    const [showFilter, setShowFilter] = useState(initialShowValue)


    return (
        <>
            {showFilter ? <Grid>
                <Grid.Column width={16}>
                    <Button
                        fluid
                        basic
                        floated='right'
                        icon='hide'
                        content='Sakrij'
                        size='mini'
                        onClick={() => setShowFilter(false)}
                    />
                    {filterCounter(filterValue) > 0 &&
                    <Button
                        fluid
                        basic
                        floated='right'
                        content='Poništi sve'
                        icon='x'
                        size='mini'
                        onClick={() => onClearFilterClick()}
                    />}
                </Grid.Column>
                <Grid.Column width={16}>
                    {children}
                </Grid.Column>

            </Grid> : <Popup
                trigger={<Button
                    basic
                    floated='right'
                    icon='filter'
                    size='mini'
                    content={(filterCounter(filterValue) > 0) ? filterCounter(filterValue) : undefined}
                    onClick={() => setShowFilter(true)}
                />}
                content='Otrkij filtere'
            />}

        </>)
}

export default CompactFilterLayout
