import {IBill} from "../../models/bills";
import {IBrand} from "../../models/brands";

export const billUsedBrandsWithValue = (bills: IBill[]) => {
    let brands = new Map<number, IBrand>()
    bills.forEach(bill => bill.billOrders.forEach(billOrder => {
        const {productBrandId: brandId} = billOrder
        const brandExist = brands.get(brandId)
        if (brandExist) {
            brands.set(brandId, {...brandExist, value: brandExist.value! + billOrder.summaryPriceWithDiscount})
        } else {
            brands.set(brandId, {
                id: billOrder.productBrandId,
                used: true,
                
                color: billOrder.productBrandColor,
                name: billOrder.productBrandName,
                // nameId: '',
                clients: null,
                products: null,
                value: billOrder.summaryPriceWithDiscount
            })
        }
    }))

    return Array.from(brands.values())
        .sort((a, b) => b.value! - a.value!)
}