import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable} from "mobx";

export default class NavbarStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable leftSidebarVisible = window.localStorage.getItem('leftSidebarVisible') === 'true'
    @observable rightSidebarVisible = false
    @observable selectedItem = ''

    @computed get mainHubsStatusColorString() {
        const clientOrdersHubColorString = this.rootStore.clientOrdersStore.hubConnectionStatusColor
        const userPostsHubColorString = this.rootStore.userPostsStore.hubConnectionStatusColor
        const clientGoalsHubColorString = this.rootStore.clientGoalsStore.hubConnectionStatusColor
        const employeeGoalsHubColorString = this.rootStore.employeeGoalsStore.hubConnectionStatusColor

        if (clientOrdersHubColorString === 'red' || userPostsHubColorString === 'red' || clientGoalsHubColorString === 'red' || employeeGoalsHubColorString === 'red') {
            return 'red'
        } else if (clientOrdersHubColorString === 'yellow' || userPostsHubColorString === 'yellow' || clientGoalsHubColorString === 'yellow' || employeeGoalsHubColorString === 'yellow') {
            return 'yellow'
        } else if (clientOrdersHubColorString === 'green' && userPostsHubColorString === 'green' && clientGoalsHubColorString === 'green' && employeeGoalsHubColorString === 'green') {
            return 'green'
        } else {
            return undefined
        }
    }

    @computed get homePageWithCurrentLinkTrafficOrDefault() {
        const currentTraffic = this.rootStore.trafficsStore.currentTraffic ?? null
        
        if (currentTraffic) {
            return `/${currentTraffic.id}/dashboard`
        } else {
            return '/'
        }
    }
    
    @action setLeftSidebarVisible = (visible: boolean) => {
        window.localStorage.setItem('leftSidebarVisible', visible ? 'true' : 'false')
        this.leftSidebarVisible = visible
    }

    @action setRightSidebarVisible = (visible: boolean) => {
        this.rightSidebarVisible = visible
    }

    @action setSelectedItem = (itemId: string) => this.selectedItem = itemId
}