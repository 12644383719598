import {FunctionComponent} from 'react';
import Desktop, {useDesktop} from "./Desktop";
import Tablet, {useTablet} from "./Tablet";
import Mobile, {useMobile} from "./Mobile";
import NotMobile from "./NotMobile";


interface IProps {
}

const Responsive: FunctionComponent<IProps> & {
    Desktop: typeof Desktop
    Tablet: typeof Tablet
    Mobile: typeof Mobile
    NotMobile: typeof NotMobile
} = (props) => {
    return null
};

Responsive.Desktop = Desktop
Responsive.Tablet = Tablet
Responsive.Mobile = Mobile
Responsive.NotMobile = NotMobile

export const mobileSettings = {
    maxWidth: 767
}

export const useResponsive = () => {
    const isMobile = useMobile()
    const isTablet = useTablet()
    const isDesktop = useDesktop()

    return({
            isMobile,
            isTablet,
            isDesktop
        })
    
}


export default Responsive;


