import React, { FunctionComponent } from 'react';
import {AxiosResponse} from "axios";
import {Message} from "semantic-ui-react";
import {httpStatusCodeTextToSerbian} from "../http/httpStatusCodes";

interface IProps {
    error: AxiosResponse,
    text?: string
}

const ErrorMessage: FunctionComponent<IProps> = ({error, text}) => {
    
  return (
      <Message error visible>
          <Message.Header>{httpStatusCodeTextToSerbian(error.status) || error.statusText || 'Greška'}</Message.Header>
          {error.data && Object.keys(error.data.errors).length > 0 && (
              <Message.List>
                  {Object.values(error.data.errors).flat().map((err: any, i) => (
                      <Message.Item key={i}>{err && err.toString()}</Message.Item>
                  ))}
              </Message.List>
          )}
          {text && <Message.Content content={text}  />}
      </Message>
  );
};

export default ErrorMessage;
