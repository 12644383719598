import React, {FunctionComponent, useContext, useEffect} from 'react';
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import LoadingComponent from "../../../core/layout/LoadingComponent";
import {Button, Grid, Header, Icon, Image, Item, Segment, Statistic} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {Editor} from "draft-js";
import {convertStringToState} from "../../../core/common/util/draft-js";
import moment from "moment";
import {formatRsd} from "../../../core/common/util/format";
import Responsive from "../../../core/common/responsive/Responsive";
import {replaceLatinCharacters} from "../../../core/common/util/string";
import ClientActivityParticipantsIdentity from "../commom/ClientActivityParticipantsIdentity";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface DetailParams {
    id: string
}

const activityImageStyle = {
    filter: 'brightness(50%)'
};

const activityImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};


const ClientActivityDetails: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadClientActivity,
        loading,
        clientActivity,
        loadClientActivityDoc,
        loadingId

    } = rootStore.clientActivitiesStore
    const {currentTraffic} = rootStore.trafficsStore

    useEffect(() => {
        loadClientActivity(match.params.id)
    }, [
        loadClientActivity,
        match.params.id
    ])

    if (loading || clientActivity === null) return <LoadingComponent content='Učitavanje aktivnosti...'/>

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Aktivnosti',
                            linkWithoutCurrentTrafficId: 'clientActivities'
                        },
                        {
                            text: clientActivity.type,
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
            <Grid.Column computer={12} mobile={16} tablet={16}>
                <Segment.Group>


                    <Segment basic attached='top' style={{padding: '0'}}>
                        <Responsive.NotMobile>
                            <Image
                                src={`/assets/clientActivitiesTypesImages/${replaceLatinCharacters(clientActivity.type)}_d.jpg`}
                                fluid
                                style={activityImageStyle}
                            />
                        </Responsive.NotMobile>
                        <Responsive.Mobile>
                            <Image
                                src={`/assets/clientActivitiesTypesImages/${replaceLatinCharacters(clientActivity.type)}_m.jpg`}
                                fluid
                                style={activityImageStyle}
                            />
                        </Responsive.Mobile>

                        <Segment style={activityImageTextStyle} basic>
                            <Item.Group>
                                <Item>
                                    <Item.Content>
                                        <Header
                                            size='huge'
                                            content={clientActivity.type}
                                            style={{color: 'white'}}
                                        />
                                        <p>{moment(clientActivity.date).format('l')}</p>
                                        <p>
                                            Klijent{' '}
                                            <Link to={`/${currentTraffic?.id}/clients/${clientActivity.client.id}`}>
                                                <strong>{clientActivity.client.name}</strong>
                                            </Link>
                                        </p>
                                    </Item.Content>
                                </Item>
                            </Item.Group>
                        </Segment>
                    </Segment>

                    <Segment>
                        <Editor onChange={() => {
                        }} editorState={convertStringToState(clientActivity.content)} readOnly={true}/>
                    </Segment>

                    {clientActivity.observation.length > 0 &&
                    <Segment>
                        <p>{clientActivity.observation}</p>
                    </Segment>}

                    <Segment clearing>

                        <ClientActivityParticipantsIdentity clientActivity={clientActivity}/>

                        {clientActivity.cost > 0 &&
                        <Statistic floated='right' size='tiny'>
                            <Statistic.Label>Trošak</Statistic.Label>
                            <Statistic.Value>{formatRsd(clientActivity.cost)}</Statistic.Value>
                        </Statistic>}

                        {clientActivity.hasDocument &&
                        <Button
                            size='big'
                            floated='right'
                            onClick={() => loadClientActivityDoc(clientActivity.id)}
                            loading={loadingId === clientActivity.id}
                            icon
                            color='blue'
                            labelPosition='left'>
                            <Icon name='file'/>
                            Dokument
                        </Button>}
                    </Segment>

                    <Segment clearing>
                        <Button
                            as={Link}
                            icon='edit'
                            to={`/${currentTraffic?.id}/clientActivities/manage/${clientActivity.id}/edit`}
                            floated='right'
                            content='Izmeni'
                            color='orange'
                        />
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid>)
}

export default observer(ClientActivityDetails)
