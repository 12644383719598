import React from 'react';
import {Segment, Grid, Icon, Header} from 'semantic-ui-react';
import { IClientGoal} from "../../../core/models/clientGoals";
import ClientTableBasicCell from "../../clients/list/components/ClientTableBasicCell";
import moment from "moment";

const ClientGoalDetailedInfo: React.FC<{ clientGoal: IClientGoal }> = ({clientGoal}) => {

    return (
        <Segment.Group>
            <Segment attached='top'>
                <Grid>
                    <Grid.Column width={1}>
                        <Icon size='large' color='teal' name='info'/>
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <Header size='small' 
                                color='teal'
                                // style={{color: getNiceColorFromStingAsciiSum(clientGoal.category)}}
                        >
                            {clientGoal.category}
                            <Header.Subheader>
                                Postavljen {moment(clientGoal.createdAt).calendar()}
                                {(clientGoal.createdAt !== clientGoal.updatedAt) &&
                                <span>, izmenjen {moment(clientGoal.updatedAt).calendar()}</span>}
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='user' size='large' color='teal'/>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <ClientTableBasicCell client={clientGoal.client}/>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment.Group>
    );
};

export default ClientGoalDetailedInfo