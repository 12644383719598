import React, {FunctionComponent, useContext} from 'react'
import {RootStoreContext} from "../../../../core/stores/rootStore"
import {Form, Input, Label, List} from "semantic-ui-react"
import {observer} from "mobx-react-lite"
import moment from 'moment'

interface IProps {
}

const BillCartInstallmentsList: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {installmentsArray, installments, updateInstallmentValue, updateInstallmentPercentage, updateInstallmentDate, formatInstallmentsValues} = rootStore.billsCartStore
    
    return (
        <List divided>
            {installmentsArray.map(installment =>
                <List.Item key={installment.id}>
                    <List.Header>
                        {installment.paid &&
                        <Label
                            ribbon
                            content='Plaćeno'
                            color='orange'
                        />}
                        {`${installment.ordinal}. rata`}
                    </List.Header>
                    <List.Content>
                        <Input
                            size='mini'
                            as={Form.Field}
                            type='number'
                            min={0}
                            fluid
                            labelPosition='right'
                            label={{basic: true, content: 'RSD'}}
                            value={installments.get(installment.id)!.value}
                            onBlur={formatInstallmentsValues}
                            onChange={((event, data) =>
                                updateInstallmentValue(installment.id, Number(data.value)))}/>
                        <Input
                            size='mini'
                            as={Form.Field}
                            type='number'
                            fluid
                            labelPosition='right'
                            label={{basic: true, content: '%'}}
                            value={installments.get(installment.id)!.percentage}
                            onChange={((event, data) =>
                                updateInstallmentPercentage(installment.id, Number(data.value)))}/>
                        <Input
                            size='mini'
                            as={Form.Field}
                            type='date'
                            step={0.01}
                            fluid
                            labelPosition='right'
                            label={{basic: true, icon: 'calendar alternate outline'}}
                            value={moment(installments.get(installment.id)!.date).format('YYYY-MM-DD')}
                            onChange={(event, data) =>
                                updateInstallmentDate(installment.id, data.value)}/>
                    </List.Content>
                </List.Item>)}
        </List>
    );
};

export default observer(BillCartInstallmentsList)
