import {IClientGoal, IClientGoalFilter} from "../../../../models/clientGoals";

export const clientGoalFilter = (rows: any, id: any, filterValue: IClientGoalFilter) => {
    return rows.filter((row: any) => {
        const goal: IClientGoal = row.original
        
        if (filterValue.colors.length > 0 && !filterValue.colors.includes(goal.color)) {
            return false
        }
        

        if (filterValue.categories.length > 0 && !filterValue.categories.includes(goal.category)) {
            return false
        }
        
        if (filterValue.name) {
            return String(goal.name)
                .toLowerCase()
                .includes(String(filterValue.name).toLowerCase())
        }
                        
        return true
    })
}
