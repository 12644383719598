import React, { FunctionComponent } from 'react';
import {Icon, Segment, Header, Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

interface IProps {}

const NotFound: FunctionComponent<IProps> = (props) => {
  
  return (
      <Segment>
          <Header icon>
              <Icon name='search' />
                  Ups - svuda smo tražili ali nažalost nismo pronašli.
          </Header>
          <Segment.Inline>
              <Button as={Link} to='/' primary>
                  Povratak na početnu stranu
              </Button>
          </Segment.Inline>
      </Segment>
  );
};

export default NotFound;
