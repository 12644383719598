import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, reaction, runInAction} from "mobx";
import {IClientSchedule, IClientScheduleComment, IClientScheduleFormValues} from "../models/clientSchedules";
import agent from "../api/agent";
import {history} from "../../index";
import {stringNumberSort} from "../common/util/string";
import moment from "moment";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {SemanticCOLORS} from "semantic-ui-react";

export default class ClientSchedulesStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

        reaction(
            () => this.year,
            (year) => {
                if (year === 'all') {   
                    this.predicate.delete('byYear')
                } else {
                    this.setPredicate('byYear', year)
                }
                this.loadClientSchedules()
            })
    }
    
    @observable clientSchedulesRegistry = new Map<string, IClientSchedule>()
    @observable clientSchedule: IClientSchedule | null = null
    @observable loading = false
    @observable submitting = false
    @observable deletingId: string | null = null
    @observable predicate = new Map()
    @observable yearsWithAny: string[] | null = null
    @observable loadingYearsWithAny = false
    @observable year = ''

    @observable.ref hubConnection: HubConnection | null = null;
    @observable hubConnectionStatusColor: SemanticCOLORS | undefined = undefined
    @observable editingCommentId: string | null = null
    @observable deletingCommentId: string | null = null
    
    @action setHubConnectionStatusColor = (color: SemanticCOLORS | undefined) => this.hubConnectionStatusColor = color

    @computed get sortedComments() {
        if (!this.clientSchedule) return []

        return this.clientSchedule.comments.slice().reverse()
    }

    startHubConnection = async () => {
        if (this.hubConnection?.state !== 'Connected') {
            this.hubConnection!
                .start()
                .then(() => {
                    console.log(this.hubConnection!.state)
                    this.setHubConnectionStatusColor('green')
                })
                .catch((error) => {
                    console.log('Error establishing connection: ', error)
                    this.setHubConnectionStatusColor('red')
                    setTimeout(() => {
                        this.startHubConnection()
                    }, 5000);
                })
        }
    }

    @action createHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_CLIENT_SCHEDULE_CHAT_URL!, {
                accessTokenFactory: () => this.rootStore.commonStore.token!
            })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection?.onclose((error) => {
            this.setHubConnectionStatusColor('red')
            if (error) {
                setTimeout(() => {
                    this.startHubConnection()
                }, 5000)
            } else {
                this.setHubConnectionStatusColor(undefined)
            }
        })

        this.startHubConnection()

        this.hubConnection.onreconnected(() => {
            this.setHubConnectionStatusColor('green')
            console.log('Reconnected')
        })

        this.hubConnection.onreconnecting(() => {
            // this.setHubConnectionStatusColor('yellow')
            console.log('Reconnecting')
        })

        this.hubConnection.on('ReceiveClientScheduleComment', (clientScheduleComment: IClientScheduleComment) => {
            runInAction(() => {
                this.clientSchedule?.comments.push(clientScheduleComment)

                const clientScheduleFormRegistry = this.clientSchedulesRegistry.get(clientScheduleComment.clientScheduleId) ?? null


                if (clientScheduleFormRegistry) {
                    this.clientSchedulesRegistry.set(clientScheduleComment.clientScheduleId, {
                        ...clientScheduleFormRegistry,
                        comments: [
                            ...clientScheduleFormRegistry.comments,
                            clientScheduleComment
                        ]
                    })
                }
            })
        })

        this.hubConnection.on('ReceiveUpdatedClientScheduleComment', (clientScheduleComment: IClientScheduleComment) => {
            runInAction(() => {
                if (this.clientSchedule) {
                    this.clientSchedule.comments = this.clientSchedule?.comments
                        .map(comment => comment.id === clientScheduleComment.id ? clientScheduleComment : comment)
                }

                const clientScheduleFormRegistry = this.clientSchedulesRegistry.get(clientScheduleComment.clientScheduleId) ?? null

                if (clientScheduleFormRegistry) {
                    this.clientSchedulesRegistry.set(clientScheduleComment.clientScheduleId, {
                        ...clientScheduleFormRegistry,
                        comments: clientScheduleFormRegistry.comments
                            .map(comment => comment.id === clientScheduleComment.id ? clientScheduleComment : comment)
                    })
                }
            })
        })

        this.hubConnection.on('ReceiveDeletedClientScheduleComment', 
            (payload: { clientScheduleId: string, commentId: string }) => {
            runInAction(() => {
                if (this.clientSchedule) {
                    this.clientSchedule.comments = this.clientSchedule?.comments.filter(comment => comment.id !== payload.commentId)
                }

                const clientScheduleFromRegistry = this.clientSchedulesRegistry.get(payload.clientScheduleId) ?? null
                if (clientScheduleFromRegistry) {
                    this.clientSchedulesRegistry.set(payload.clientScheduleId,
                        {
                            ...clientScheduleFromRegistry,
                            comments: clientScheduleFromRegistry.comments.filter(comment => comment.id !== payload.commentId)
                        })
                }
            })
        })
    }

    @action stopHubConnection = () => {
        if (this.hubConnection) {
            this.hubConnection!.stop()
                .then(() => console.log('Connection stopped'))
                .catch((error) => console.log(error))
        }
    }
    
    @computed get clientSchedulesArray() {
        return Array.from(this.clientSchedulesRegistry.values())
    }

    @action setEditingCommentId = (id: string | null) => this.editingCommentId = id

    @action setYear = (year: string) => this.year = year

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @computed get axiosParams () {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }
    
    @action loadYearsWithAny = async () => {
        this.loadingYearsWithAny = true
        try {
            const yearsWithAny = await agent.ClientSchedules.yearsWithAny()
            runInAction(() => { 
                this.yearsWithAny = stringNumberSort(yearsWithAny)
            })
            return yearsWithAny
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingYearsWithAny = false
            })
        }
    }
    
    @action loadClientSchedule = async (options: { id?: string, clientId?: string, year?: string }) => {
        this.loading = true
        try {
            let clientSchedule: IClientSchedule
            if (options.id) {
                clientSchedule = await agent.ClientSchedules.details(options.id)
            } else if (options.clientId && options.year) {
                clientSchedule = await agent.ClientSchedules.detailsByClientAndYear(options.clientId, options.year)
            } else {
                throw new Error('Invalid options')
            }
            
            runInAction(() => {
                this.clientSchedule = clientSchedule
            })
            return clientSchedule
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action loadClientSchedules = async () => {
        this.clientSchedulesRegistry.clear()
        this.loading = true
        try {   
            const clientSchedules = await agent.ClientSchedules.list(this.axiosParams)
            runInAction(() => {
                clientSchedules.forEach(clientSchedule => {
                    this.clientSchedulesRegistry.set(clientSchedule.id, clientSchedule)
                })            
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action createClientSchedule = async (clientScheduleFormValues: IClientScheduleFormValues) => {
        this.submitting = true
        try {   
            await agent.ClientSchedules.create(clientScheduleFormValues)
            const newClientSchedule = await agent.ClientSchedules.details(clientScheduleFormValues.id)
            runInAction(() => {
                if (this.year === newClientSchedule.year || this.yearsWithAny?.length === 0 || !this.yearsWithAny?.includes(moment().format('YYYY'))) {
                    this.clientSchedulesRegistry.set(newClientSchedule.id, newClientSchedule)                    
                }
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientSchedules`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editClientSchedule = async (clientScheduleFormValues: IClientScheduleFormValues) => {
        this.submitting = true
        try {
            await agent.ClientSchedules.edit(clientScheduleFormValues)
            const updatedClientSchedule = await agent.ClientSchedules.details(clientScheduleFormValues.id)
            runInAction(() => {
                this.clientSchedulesRegistry.set(updatedClientSchedule.id, updatedClientSchedule)
                this.clientSchedule = updatedClientSchedule
                if (this.year === updatedClientSchedule.year) {
                    this.clientSchedulesRegistry.set(updatedClientSchedule.id, updatedClientSchedule)
                }
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action deleteClientSchedule = async (id: string) => {
        this.deletingId = id
        try {   
            await agent.ClientSchedules.delete(id)
            runInAction(() => {
                this.clientSchedulesRegistry.delete(id)
                if (this.clientSchedule?.id === id) {
                    this.clientSchedule = null
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deletingId = null            
            })
        }
    }

    @action addComment = async (id: string, values: any) => {
        values.clientScheduleId = id
        try {
            await this.hubConnection!.invoke('SendComment', values)
        } catch (error) {
            console.log(error)
        }
    }

    @action editComment = async (clientScheduleId: string, commentId: string, values: any) => {
        values.clientScheduleId = clientScheduleId
        values.id = commentId
        try {
            await this.hubConnection!.invoke('EditComment', values)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingCommentId = null
            })
        }
    }

    @action deleteComment = async (id: string) => {
        const request = {
            id
        }
        try {
            await this.hubConnection!.invoke('DeleteComment', request)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingCommentId = null
            })
        }
    }
    
}