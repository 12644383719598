import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {
    IProductConsumption, IProductConsumptionAnalyseEntity,
    IProductConsumptionFormValues,
    IProductConsumptionItem
} from "../models/productConsumptions";
import agent from "../api/agent";
import {history} from "../../index";
import {PRODUCT_CONSUMPTIONS_TRACKING_ID} from "../../features/productConsumptions/dashboard/ProductConsumptionsDashboard";
import {groupBy} from "../common/util/array";
import {getNiceColorFromStingAsciiSum} from "../common/util/colors";
import {v4 as uuid} from 'uuid'

export default class ProductConsumptionsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable productConsumption: IProductConsumption | null = null
    @observable productConsumptionsRegistry = new Map<string, IProductConsumption>()
    @observable submitting = false
    @observable loading = false
    @observable deletingId: string | null = null
    @observable predicate = new Map<string, string>()
    @observable lastRequestId = ''

    @observable analysisMode = window.localStorage.getItem('productConsumptionsAnalysisMode') === 'true'

    @computed get axiosParams() {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get productConsumptionsArray() {
        return Array.from(this.productConsumptionsRegistry.values())
    }

    @computed get filteredRowsFromTable() {
        return this.rootStore.tablesStore.SHIT_getFilteredRowsById(PRODUCT_CONSUMPTIONS_TRACKING_ID) as IProductConsumption[] ?? []
    }

    @computed get extractedItemsFromProductConsumptionsFilteredTable() {
        return this.filteredRowsFromTable.reduce((total, current) => total.concat(current.items), [] as IProductConsumptionItem[])

    }

    @computed get filteredItemsGropedByBrand() {
        return groupBy(this.extractedItemsFromProductConsumptionsFilteredTable, (g: IProductConsumptionItem) => g.product.brandId)
            .map(p => p[1]).map((group: IProductConsumptionItem[]) =>
                group.reduce((total: IProductConsumptionAnalyseEntity, current, index) => {
                        if (index > 0) {
                            return {
                                ...total,
                                count: total.count + current.count,
                                value: total.value + current.singleRsdValue
                            } as IProductConsumptionAnalyseEntity
                        } else {
                            return total as IProductConsumptionAnalyseEntity
                        }
                    },
                    {
                        brand: {
                            color: (group[0].product.brandColor && group[0].product.brandColor.length > 0) ? group[0].product.brandColor : getNiceColorFromStingAsciiSum(group[0].product.brandName),
                            id: group[0].product.brandId,
                            name: group[0].product.brandName
                        },
                        count: group[0].count,
                        value: group[0].summaryRsdValue,
                    } as IProductConsumptionAnalyseEntity
                ))
    }

    @computed get filteredItemsGropedByProduct() {
        return groupBy(this.extractedItemsFromProductConsumptionsFilteredTable, (g: IProductConsumptionItem) => g.product.id)
            .map(p => p[1]).map((group: IProductConsumptionItem[]) =>
                group.reduce((total: IProductConsumptionAnalyseEntity, current, index) => {
                        if (index > 0) {
                            return {
                                ...total,
                                count: total.count + current.count,
                                value: total.value + current.singleRsdValue
                            } as IProductConsumptionAnalyseEntity
                        } else {
                            return total as IProductConsumptionAnalyseEntity
                        }
                    },
                    {
                        product: group[0].product,
                        count: group[0].count,
                        value: group[0].summaryRsdValue,
                    } as IProductConsumptionAnalyseEntity
                ))
    }

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @action createProductConsumption = async (productConsumptionFormValues: IProductConsumptionFormValues) => {
        this.submitting = true
        try {
            await agent.ProductConsumptions.create(productConsumptionFormValues)
            const newProductConsumption = await agent.ProductConsumptions.details(productConsumptionFormValues.id)
            runInAction(() => {
                this.productConsumptionsRegistry.set(newProductConsumption.id, newProductConsumption)
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/productConsumptions`)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editProductConsumption = async (productConsumptionFormValues: IProductConsumptionFormValues) => {
        this.submitting = true
        try {
            await agent.ProductConsumptions.edit(productConsumptionFormValues)
            const updatedProductConsumption = await agent.ProductConsumptions.details(productConsumptionFormValues.id)
            runInAction(() => {
                this.productConsumptionsRegistry.set(updatedProductConsumption.id, updatedProductConsumption)
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/productConsumptions/${updatedProductConsumption.id}`)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action loadProductConsumptions = async () => {
        this.loading = true
        const requestId = uuid()
        this.lastRequestId = requestId
        try {
            const productConsumptions = await agent.ProductConsumptions.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.productConsumptionsRegistry.clear()
                    productConsumptions.forEach(productConsumption => {
                        this.productConsumptionsRegistry.set(productConsumption.id, productConsumption)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.productConsumptionsRegistry.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loading = false   
                }
            })
        }
    }

    @action deleteProductConsumption = async (id: string) => {
        this.deletingId = id
        try {
            await agent.ProductConsumptions.delete(id)
            runInAction(() => {
                this.productConsumptionsRegistry.delete(id)
                if (this.productConsumption?.id === id) {
                    this.productConsumption = null
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deletingId = null
            })
        }
    }

    @action loadProductConsumption = async (id: string) => {
        this.loading = true
        try {
            const productConsumption = await agent.ProductConsumptions.details(id)
            runInAction(() => {
                this.productConsumption = productConsumption
            })
            return productConsumption
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action setAnalysisMode = (analysisMode: boolean) => {
        window.localStorage.setItem('productConsumptionsAnalysisMode', analysisMode.toString())
        this.analysisMode = analysisMode
    }
} 