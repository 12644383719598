import React, {useContext} from 'react';
import {Menu, Button, Dropdown, Icon, Popup, Image, Header, Label} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import {NavLink, Link} from 'react-router-dom';
import {RootStoreContext} from '../../core/stores/rootStore';
import SpecialMenu from "./SpecialMenu";
import Responsive from "../../core/common/responsive/Responsive";
import {history} from "../../index";
import styled from "styled-components";
import {formatPrice} from "../../core/common/util/format";


const NavBar = () => {
    const rootStore = useContext(RootStoreContext);
    const {user, logout, isEmployee, isSuperAdmin, isTrafficManager} = rootStore.userStore;
    const {currentTraffic, setCurrentTraffic, trafficsArray: traffics} = rootStore.trafficsStore
    const {hubConnectionStatusColor: clientsHubConnectionStatusColor} = rootStore.clientsStore
    const {siteName, currentMonthTraffic} = rootStore.globalSettingsStore
    const {mainHubsStatusColorString, homePageWithCurrentLinkTrafficOrDefault} = rootStore.navbarStore
    const {unreadByMeCount, setPredicate: setUserPostsPredicate} = rootStore.userPostsStore

    const RightMenu = (mobile?: boolean) => {

        return (<>
            <Dropdown item text={mobile ? 'Promet' : currentTraffic?.name}>
                <Dropdown.Menu>
                    {traffics.map(traffic => (
                        <Dropdown.Item
                            key={traffic.id}
                            text={traffic.name}
                            selected={traffic.id === currentTraffic?.id}
                            onClick={() => setCurrentTraffic(traffic.id)}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            {(isTrafficManager || isSuperAdmin) &&
                <Dropdown item text={mobile ? 'Specijal' : undefined} icon={mobile ? undefined : 'th'}>
                    <Dropdown.Menu>
                        <SpecialMenu/>
                    </Dropdown.Menu>
                </Dropdown>}

            {user && (
                <>
                    <Dropdown item pointing='top left' text={mobile ? 'Profil' : user.displayName}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={Link}
                                to={`/${currentTraffic?.id}/profile/${user.username}`}
                                text='Moj profil'
                                icon='user'
                            />
                            <Dropdown.Item onClick={logout} text='Izloguj se' icon='power'/>
                        </Dropdown.Menu>
                    </Dropdown>
                </>

            )}
        </>)
    }

    return (<Menu size='large' inverted fluid style={{backgroundColor: 'transparent', marginTop: 0}}>
        <Menu.Item
            style={{margin: 0, padding: 0}}
        >
            <Responsive.Mobile>
                <Icon
                    style={{cursor: 'pointer'}}
                    name='home'
                    onClick={() => history.push(`/${currentTraffic?.id}/dashboard`)}
                />
            </Responsive.Mobile>

            <Responsive.NotMobile>
                <SiteLogoWrapper>
                    {siteName === 'Aspective.' ?
                        <Image
                            as={Link}
                            to={homePageWithCurrentLinkTrafficOrDefault}
                            style={{margin: 0, padding: 0}}
                            size='small'
                            src={'/assets/logo-white-on-transparent.png'}
                        /> :
                        <Header
                            inverted as={NavLink} to={`/${currentTraffic?.id}/dashboard`}>
                            <span className='siteTitle'>{siteName}</span>
                        </Header>}
                </SiteLogoWrapper>
            </Responsive.NotMobile>

        </Menu.Item>


        {isEmployee && <>
            <Responsive.Desktop>
                <Menu.Item>
                    <Button
                        positive
                        as={NavLink}
                        to={`/${currentTraffic?.id}/bills/createCart`}
                        content='Napravi račun'/>
                </Menu.Item>
            </Responsive.Desktop>
            <Menu.Item>
                <Responsive.NotMobile>
                    <Button
                        color='orange'
                        as={NavLink}
                        to={`/${currentTraffic?.id}/clientOrders/create`}
                        content='Nova narudžbina'/>
                </Responsive.NotMobile>
                <Responsive.Mobile>
                    <Button
                        icon
                        color='orange'
                        as={NavLink}
                        to={`/${currentTraffic?.id}/clientOrders/create`}>
                        <Icon name='cart plus'/>
                    </Button>
                </Responsive.Mobile>
            </Menu.Item>
        </>}


        <Menu.Menu position='right'>
            {unreadByMeCount > 0 &&
                <Menu.Item
                    onClick={() => {
                        setUserPostsPredicate('isReadByMe', 'excluded')
                        history.push(`/${currentTraffic?.id}/dashboard`)
                    }}
                >
                    <Label color='red'>
                        <Icon name='mail'/> {unreadByMeCount}
                    </Label>
                </Menu.Item>}

            <Menu.Item>
                <Popup
                    content='Status konekcije sa serverom'
                    trigger={
                        <div>
                            {clientsHubConnectionStatusColor &&
                                <Icon size='mini' name='circle' color={clientsHubConnectionStatusColor}/>}
                            <Icon name='signal' color={mainHubsStatusColorString}/>
                        </div>
                    }
                />
            </Menu.Item>

            <Responsive.NotMobile>
                {(currentMonthTraffic && currentMonthTraffic > 0) ? <Menu.Item>
                    <CurrentMonthTraffic>
                        {formatPrice({
                            amount: currentMonthTraffic,
                            currency: "EUR",
                            // options: {minimumFractionDigits: 0, maximumFractionDigits: 0}
                        })}
                    </CurrentMonthTraffic>
                </Menu.Item> : null}
            </Responsive.NotMobile>

            <Responsive.NotMobile>
                {RightMenu()}
            </Responsive.NotMobile>

            <Responsive.Mobile>
                <Dropdown item icon='bars'>
                    <Dropdown.Menu>
                        {RightMenu(true)}
                    </Dropdown.Menu>
                </Dropdown>

            </Responsive.Mobile>

        </Menu.Menu>
    </Menu>);
}

export default observer(NavBar);

const CurrentMonthTraffic = styled.p`
  font-size: 22px;
  background: #ffe259; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffa751, #ffe259); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffa751, #ffe259); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const SiteLogoWrapper = styled.div`
  padding: 8px 16px;
`;