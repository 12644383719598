import React, {useContext, Fragment, useEffect} from 'react';
import {Container, Segment, Header, Button, Image, Divider} from 'semantic-ui-react';
import {RootStoreContext} from "../../core/stores/rootStore";
import LoginForm from "../user/LoginForm";
import RegisterForm from "../user/RegisterForm";
import {history} from "../../index";
import LoadingComponent from "../../core/layout/LoadingComponent";


const HomePage = () => {
    const token  = window.localStorage.getItem('jwt')
    const rootStore = useContext(RootStoreContext)
    const { isLoggedIn, user, isAnyNonClientRole, logout} = rootStore.userStore
    const { openModal } = rootStore.modalStore
    const { currentTraffic } = rootStore.trafficsStore
    const { siteName } = rootStore.globalSettingsStore

    useEffect(() => {
        if (isLoggedIn && isAnyNonClientRole) {

            history.push(`${currentTraffic?.id}/dashboard`)
            
            // setTimeout(() => {
            //     history.push(`${currentTraffic?.id}/dashboard`)                
            // }, 500)
        }
    }, [
        isLoggedIn, currentTraffic, isAnyNonClientRole
    ])
    
    if (isLoggedIn && isAnyNonClientRole) {
        return <LoadingComponent content='Preusmeravanje...'/>
    }    
    
    return (
        <Segment inverted textAlign='center' vertical className='masthead'>
            <Container text>
                <Image
                    centered
                    size='massive'
                    src='/assets/AspectiveLogo1.png'
                    alt='logo'
                    style={{ marginBottom: 12 }}
                />
                {isLoggedIn && user && token ? (
                    <Fragment>
                        <Header as='h2' 
                                inverted 
                        >
                            {`Zdravo ${user!.displayName}! Dobrodošao u ${siteName}`}
                            <Header.Subheader>
                                Čeka se odobrenje Administratora.
                            </Header.Subheader>
                        </Header>
                        
                        <Divider hidden />
                        
                        <Button
                            inverted
                            
                            onClick={() => logout()}
                        >
                            Izloguj se
                        </Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Header as='h2' inverted content='Welcome to Aspective.' />
                        <Button onClick={() => openModal(<LoginForm />)} size='huge' inverted>
                            Uloguj se
                        </Button>
                        <Button onClick={() => openModal(<RegisterForm />)} size='huge' inverted>
                            Registruj se
                        </Button>
                    </Fragment>
                )}
            </Container>
        </Segment>
    );
};

export default HomePage;