import React, {FunctionComponent} from 'react'
import {Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';


interface IProps {
    editorState: EditorState,
    setEditorState: any,
    onChangeCallback: () => void,
    placeholder?: string
}

const WysiwygEditor: FunctionComponent<IProps> = ({setEditorState, editorState, onChangeCallback, placeholder = 'Sadržaj...'}) => {

    const editor = React.useRef<Editor>(null);

    const focusEditor = () => {
        editor?.current?.focus()
    }

    React.useEffect(() => {
        focusEditor()
    }, [])
    
    const handleKeyCommand = (command: any) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    // const onUnderlineClick = () => {
    //     setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    // }
    //
    // const onBoldClick = () => {
    //     setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
    // }
    //
    // const onItalicClick = () => {
    //     setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
    // }


    return (
        <div className="editorContainer">
            {/*<button type='button' onClick={onUnderlineClick}>U</button>*/}
            {/*<button type='button' onClick={onBoldClick}><b>B</b></button>*/}
            {/*<button type='button' onClick={onItalicClick}><em>I</em></button>*/}
            <div className="editors"
                 style={{border: '1px solid #DFDFDF', marginTop: '1em', marginBottom: '1em', padding: '1em'}}>
                <Editor
                    placeholder={placeholder}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={editorState1 => {
                        setEditorState(editorState1)
                        onChangeCallback()
                    }}
                />
            </div>
        </div>
    )

}

export default WysiwygEditor;

