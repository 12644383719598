
export const stringPreview = (text: string, size: number) => {
    if (!text) {
        return ''
    }
    
    let textPreview = ''
    textPreview += text.substring(0, size)
    if (text.substring(size, size + 1) !== '') {
        textPreview += '...'
    }

    return textPreview    
}

export const stringNumberSort = (stringNumberArray: string[]) => {
    return stringNumberArray.sort((a, b) => {
        const numberAYear = Number(a)
        const numberBYear = Number(b)
        if(numberAYear < numberBYear) { return -1; }
        if(numberAYear > numberBYear) { return 1; }
        return 0
    })
}

export const replaceLatinCharacters = (text: string): string => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

export const numberToStringToFixedOrInt = (number: number, digits: number) => number.toFixed(digits).replace(/[.,]00$/, '')