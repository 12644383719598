import React, {FunctionComponent, useContext} from 'react';
import {Modal} from "semantic-ui-react";
import {RootStoreContext} from "../../stores/rootStore";
import {observer} from "mobx-react-lite";

interface IProps {
}

const ModalContainer: FunctionComponent<IProps> = (props) => {
  const rootStore = useContext(RootStoreContext)
    const { modal: { open, body, size }, closeModal } = rootStore.modalStore
    
  return (
      <Modal open={open} dimmer='blurring' onClose={closeModal} size={size}>
          <Modal.Content>{body}</Modal.Content>
      </Modal>
  );
};

export default observer(ModalContainer)
