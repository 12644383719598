import React, {useContext, useEffect, useMemo} from 'react';
import Table from "../../../core/common/tables/Table";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {ClientGoalStatus, ClientGoalType} from "../../../core/models/clientGoals";
import {Message} from "semantic-ui-react";
import {useClientGoalsColumns} from "./useClientGoalsColumns";


interface IClientGoalsListProps {
    hideClient?: boolean,
    trackingId?: string,
    hideMetFilter?: boolean // todo: setiti se zasto sam ovo stavio,
    type: ClientGoalType
    status?: ClientGoalStatus
}

const ClientGoalsList = ({
                             hideClient = false,
                             trackingId,
                             type,
                             status
                         }: IClientGoalsListProps) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clientGoalsFilteredClassic,
        clientGoalsFilteredPayment,
        clientGoalsFilteredClientRequests,
        loadClientGoalsCategories,
    } = rootStore.clientGoalsStore
    const {loadRegionsIfNull} = rootStore.regionsStore
    const {loadClientCategoriesIfNull} = rootStore.clientsStore
    const {loadUsers} = rootStore.usersStore
    const {columns} = useClientGoalsColumns({hideClient, type, status});

    useEffect(() => {
        loadRegionsIfNull()
        loadClientCategoriesIfNull()
        loadClientGoalsCategories(true)
        loadUsers(true) // for participants
    }, [
        loadRegionsIfNull,
        loadClientCategoriesIfNull,
        loadClientGoalsCategories,
        loadUsers
    ])

    const data = useMemo(() =>
            ({
                [ClientGoalType.payment]: clientGoalsFilteredPayment,
                [ClientGoalType.clientRequest]: clientGoalsFilteredClientRequests,
                [ClientGoalType.classic]: clientGoalsFilteredClassic
            }[type])
        , [
            type,
            clientGoalsFilteredPayment,
            clientGoalsFilteredClientRequests,
            clientGoalsFilteredClassic
        ])

    if (data.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje {messageText[type]} po zadatom
                    kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={data}/>
    )
}

const messageText: Record<ClientGoalType, string> = {
    [ClientGoalType.payment]: 'naplate',
    [ClientGoalType.clientRequest]: 'zahtevi klijenata',
    [ClientGoalType.classic]: 'ciljevi'
}

export default observer(ClientGoalsList)
