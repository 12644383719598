import _ from "lodash";

export const groupBy = <T>(array: T[], groupator: (el: T) => any): any[] => {
    return Object.entries(
        array.reduce((elements, element) => {
            const group = groupator(element)
            
            elements[group] = elements[group] ?
                [...elements[group], element] :
                [element]
            return elements
        }, {} as { [key: string]: any[] })
    )
}

export const summator = (array: any[], accessor: string): number => {
    return array.reduce((total, current: any) => total + _.get(current, accessor) ?? NaN, 0)
}

interface ISumatorReducator {
    integrantSum: number,
    totalitySum: number,
    percentageSum: number
}

export const sumatorIntegrantTotalityWithPercentage = (array: any[], integrantAccessor: string, totalityAccessor: string, percentageType: 'sum' | 'average', disallowAveragePercentageOverload = false) => {

    const sumare: ISumatorReducator = array.reduce((total: ISumatorReducator, current: any) => {
        const currentIntegrantSum = _.get(current, integrantAccessor) ?? 0
        const currentTotalitySum = _.get(current, totalityAccessor) ?? 0

        let currentPercentage = currentIntegrantSum / currentTotalitySum

        if (disallowAveragePercentageOverload && currentPercentage > 1) {
            currentPercentage = 1
        }

        return {
            integrantSum: total.integrantSum + currentIntegrantSum,
            totalitySum: total.totalitySum + currentTotalitySum,
            percentageSum: total.percentageSum + currentPercentage
        }
    }, {
        integrantSum: 0,
        totalitySum: 0,
        percentageSum: 0
    })

    const wholePercentage = sumare.integrantSum / sumare.totalitySum * 100
    const averagePercentage = sumare.percentageSum / array.length * 100


    return {
        integrant: sumare.integrantSum,
        totality: sumare.totalitySum,
        percentage: percentageType === 'sum'
            ? wholePercentage
            : averagePercentage
    }
}

export const extract = <T>(array: T[], accessor: (element: T) => any) => {
    return array
        .reduce((c, v) => c.concat(accessor(v)), [])
}