import {IClientOrder} from "../../../../../core/models/clientsOrders";
import {observer} from "mobx-react-lite";
import {Checkbox, Grid, Item} from "semantic-ui-react";
import DateTimePickerWithShortcuts from "../../../../commom/date/DateTimePickerWithShortcuts";
import moment from "moment";
import React, {useContext} from "react";
import {RootStoreContext} from "../../../../../core/stores/rootStore";
import styled from "styled-components";

export const _ClientOrderItemActions = ({clientOrder}: { clientOrder: IClientOrder }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        setBillCreated,
        setCorrection,
        setPostExpress,
        editingId,
        setDelivered,
        setUrgent
    } = rootStore.clientOrdersStore;

    return <Item>
        <Grid>
            <Grid.Column width={16}>
                <label>Otpremljeno: </label>
                <DateTimePickerWithShortcuts
                    placeholder='Datum otpreme'
                    shortcuts={{
                        today: true,
                        tomorrow: true,
                        yesterday: true
                    }}
                    onChange={date => setDelivered(clientOrder.id, date ? moment(date).format('YYYY-MM-DD') : '')}
                    date={clientOrder.deliveredDate}
                />
            </Grid.Column>

            <Grid.Column width={16}>

                <CheckboxesWrapper>
                    <Checkbox
                        label='Napravljen račun'
                        disabled={clientOrder.id === editingId}
                        toggle
                        checked={clientOrder.billCreated}
                        onChange={(event, data) => setBillCreated(clientOrder.id, data.checked!)}
                    />

                    <Checkbox
                        label='Korekcija'
                        disabled={clientOrder.id === editingId}
                        toggle
                        checked={clientOrder.correction}
                        onChange={(event, data) => setCorrection(clientOrder.id, data.checked!)}
                    />

                    {clientOrder.deliveredDate && <Checkbox
                        label='Post express'
                        disabled={clientOrder.id === editingId}
                        toggle
                        checked={clientOrder.postExpress}
                        onChange={(event, data) => setPostExpress(clientOrder.id, data.checked!)}
                    />}
                    
                    <Checkbox
                        label='Hitno'
                        disabled={clientOrder.id === editingId}
                        toggle
                        checked={clientOrder.urgent}
                        onChange={(event, data) => setUrgent(clientOrder.id, data.checked!)}
                    />
                </CheckboxesWrapper>

            </Grid.Column>
        </Grid>

    </Item>
}

const CheckboxesWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const ClientOrderItemActions = observer(_ClientOrderItemActions);