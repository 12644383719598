import React, {FunctionComponent, useContext} from 'react'
import {Button, Form} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import TextAreaInput from "../../../core/common/form/TextAreaInput";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";

interface OwnProps {
    clientGoalId: string,
    onCancel?: () => void,
    onSubmitSuccess?: () => void,
    small?: boolean
}

type Props = OwnProps

const ClientGoalCommentForm: FunctionComponent<Props> = ({
                                                             clientGoalId,
                                                             onCancel,
                                                             onSubmitSuccess,
                                                             small
                                                         }) => {
    const rootStore = useContext(RootStoreContext)
    const {hubConnectionStatusColor, addComment} = rootStore.clientGoalsStore

    const handleOnFinalFormSubmit = (values: any) => {
        return addComment(clientGoalId, values)
    }

    return <FinalForm
        onSubmit={handleOnFinalFormSubmit}
        render={({
                     handleSubmit,
                     submitting,
                     form,
                     invalid,
                     dirtySinceLastSubmit,
                     pristine,
                     values
                 }) => (
            <Form onSubmit={() => handleSubmit()!.then(() => {
                form.reset()
                if (onSubmitSuccess) {
                    onSubmitSuccess()
                }
            })}>
                <Field
                    name='content'
                    component={TextAreaInput}
                    size={small ? 'mini' : undefined}
                    hideMessage
                    rows={2}
                    placeholder='Dodaj komentar'
                />
                {onCancel &&
                <Button
                    size={small ? 'mini' : undefined}
                    content='Odustani'
                    onClick={onCancel}
                />}
                <Button
                    size={small ? 'mini' : undefined}
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine || hubConnectionStatusColor !== 'green' || !values.content}
                    loading={submitting}
                    content='Dodaj komentar'
                    labelPosition='left'
                    icon='edit'
                    primary
                />
            </Form>
        )}
    />
}

export default observer(ClientGoalCommentForm)
