export const clientStatusOptions = [
    { key: 'prioritet1', text: 'Prioritet 1', value: 'Prioritet 1' },
    { key: 'prioritet2', text: 'Prioritet 2', value: 'Prioritet 2' },
    { key: 'prioritet3', text: 'Prioritet 3', value: 'Prioritet 3' },
    { key: 'prioritet4', text: 'Prioritet 4', value: 'Prioritet 4' },
    { key: 'prioritet5', text: 'Prioritet 5', value: 'Prioritet 5' },
    { key: 'zanemarljiv', text: 'Zanemarljiv', value: 'Zanemarljiv' }
];

export const clientTypesOptions = [
    { key: 'potencijalni', text: 'Potencijalni', value: 'Potencijalni' },
    { key: 'akvizicija', text: 'Akvizicija', value: 'Akvizicija' },
    { key: 'aktivan', text: 'Aktivan', value: 'Aktivan' },
    { key: 'pasivan', text: 'Pasivan', value: 'Pasivan' }
];