import React, {FunctionComponent, useContext} from 'react';
import {Icon} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../core/stores/rootStore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {history} from "../../index";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {
    FcBiomass,
    FcBullish,
    FcBusinessman,
    FcCalendar,
    FcClock,
    FcCurrencyExchange,
    FcDebt,
    FcDocument,
    FcFolder,
    FcInTransit,
    FcMoneyTransfer,
    FcPodiumWithAudience,
    FcStatistics,
    FcTodoList
} from 'react-icons/fc'
import {MdReportProblem} from 'react-icons/md'
import {Badge, Menu as MatMenu, MenuItem as MatMenuItem} from "@material-ui/core";
import {
    BsChatSquareQuote,
    FaRegComments,
    FaTasks,
    FaUserLock,
    FiTarget,
    GiConvergenceTarget,
    GiTakeMyMoney,
    IoCalendarOutline,
    RiFolderFill,
    RiFolderLockFill
} from "react-icons/all";
import {ClientGoalType} from "../../core/models/clientGoals";
import {EmployeeGoalType} from "../../core/models/employeeGoals";
import {clientGoalNavbarIdFromType} from "../clientGoals/dashboard/ClientGoalsDashboard";

interface IProps {
}

const LeftSideNavBar: FunctionComponent<IProps> = () => {

    const rootStore = useContext(RootStoreContext)
    const {selectedItem} = rootStore.navbarStore
    const {currentTraffic} = rootStore.trafficsStore
    const {isEmployee, isTrafficManager} = rootStore.userStore
    const {newClientOrders} = rootStore.clientOrdersStore
    const {documentationFoldersArray: documentationFolders} = rootStore.documentationsStore

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            {isEmployee &&
            <>
                {/*     Plan rada       */}
                <List>
                    <MatMenu
                        anchorEl={anchorEl}
                        keepMounted
                        open={anchorEl?.id === 'clientSchedulesMenu'}
                        onClose={handleClose}
                    >
                        <MatMenuItem
                            selected={selectedItem === 'clientSchedules'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/clientSchedules`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <FiTarget/>
                            </ListItemIcon>
                            Plan prometa
                        </MatMenuItem>

                        <MatMenuItem
                            selected={selectedItem === 'clientGoals'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/clientGoals?type=${ClientGoalType.classic}`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <GiConvergenceTarget/>
                            </ListItemIcon>
                            Ciljevi
                        </MatMenuItem>

                        <MatMenuItem
                            selected={selectedItem === 'employeeGoals'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/employeeGoals?type=${EmployeeGoalType.classic}`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <FaTasks/>
                            </ListItemIcon>
                            Tekuće obaveze
                        </MatMenuItem>

                        <MatMenuItem
                            selected={selectedItem === 'employeeGoals-private'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/employeeGoals?type=${EmployeeGoalType.private}`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <FaUserLock/>
                            </ListItemIcon>
                            Privatne obaveze
                        </MatMenuItem>

                        <MatMenuItem
                            selected={selectedItem === clientGoalNavbarIdFromType[ClientGoalType.clientRequest]}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/clientGoals?type=${ClientGoalType.clientRequest}`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <BsChatSquareQuote />
                            </ListItemIcon>
                            Zahtevi klijenata
                        </MatMenuItem>
                        
                        <MatMenuItem
                            selected={selectedItem === 'clientGoals-payments'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/clientGoals?type=${ClientGoalType.payment}`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <GiTakeMyMoney/>
                            </ListItemIcon>
                            Naplate
                        </MatMenuItem>

                        <MatMenuItem
                            selected={selectedItem === 'employeeGoals-problems'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/employeeGoals?type=${EmployeeGoalType.problem}`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <MdReportProblem/>
                            </ListItemIcon>
                            Uočeni problemi
                        </MatMenuItem>

                        <MatMenuItem
                            selected={selectedItem === 'clientTours'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/clientTours`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <IoCalendarOutline/>
                            </ListItemIcon>
                            Kalendar obilazaka</MatMenuItem>
                        <MatMenuItem
                            selected={selectedItem === 'commentsSection'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/commentsSection`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                <FaRegComments/>
                            </ListItemIcon>
                            Komentari</MatMenuItem>
                    </MatMenu>

                    <ListItem
                        id='clientSchedulesMenu'
                        button aria-controls="clientSchedulesMenu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        selected={selectedItem === 'scheduleOfWork'}
                    >
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcCalendar/>
                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Plan rada'/>
                    </ListItem>
                </List>

                <Divider/>

                {/*     Dokumentacija       */}
                <List>

                    <ListItem
                        button
                        onClick={() => history.push(`/${currentTraffic?.id}/documentations/create`)}
                        selected={selectedItem === 'documentationsCreate'}
                    >
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <FcFolder/>
                                <Icon corner name='add' color='orange'/>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Dodaj dokumentaciju'/>
                    </ListItem>

                    <MatMenu
                        anchorEl={anchorEl}
                        keepMounted
                        open={anchorEl?.id === 'documentationMenu'}
                        onClose={handleClose}
                    >
                        {documentationFolders.map(folder => <MatMenuItem
                            key={folder.id}
                            selected={selectedItem === `documentationFolder-${folder.id}`}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/documentationFolder/${folder.id}`)
                                handleClose()
                            }}>
                            <ListItemIcon>
                                {folder.private ?
                                    <RiFolderLockFill/> :
                                    <RiFolderFill/>}
                            </ListItemIcon>
                            {folder.name}</MatMenuItem>)}


                    </MatMenu>

                    <ListItem
                        id='documentationMenu'
                        button aria-controls="documentationMenu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcFolder/>
                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Dokumentacija'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/clientOrders/create`)}
                              selected={selectedItem === 'clientOrdersCreate'}>
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <FcInTransit/>
                                <Icon corner name='add' color='violet'/>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Kreiraj narudžbinu'/>
                    </ListItem>

                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/clientOrders`)}
                              selected={selectedItem === 'clientOrders'}>
                        <ListItemIcon>
                            <Badge badgeContent={newClientOrders.length > 0 ? newClientOrders.length : undefined}
                                   color="primary">
                                <FcInTransit size='2em'/>
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary='Lista narudžbina'/>
                    </ListItem>

                </List>

                <Divider/>

                {/*<List>*/}
                {/*    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/onHoldProducts`)}*/}
                {/*              color='primary'*/}
                {/*              selected={selectedItem === 'onHoldProductsDashboard'}>*/}
                {/*        <ListItemIcon>*/}
                {/*            <Icon size='big'>*/}
                {/*                <FcClock/>*/}
                {/*            </Icon>*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary='Proizvodi na čekanju'/>*/}
                {/*    </ListItem>*/}
                {/*</List>*/}

                <List>
                    <MatMenu
                        id="on-hold-products-menu"
                        keepMounted
                        open={anchorEl?.id === 'onHoldProductsMenu'}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                    >

                        <MatMenuItem
                            selected={selectedItem === 'onHoldProductsCreate'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/onHoldProducts/create`)
                                handleClose()
                            }}>Dodaj proizvod na čekanju</MatMenuItem>
                        <MatMenuItem
                            selected={selectedItem === 'onHoldProductsDashboard'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/onHoldProducts`)
                                handleClose()
                            }}>Lista proizvoda na čekanju</MatMenuItem>
                        <Divider />
                        <MatMenuItem
                            selected={selectedItem === 'requiredProductsAdd'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/requiredProducts/add`)
                                handleClose()
                            }}>Dodaj traženi proizvod</MatMenuItem>
                        <MatMenuItem
                            selected={selectedItem === 'requiredProductsDashboard'}
                            onClick={() => {
                                history.push(`/${currentTraffic?.id}/requiredProducts`)
                                handleClose()
                            }}>Lista traženih proizvoda</MatMenuItem>
                    </MatMenu>
                    <ListItem
                        id='onHoldProductsMenu'
                        button
                        aria-controls="on-hold-products-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        // onClick={() => history.push(`/${currentTraffic?.id}/clients`)} 
                        color='primary'
                        // selected={selectedItem === 'clients'}
                    >
                        <ListItemIcon>
                            <Icon>
                                <FcClock size='2em'/>
                            </Icon>
                        </ListItemIcon>
                        <ListItemText primary='Proizvodi na čekanju'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <MatMenu
                        id="clients-menu"
                        keepMounted
                        open={anchorEl?.id === 'clientsMenu'}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                    >
                        <MatMenuItem onClick={() => {
                            history.push(`/${currentTraffic?.id}/clients/create`)
                            handleClose()
                        }}>Dodaj klijenta</MatMenuItem>
                        <MatMenuItem onClick={() => {
                            history.push(`/${currentTraffic?.id}/clients`)
                            handleClose()
                        }}>Lista klijenata</MatMenuItem>
                    </MatMenu>
                    <ListItem
                        id='clientsMenu'
                        button
                        aria-controls="clients-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        // onClick={() => history.push(`/${currentTraffic?.id}/clients`)} 
                        color='primary'
                        selected={selectedItem === 'clients'}>
                        <ListItemIcon>
                            <Icon>
                                <FcBusinessman size='2em'/>
                            </Icon>
                        </ListItemIcon>
                        <ListItemText primary='Klijenti'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/products`)}
                              selected={selectedItem === 'products'}>
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <FcBiomass/>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Proizvodi'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/bills/createCart`)}
                              selected={selectedItem === 'billsCreate'}>
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcDocument/>
                                </Icon>
                                <Icon name='add' corner color='blue'/>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Napravi račun'/>
                    </ListItem>
                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/bills`)}
                              selected={selectedItem === 'billsDashboard'}>
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcDocument/>
                                </Icon>
                                <Icon corner>
                                    <FcDocument/>

                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Računi'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <MatMenu
                        id="payments-menu"
                        keepMounted
                        open={anchorEl?.id === 'paymentsMenu'}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                    >
                        <MatMenuItem onClick={() => {
                            history.push(`/${currentTraffic?.id}/payments/create`)
                            handleClose()
                        }}>Dodaj uplatu</MatMenuItem>
                        <MatMenuItem onClick={() => {
                            history.push(`/${currentTraffic?.id}/payments`)
                            handleClose()
                        }}>Lista uplata</MatMenuItem>
                    </MatMenu>

                    <ListItem
                        id='paymentsMenu'
                        aria-controls="payments-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color='primary'
                        button
                        selected={selectedItem === 'payments'}>
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <FcMoneyTransfer/>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Uplate'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <MatMenu
                        id="activities-menu"
                        keepMounted
                        open={anchorEl?.id === 'clientActivitiesMenu'}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                    >
                        <MatMenuItem onClick={() => {
                            history.push(`/${currentTraffic?.id}/clientActivities/create`)
                            handleClose()
                        }}>Dodaj aktivnost</MatMenuItem>
                        <MatMenuItem onClick={() => {
                            history.push(`/${currentTraffic?.id}/clientActivities`)
                            handleClose()
                        }}>Lista aktivnosti</MatMenuItem>
                    </MatMenu>

                    <ListItem
                        id='clientActivitiesMenu'
                        aria-controls="activities-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color='primary'
                        button
                        selected={selectedItem === 'clientActivities'}
                    >
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcTodoList/>
                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Aktivnosti'/>
                    </ListItem>
                </List>

            </>}
            {isTrafficManager &&
            <>
                <Divider/>

                <List>
                    <MatMenu
                        anchorEl={anchorEl}
                        keepMounted
                        open={anchorEl?.id === 'productConsumptionsMenu'}
                        onClose={handleClose}
                    >
                        <MatMenuItem onClick={() => {
                            history.push(`/${currentTraffic?.id}/productConsumptions/create`)
                            handleClose()
                        }}>Dodaj rashod</MatMenuItem>
                        <MatMenuItem onClick={() => {
                            history.push(`/${currentTraffic?.id}/productConsumptions`)
                            handleClose()
                        }}>Lista rashoda</MatMenuItem>
                    </MatMenu>

                    <ListItem
                        id='productConsumptionsMenu'
                        button aria-controls="productConsumptionsMenu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        selected={selectedItem === 'productConsumptions'}
                    >
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcPodiumWithAudience/>
                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Rashod proizvoda'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/trafficAnalysis`)}
                              selected={selectedItem === 'trafficAnalysis'}
                    >
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcStatistics/>
                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Analiza prometa'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/receivables`)}
                              selected={selectedItem === 'receivables'}
                    >
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcDebt/>
                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Potraživanja'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/generalExpenses`)}
                              selected={selectedItem === 'expenses'}>
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcCurrencyExchange/>
                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Troškovi'/>
                    </ListItem>
                </List>

                <Divider/>

                <List>
                    <ListItem button onClick={() => history.push(`/${currentTraffic?.id}/profitability`)}
                              selected={selectedItem === 'profitability'}
                    >
                        <ListItemIcon>
                            <Icon.Group size='big'>
                                <Icon>
                                    <FcBullish/>
                                </Icon>
                            </Icon.Group>
                        </ListItemIcon>
                        <ListItemText primary='Profitabilnost'/>
                    </ListItem>
                </List>
            </>}
        </>
    )
}

export default observer(LeftSideNavBar)

