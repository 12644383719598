import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {
    IPostExpressDocument,
    IPostExpressDocumentFormValues,
    PostExpressDocument
} from "../models/postExpressDocuments";
import agent from "../api/agent";
import moment from "moment";

export default class PostExpressDocumentsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        makeObservable(this)
    }

    @observable postExpressDocumentsRegistry = new Map<string, IPostExpressDocument>()
    @observable loading = false
    @observable predicate = new Map<string, string>()
    @observable downloadingFileDate = ''
    @observable downloadingDocumentId = ''
    @observable documentDeletingId = ''
    @observable submitting = false


    @computed get axiosParams() {
        const params = new URLSearchParams();

        this.predicate.forEach((value, key) => {
            params.append(key, value)
        })
        return params;
    }

    @computed get postExpressDocumentsArray() {
        return Array.from(this.postExpressDocumentsRegistry.values())
    }
    
    // @action getPostExpressDocument = (date: string) => this.postExpressDocumentsRegistry.get(date) ?? null

    // @action addMockDoc = () => {
    //     const doc: IPostExpressDocument = {
    //         id: new Date().toISOString(),
    //         date: new Date('2021-9-12'),
    //         file: {
    //             id: 'adss',
    //             path: 'sadsad',
    //             updatedAt: new Date(),
    //             extension: 'asdasd',
    //             createdAt: new Date(),
    //             contentType: 'adsasd'
    //         },
    //         name: 'Name'
    //     }
    //    
    //     this.postExpressDocumentsRegistry.set(moment(doc.date).format('l'), doc)
    // }
    //
    // @action clearRegistry = () => this.postExpressDocumentsRegistry.clear()
    
    @action getFile = async (id: string, date = '') => {
        this.downloadingFileDate = date
        this.downloadingDocumentId = id
        try {
            const file = await agent.PostExpressDocuments.getFile(id)
            window.open(URL.createObjectURL(file))
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.downloadingDocumentId = ''
                this.downloadingFileDate = ''
            })
        }
    }

    @action clearPredicates = () => this.predicate.clear()

    @action setPredicate = (predicate: string, value: string) => this.predicate.set(predicate, value)

    @action setDateRangePredicate = (start: string | null, end: string | null) => {
        if (start) {
            this.setPredicate('startDate', start)
        }

        if (end) {
            this.setPredicate('endPredicate', end)
        }
    }

    @action loadPostExpressDocuments = async () => {
        this.postExpressDocumentsRegistry.clear()
        this.loading = true
        try {
            const postExpressDocuments = await agent.PostExpressDocuments.list(this.axiosParams)
            runInAction(() => {
                postExpressDocuments.forEach(postExpressDocument => {
                    this.postExpressDocumentsRegistry.set(moment(postExpressDocument.date).format('l'), postExpressDocument)
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action addDocument = async (postExpressDocumentFormValues: IPostExpressDocumentFormValues) => {
        this.submitting = true
        try {
            await agent.PostExpressDocuments.create(postExpressDocumentFormValues)
            runInAction(() => {
                    const newDocument = new PostExpressDocument(postExpressDocumentFormValues)

                    this.postExpressDocumentsRegistry.set(moment(newDocument.date).format('l'), newDocument)
                })
        } catch
            (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action deleteDocument = async (postExpressDocument: IPostExpressDocument) => {
        this.documentDeletingId = postExpressDocument.id
        try {
            await agent.PostExpressDocuments.delete(postExpressDocument.id)
            runInAction(() => {
                this.postExpressDocumentsRegistry.delete(moment(postExpressDocument.date).format('l'))
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.documentDeletingId = ''
            })
        }
    }
}