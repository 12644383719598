import {Icon, Label} from "semantic-ui-react";
import {IClientOrder, isClientOrderComplete} from "../../../../../core/models/clientsOrders";
import React, {useContext} from "react";
import {RootStoreContext} from "../../../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";

const _ClientOrderItemLabels = ({clientOrder}: { clientOrder: IClientOrder }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        newClientOrdersIds,
    } = rootStore.clientOrdersStore;


    return (<Label basic color='blue' style={{border: '0px'}} size='big' attached='top right'>
        {clientOrder.correction &&
            <Icon size='big' color='red' name='pencil'/>}
        {clientOrder.postExpress &&
            <Icon size='big' color='yellow' name='mail'/>}
        {isClientOrderComplete(clientOrder) &&
            <Icon size='big' color='green' name='check'/>}
        {newClientOrdersIds.includes(clientOrder.id) &&
            <Label color='violet' content='NOVO'/>}
    </Label>)
}

export const ClientOrderItemLabels =  observer(_ClientOrderItemLabels)