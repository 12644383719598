import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import Table from "../../../core/common/tables/Table";
import {Header} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {Cell, FilterProps} from "react-table";
import {IBillOrderAnalyzeEntity} from "../../../core/models/billOrders";
import SumTableHeader from "../../../core/common/tables/headers/SumTableHeader";
import {formatRsd} from "../../../core/common/util/format";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";
import MultiselectFilter from "../../../core/common/tables/filters/multiselect/MultiselectFilter";


interface IProps {
    trackingId?: string
}


const IncomeTrafficsGroupByClientCategoryList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {incomeTrafficsGroupByClientCategory} = rootStore.profitabilityStore
    const { clientCategoriesAsOptions, loadClientCategoriesIfNull } = rootStore.clientsStore

    useEffect(() => {
        loadClientCategoriesIfNull()
    },[
        loadClientCategoriesIfNull
    ])

    const columns = useMemo(() => [
        {
            Header: 'Kategorija',
            accessor: 'clientCategory',
            Cell: ({ row: { original: analyseEntity } }: Cell<IBillOrderAnalyzeEntity>) => <Header as='h4' style={{color: analyseEntity.clientCategoryColor!}} >{analyseEntity.clientCategory!}</Header>,
            Filter: (d: FilterProps<any>) => MultiselectFilter(d, clientCategoriesAsOptions, 'Kategorija'),
            filter: 'multiselectFilter'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Nabavna cena u RSD'}
                                                  accessor={'summaryBasePriceInRsd'}/>,
            accessor: 'summaryBasePriceInRsd',
            Cell: ({ row: { original: analyseEntity } }: Cell<IBillOrderAnalyzeEntity>) => <span>{formatRsd(analyseEntity.summaryBasePriceInRsd)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Prodajna'}
                                                  accessor={'summaryPriceWithDiscountWithBillDiscount'}/>,
            accessor: 'summaryPriceWithDiscountWithBillDiscount',
            Cell: ({ row: { original: analyseEntity } }: Cell<IBillOrderAnalyzeEntity>) => <span>{formatRsd(analyseEntity.summaryPriceWithDiscountWithBillDiscount)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'RUC'}
                                                  accessor={'differenceInPrice'}/>,
            accessor: 'differenceInPrice',
            Cell: ({ row: { original: analyseEntity } }: Cell<IBillOrderAnalyzeEntity>) => <span>{formatRsd(analyseEntity.differenceInPrice)}</span>,
            id: 'differenceInPrice',
            Filter: NumberFilter,
            filter: 'numberFilter'
        },
        {
            id: 'diffInPriceCoef',
            Header: (row: any) => {
                const {
                    differenceInPrice,
                    summaryPriceWithDiscountWithBillDiscount
                } = row.filteredRows.reduce((total: { differenceInPrice: number, summaryPriceWithDiscountWithBillDiscount: number }, current: any) => {
                    return {
                        differenceInPrice: total.differenceInPrice + current.original.differenceInPrice,
                        summaryPriceWithDiscountWithBillDiscount: total.summaryPriceWithDiscountWithBillDiscount + current.original.summaryPriceWithDiscountWithBillDiscount
                    }
                }, {
                    differenceInPrice: 0,
                    summaryPriceWithDiscountWithBillDiscount: 0
                })

                return (
                    <div>
                        <Header sub>Koeficijent RUC-a</Header>
                        <strong>Prosek: {(differenceInPrice / summaryPriceWithDiscountWithBillDiscount).toFixed(2)}</strong>
                    </div>
                )
            },
            accessor: (d: IBillOrderAnalyzeEntity) => d.differenceInPrice / d.summaryPriceWithDiscountWithBillDiscount,
            Cell: ({ row: { original: analyseEntity } }: Cell<IBillOrderAnalyzeEntity>) => <span>{(analyseEntity.differenceInPrice / analyseEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter'
        },
        {
            id: 'margin',
            Header: (row: any) => {
                const {
                    summaryPriceWithDiscountWithBillDiscount,
                    summaryBasePriceInRsd
                } = row.filteredRows.reduce((total: { summaryPriceWithDiscountWithBillDiscount: number, summaryBasePriceInRsd: number }, current: any) => {
                    return {
                        summaryPriceWithDiscountWithBillDiscount: total.summaryPriceWithDiscountWithBillDiscount + current.original.summaryPriceWithDiscountWithBillDiscount,
                        summaryBasePriceInRsd: total.summaryBasePriceInRsd + current.original.summaryBasePriceInRsd
                    }
                }, {
                    summaryPriceWithDiscountWithBillDiscount: 0,
                    summaryBasePriceInRsd: 0
                })

                return (
                    <div>
                        <Header sub>Marža</Header>
                        <strong>Prosek: {(summaryPriceWithDiscountWithBillDiscount / summaryBasePriceInRsd).toFixed(2)}</strong>
                    </div>
                )
            },
            accessor: (d: IBillOrderAnalyzeEntity) => d.summaryPriceWithDiscountWithBillDiscount / d.summaryBasePriceInRsd,
            Cell: ({ row: { original: analyseEntity } }: Cell<IBillOrderAnalyzeEntity>) => 
                <span>{(analyseEntity.summaryPriceWithDiscountWithBillDiscount / analyseEntity.summaryBasePriceInRsd).toFixed(2)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter'
        }
    ], [
        clientCategoriesAsOptions
    ])

    return (
        <Table
            trackingId={trackingId}
            data={incomeTrafficsGroupByClientCategory}
            columns={columns}
        />
    )
}

export default observer(IncomeTrafficsGroupByClientCategoryList)
