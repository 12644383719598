import {FilterProps} from "react-table";
import React from "react";
import {Input} from "semantic-ui-react";

export interface IDateFilter {
    start: string
    end: string
}

export const DateFilter = ({
                               column: {
                                   filterValue,
                                   preFilteredRows,
                                   filteredRows,
                                   setFilter,
                                   filter
                               }
                           }: FilterProps<IDateFilter>,
                           defaultStart: string,
                           defaultEnd: string
) => {

    return (<>
            <Input
                size='mini'
                label={{basic: true, content: 'Od'}}
                labelPosition='left'
                type='date'
                placeholder='Od'
                value={filterValue?.start}
                onChange={(event, data) => setFilter({...filterValue, start: data.value})}
            />
            <Input
                size='mini'
                label={{basic: true, content: 'Do'}}
                labelPosition='left'
                type='date'
                placeholder='Do'
                value={filterValue?.end}
                onChange={(event, data) => setFilter({...filterValue, end: data.value})}
            />
        </>
    );
}