import {useContext, useEffect} from 'react'
import {RootStoreContext} from "../../../core/stores/rootStore";

export const useNavbarSelectedItem = (item: string) => {
    const { navbarStore: { setSelectedItem } } = useContext(RootStoreContext)
    
    useEffect(() => {
        setSelectedItem(item)
        
        return () => {
            setSelectedItem('')
        }
    }, [
        setSelectedItem,
        item
    ])
}