import React, {FunctionComponent, useContext, useMemo} from 'react';
import Table from "../../../core/common/tables/Table";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {IPricelist} from "../../../core/models/pricelists";
import {Cell} from "react-table";
import {Button, Header, Icon, Item, Message, Segment} from "semantic-ui-react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import {Link} from "react-router-dom";

interface IProps {
}

const PricelistsList: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        pricelistsArray: pricelists,
        deleting,
        deletingId,
        deletePricelist,
    } = rootStore.pricelistsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {openModal, closeModal} = rootStore.modalStore

    const columns = useMemo(() => [
        {
            Header: 'Naziv',
            accessor: 'name',
            Cell: ({row: {original: pricelist}}: Cell<IPricelist>) => (<Item>
                {pricelist.isBase ?
                    <Label ribbon color='grey' content='Nabavna cena'/> :
                    pricelist.isDefault ?
                        <Label ribbon color='teal' content='Redovni'/> :
                        pricelist.isSelling ?
                            <Label ribbon color='violet' content='Prodajna cena'/> :
                            ''}
                <Item.Content content={pricelist.name}/>
            </Item>)
        },
        {
            Header: 'Upravljanje',
            Cell: ({row: {original: pricelist}}: Cell<IPricelist>) => <Button.Group>
                <Button as={Link} to={`/${currentTraffic?.id}/pricelists/manage/${pricelist.id}/edit`} icon='edit'
                        color='yellow'/>
                {(!pricelist.isDefault && !pricelist.isBase && !pricelist.isSelling) &&
                <Button onClick={() => openModal(
                    <Segment basic clearing>
                        <Header textAlign='center' as='h2'>
                            Brisanje cenovnika
                            <Icon color='red' name='warning circle'/>
                        </Header>
                        <p style={{textAlign: 'center'}}>
                            Da li zaista želiš da obrišeš cenovnik <strong>{pricelist.name} ?</strong>
                        </p>
                        <Button color='red' floated='right' onClick={(event: any, data) => {
                            event.target.className += ' loading'
                            deletePricelist(pricelist.id!)
                                .then(() => closeModal())
                        }}>
                            <Icon name='checkmark'/> Da
                        </Button>
                        <Button onClick={() => closeModal()} floated='right'>
                            <Icon name='remove'/> Ne
                        </Button>
                    </Segment>, 'small'
                )} icon='trash alternate' color='red' loading={(deletingId === pricelist.id) && deleting}/>}
            </Button.Group>
        }
    ], [deletingId, deleting, deletePricelist, closeModal, currentTraffic, openModal])

    if (pricelists.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje cenovnici po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (
        <Table
            columns={columns}
            data={pricelists}/>);
};

export default observer(PricelistsList)