import {IClient} from "./clients";

export interface IClientInitialState {
    id: string,
    client: IClient
    year: string
    value: number
}

export interface IClientInitialStateFormValues {
    id: string,
    clientId: string
    year: string
    value: string
}

export class ClientInitialStateFormValues implements IClientInitialStateFormValues{
    clientId = ''
    id = ''
    value = ''
    year = ''
    
    constructor(clientInitialState?: IClientInitialState) {
        if (clientInitialState) {
            this.id = clientInitialState.id
            this.clientId = clientInitialState.client.id!
            this.value = clientInitialState.value.toString()
            this.year = clientInitialState.year
        }
    }
}

