import React, {FunctionComponent, useContext, useState} from 'react'
import {Grid, Header, Icon, Label, Loader, Segment} from "semantic-ui-react";
import moment from "moment";
import {EmployeeGoalStatus, IEmployeeGoal} from "../../../core/models/employeeGoals";
import DateTimePickerWithShortcuts from "../../commom/date/DateTimePickerWithShortcuts";
import ShowMoreButton from "../../commom/buttons/ShowMoreButton";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {
    dateToLocaleStringOrAccentYesterdayTodayAndTomorrow
} from "../../../core/common/util/date";

interface OwnProps {
    employeeGoal: IEmployeeGoal
}

type Props = OwnProps

const EmployeeGoalDatesCellPlannedDate: FunctionComponent<Props> = ({employeeGoal}) => {
    const rootStore = useContext(RootStoreContext)
    const {updateDate} = rootStore.employeeGoalsStore

    const [editMode, setEditMode] = useState(false)
    const [showEditIcon, setShowEditIcon] = useState(false)

    return <>
        {editMode ?
            <Segment.Group compact>
                <Segment attached='bottom' style={{padding: 5}}>

                    <Label
                        basic
                        style={{border: 0}}
                        attached='top right'>
                        <Loader size='mini' inline active={employeeGoal.flags?.updating}/>
                        <Icon
                            style={{cursor: 'pointer'}}
                            name='x'
                            circular
                            size='small'
                            onClick={() => setEditMode(false)}
                        />
                    </Label>
                    <DateTimePickerWithShortcuts
                        placeholder='Planirani datum'
                        shortcuts={{
                            today: true,
                            tomorrow: true,
                            nextWeek: true
                        }}
                        vertical
                        disabled={employeeGoal.flags?.updating}
                        date={employeeGoal.date ? moment(employeeGoal.date) : null}
                        onChange={date =>
                            updateDate(employeeGoal.id, 'planed', date ? date.format('YYYY-MM-DD') : null)}/>
                </Segment>
            </Segment.Group> :
            <div>
                {employeeGoal.date ? <Header
                        size='tiny'
                        color='grey'>
                        <Header.Content>
                            <Header.Subheader
                                onMouseEnter={() => setShowEditIcon(true)}
                                onMouseLeave={() => setShowEditIcon(false)}
                            >
                                Predviđen:
                                {moment(employeeGoal.date).isBetween(moment().subtract(2, 'day'), moment().add(2, 'day'), 'day') ?
                                    <Header as='h4' color='blue' content={dateToLocaleStringOrAccentYesterdayTodayAndTomorrow(employeeGoal.date)} /> :
                                    <span>{moment(employeeGoal.date).format('l')}</span>}{' '}{showEditIcon && <Icon
                                link
                                name='pencil alternate'
                                onClick={() => setEditMode(true)}
                                color='orange'/>}</Header.Subheader>
                            {employeeGoal.status === EmployeeGoalStatus.inProgress && moment(employeeGoal.date).isBefore(new Date(), 'day') &&
                            <Header style={{marginTop: 3}} as='h4' color='red'>Istekao
                                pre {moment(new Date()).diff(employeeGoal.date, 'days')} dana <Icon
                                    name='info circle'/></Header>}
                        </Header.Content>
                    </Header> :
                    <Grid>
                        <Grid.Column textAlign='center' width={16}>
                            <ShowMoreButton content='Dodaj planirani datum' onClick={() => setEditMode(true)}/>
                        </Grid.Column>
                    </Grid>}
            </div>}
    </>
}

export default EmployeeGoalDatesCellPlannedDate
