interface IParams {
    amount?: number | null;
    currency?: string;
    locale?: string;
    isRounded?: boolean;
    options?: Intl.NumberFormatOptions;
    invalidValue?: string;
}

export const formatPrice = ({
                                amount,
                                currency,
                                locale,
                                isRounded = false,
                                invalidValue = "n/a",
                                options,
                            }: IParams) => {
    const quantity = Number(amount);

    if (amount === null || isNaN(quantity)) {
        return invalidValue;
    }

    const formatter = new Intl.NumberFormat(locale, {
        maximumFractionDigits: isRounded ? 0 : 2,
        style: "currency",
        currency: currency || "RSD",
        currencyDisplay: "symbol",
        ...options,
    });
    
    return formatter.format(quantity);
};

export const formatRsd = (amount: number | null) => formatPrice({ amount  })

export const formatEur = (amount: number | null) => formatPrice({ amount, currency: "EUR" })

