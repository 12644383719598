import {FilterProps} from "react-table";
import React from "react";
import {Dropdown, Grid, Input} from "semantic-ui-react";
import {employeeGoalsColorsOptions} from "../../../options/employeeGoals";
import LoadingComponent from "../../../../layout/LoadingComponent";
import {IEmployeeGoalFilter, EmployeeGoalFilter as EmployeeGoalFilterClass} from "../../../../models/employeeGoals";
import CompactFilterLayout from "../common/CompactFilterLayout";


export const EmployeeGoalFilter = ({
                                       column: {
                                           filterValue,
                                           preFilteredRows,
                                           filteredRows,
                                           setFilter,
                                           filter
                                       },
                                   }: FilterProps<IEmployeeGoalFilter>,
                                   categories: string[]
) => {

    const ColorFilter = () => <Dropdown
        clearable
        fluid
        button
        multiple
        search
        selection
        basic
        options={employeeGoalsColorsOptions.map(x => ({key: x.key, value: x.value, image: x.image}))}
        value={filterValue?.colors ?? []}
        placeholder='Prioritet'
        onChange={(event, data) => setFilter({...filterValue, colors: data.value ?? []})}/>

    const CategoryFilter = () => <Dropdown
        clearable
        fluid
        button
        multiple
        search
        selection
        basic
        options={categories.map(category => ({key: category, value: category, text: category}))}
        value={filterValue?.categories ?? []}
        placeholder='Kategorija'
        onChange={(event, data) => setFilter({...filterValue, categories: data.value ?? []})}/>

    const OtherFilters = () => <>
        <Input
            fluid
            value={filterValue?.name}
            onChange={(event, data) =>
                setFilter({...filterValue, name: data.value})}
            placeholder='Naziv'/>

    </>

    if (categories === null) return <LoadingComponent/>

    return <Grid>
        <Grid.Column width={16}>
            <CompactFilterLayout
                filterValue={filterValue}
                onClearFilterClick={() => setFilter(new EmployeeGoalFilterClass())}
            >
                <OtherFilters />
            </CompactFilterLayout>
        </Grid.Column>
        <Grid.Column width={16}>
            <CategoryFilter />
            <ColorFilter />
        </Grid.Column>
    </Grid>
}