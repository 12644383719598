import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IRequiredProduct, IRequiredProductFormValues} from "../models/requiredProducts";
import {v4 as uuid} from 'uuid'
import agent from "../api/agent";
import {history} from "../../index";

export class RequiredProductsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        makeObservable(this)
    }

    @observable requiredProductsRegistry = new Map<string, IRequiredProduct>()
    @observable predicate = new Map<string, string>()
    @observable lastRequestId = ''
    @observable loading = false
    @observable submitting = false
    @observable requiredProduct: IRequiredProduct | null = null
    @observable deletingId = ''

    @computed get axiosParams() {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get requiredProductsArray() {
        return Array.from(this.requiredProductsRegistry.values())
    }

    @action addRequiredProduct = async (requiredProductFormValues: IRequiredProductFormValues) => {
        this.submitting = true
        try {
            await agent.RequiredProducts.add(requiredProductFormValues)
            runInAction(() => {
                //  we dont need here to insert requiredProducts on registry
                //  because redirecting to list page will load all (with updated one)
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/requiredProducts`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editRequiredProduct = async (requiredProductFormValues: IRequiredProductFormValues) => {
        this.submitting = true
        try {
            await agent.RequiredProducts.edit(requiredProductFormValues)
            runInAction(() => {
                //  we dont need here to insert requiredProducts on registry
                //  because redirecting to list page will load all (with updated one)
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/requiredProducts`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action loadRequiredProducts = async () => {
        const lastRequestId = uuid()
        this.lastRequestId = lastRequestId
        this.loading = true
        try {
            const requiredProducts = await agent.RequiredProducts.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === lastRequestId) {
                    this.requiredProductsRegistry.clear()
                    requiredProducts.forEach(requiredProduct => {
                        this.requiredProductsRegistry.set(requiredProduct.id,
                            requiredProduct.isOther ? // Little hack here, other product acting real product :)
                                {
                                    ...requiredProduct,
                                    product: {
                                        id: `OTHER_PRODUCT_${requiredProduct.otherContent}`,
                                        name: requiredProduct.otherContent,
                                        brandId: 0,
                                        brandName: 'OTHER_PRODUCT',
                                        sku: `OTHER_PRODUCT_${requiredProduct.otherContent}`,
                                        brandColor: 'black',
                                        prices: [],
                                        description: ''
                                    }
                                } :
                                requiredProduct)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.requiredProductsRegistry.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === lastRequestId) {
                    this.loading = false
                }
            })
        }
    }

    @action loadRequiredProduct = async (id: string) => {
        this.loading = true
        try {
            const requiredProduct = await agent.RequiredProducts.details(id)
            runInAction(() => {
                this.requiredProduct = requiredProduct
            })

            return requiredProduct
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action loadRequiredProductsToRegistry = (requiredProducts: IRequiredProduct[]) => {
        this.requiredProductsRegistry.clear()
        requiredProducts.forEach(requiredProduct => {
            this.requiredProductsRegistry.set(requiredProduct.id, requiredProduct)
        })
    }

    @action deleteRequiredProduct = async (id: string) => {
        this.deletingId = id
        try {
            await agent.RequiredProducts.delete(id)
            runInAction(() => {
                this.requiredProduct = null
                this.requiredProductsRegistry.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deletingId = ''
            })
        }
    }
}