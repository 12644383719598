import React, {useContext} from 'react';
import {Button, Header, Icon, Item, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import {RootStoreContext} from '../../../core/stores/rootStore';
import {IClientSchedule} from "../../../core/models/clientSchedules";
import {getClientScheduleColorNameByRealization} from "../../../core/common/clientSchedules";
import {numberToStringToFixedOrInt} from "../../../core/common/util/string";

const activityImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};


const ClientScheduleDetailedHeader: React.FC<{ clientSchedule: IClientSchedule }> = ({
                                                                     clientSchedule
                                                                 }) => {
    const rootStore = useContext(RootStoreContext);
    const {currentTraffic} = rootStore.trafficsStore

    return (
        <Segment.Group>
            <Segment
                basic
                attached='top'
                className={`${getClientScheduleColorNameByRealization(clientSchedule)}Background`}
                style={{height: '20em'}}>
                
                <Header
                    size='huge'
                    inverted
                    floated='right'>
                    {clientSchedule.realizationValue / clientSchedule.value < 1 ? 
                        numberToStringToFixedOrInt(clientSchedule.realizationValue / clientSchedule.value * 100, 2) : 
                        100}%
                </Header>

                <Segment style={activityImageTextStyle} basic>
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Header
                                    size='huge'
                                    content={clientSchedule.client.name}
                                    style={{color: 'white'}}
                                />
                                <p>
                                    <strong>
                                        {clientSchedule.year}
                                    </strong>
                                </p>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>
            </Segment>
            <Segment clearing attached>
                
                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/clientSchedules/manage/${clientSchedule.id}/edit`}
                    color='orange'
                    floated='right'
                >
                    <Icon name='edit'/>
                    Izmeni plan
                </Button>
            </Segment>
        </Segment.Group>
    )
}

export default observer(ClientScheduleDetailedHeader)