export const getSignalRHubErrorMessageFromResponse = (response: any) => {
    if (response && response.message) {
        const parts = response.message.split('HubException: ')
        if (parts[1]) {
            return parts[1]
        }
    }

    return null
}

export const signalRHubErrorMessageToFormErrorObject = (response: any) => ({
    data: {
        errors: {
            Error: getSignalRHubErrorMessageFromResponse(response)
        }
    }
})

