import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import PaymentsList from "../list/PaymentsList";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {PdfService} from "../../../core/common/pdf/PdfService";
import autoTable from "jspdf-autotable";
import {formatRsd} from "../../../core/common/util/format";
import {IPayment} from "../../../core/models/payments";
import moment from "moment";
import {formatDateOrDefault} from "../../../core/common/util/date";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {DatePicker} from "antd";

interface IProps {
}

const TRACKING_ID = 'paymentsList'

const PaymentsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loadPayments, loading, clearPredicate, setPredicate} = rootStore.paymentsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))

    useNavbarSelectedItem('payments')
    
    useEffect(() => {
        clearPredicate()
        setPredicate('startDate', startDate)
        setPredicate('endDate', endDate)
        loadPayments()
        
        return () => {
            removeFromRegistry(TRACKING_ID)
        }
    }, [
        removeFromRegistry, 
        loadPayments, 
        startDate, 
        endDate, 
        setPredicate, 
        clearPredicate])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        doc.text(`Tabela uplata za period ${formatDateOrDefault(startDate, 'start')} - ${formatDateOrDefault(endDate, 'end')}`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                ['#', 'Klijent', 'Iznos', 'Datum', 'Napomena', 'Unesena od strane']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((payment: IPayment, index) => ([
                index + 1,
                payment.client.name,
                formatRsd(payment.value),
                moment(payment.date).format('l'),
                payment.note,
                payment.user.displayName
            ])) ?? []
        })

        doc.save("Uplate.pdf")
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <BreadcrumbNavigation 
                        items={[
                            {
                                text: 'Uplate',
                                active: true
                            }
                        ]}
                        />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/payments/create`}
                        floated='right'
                        color='blue'
                        icon
                        labelPosition='left'>
                        <Icon name='add'/>
                        Dodaj uplatu
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment.Group>
                        <Segment clearing color='blue' attached='top'>

                            <DatePicker.RangePicker
                                // disabled={loading}
                                placeholder={['Početni datum', 'Krajni datum']}
                                format='DD.MM.YYYY.'
                                onChange={(date, dateString) => {
                                    setStartDate(date && date[0] ? moment(date[0]).format('YYYY-MM-DD') : '')
                                    setEndDate(date && date[1] ? moment(date[1]).format('YYYY-MM-DD') : '')

                                }}
                                value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
                            />
                            
                            {/*<Input*/}
                            {/*    label={{basic: true, content: 'Od'}}*/}
                            {/*    type='date'*/}
                            {/*    value={startDate}*/}
                            {/*    onChange={((event, data) => setStartDate(data.value))}*/}
                            {/*/>*/}
                            {/*<Input*/}
                            {/*    label={{basic: true, content: 'Do'}}*/}
                            {/*    type='date'*/}
                            {/*    value={endDate}*/}
                            {/*    onChange={((event, data) => setEndDate(data.value))}*/}
                            {/*/>*/}
                            
                            <Button
                                disabled={loading}
                                icon
                                labelPosition='left'
                                floated='right'
                                color='teal'
                                onClick={() => exportPdf()}>
                                <Icon name='file pdf'/>
                                PDF
                            </Button>
                        </Segment>
                        <Segment attached='bottom' loading={loading}>
                            <PaymentsList trackingId={TRACKING_ID}/>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default observer(PaymentsDashboard)
