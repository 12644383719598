import React, {useContext, useEffect, useState} from "react";
import {Button, Icon, Input} from "semantic-ui-react";
import {IClient} from "../../../../core/models/clients";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import styled from "styled-components";

export const EditClientPaymentNote = ({client}: { client: IClient }) => {
    const rootStore = useContext(RootStoreContext)
    const {editPaymentNote} = rootStore.clientsStore

    const [editMode, setEditMode] = useState(false)
    const [value, setValue] = useState(client.paymentNote || "")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setValue(client.paymentNote || "")
    }, [client.paymentNote, setValue])

    const handleUpdate = () => {
        setLoading(true)
        editPaymentNote(value, client)
            .then(() => setEditMode(false))
            .finally(() => setLoading(false))
    }

    return <Wrapper>
        {editMode ? <>
                <Input
                    value={value}
                    disabled={loading}
                    onChange={(e) => setValue(e.target.value)}/>
                <Button
                    size={"mini"}
                    icon
                    content={<Icon name={"x"}/>}
                    onClick={() => setEditMode(false)}
                />
                <Button
                    size={"mini"}
                    icon
                    color={"green"}
                    loading={loading}
                    content={<Icon name={"check"}/>}
                    onClick={handleUpdate}
                />
            </> :
            <>
                {client.paymentNote}
                <Button
                    size={"mini"}
                    icon color={client.paymentNote ? "orange" : "blue"}
                    content={<Icon name={client.paymentNote ? "edit" : "add"}/>} onClick={() => setEditMode(true)}/>
            </>}
    </Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;