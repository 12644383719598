import {RootStore} from "./rootStore";
import {action, makeObservable, observable, toJS} from "mobx";

export default class TablesStore {
    rootStore: RootStore
    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }
    
    observableFilteredRowsRegistry = observable.map(new Map<string, any[]>())
        
    // This doesn't work when multiple layer access (ProductConsumptionsList -> ProductConsumptionsAnalyseList) 
    // @computed get filteredRowsRegistry() {
    //     return toJS(this.observableFilteredRowsRegistry)
    // }
    
    SHIT_getFilteredRowsById = (id: string) => toJS(this.observableFilteredRowsRegistry.get(id))
    
    @action setFilteredRows = (key: string, filteredRows: any[]) => this.observableFilteredRowsRegistry.set(key, filteredRows)
    
    @action removeFromRegistry = (key: string) => this.observableFilteredRowsRegistry.delete(key)
    
    @action clearFilteredRowsRegistry = () => this.observableFilteredRowsRegistry.clear()
}