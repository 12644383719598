export const httpStatusCodeTextToSerbian = (code: number): string | null =>  {
    switch (code) {
        case 400:
            return 'Neispravan zahtev'
        case 409:
            return 'Konflikt'
        case 500:
            return 'Serverska greška'
        default:
            return null
    }
}