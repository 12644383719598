import {EditorState, convertToRaw, convertFromRaw } from "draft-js";

export const convertStateToString = (editorState: EditorState): string => JSON.stringify(convertToRaw(editorState.getCurrentContent()))

export const convertStringToState = (string: string): EditorState =>  {
    if (!string) {
        return EditorState.createEmpty()
    }

    return  EditorState.createWithContent(convertFromRaw(JSON.parse(string)))   
}

export const convertStringToPlainText = (string: string): string =>  {
    if (!string) {
        return ''
    }

    return convertStringToState(string).getCurrentContent().getPlainText()
}