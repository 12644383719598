import React, {FunctionComponent} from 'react'
import {Button, SemanticCOLORS} from "semantic-ui-react";
import {DatePicker} from "antd";
import moment from "moment";

interface OwnProps {
    onChange: (date: moment.Moment | null) => void,
    date: moment.Moment | Date | string | null | undefined,
    activeColor?: SemanticCOLORS,
    disabled?: boolean,
    vertical?: boolean,
    placeholder?: string,
    shortcuts: {
        yesterday?: boolean
        today?: boolean,
        tomorrow?: boolean,
        nextWeek?: boolean,
    }
}

type Props = OwnProps

const DateTimePickerWithShortcuts: FunctionComponent<Props> = ({
                                                                   activeColor,
                                                                   onChange,
                                                                   date,
                                                                   disabled,
                                                                   vertical,
                                                                   placeholder,
                                                                   shortcuts
                                                               }) => {

    const momentOrNullDate = date ? moment(date) : null

    const onChangeHandler = (date: moment.Moment | null) => {
        onChange(date)
    }

    const getNextMonday = () => {
        const dayINeed = 1;
        // if we haven’t yet passed the day of the week that I need:
        if (moment().isoWeekday() < dayINeed) {
            // then just give me this week’s instance of that day
            return moment().isoWeekday(dayINeed);
        } else {
            // otherwise, give me next week’s instance of that day
            return moment().add(1, 'weeks').isoWeekday(dayINeed);
        }
    }

    return <Button.Group vertical={vertical} size='mini'>
        <DatePicker
            format='l'
            disabled={disabled}
            value={momentOrNullDate}
            placeholder={placeholder ?? 'Odaberi datum'}
            onChange={(date, dateSting) => onChangeHandler(date)}/>

        {shortcuts.yesterday &&
        <Button
            disabled={disabled}
            color={momentOrNullDate?.isSame(moment().subtract(1, 'day'), 'day') ? activeColor : undefined}
            onClick={() => !momentOrNullDate?.isSame(moment().subtract(1, 'day'), 'day') && onChangeHandler(moment().subtract(1, 'day'))}>
            JUČE
        </Button>}
        
        {shortcuts.today &&
        <Button
            disabled={disabled}
            color={momentOrNullDate?.isSame(moment(), 'day') ? activeColor : undefined}
            onClick={() => !momentOrNullDate?.isSame(moment(), 'day') && onChangeHandler(moment())}>
            DANAS
        </Button>}

        {shortcuts.tomorrow &&
        <Button
            disabled={disabled}
            onClick={() => !momentOrNullDate?.isSame(moment().add(1, 'day'), 'day') && onChangeHandler(moment().add(1, 'days'))}
            color={momentOrNullDate?.isSame(moment().add(1, 'day'), 'day') ? activeColor : undefined}>
            SUTRA
        </Button>}

        {shortcuts.nextWeek && <Button
            disabled={disabled}
            onClick={() => !momentOrNullDate?.isSame(getNextMonday(), 'day') && onChangeHandler(getNextMonday())}
            color={momentOrNullDate?.isSame(getNextMonday(), 'day') ? activeColor : undefined}
        >
            NARENDE NEDELJE
        </Button>}
    </Button.Group>;
};

DateTimePickerWithShortcuts.defaultProps = {
    activeColor: 'blue'
}

export default DateTimePickerWithShortcuts