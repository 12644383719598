import {FilterProps} from "react-table";
import React, {FC} from "react";
import {Dropdown, Grid, Input} from "semantic-ui-react";
import {clientGoalsColorsOptions} from "../../../options/clientGoals";
import LoadingComponent from "../../../../layout/LoadingComponent";
import {IClientGoalFilter} from "../../../../models/clientGoals";


export const ClientGoalFilter = ({
                                     column: {
                                         filterValue,
                                         preFilteredRows,
                                         filteredRows,
                                         setFilter,
                                         filter
                                     },
                                 }: FilterProps<IClientGoalFilter>,
                                 categories: string[]
) => {
    
    const ColorFilter = () => <Dropdown
        clearable
        fluid
        button
        multiple
        search
        selection
        basic
        options={clientGoalsColorsOptions.map(x => ({key: x.key, value: x.value, image: x.image}))}
        value={filterValue?.colors ?? []}
        placeholder='Prioritet'
        onChange={(event, data) => setFilter({...filterValue, colors: data.value ?? []})}/>

    const OtherFilters: FC = () => <>
        <Input
            fluid
            value={filterValue.name}
            onChange={(event, data) =>
                setFilter({...filterValue, name: data.value})}
            placeholder='Naziv'/>

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={categories.map(category => ({key: category, value: category, text: category}))}
            value={filterValue?.categories ?? []}
            placeholder='Kategorija'
            onChange={(event, data) => setFilter({...filterValue, categories: data.value ?? []})}/>
    </>

    if (categories === null) return <LoadingComponent/>

    return <Grid>
        <Grid.Column width={16}>
            <OtherFilters/>
        </Grid.Column>
        <Grid.Column width={16}>
            <ColorFilter/>
        </Grid.Column>
    </Grid>
}