import {IPricelist} from "./pricelists";
import { v4 as uuid } from 'uuid'

export interface IProduct {
    id: string,
    sku: string,
    brandName: string,
    brandId: number,
    brandColor: string 
    name: string,
    description: string,
    prices: IPrice[]
}

export class Product implements IProduct{
    brandColor: string 
    brandId: number;
    brandName: string;
    description: string;
    id: string;
    name: string;
    prices: IPrice[];
    sku: string;
    
    constructor(product: IProduct, pricelists: IPricelist[]) {
        
        this.brandColor = product.brandColor
        this.brandId = product.brandId
        this.brandName = product.brandName
        this.description = product.description
        this.id = product.id
        this.name = product.name
        this.sku = product.sku
        
        this.prices = []
        pricelists.forEach(pricelist => {
            const price = product.prices.filter(p => p.pricelistId === pricelist.id)[0]
            
            if (price) {
                this.prices.push(price)
            } else {
                const emptyPrice = new Price(pricelist, product)
                this.prices.push(emptyPrice)
                
            }
            
            
        })
        
        // this.prices = product.prices
    }
}

export interface IProductFormValues {
    brandColor: string
    brandId: string
    brandName: string
    description: string
    id: string
    name: string
    prices: IPrice[]
    sku: string    
}

export class ProductFormValues implements IProductFormValues {
    brandColor: string = 'black'
    brandId: string = ''
    brandName: string = ''
    description: string = ''
    id: string = ''
    name: string = ''
    prices: IPrice[] = []
    sku: string = ''
    
    constructor(pricelists?: IPricelist[], product?: IProduct) {
        if (pricelists) {
            pricelists.forEach(pricelist => {
                const hasPrice = product?.prices.filter(price => price.pricelistId === pricelist.id)[0]
                if (hasPrice) {
                    this.prices.push(hasPrice)
                } else {
                    this.prices.push(new Price(pricelist, product))
                }

            })

        }
        
        if (product) {
            this.brandColor = product.brandColor
            this.brandId = product.brandId.toString()
            this.brandName = product.brandName
            this.description = product.description
            this.id = product.id
            this.name = product.name
            this.sku = product.sku
        }
    }
    
}

export interface IPrice {
    id: string,
    pricelistId: number,
    pricelistName: string,
    value: number | null
    productId: string,
    productName: string,
    isDefault: boolean,
    isBase: boolean
    isSelling: boolean
    createdAt: Date,
    updatedAt: Date
}

export class Price implements IPrice {
    isBase: boolean;
    isDefault: boolean;
    isSelling: boolean
    createdAt: Date
    id: string
    pricelistId: number
    pricelistName: string
    productId: string
    productName: string
    updatedAt: Date
    value: number | null
    
    constructor(pricelist: IPricelist, product?: IProduct) {
        this.pricelistId = pricelist.id
        this.isDefault = pricelist.isDefault
        this.isBase = pricelist.isBase
        this.isSelling = pricelist.isSelling
        this.pricelistName = pricelist.name
        this.id = uuid()
        this.createdAt = new Date()
        this.updatedAt = new Date()
        if (product) {
            this.productId = product.id
            this.productName = product.name
        } else {
            this.productId = ''
            this.productName = ''
        }
        this.value = null
    }
}

export interface IProductsFilter {
    brands: number[]
    name: string,
    sku: string
}


export class ProductsFilter implements IProductsFilter {
    brands: number[] = []
    name: string = ''
    sku: string = ''
}