import UserStore from './userStore';
import { createContext } from 'react';
import { configure } from 'mobx';
import CommonStore from './commonStore';
import ModalStore from './modalStore';
import NavbarStore from "./navbarStore";
import ClientsStore from "./clientsStore";
import RegionsStore from "./regionsStore";
import ProductsStore from "./productsStore";
import BrandsStore from "./brandsStore";
import PricelistsStore from "./pricelistsStore";
import BillsCartStore from "./billsCartStore";
import TrafficsStore from "./trafficsStore";
import BillsStore from "./billsStore";
import BillsInstallmentsStore from "./billsInstallmentsStore";
import PaymentsStore from "./paymentsStore";
import UsersStore from "./usersStore";
import GlobalSettingsStore from "./globalSettingsStore";
import ClientOrdersStore from "./clientOrdersStore";
import OnHoldProductsStore from "./onHoldProductsStore";
import ClientInitialStatesStore from "./clientInitialStatesStore";
import ClientActivitiesStore from "./clientActivitiesStore";
import ClientSchedulesStore from "./clientSchedulesStore";
import ClientGoalsStore from "./clientGoalsStore";
import TablesStore from "./tablesStore";
import ClientEmployeesStore from "./clientEmployeesStore";
import ExpensesStore from "./expensesStore";
import ProductConsumptionsStore from "./productConsumptionsStore";
import ProfitabilityStore from "./profitabilityStore";
import DocumentationsStore from "./documentationsStore";
import TrafficAnalyseStore from "./trafficAnalyseStore";
import ProfileStore from "./profileStore";
import ClientToursStore from "./clientToursStore";
import UserPostsStore from "./userPostsStore";
import EmployeeGoalsStore from "./employeeGoalsStore";
import PostExpressDocumentsStore from "./postExpressDocumentsStore";
import CommentsSectionStore from "./commentsSectionStore";
import {RequiredProductsStore} from "./requiredProductsStore";

configure({enforceActions: 'always'});

export class RootStore {
    globalSettingsStore: GlobalSettingsStore
    trafficsStore: TrafficsStore
    userStore: UserStore
    usersStore: UsersStore
    commonStore: CommonStore
    modalStore: ModalStore
    navbarStore: NavbarStore
    clientsStore: ClientsStore
    regionsStore: RegionsStore
    productsStore: ProductsStore
    brandsStore: BrandsStore
    pricelistsStore: PricelistsStore
    billsCartStore: BillsCartStore
    billsStore: BillsStore
    billsInstallmentsStore: BillsInstallmentsStore
    paymentsStore: PaymentsStore
    clientOrdersStore: ClientOrdersStore
    onHoldProductsStore: OnHoldProductsStore
    requiredProductsStore: RequiredProductsStore
    clientInitialStatesStore: ClientInitialStatesStore
    clientActivitiesStore: ClientActivitiesStore
    clientScheduleStore: ClientSchedulesStore
    clientGoalsStore: ClientGoalsStore
    employeeGoalsStore: EmployeeGoalsStore
    tablesStore: TablesStore
    clientEmployeesStore: ClientEmployeesStore
    trafficAnalyseStore: TrafficAnalyseStore
    expensesStore: ExpensesStore
    productConsumptionsStore: ProductConsumptionsStore
    profitabilityStore: ProfitabilityStore
    documentationsStore: DocumentationsStore
    profileStore: ProfileStore
    clientToursStore: ClientToursStore
    userPostsStore: UserPostsStore
    postExpressDocumentsStore: PostExpressDocumentsStore
    commentsSectionStore: CommentsSectionStore
    
    constructor() {
        this.globalSettingsStore = new GlobalSettingsStore(this)
        this.trafficsStore = new TrafficsStore(this)
        this.userStore = new UserStore(this)
        this.usersStore = new UsersStore(this)
        this.commonStore = new CommonStore(this);
        this.modalStore = new ModalStore(this);
        this.navbarStore = new NavbarStore(this)
        this.clientsStore = new ClientsStore(this)
        this.regionsStore = new RegionsStore(this)
        this.productsStore = new ProductsStore(this)
        this.brandsStore = new BrandsStore(this)
        this.pricelistsStore = new PricelistsStore(this)
        this.billsCartStore = new BillsCartStore(this)
        this.billsStore = new BillsStore(this)
        this.billsInstallmentsStore = new BillsInstallmentsStore(this)
        this.paymentsStore = new PaymentsStore(this)
        this.clientOrdersStore = new ClientOrdersStore(this)
        this.onHoldProductsStore = new OnHoldProductsStore(this)
        this.requiredProductsStore = new RequiredProductsStore(this)
        this.clientInitialStatesStore = new ClientInitialStatesStore(this)
        this.clientActivitiesStore = new ClientActivitiesStore(this)
        this.clientScheduleStore = new ClientSchedulesStore(this)
        this.clientGoalsStore = new ClientGoalsStore(this)
        this.employeeGoalsStore = new EmployeeGoalsStore(this)
        this.tablesStore = new TablesStore(this)
        this.clientEmployeesStore = new ClientEmployeesStore(this)
        this.trafficAnalyseStore = new TrafficAnalyseStore(this)
        this.expensesStore = new ExpensesStore(this)
        this.productConsumptionsStore = new ProductConsumptionsStore(this)
        this.profitabilityStore = new ProfitabilityStore(this)
        this.documentationsStore = new DocumentationsStore(this)
        this.profileStore = new ProfileStore(this)
        this.clientToursStore = new ClientToursStore(this)
        this.userPostsStore = new UserPostsStore(this)
        this.postExpressDocumentsStore = new PostExpressDocumentsStore(this)
        this.commentsSectionStore = new CommentsSectionStore(this)
    }
}

export const RootStoreContext = createContext(new RootStore());