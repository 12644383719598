import {action, computed, makeObservable, observable, runInAction, toJS} from "mobx";
import {
    IUser,
    IUserChangePasswordFormValues,
    IUserForgetPasswordFormValues,
    IUserFormValues,
    IUserResetPasswordFormValues
} from "../models/user";
import agent from "../api/agent";
import {RootStore} from "./rootStore";
import {history} from "../../index";

export default class UserStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable user: IUser | null = null
    @observable changingPassword = false
    @observable resettingPassword = false

    @computed get isLoggedIn() {
        return !!this.user
    }

    @computed get isAnyNonClientRole() {
        return this.isEmployee || this.isTrafficManager || this.isSuperAdmin
    }

    @computed get isEmployee() {
        return this.user?.roles.some(role => role === "Employee") ?? false
    }

    @computed get isSuperAdmin() {
        return this.user?.roles.some(role => role === "SuperAdministrator") ?? false
    }

    @computed get isTrafficManager() {
        return this.user?.roles.some(role => role === "TrafficManager") ?? false
    }

    @action login = async (values: IUserFormValues) => {
        try {
            const user = await agent.User.login(values)
            runInAction(() => {
                this.user = user
            })
            this.rootStore.commonStore.setToken(user.token)
            this.rootStore.modalStore.closeModal()
            history.push('/')
        } catch (error) {
            throw error
        }
    }

    @action register = async (values: IUserFormValues) => {
        try {
            const user = await agent.User.register(values)
            this.rootStore.commonStore.setToken(user.token)
            this.rootStore.modalStore.closeModal()
            history.push('/')
        } catch (error) {
            throw error
        }
    }

    @action getUser = async () => {
        try {
            const user = await agent.User.current()
            runInAction(() => {
                this.user = user
            })

            return toJS(user)
        } catch (error) {
            console.log(error)
        }
    }

    @action logout = () => {
        this.rootStore.commonStore.setToken(null)
        this.user = null
        history.push('/')
    }

    @action addRoleToArray = (role: string) => {
        if (this.user) {
            this.user.roles = [...this.user.roles, role]
        }
    }

    @action removeRoleFromArray = (role: string) => {
        if (this.user) {
            this.user.roles = this.user.roles.filter(r => r !== role)
        }
    }

    @action changePassword = async (userChangePasswordFormValues: IUserChangePasswordFormValues) => {
        this.changingPassword = true
        try {
            await agent.User.changePassword(userChangePasswordFormValues)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            this.changingPassword = false
        }
    }

    @action resetPassword = async (userResetPasswordFormValues: IUserResetPasswordFormValues) => {
        this.resettingPassword = true
        try {
            await agent.User.resetPassword(userResetPasswordFormValues)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            this.resettingPassword = false
        }
    }

    @action forgetPassword = async (userForgetPasswordFormValues: IUserForgetPasswordFormValues) => {
        try {
            await agent.User.forgetPassword(userForgetPasswordFormValues)
        } catch (error) {
            console.log(error)
            throw error
        } 
    }
}
