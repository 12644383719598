import React, {FunctionComponent} from 'react';
import {FieldRenderProps} from "react-final-form";
import {FormFieldProps, Form, Label, Select} from "semantic-ui-react";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";

interface IProps extends FieldRenderProps<number, HTMLElement>, FormFieldProps {
}

const SelectNumberInput: FunctionComponent<IProps> = ({
                                                    input,
                                                    width,
                                                    options,
                                                    placeholder,
                                                    meta: {error, touched},
                                                    ...rest
                                                }) => {

    return (
        <Form.Field error={touched && !!error} width={width}>
            <Select
                value={input.value}
                onChange={(e, data) => input.onChange(data.value)}
                placeholder={placeholder}
                options={options}
                {...rest as DropdownProps}
            />
            {touched && error && (
                <Label basic color='red'>
                    {error}
                </Label>
            )}
        </Form.Field>
    )
}


export default SelectNumberInput;
