import React, {useContext, useMemo} from 'react';
import Table from "../../../../core/common/tables/Table";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";
import {Cell} from "react-table";
import ClientDetailedFilter from "../../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import ClientDetailedCell from "../components/ClientDetailedCell";
import {IClientEmployee} from "../../../../core/models/clients";
import moment from "moment";
import {DateFilter} from "../../../../core/common/tables/filters/date/DateFilter";
import {Message} from "semantic-ui-react";

interface IClientEmployeesListProps {
    trackingId?: string
}

const ClientEmployeesList = ({trackingId}: IClientEmployeesListProps) => {
    const rootStore = useContext(RootStoreContext)
    const {clientEmployeesArray: clientEmployees} = rootStore.clientEmployeesStore
    
    const columns = useMemo(() => [
            {
                Header: 'Ime',
                accessor: 'name'
            },
            {
                id: 'client',
                Header: 'Klijent',
                Cell: ({row: {original: employee}}: Cell<IClientEmployee>) => (<ClientDetailedCell client={employee.client!}/>),
                accessor: 'client.name',
                Filter: ClientDetailedFilter,
                filter: 'clientDetailedFilter'
            },
            {
                Header: 'Pozicija',
                accessor: 'job'
            },
            {
                id: 'thisYearBirthday',
                Header: 'Rodjendan',
                accessor: 'thisYearBirthday',
                Cell: ({row: {original: employee}}: Cell<IClientEmployee>) => moment(employee.thisYearBirthday).format('l'),
                Filter: DateFilter,
                filter: 'dateFilter'
            },
            {
                Header: 'Broj telefona',
                accessor: 'telNumber',
            },
            {
                Header: 'Komentar',
                accessor: 'note'
            },
        ],
        [])

    if (clientEmployees.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Nismo pornašli zaposlene po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>


    return (<Table
        trackingId={trackingId}
        data={clientEmployees}
        columns={columns}
    />);
};

export default observer(ClientEmployeesList)
