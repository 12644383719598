import React, {FunctionComponent, useContext, useEffect} from 'react'
import PostExpressDocumentsList from "../list/PostExpressDocumentsList";
import {observer} from "mobx-react-lite";
import {Grid, Segment} from "semantic-ui-react";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import {useNavbarSelectedItem} from "../../../nav/utils/index.js";
import BreadcrumbNavigation from "../../../nav/BreadcrumbNavigation";
import PostExpressAddDocument from "../commom/PostExpressAddDocument";
import {useResponsive} from "../../../../core/common/responsive/Responsive";

interface OwnProps {
}

type Props = OwnProps

const PostExpressDocumentsDashboard: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loadPostExpressDocuments, loading} = rootStore.postExpressDocumentsStore

    useNavbarSelectedItem('postExpressDocuments')
    const { isMobile} = useResponsive()
    
    useEffect(() => {
        loadPostExpressDocuments()
    }, [
        loadPostExpressDocuments
    ])

    return <Grid>
        <Grid.Row>
            <Grid.Column
                computer={8}
                tablet={8}
                mobile={16}
            >
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Narudžbine',
                            linkWithoutCurrentTrafficId: 'clientOrders'
                        },
                        {
                            text: 'PostExpress dokumentacija',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
        
            <Grid.Column
                computer={6}
                tablet={8}
                mobile={16}
                floated='right'
            >

            </Grid.Column>
        </Grid.Row>
        
        <Grid.Row centered>
            <Grid.Column computer={8} tablet={10} mobile={16}>
                <Segment loading={loading}>
                    <Grid>
                            <Grid.Column
                                width={16}
                                floated='right'
                            >
                                <PostExpressAddDocument fluid={isMobile} />            
                            </Grid.Column>
                    
                        <Grid.Column width={16}>
                            <PostExpressDocumentsList/>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default observer(PostExpressDocumentsDashboard)
