import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../../core/stores/rootStore";
import LoadingComponent from "../../../core/layout/LoadingComponent";
import {RouteComponentProps} from "react-router";
import {Button, Grid, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import ClientDetailedHeader from "./ClientDetailedHeader";
import ClientDetailedInfo from "./ClientDetailedInfo";
import ClientDetailedMenu from "./ClientDetailedMenu";
import ClientDetailsBills from "./bills/ClientDetailsBills";
import ClientDetailsPayments from "./payments/ClientDetailsPayments";
import ClientDetailsBrands from "./brands/ClientDetailsBrands";
import ClientDetailsOnHoldProducts from "./onHoldProducts/ClientDetailsOnHoldProducts";
import ClientChat from "./ClientChat";
import ClientDetailsEmployees from "./employees/ClientDetailsEmployees"
import ClientDetailsOrders from "./orders/ClientDetailsOrders";
import Responsive from "../../../core/common/responsive/Responsive";
import ClientDetailsGoals from "./goals/ClientDetailsGoals";
import ClientDetailsGratis from "./gratis/ClientDetailsGratis";
import ClientDetailsActivities from "./activities/ClientDetailsActivities";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {ClientGoalType} from "../../../core/models/clientGoals";

interface IProps {
    currentTab: string
}

interface DetailParams {
    id: string;
}

const ClientDetailsLayout: FunctionComponent<RouteComponentProps<DetailParams & IProps>> = ({match: { params: { id, currentTab } }}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadClient,
        client,
        setPredicate,
        clearPredicate,
        loading: loadingClient,
        year, setYear,
        updateFinancialState,
        loadClientComments
    } = rootStore.clientsStore
    // const {loadBillsToRegistry} = rootStore.billsStore
    // const {loadPaymentsToRegistry} = rootStore.paymentsStore
    // const {loadOnHoldProductsToRegistry} = rootStore.onHoldProductsStore
    // const {loadBrandsToRegistry} = rootStore.brandsStore
    // const {loadClientOrdersToRegistry} = rootStore.clientOrdersStore
    // const {loadClientGoalsToRegistry} = rootStore.clientGoalsStore
    // const { loadClientActivitiesToRegistry } = rootStore.clientActivitiesStore
    // const {
    //     setCompletenessFilter: clientOrdersSetCompletenessFilter,
    //     setClientIdFilter: clientOrdersSetClientIdFilter,
    //     loadClientOrders
    // } = rootStore.clientOrdersStore

    // const [year, setYear] = useState(moment().format('YYYY'))
    // const [currentTab, setCurrentTab] = useState('bills')
    const [infoSegmentVisible, setInfoSegmentVisible] = useState(true)
    // const [loading, setLoading] = useState(true)
    // const [error, setError] = useState(false)

    useEffect(() => {
        const visible = window.localStorage.getItem('clientDetailsInfoSegmentVisible')
        if (visible) {
            if (visible === 'false') {
                setInfoSegmentVisible(false)
            } else {
                setInfoSegmentVisible(true)
            }
        } else {
            window.localStorage.setItem('clientDetailsInfoSegmentVisible', 'true')
        }

        clearPredicate()
        setPredicate('year', year)
        // createHubConnection(match.params.id)
        loadClient(id)
            .then(() => {
                updateFinancialState()       
                loadClientComments(id)
            })

            // .then(client => {
            //     loadBillsToRegistry(client!.bills!)
            //     loadPaymentsToRegistry(client!.payments!)
            //     loadOnHoldProductsToRegistry(client!.onHoldProducts!)
            //     loadBrandsToRegistry(client!.brands!)
            //     loadClientGoalsToRegistry(client!.goals!)
            //     loadClientActivitiesToRegistry(client!.activities!)
            //     setLoading(false)
            // })
            // .catch(error => {
            //     setError(true)
            // })

        // return () => {
        //     clientOrdersClearPredicate()
        // }

    }, [
        clearPredicate,
        id,
        loadClient,
        setPredicate,
        updateFinancialState,
        year,
        loadClientComments
    ])

    
    const toggleInfoSegmentVisible = () => {
        const value = infoSegmentVisible
        setInfoSegmentVisible(!value)
        if (value) {
            window.localStorage.setItem('clientDetailsInfoSegmentVisible', 'false')
        } else {
            window.localStorage.setItem('clientDetailsInfoSegmentVisible', 'true')
        }

    }

    const tabulator = () => {
        switch (currentTab) {
            case 'bills':
                return <ClientDetailsBills clientId={client!.id!} year={year} />
            case 'payments':
                return <ClientDetailsPayments clientId={client!.id!} year={year} />
            case 'goals':
                return <ClientDetailsGoals clientId={client!.id!} type={ClientGoalType.classic} />
            case 'goals-payments':
                return <ClientDetailsGoals clientId={client!.id!} type={ClientGoalType.payment} />
            case 'brands':
                return <ClientDetailsBrands clientId={client!.id!}  />
            case 'onHoldProducts':
                return <ClientDetailsOnHoldProducts clientId={client!.id!} />
            case 'employees':
                return <ClientDetailsEmployees clientId={client!.id!}  />
            case 'orders':
                return <ClientDetailsOrders clientId={client!.id!} />
            case 'gratis':
                return <ClientDetailsGratis clientId={client!.id!} year={year} />
            case 'activities':
                return <ClientDetailsActivities clientId={client!.id!} />
            default:
                return <ClientDetailsBills clientId={client!.id!} year={year} />
        }
    }

    if (loadingClient)
        return (<LoadingComponent content='Učitavanje klijenta...'/>)
    
    if (client === null)
        return (<>NOC</>)
    
    return (
        <Grid>

            <Grid.Column width={16}>                
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Klijenti',
                            linkWithoutCurrentTrafficId: 'clients'
                        },
                        {
                            text: client?.name ?? '',
                            active: true
                        }
                    ]} 
                />
            </Grid.Column>
            
            <Grid.Column computer={12} tablet={12} mobile={16}>
                <ClientDetailedHeader client={client!} year={year}/>
                {infoSegmentVisible ?
                    <ClientDetailedInfo toggleInfoSegmentVisible={toggleInfoSegmentVisible} client={client!}/> :
                    <Segment clearing>
                        <Button floated='right' size='mini' icon='angle down' onClick={toggleInfoSegmentVisible}/>
                    </Segment>}
                <Responsive.Mobile>
                    <ClientDetailedMenu 
                        year={year} 
                        setYear={setYear}
                        currentTab={currentTab || 'bills'}/>
                </Responsive.Mobile>
                {tabulator()}
            </Grid.Column>

            <Grid.Column computer={4} tablet={4} mobile={16}>
                <Responsive.NotMobile>
                    <ClientDetailedMenu year={year} setYear={setYear} 
                                        currentTab={currentTab || 'bills'}/>
                </Responsive.NotMobile>
                <ClientChat/>
            </Grid.Column>
        </Grid>
    )
}

export default observer(ClientDetailsLayout)
