import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import agent from "../api/agent";
import {IGlobalSetting, IPdvTaxParameters} from "../models/globalSettings";

const currentMonthTrafficSumKey = 'currentMonthTrafficSum'

export default class GlobalSettingsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable globalSettings = new Map<string, IGlobalSetting>()
    @observable loading = false
    @observable submittingKey: string | null = null

    @computed get siteName() {
        return this.globalSettings.get('siteName')?.value ?? 'Aspective.'
    }

    @computed get currentMonthTraffic() {
        const sum = Number(this.globalSettings.get(currentMonthTrafficSumKey)?.value)

        return isNaN(sum) ? null : sum
    }

    @computed get globalSettingsArray() {
        return Array.from(this.globalSettings.values())
    }

    @computed get eurExchangeRate() {
        return Number(this.globalSettings.get('eurRsdCoef')!.value)
    }

    @computed get pdvTaxParameters(): IPdvTaxParameters {
        const pdvSetting = this.globalSettings.get('pdvTaxRate') ?? null

        if (pdvSetting === null)
            return {
                summaryRateDecimal: 0,
                rateDecimal: 0,
                ratePercentage: 0,
                summaryRatePercentage: 0
            }

        const ratePercentage = Number(pdvSetting.value)
        const summaryRatePercentage = 100 + ratePercentage
        const rateDecimal = ratePercentage / 100
        const summaryRateDecimal = summaryRatePercentage / 100

        const ret = {
            summaryRatePercentage,
            ratePercentage,
            rateDecimal,
            summaryRateDecimal
        }

        return ret
    }

    @action loadGlobalSettings = async () => {
        this.loading = true
        this.globalSettings.clear()
        try {
            const globalSettings = await agent.GlobalSettings.list()
            runInAction(() => {
                globalSettings.forEach(setting => {
                    this.globalSettings.set(setting.key, setting)
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action editGlobalSetting = async (key: string, value: string) => {
        this.submittingKey = key
        try {
            await agent.GlobalSettings.edit(key, value)
            runInAction(() => {
                const oldGlobalSetting = this.globalSettings.get(key)!
                this.globalSettings.set(key, {...oldGlobalSetting, value: value})
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.submittingKey = null
            })
        }
    }
    
    @action getCurrentMonthTraffic = async () => {
        const exist = this.globalSettings.get(currentMonthTrafficSumKey) ?? null
        try {
            const sum = await agent.CurrentMonthTraffic.get();
            runInAction(() => {
                if (exist) {
                    this.globalSettings.set(currentMonthTrafficSumKey, {
                        ...exist,
                        value: sum.toString()
                    })
                }
            })
            
        } catch (error) {
            console.log(error)
        }
    }
}