import {semanticColorToHash} from "../util/semanticUi";
import {IClient} from "../../models/clients";

export const clientStatusToColorName = (status: string) => {
    switch (status) {
        case 'Prioritet 1':
            return 'purple'
        case 'Prioritet 2':
            return 'blue'
        case 'Prioritet 3':
            return 'teal'
        case 'Prioritet 4':
            return 'olive'
        case 'Prioritet 5':
            return 'yellow'
        case 'Zanemarljiv':
            return 'grey'
        default:
            return 'black'
    }
}

export const clientStatusToColor = (status: string) => semanticColorToHash(clientStatusToColorName(status))

export const clientHTMLSelectOptions = (clients: IClient[]) => clients.map(client => ({
    key: client.id,
    value: client.id,
    text: client.name
}))
