import React, {FunctionComponent} from 'react';
import {FieldRenderProps} from "react-final-form";
import {FormFieldProps, Form, Label} from "semantic-ui-react";

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {
}

const TextAreaInput: FunctionComponent<IProps> = ({
                                                      input,
                                                      width,
                                                      rows,
                                                      placeholder,
                                                      meta: {error, touched}
                                                  }) => {

    return (
        <Form.Field error={touched && !!error} width={width}>
            <textarea rows={rows} {...input} placeholder={placeholder}/>
            {touched && error && (
                <Label basic color='red'>
                    {error}
                </Label>
            )}
        </Form.Field>
    );
};

export default TextAreaInput;