import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import TrafficAnalysisByBrandList from "../lists/TrafficAnalysisByBrandList";
import {Grid, Segment} from "semantic-ui-react";
import TrafficAnalysisByClientCategoryList from "../lists/TrafficAnalysisByClientCategoryList";
import TrafficAnalysisByClientList from "../lists/TrafficAnalysisByClientList";
import TrafficAnalysisByClientAndBrandList from "../lists/TrafficAnalysisByClientAndBrandList";
import TrafficAnalysisByProductList from "../lists/TrafficAnalysisByProductList";
import TrafficAnalysisByClientAndProductList from "../lists/TrafficAnalysisByClientAndProductList";
import TrafficAnalysisDashboardSidebar from "./TrafficAnalysisDashboardSidebar";
import moment from "moment";
import Responsive from "../../../core/common/responsive/Responsive";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import TrafficAnalysisByOnSaleNoteAndClientAndProductList
    from "../lists/onSale/TrafficAnalysisByOnSaleNoteAndClientAndProductList";

export const TRAFFIC_ANALYSIS_BY_BRAND_TRACKING_ID = 'trafficAnalysisByBrand'
export const TRAFFIC_ANALYSIS_BY_PRODUCT_TRACKING_ID = 'trafficAnalysisByProduct'
export const TRAFFIC_ANALYSIS_BY_CLIENT_TRACKING_ID = 'trafficAnalysisByClient'
export const TRAFFIC_ANALYSIS_BY_CLIENT_CATEGORY_TRACKING_ID = 'trafficAnalysisByClientCategory'
export const TRAFFIC_ANALYSIS_BY_CLIENT_AND_BRAND_TRACKING_ID = 'trafficAnalysisByClientAndBrand'
export const TRAFFIC_ANALYSIS_BY_CLIENT_AND_PRODUCT_TRACKING_ID = 'trafficAnalysisByClientAndProduct'
export const TRAFFIC_ANALYSIS_BY_ON_SALE_NOTE_AND_CLIENT_AND_PRODUCT_TRACKING_ID = 'trafficAnalysisByOnSaleNoteAndClientAndProduct'

interface IProps {
}

const DEFAULT_TAB = 'brands'

const TrafficAnalysisDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadAll,
        loadingAll: loading,
        setPredicate,
        clearPredicate,
        setStartDateFilter,
        setEndDateFilter,
        // setGratisFilter,
        setTrafficFilterToCurrent,
        // gratisFilter
        mode, setMode
    } = rootStore.trafficAnalyseStore

    const [currentTab, setCurrentTab] = useState(DEFAULT_TAB)
    const [showingCharts, setShowingCharts] = useState<string[]>([
        'summaryPriceWithDiscountWithBillDiscount',
        'ruc',
        'rucCoef'
    ])

    useNavbarSelectedItem('trafficAnalysis')

    useEffect(() => {
        if (mode === 'gratis') {
            setShowingCharts([
                'base'
            ])
        } else {
            setShowingCharts([
                'summaryPriceWithDiscountWithBillDiscount',
                'ruc',
                'rucCoef'
            ])
        }
    }, [
        mode
    ])

    useEffect(() => {
        clearPredicate()

        setStartDateFilter(moment().startOf('month').format('YYYY-MM-DD'))
        setEndDateFilter(moment().endOf('month').format('YYYY-MM-DD'))
        setMode('classic')
        setTrafficFilterToCurrent()

        loadAll()
    }, [
        loadAll,
        clearPredicate,
        setPredicate,
        setStartDateFilter,
        setEndDateFilter,
        setMode,
        setTrafficFilterToCurrent
    ])
    
    // Reset to default tab if tab is special case for some mode
    useEffect(() => {
        if (mode !== 'onSale' && ['onSaleAndClientsAndProducts'].includes(currentTab)) {
            setCurrentTab(DEFAULT_TAB)
        }
        
    }, [
        mode,
        currentTab
    ])
    
    const tabulator = () => {
        switch (currentTab) {
            case 'brands':
                return <TrafficAnalysisByBrandList trackingId={TRAFFIC_ANALYSIS_BY_BRAND_TRACKING_ID}
                                                   setShowingCharts={setShowingCharts} showingCharts={showingCharts}/>
            case 'products':
                return <TrafficAnalysisByProductList trackingId={TRAFFIC_ANALYSIS_BY_PRODUCT_TRACKING_ID}/>
            case 'clients':
                return <TrafficAnalysisByClientList trackingId={TRAFFIC_ANALYSIS_BY_CLIENT_TRACKING_ID}/>
            case 'clientCategories':
                return <TrafficAnalysisByClientCategoryList trackingId={TRAFFIC_ANALYSIS_BY_CLIENT_CATEGORY_TRACKING_ID}
                                                            setShowingCharts={setShowingCharts}
                                                            showingCharts={showingCharts}/>
            case 'clientsAndBrands':
                return <TrafficAnalysisByClientAndBrandList
                    trackingId={TRAFFIC_ANALYSIS_BY_CLIENT_AND_BRAND_TRACKING_ID}/>
            case 'clientsAndProducts':
                return <TrafficAnalysisByClientAndProductList
                    trackingId={TRAFFIC_ANALYSIS_BY_CLIENT_AND_PRODUCT_TRACKING_ID}/>
            case 'onSaleAndClientsAndProducts':
                return <TrafficAnalysisByOnSaleNoteAndClientAndProductList
                    trackingId={TRAFFIC_ANALYSIS_BY_ON_SALE_NOTE_AND_CLIENT_AND_PRODUCT_TRACKING_ID}/>
            default:
                return ''
        }
    }


    const Main = () => (<Grid.Column computer={12} tablet={16} mobile={16}>
        <Segment loading={loading}>
            {tabulator()}
        </Segment>
    </Grid.Column>)

    const Side = () => (<Grid.Column computer={4} tablet={16} mobile={16}>
        <TrafficAnalysisDashboardSidebar currentTab={currentTab} setCurrentTab={setCurrentTab}/>
    </Grid.Column>)

    return (<Grid>
        <Grid.Column width={16}>
            <BreadcrumbNavigation
                items={[
                    {
                        text: 'Analiza prometa',
                        active: true
                    }
                ]}
            />
        </Grid.Column>
        <Responsive.Mobile>
            <Side/>
        </Responsive.Mobile>
        <Responsive.Tablet>
            <Side/>
        </Responsive.Tablet>
        <Main/>
        <Responsive.Desktop>
            <Side/>
        </Responsive.Desktop>
    </Grid>)
}

export default observer(TrafficAnalysisDashboard)
