import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import SelectStringInput from "../../../core/common/form/SelectStringInput";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {combineValidators, composeValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../core/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import {ClientInitialStateFormValues, IClientInitialStateFormValues} from "../../../core/models/clientInitialStates";
import {generateYearsOptions} from "../../../core/common/util/options";
import {isDecimal} from "../../../core/common/validators/general";
import TextInput from "../../../core/common/form/TextInput";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    value: composeValidators(
        isRequired({message: 'Iznos je obavezan'}),
        isDecimal(2)
    )(),
    clientId: isRequired({message: 'Klijent je obavezan'}),
    year: isRequired({message: 'Godina je obavezana'}),
});

interface DetailParams {
    id: string;
}

const ClientInitialStateForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {
        loadClientInitialState,
        createClientInitialState,
        editClientInitialState,
        submitting
    } = rootStore.clientInitialStatesStore
    const {loadClients, clientsArray: clients, loading: loadingClients} = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore

    const [clientInitialState, setClientInitialState] = useState<IClientInitialStateFormValues>(new ClientInitialStateFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem(match.params.id ? '' : 'clientInitialStatesCreate')
    
    useEffect(() => {
        if (match.params.id) {
            setLoading(true)
            loadClientInitialState(match.params.id)
                .then(clientInitialState => setClientInitialState(new ClientInitialStateFormValues(clientInitialState)))
                .finally(() => setLoading(false))

        } else {
            setClientInitialState(new ClientInitialStateFormValues())
        }
        loadClients()

    }, [
        match.params.id,
        loadClients,
        loadClientInitialState
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const clientInitialState = {...values, value: Number(values.value)}

        if (!clientInitialState.id) {
            let newClientInitialState = {
                ...clientInitialState,
                id: uuid()
            }
            
            return createClientInitialState(newClientInitialState)
        } else {
            return editClientInitialState(clientInitialState)
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Početna stanja',
                            linkWithoutCurrentTrafficId: 'clientInitialStates'
                        },
                        {
                            text: match.params.id ? 'Izmena početnog stanja' : 'Dodavanje početnog stanja',
                            active: true
                        }
                    ]}
                    />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm onSubmit={(values: IClientInitialStateFormValues) => handleFinalFormSubmit(values).catch(error => ({
                            [FORM_ERROR]: error
                        })
                    )}
                               validate={validate}
                               initialValues={clientInitialState}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit
                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={loading || loadingClients}>

                                       <Field
                                           search
                                           name='clientId'
                                           placeholder='Klijent'
                                           value={clientInitialState.clientId}
                                           options={clients.map(client => ({
                                               key: client.id,
                                               value: client.id,
                                               text: client.name
                                           }))}
                                           component={SelectStringInput}/>

                                       <Field
                                           name='year'
                                           placeholder='Godina'
                                           value={clientInitialState.year}
                                           options={generateYearsOptions(2010, 2050)}
                                           component={SelectStringInput}/>
                                           
                                       <Field
                                           labelPosition='right'
                                           label={{basic: true, content: 'RSD'}}
                                           name='value'
                                           placeholder='Iznos'
                                           value={clientInitialState.value.toString()}
                                           component={TextInput}/>
                                       

                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(clientInitialState.id) ? 'Izmeni' : 'Dodaj' }
                                       />
                                       <Button
                                           onClick={clientInitialState.id ? 
                                               () => history.push(`/${currentTraffic?.id}/clientInitialStates`) : 
                                               () => history.push(`/${currentTraffic?.id}/clientInitialStates`)}
                                           disabled={loading || loadingClients}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(ClientInitialStateForm)
