import {DropdownItemProps} from "semantic-ui-react";

export const generateYearsOptions = (start: number, end: number) => {
    let array: DropdownItemProps[] = []
    for (let i = start; i <= end; i++) {
        array.push({
            key: i.toString(),
            value: i.toString(),
            text: i.toString()
        })
    }

    return array
}