import React, {FunctionComponent, useContext} from 'react';
import {IClientOrder} from "../../../../core/models/clientsOrders";
import {Icon, Item, Label, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import moment from "moment";
import {convertStringToState} from "../../../../core/common/util/draft-js";
import {stringPreview} from "../../../../core/common/util/string";
import {observer} from "mobx-react-lite";
import BillPopup from "../../../bills/components/BillPopup";
import ClientOrderBillDocumentDownloadButton from "../../commom/ClientOrderBillDocumentDownloadButton";
import {ClientOrderItemLabels} from "./components/ClientOrderItemLabels";
import {ClientOrderItemFooterActions} from "./components/ClientOrderItemFooterActions";
import {ClientOrderItemActions} from "./components/ClientOrderItemActions";
import styled from "styled-components";

interface IProps {
    clientOrder: IClientOrder
}

const ClientOrderListItem: FunctionComponent<IProps> = ({
                                                            clientOrder
                                                        }) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {isEmployee} = rootStore.userStore

    return (
        <Wrapper $urgent={clientOrder.urgent}>
            <Segment.Group raised>
                <Segment>
                    <ClientOrderItemLabels clientOrder={clientOrder}/>

                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Item.Header
                                    to={`/${currentTraffic?.id}/clients/${clientOrder.client.id}`}
                                    as={Link}>
                                    {clientOrder.client.name}
                                </Item.Header>
                                <Item.Description>
                                    <Link
                                        to={`/${currentTraffic?.id}/clientOrders/${clientOrder.id}`}> {clientOrder.orderNumber}</Link>
                                </Item.Description>

                                <Item.Description>
                                    Kreirao
                                    <Link
                                        to={`/${currentTraffic?.id}/profile/${clientOrder.createdBy.username}`}> {clientOrder.createdBy.displayName}</Link>
                                </Item.Description>
                                <Item.Description>

                                    {clientOrder.hasBillDocument &&
                                        <ClientOrderBillDocumentDownloadButton clientOrder={clientOrder}/>}

                                    {clientOrder.bill &&
                                        <span style={{margin: 3}}>
                                    <BillPopup bill={clientOrder.bill}/>                                        
                                </span>}

                                    {clientOrder.billCreated && (
                                        <Label
                                            style={{margin: 3}}
                                            basic
                                            color='orange'
                                            content='Uradjen račun'
                                        />
                                    )}
                                    {clientOrder.deliveredDate && (
                                        <Label
                                            style={{margin: 3}}
                                            basic
                                            color='blue'
                                            content='Otpremljeno'
                                        />
                                    )}
                                </Item.Description>
                            </Item.Content>
                        </Item>
                        {isEmployee &&
                            <ClientOrderItemActions clientOrder={clientOrder}/>}
                    </Item.Group>
                </Segment>

                <Segment>
                    <Icon name='calendar'/> {moment(clientOrder.date).format('l')}
                    <Icon style={{marginLeft: 2}}
                          name='clock outline'/> dodato {moment(clientOrder.createdAt).calendar()}
                    {clientOrder.createdAt !== clientOrder.updatedAt ? `, izmenjeno ${moment(clientOrder.updatedAt).calendar()}` : ''}
                </Segment>
                <Segment secondary>
                    {stringPreview(clientOrder.note, 100)}
                </Segment>
                <Segment clearing>
                    <div>{stringPreview(convertStringToState(clientOrder.text).getCurrentContent().getPlainText(), 100)}</div>
                    {isEmployee && <ClientOrderItemFooterActions clientOrder={clientOrder}/>}
                </Segment>
            </Segment.Group>
        </Wrapper>
    )
};

export const Wrapper = styled.div<{ $urgent?: boolean }>`
  border: ${(props) => props.$urgent ? "3px solid red" : undefined};
  border-radius: ${(props) => props.$urgent ? "5px" : undefined};
`;

export default observer(ClientOrderListItem)


