import {FilterProps} from "react-table";
import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {Dropdown, Input, Select} from "semantic-ui-react";
import {ClientFilter, IClientFilter} from "../../../../../models/clients";
import {IRegion} from "../../../../../models/region";
import LoadingComponent from "../../../../../layout/LoadingComponent";
import {stringPreview} from "../../../../util/string";
import {clientStatusOptions, clientTypesOptions} from "../../../../options/client";
import CompactFilterLayout from "../../common/CompactFilterLayout";
import {RootStoreContext} from "../../../../../stores/rootStore";
import {observer} from "mobx-react-lite";

const ClientDetailedFilter = ({
                                         column: {
                                             filterValue,
                                             preFilteredRows,
                                             filteredRows,
                                             setFilter,
                                             filter
                                         }
                                     }: FilterProps<IClientFilter>,
                                     
) => {
    const rootStore = useContext(RootStoreContext);
    const { loadRegionsIfNull, regionsArray: regions } = rootStore.regionsStore;
    const { loadClientCategoriesIfNull, categories } = rootStore.clientsStore;
    const { loadUsers, usersArray: users } = rootStore.usersStore;
    
    useEffect(() => {
        loadRegionsIfNull();
        loadClientCategoriesIfNull();
        loadUsers(true);
    }, [
        loadRegionsIfNull,
        loadClientCategoriesIfNull,
        loadUsers
    ])
    
    const patchFilter = useCallback( (filter: Partial<IClientFilter>) =>
            setFilter({...filterValue, ...filter})
        , [setFilter, filterValue]);
    
    const isLoading = useMemo(() => (regions === null || categories === null || users === null), [regions, categories, users]);

    const renderLabel = (label: any) => ({
        color: 'blue',
        content: filterValue.regions.length > 3 ? stringPreview(label.text, 5) : label.text,
        size: 'mini'
    })
    
    
    return isLoading ? <LoadingComponent/> : <CompactFilterLayout
        filterValue={filterValue}
        onClearFilterClick={() => setFilter(new ClientFilter())}>
        <Input
            fluid
            // value={filterValue.name}
            onChange={(event, {value: name}) => patchFilter({ name })}
            placeholder='Naziv'
        />

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={regions.map((region: IRegion) => ({
                key: region.id!,
                text: region.name.toString(),
                value: region.id!
            }))}
            value={filterValue.regions ?? []}
            renderLabel={renderLabel}
            placeholder='Region'
            onChange={(event, data) => setFilter({...filterValue, regions: data.value ?? []})}
        />

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={categories?.map((category: string) => ({
                key: category,
                text: category,
                value: category
            }))}
            value={filterValue.categories ?? []}
            renderLabel={renderLabel}
            placeholder='Kategorija'
            onChange={(event, data) =>
                patchFilter({ categories: data.value as string[] || [] })
            }/>

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={clientStatusOptions}
            value={filterValue.statusList ?? []}
            renderLabel={renderLabel}
            placeholder='Status'
            onChange={(event, data) => 
                patchFilter({ statusList: data.value as string[] ?? []})} />
        
        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={clientTypesOptions}
            value={filterValue.types ?? []}
            renderLabel={renderLabel}
            placeholder='Vrsta'
            onChange={(event, data) => patchFilter({ types: data.value as string[] ?? []})}
        />
        
        <Select
            fluid
            placeholder='Aktuelnost'
            options={[
                {key: 'included', value: '', text: 'Svi'},
                {key: 'only', value: 'only', text: 'Aktuelni'},
                {key: 'excluded', value: 'excluded', text: 'Ne-aktuelni'},
            ]}
            onChange={(event, data) => patchFilter({ focus: data.value as 'only' | 'excluded' | ''})}
        />

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={users.map((user) => ({
                key: user.id,
                value: user.id,
                text: user.username,
                image: user.image
            }))}
            value={filterValue.agent ?? []}
            renderLabel={renderLabel}
            placeholder='Agent'
            onChange={(event, data) => patchFilter({ agent: data.value as string[] ?? []})}
        />
        
    </CompactFilterLayout>
}

export default observer(ClientDetailedFilter)