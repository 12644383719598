import React, {FunctionComponent, useContext, useState} from 'react';
import {Form as FinalForm, Field} from 'react-final-form'
import {Button, Checkbox, Form} from "semantic-ui-react";
import {FORM_ERROR} from "final-form";
import {combineValidators, isRequired} from "revalidate";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {IUserChangePasswordFormValues} from "../../../core/models/user";
import TextInput from "../../../core/common/form/TextInput";
import ErrorMessage from "../../../core/common/form/ErrorMessage";

interface IProps {
    onSuccess?: () => void,
    onCancel?: () => void
}


const validate = combineValidators({
    oldPassword: isRequired({message: 'Stara lozinka je obavezna'}),
    newPassword: isRequired({message: 'Nova lozinka je obavezna'})
})

const UserChangePasswordForm: FunctionComponent<IProps> = ({ onSuccess, onCancel }) => {
    const rootStore = useContext(RootStoreContext)
    const {changingPassword, changePassword} = rootStore.userStore

    const [showPasswords, setShowPasswords] = useState(false)
    
    return (
        <FinalForm
            onSubmit={(values: IUserChangePasswordFormValues) => changePassword(values)
                .then(() => {
                    if (onSuccess) {
                        onSuccess()
                    }
                })
                .catch(error => ({
                [FORM_ERROR]: error
            }))}
            validate={validate}
            render={({
                         handleSubmit,
                         submitting,
                         form: {
                             change
                         },
                         submitError,
                         invalid,
                         pristine,
                         dirtySinceLastSubmit,
                     }) => (
                <Form onSubmit={handleSubmit} error>
                    
                    <Form.Field>
                        <Checkbox
                            slider
                            label='Pirkaži lozinke'
                            onChange={((event, data) => setShowPasswords(data.checked!))}
                            checked={showPasswords}
                        />    
                    </Form.Field>
                    
                    <Form.Field>
                        <label>Stara lozinka</label>
                        <Field
                            name='oldPassword'
                            component={TextInput}
                            placeholder='Stara lozinka'
                            type={showPasswords ? 'text' : 'password'} />
                    </Form.Field>
                    
                    <Form.Field>
                        <label>Nova lozinka</label>
                        <Field
                            name='newPassword'
                            component={TextInput}
                            placeholder='Nova lozinka'
                            type={showPasswords ? 'text' : 'password'} />
                    </Form.Field>
                    
                    {submitError && !dirtySinceLastSubmit &&
                    <ErrorMessage error={submitError}/>}
                    <Button
                        disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                        color='blue' 
                        content='Promeni'
                        loading={submitting} 
                        floated='right'/>
                    <Button
                        disabled={changingPassword}
                        content='Odustani'
                        onClick={() => {
                            if (onCancel) {
                                onCancel()
                            }
                        }}
                        floated='right'/>
                </Form>
            )}
        />
    );
};

export default UserChangePasswordForm
