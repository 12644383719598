import React, {FunctionComponent, useState} from 'react'
import {IEmployeeGoal} from "../../../core/models/employeeGoals";
import {Divider, Grid} from "semantic-ui-react";
import EmployeeGoalSingleDocument from "../documents/EmployeeGoalSingleDocument";
import EmployeeGoalDocumentForm from "../form/EmployeeGoalDocumentForm";
import ShowMoreButton from "../../commom/buttons/ShowMoreButton";

interface OwnProps {
  employeeGoal: IEmployeeGoal
}

type Props = OwnProps

const EmployeeGoalDocumentsCell: FunctionComponent<Props> = ({ employeeGoal }) => {

  const [addDocumentMode, setAddDocumentMode] = useState(false)

  const lastDocument = (employeeGoal.documents.length > 0) ?
      employeeGoal.documents[employeeGoal.documents.length - 1] : null


  return <Grid centered>
    {/*     Last document     */}
    {lastDocument && <Grid.Row>
      <Grid.Column>
          <EmployeeGoalSingleDocument compact alone employeeGoalDocument={lastDocument}/>
        <Divider />
      </Grid.Column>
    </Grid.Row>}

    <Grid.Row>
      <Grid.Column textAlign='center' verticalAlign='middle'>
        {/*     Add document     */}
        {addDocumentMode ?
            <EmployeeGoalDocumentForm
                small
                employeeGoalId={employeeGoal.id}
                onUploadSuccess={() => setAddDocumentMode(false)}
                onCancel={() => setAddDocumentMode(false)}
            /> :
            <ShowMoreButton content='Dodaj dokument' onClick={() => setAddDocumentMode(true)} />}
      </Grid.Column>
    </Grid.Row>
  </Grid>
}

export default EmployeeGoalDocumentsCell
