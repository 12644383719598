import React from 'react';
import {Segment, Grid, Icon, Header} from 'semantic-ui-react';
import { IEmployeeGoal} from "../../../core/models/employeeGoals";
import moment from "moment";

const EmployeeGoalDetailedInfo: React.FC<{ employeeGoal: IEmployeeGoal }> = ({employeeGoal}) => {

    return (
        <Segment.Group>
            <Segment attached='top'>
                <Grid>
                    <Grid.Column width={1}>
                        <Icon size='large' color='teal' name='info'/>
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <Header size='small' 
                                color='teal'
                                // style={{color: getNiceColorFromStingAsciiSum(employeeGoal.category)}}
                        >
                            {employeeGoal.category}
                            <Header.Subheader>
                                Postavljen {moment(employeeGoal.createdAt).calendar()}
                                {(employeeGoal.createdAt !== employeeGoal.updatedAt) &&
                                <span>, izmenjen {moment(employeeGoal.updatedAt).calendar()}</span>}
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                </Grid>
            </Segment>

        </Segment.Group>
    );
};

export default EmployeeGoalDetailedInfo