import {IProduct, IProductsFilter} from "../../../../../models/products";

export const productsDetailedFilter = (rows: any, id: any, filterValue: IProductsFilter, root = false) => {
    return rows.filter((row: any) => {
        const product: IProduct = root ? row.original : row.original.product
        
        if (filterValue.brands.length > 0 && !filterValue.brands.includes(product.brandId!)) {
            return false
        }
        
        if (filterValue.name) {
            return String(product.name)
                .toLowerCase()
                .includes(String(filterValue.name).toLowerCase())
        }

        if (filterValue.sku) {
            return String(product.sku)
                .toLowerCase()
                .includes(String(filterValue.sku).toLowerCase())
        }
        

        return true
    })
}
