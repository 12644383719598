import {IEmployeeGoal, IEmployeeGoalFilter} from "../../../../models/employeeGoals";

export const employeeGoalFilter = (rows: any, id: any, filterValue: IEmployeeGoalFilter) => {
    return rows.filter((row: any) => {
        const goal: IEmployeeGoal = row.original
        
        if (filterValue.colors.length > 0 && !filterValue.colors.includes(goal.color)) {
            return false
        }
        
        
        if (filterValue.categories.length > 0 && !filterValue.categories.includes(goal.category)) {
            return false
        }
        
        if (filterValue.name) {
            return String(goal.name)
                .toLowerCase()
                .includes(String(filterValue.name).toLowerCase())
        }
        
        // if (filterValue.met === 'only') {
        //     return goal.isMet
        // } else if (filterValue.met === 'excluded') {
        //     return !goal.isMet
        // }
                
        return true
    })
}
