import React, {useContext, useEffect} from 'react';
import {Grid} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import {RouteComponentProps} from 'react-router';
import LoadingComponent from '../../../core/layout/LoadingComponent'
import {RootStoreContext} from "../../../core/stores/rootStore";
import ClientScheduleDetailedChat from "./ClientScheduleDetailedChat";
import ClientScheduleDetailedHeader from "./ClientScheduleDetailedHeader";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface DetailParams {
    id: string,
    clientId: string,
    year: string
}

const ClientScheduleDetails: React.FC<RouteComponentProps<DetailParams>> = ({
                                                                            match,
                                                                            history
                                                                        }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        clientSchedule,
        loadClientSchedule,
        loading
    } = rootStore.clientScheduleStore

    useEffect(() => {
        if (match.params.id) {
            loadClientSchedule({ id: match.params.id })            
        } else if (match.params.clientId && match.params.year) {
            loadClientSchedule({ 
                clientId: match.params.clientId,
                year: match.params.year
            })
        }

    }, [
        loadClientSchedule, 
        match.params.id,
        match.params.clientId,
        match.params.year,
        history]);



    if (loading) return <LoadingComponent content='Učitavanje plana prometa...'/>;

    if (!clientSchedule) return <h2>Plan prometa nije pornadjen</h2>;

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <BreadcrumbNavigation 
                        items={[
                            {
                                text: 'Plan rada'
                            },
                            {
                                text: 'Plan prometa',
                                linkWithoutCurrentTrafficId: 'clientSchedules',
                            },
                            {
                                text: `${clientSchedule.client.name} - ${clientSchedule.year}`,
                                active: true
                            }
                        ]} 
                    
                    />
                </Grid.Column>    
            </Grid.Row>
            
            <Grid.Row centered>
                <Grid.Column computer={10} tablet={10} mobile={16}>
                    
                    <ClientScheduleDetailedHeader clientSchedule={clientSchedule} />
                    
                    <ClientScheduleDetailedChat clientSchedule={clientSchedule} />
                    
                </Grid.Column>    
            </Grid.Row>
            
        </Grid>
    );
};

export default observer(ClientScheduleDetails);