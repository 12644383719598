import React, {FunctionComponent, useContext} from 'react';
import {Button, Grid, Header, Icon, Label, LabelProps, Popup} from "semantic-ui-react";
import {BillType, IBill} from "../../../core/models/bills";
import {Link} from "react-router-dom";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";

interface IProps {
    bill: IBill,
    labelProps?: LabelProps
}

const BillPopup: FunctionComponent<IProps> = ({bill, labelProps}) => {
    const rootStore = useContext(RootStoreContext)
    const {getBillDoc, loadingBillId} = rootStore.billsStore
    const {currentTraffic} = rootStore.trafficsStore

    return (<Popup
        flowing
        hoverable
        trigger={<Label
        content={bill.idString}
        {...labelProps}
    
    />}>
        <Grid centered>
            <Grid.Column textAlign='center'>
                <Header as='h4'>{bill.type === BillType.Invoice ? 'Faktura' : 'Specifikacija'}</Header>
                <p>{bill.idString}</p>
                <Button.Group>
                    <Button
                        icon
                        color='blue'
                        loading={bill.id === loadingBillId}
                        onClick={() => getBillDoc(bill.id, true)}
                        >
                        <Icon name='eye'/> Prikaži
                    </Button>
                    <Button
                        icon
                        color='orange'
                        as={Link}
                        to={`/${currentTraffic?.id}/bills/manage/${bill.id}/editCart`}>
                        <Icon name='edit'/> Izmeni
                    </Button>
                </Button.Group>
            </Grid.Column>
        </Grid>
    </Popup>)
}

BillPopup.defaultProps = {
    labelProps: {
        basic: true,
        color: 'teal'
    }
}

export default observer(BillPopup)
