import {INumberFilter} from "./NumberFilter";

export const numberFilter = (rows: any, id: any, filterValue: INumberFilter, root = false) => {
    return rows.filter((row: any) => {
        const rowValue = row.values[id]
        
        let minSatisfied = true
        let maxSatisfied = true
        
        if (filterValue.min) {
            minSatisfied = Number(rowValue) >= filterValue.min 
        }

        if (filterValue.max) {
            maxSatisfied = Number(rowValue) <= filterValue.max
        }
        
        return minSatisfied && maxSatisfied
    })
}
