import React, {useContext, useEffect} from 'react'
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import UserPostDetails from "./UserPostDetails";
import {UserPost} from "../../../core/models/userPosts";
import LoadingComponent from "../../../core/layout/LoadingComponent";
import {Grid, Header} from "semantic-ui-react";

interface DetailParams {
    id: string
}


const UserPostDetailsRouteWrapper: React.FC<RouteComponentProps<DetailParams>> = ({
                                                                                      match,
                                                                                      history
                                                                                  }) => {

    const rootStore = useContext(RootStoreContext)
    const {loadUserPost, loadingSinglePost: loading, userPost} = rootStore.userPostsStore

    useEffect(() => {
        loadUserPost(match.params.id)
    }, [
        loadUserPost,
        match.params.id
    ])

    if (loading || !userPost)
        return <LoadingComponent content='Učitavanje posta...'/>

    return <Grid>
        <Grid.Row centered>
            <Grid.Column textAlign='center'>
                <Header
                    as='h2'
                    color='blue'>
                    Pojedinačni post: {userPost.title}
                </Header>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
            <Grid.Column computer={10} tablet={12} mobile={16}>
                <UserPostDetails userPost={new UserPost(userPost)}/>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default observer(UserPostDetailsRouteWrapper)
