import React, {FunctionComponent, useContext} from 'react';
import ProfitabilityDashboardIncomeDiagram from "./ProfitabilityDashboardIncomeDiagram";
import ProfitabilityDashboardDifInPriceDiagram from "./ProfitabilityDashboardDifInPriceDiagram";
import {RootStoreContext} from "../../../../../core/stores/rootStore";

interface IProps {}

const ProfitabilityIncomeDiagrams: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const { showDiagramsExtensions } = rootStore.profitabilityStore
    
  return (<>
      <ProfitabilityDashboardIncomeDiagram />
      {showDiagramsExtensions &&
      <ProfitabilityDashboardDifInPriceDiagram />}
      
  </>);
};

export default ProfitabilityIncomeDiagrams;
