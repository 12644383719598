import {IClient} from "./clients";
import {IUser} from "./user";
import {semanticColorToHash} from "../common/util/semanticUi";
import moment from "moment";
import {Header} from "semantic-ui-react";
import {DATE_TIME_SERIALIZABLE_FORMAT_STRING} from "../common/util/date";

export enum ClientTourStatus {
    Scheduled = 0,
    Realized = 1,
    Delayed = 2,
    Canceled = 3,
    Planed = 4
}


export interface IClientTourParticipant {
    id: string
    user: IUser
}

export interface IClientTour {
    id: string
    title: string
    client: IClient
    startDateTime: Date
    endDateTime: Date
    status: ClientTourStatus
    location: string
    note: string
    noteUpdatedAt?: Date
    participants: IClientTourParticipant[]
    
    //frontend only 
    flags: {
        updating: boolean,
        deleting: boolean
    }
}

export class ClientTour implements IClientTour {
    client: IClient;
    title: string
    endDateTime: Date;
    flags: { updating: boolean, deleting: boolean };
    id: string;
    location: string;
    note: string;
    noteUpdatedAt?: Date
    participants: IClientTourParticipant[];
    startDateTime: Date;
    status: ClientTourStatus;
    
    constructor(clientTour: IClientTour) {
        this.id = clientTour.id
        this.title = clientTour.title
        this.client = clientTour.client
        this.startDateTime = clientTour.startDateTime
        this.endDateTime = clientTour.endDateTime
        this.location = clientTour.location
        this.note = clientTour.note
        this.noteUpdatedAt = clientTour.noteUpdatedAt
        this.participants = clientTour.participants
        this.status = clientTour.status

        //flags
        this.flags = {
            updating: false,
            deleting: false
        }
    }
}

export interface IClientTourFormValues {
    id: string
    title: string
    clientId: string
    startDateTime: string
    endDateTime: string
    status: ClientTourStatus
    location: string
    note: string
    participantsIds: string[]
}

export class ClientTourFormValues implements IClientTourFormValues {
    clientId: string = ''
    title: string = ''
    endDateTime: string = ''
    id: string = ''
    location: string = ''
    note: string = ''
    participantsIds: string[] = []
    startDateTime: string = ''
    status: ClientTourStatus = ClientTourStatus.Scheduled

    
    
    constructor(clientTour?: IClientTour) {
        if (clientTour) {
            this.id = clientTour.id
            this.title = clientTour.title
            this.clientId = clientTour.client.id!
            this.status = clientTour.status
            this.note = clientTour.note
            this.location = clientTour.location
            this.startDateTime = clientTour.startDateTime ? moment(clientTour.startDateTime).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING) : ''
            this.endDateTime = clientTour.endDateTime ? moment(clientTour.endDateTime).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING) : ''
            this.participantsIds = clientTour.participants.map(p => p.user.id)
            
        }
    }
}


export const getClientTourStatusSerbianString = (status: ClientTourStatus) => {
    switch (status) {
        case ClientTourStatus.Canceled:
            return 'Otkazan'
        case ClientTourStatus.Delayed:
            return 'Odložen'
        case ClientTourStatus.Realized:
            return 'Realizovan'
        case ClientTourStatus.Planed:
            return 'U planu'
        default:    // scheduled
            return 'Zakazan'
    }
}

export const getClientTourStatusColorName = (status: ClientTourStatus) => {
    switch (status) {
        case ClientTourStatus.Canceled:
            return 'red'
        case ClientTourStatus.Delayed:
            return 'orange'
        case ClientTourStatus.Realized:
            return 'blue'
        case ClientTourStatus.Planed:
            return 'brown'
        default:    // scheduled
            return 'grey'
    }
}

export const getClientTourStatusColorHash = (status: ClientTourStatus) => {
    return semanticColorToHash(getClientTourStatusColorName(status))
}

export const clientToursStatusDropdownOptions = [
    {
        key: ClientTourStatus.Scheduled,
        value: ClientTourStatus.Scheduled,
        text: 'Zakazan'.toUpperCase(),
        as: Header,
        color: getClientTourStatusColorName(ClientTourStatus.Scheduled)
    },
    {
        key: ClientTourStatus.Planed,
        value: ClientTourStatus.Planed,
        text: 'U planu'.toUpperCase(),
        as: Header,
        color: getClientTourStatusColorName(ClientTourStatus.Planed)
    },
    {
        key: ClientTourStatus.Realized,
        value: ClientTourStatus.Realized,
        text: 'Realizovan'.toUpperCase(),
        as: Header,
        color: getClientTourStatusColorName(ClientTourStatus.Realized)
    },
    {
        key: ClientTourStatus.Delayed,
        value: ClientTourStatus.Delayed,
        text: 'Odložen'.toUpperCase(),
        as: Header,
        color: getClientTourStatusColorName(ClientTourStatus.Delayed)
    },
    {
        key: ClientTourStatus.Canceled,
        value: ClientTourStatus.Canceled,
        text: 'Otkazan'.toUpperCase(),
        as: Header,
        color: getClientTourStatusColorName(ClientTourStatus.Canceled)
    }
]