import React, { FunctionComponent } from 'react';

interface IProps {}

const BillDetails: FunctionComponent<IProps> = (props) => {
  
  return (<>
        <h1>Bill details</h1>
      </>);
};

export default BillDetails;
