import {FilterProps} from "react-table";
import React from "react";
import {Grid, Select} from "semantic-ui-react";
import {ITrueFalseAllFilter} from "./trueFalseAllFilter";


export const TrueFalseAllFilter = ({
                                               column: {
                                                   filterValue,
                                                   preFilteredRows,
                                                   filteredRows,
                                                   setFilter,
                                                   filter
                                               }
                                           }: FilterProps<ITrueFalseAllFilter>,
                                           trueName: string,
                                           falseName: string,
                                            placeholder?: string
) => {

    return (
        <>
            <Grid>
                <Grid.Column width={16}>
                    <Select options={[
                        {key: 'all', value: 'all', text: 'Sve'},
                        {key: 'only', value: 'only', text: trueName},
                        {key: 'excluded', value: 'excluded', text: falseName}]} 
                            fluid
                            value={filterValue?.value}
                            onChange={(event, data) => setFilter({...filterValue, value: data.value})}
                            placeholder={placeholder ?? trueName} />
                </Grid.Column>
            </Grid>
        </>
    )
}