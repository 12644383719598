import React, {useContext} from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {Icon} from "semantic-ui-react";
import {RootStoreContext} from "../stores/rootStore";
import {observer} from "mobx-react-lite";
import NavBar from "../../features/nav/NavBar";
import LeftSideNavBar from "../../features/nav/LeftSideNavBar";


export const LAYOUT_DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        },
        appBarShift: {
            marginLeft: LAYOUT_DRAWER_WIDTH,
            width: `calc(100% - ${LAYOUT_DRAWER_WIDTH}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: LAYOUT_DRAWER_WIDTH,
            flexShrink: 0,
            whiteSpace: 'nowrap'
        },
        drawerOpen: {
            width: LAYOUT_DRAWER_WIDTH,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            // backgroundImage: 'linear-gradient(90deg, rgba(188,196,237,1) 60%, rgba(131,58,180,1) 86%, rgba(53,77,201,1) 98%)'
},
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
            // backgroundImage: 'linear-gradient(90deg, rgba(188,196,237,1) 60%, rgba(131,58,180,1) 86%, rgba(53,77,201,1) 98%)'
},
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        }
    }),
);



interface IProps {
    
}

const SidebarLayout: React.FC<IProps> = ({children}) => {
    const rootStore = useContext(RootStoreContext);
    const { leftSidebarVisible, setLeftSidebarVisible, 
        // rightSidebarVisible, setRightSidebarVisible 
    } = rootStore.navbarStore
    const classes = useStyles();
    const theme = useTheme();


    // const handleDrawerOpen = () => {
    //     setLeftSidebarVisible(true)
    // };
    //
    // const handleDrawerClose = () => {
    //     setLeftSidebarVisible(false);
    // };

    return (
                            <div className={classes.root}>
                                <CssBaseline />
                                <AppBar
                                    position="fixed"
                                    className={clsx(classes.appBar, {
                                        [classes.appBarShift]: leftSidebarVisible,
                                    }, 'vice-city')}
                                >
                                    <Toolbar >
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={() => setLeftSidebarVisible(true)}
                                            edge="start"
                                            className={clsx(classes.menuButton, {
                                                [classes.hide]: leftSidebarVisible,
                                            })}
                                        >
                                            <Icon name='bars' />
                                        </IconButton>
                                        <NavBar/>
                                        {/*<Checkbox*/}
                                        {/*    checked={visible}*/}
                                        {/*    label={{ children: <code>visible</code> }}*/}
                                        {/*    onChange={(e, data) => setVisible(data.checked!)}*/}
                                        {/*/>*/}
                                    </Toolbar>
                                </AppBar>
                                <Drawer
                                    style={{background: 'white'}}
                                    variant="permanent"
                                    className={clsx(classes.drawer, {
                                        [classes.drawerOpen]: leftSidebarVisible,
                                        [classes.drawerClose]: !leftSidebarVisible,
                                    })}
                                    classes={{
                                        paper: clsx({
                                            [classes.drawerOpen]: leftSidebarVisible,
                                            [classes.drawerClose]: !leftSidebarVisible,
                                        }),
                                    }}
                                >
                                    <div className={classes.toolbar}>
                                        <IconButton onClick={() => setLeftSidebarVisible(false)}>
                                            {theme.direction === 'rtl' ? <Icon name='angle left' /> : <Icon name='angle left' />}
                                        </IconButton>
                                    </div>
                                    <Divider />
                                    <LeftSideNavBar/>
                                </Drawer>
                                <main className={classes.content}>
                                    <div className={classes.toolbar} />
                                    {children}
                                </main>
                            </div>
          
        )
}

export default observer(SidebarLayout)
