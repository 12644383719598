import {IUser} from "./user";

export interface IUserPostParticipant {
    id: string
    user: IUser
    isRead: boolean
}

export interface IUserPostComment {
    id: string
    userPostId: string
    userPostTitle: string
    commentator: IUser
    content: string
    createdAt: Date
    updatedAt: Date
}

export interface IUserPost {
    id: string
    creator: IUser
    title: string
    content: string
    participants: IUserPostParticipant[]
    comments: IUserPostComment[]
    createdAt: Date
    updatedAt: Date
    
    // frontOnly
    flags: {
        updating: boolean
    }
}

export interface IUserPostFormValues {
    id: string
    title: string
    content: string
    participantsIds: string[]
}

export interface IUserPostsEnvelope {
    userPosts: IUserPost[]
    count: number
}

export class UserPostFormValues implements IUserPostFormValues {
    content: string = ''
    id: string = ''
    participantsIds: string[] = []
    title: string = ''
    
    constructor(userPost?: IUserPost) {
        if (userPost) {
            this.id = userPost.id
            this.title = userPost.title
            this.content = userPost.content
            this.participantsIds = userPost.participants.map(participant => participant.user.id)
        }
    }
    
}

export class UserPost implements IUserPost {
    comments: IUserPostComment[];
    content: string;
    createdAt: Date;
    creator: IUser;
    id: string;
    participants: IUserPostParticipant[];
    title: string;
    updatedAt: Date;
    
    // flags
    flags: {
        updating: boolean
    }

    constructor(userPost: IUserPost) {
        this.id = userPost.id
        this.title = userPost.title
        this.creator = userPost.creator
        this.content = userPost.content
        this.createdAt = userPost.createdAt
        this.updatedAt = userPost.updatedAt
        this.participants = userPost.participants
        this.comments = userPost.comments
        
        // flags
        this.flags = userPost.flags ?? {
            updating: false
        }
    }

    readByCount = () => {
        return this.participants.reduce((total, current) => {
            if (current.isRead) {
                return total + 1
            } else {
                return total
            }
        }, 0)
    }

    isReadByUser = (id: string) => this.participants.find(participant => participant.user.id === id && participant.isRead)
}

export const isPostReadByUser = (post: IUserPost, id: string) => post.participants.find(participant => participant.user.id === id && participant.isRead)