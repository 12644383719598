import React, {FunctionComponent, useContext, useState} from 'react'
import {IEmployeeGoal} from "../../../core/models/employeeGoals";
import {Grid, Header, Icon, Label, Loader, Segment} from "semantic-ui-react";
import DateTimePickerWithShortcuts from "../../commom/date/DateTimePickerWithShortcuts";
import moment from "moment";
import ShowMoreButton from "../../commom/buttons/ShowMoreButton";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {dateToLocaleStringOrAccentYesterdayTodayAndTomorrow} from "../../../core/common/util/date";

interface OwnProps {
    employeeGoal: IEmployeeGoal
}

type Props = OwnProps

const EmployeeGoalDatesCellCompletedDate: FunctionComponent<Props> = ({employeeGoal}) => {
    const rootStore = useContext(RootStoreContext)

    const {updateDate} = rootStore.employeeGoalsStore

    const [editMode, setEditMode] = useState(false)
    const [showEditIcon, setShowEditIcon] = useState(false)


    return <>
        {editMode ?
            <Segment.Group compact>
                <Segment attached='bottom' style={{padding: 5}}>

                    <Label
                        basic
                        style={{border: 0}}
                        attached='top right'>
                        <Loader size='mini' inline active={employeeGoal.flags?.updating}/>
                        <Icon
                            style={{cursor: 'pointer'}}
                            name='x'
                            circular
                            size='small'
                            onClick={() => setEditMode(false)}
                        />
                    </Label>
                    <DateTimePickerWithShortcuts
                        placeholder='Datum završetka'
                        shortcuts={{
                            today: true,
                            tomorrow: true,
                            nextWeek: true
                        }}
                        vertical
                        disabled={employeeGoal.flags?.updating}
                        date={employeeGoal.completedDate ? moment(employeeGoal.completedDate) : null}
                        onChange={date =>
                            updateDate(employeeGoal.id, 'completed', date ? date.format('YYYY-MM-DD') : null)}/>
                </Segment>
            </Segment.Group> :
            <div>
                {employeeGoal.completedDate ? <Header as='h3'>
                    <Header.Content
                        onMouseEnter={() => setShowEditIcon(true)}
                        onMouseLeave={() => setShowEditIcon(false)}
                    >
                        <Header.Subheader>Najkasnije</Header.Subheader>
                        {moment(employeeGoal.completedDate).isBetween(moment().subtract(2, 'day'), moment().add(2, 'day'), 'day') ?
                            <Header as='h4' color='blue' content={dateToLocaleStringOrAccentYesterdayTodayAndTomorrow(employeeGoal.completedDate)} /> :
                            <span>{moment(employeeGoal.completedDate).format('l')}</span>}{' '}{showEditIcon && <Icon
                        link
                        name='pencil alternate'
                        onClick={() => setEditMode(true)}
                        color='orange'/>}
                    </Header.Content>
                </Header> : <Grid>
                    <Grid.Column textAlign='center' width={16}>
                        <ShowMoreButton content='Dodaj datum završetka' onClick={() => setEditMode(true)}/>
                    </Grid.Column>
                </Grid>}
            </div>}
    </>
}

export default EmployeeGoalDatesCellCompletedDate
