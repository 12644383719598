import {BillCreationType, BillType, IBill} from "../../../../../models/bills";
import {INCLUDED, TS_FILTER} from "../../../../filters/3sFilter";
import {CreationTypes} from "../../../../options/bill";

export interface IBillTypeFilter {
    gratis: TS_FILTER
    onSale: TS_FILTER
    type: 'onlyInvoice' | 'onlySpec' | 'all'
    creationType: CreationTypes | 'all'
}

export class BillTypeFilter implements IBillTypeFilter {
    gratis: TS_FILTER
    onSale: TS_FILTER
    type: 'onlyInvoice' | 'onlySpec' | 'all'
    creationType: CreationTypes | 'all'
    
    constructor() {
        this.gratis = INCLUDED
        this.onSale = INCLUDED
        this.type = 'all'
        this.creationType = 'all'
    }
    
}

export const billTypeFilter = (rows: any, id: any, filterValue: IBillTypeFilter) => {
    return rows.filter((row: any) => {
        const bill: IBill = row.original

        if (filterValue.gratis) {
            if (filterValue.gratis === 'only' && !bill.isGratis) {
                return false
            } else if (filterValue.gratis === 'excluded' && bill.isGratis) {
                return false
            }
        }

        if (filterValue.type) {
            if (filterValue.type === 'onlyInvoice' && bill.type !== BillType.Invoice) {
                return false
            } else if (filterValue.type === 'onlySpec' && bill.type !== BillType.Specification) {
                return false
            }
        }

        if (filterValue.onSale) {
            if (filterValue.onSale === 'only' && !bill.isOnSale) {
                return false
            } else if (filterValue.onSale === 'excluded' && bill.isOnSale) {
                return false
            }
        }

        if (filterValue.creationType !== 'all') {
            if (filterValue.creationType === CreationTypes.Cart && bill.creationType !== BillCreationType.Cart) {
                return false
            } else if (filterValue.creationType === CreationTypes.Manual && bill.creationType !== BillCreationType.Manual) {
                return false
            }
        }
        
        return true
    })
}
