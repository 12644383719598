import React, {FunctionComponent} from 'react';
import {FieldRenderProps} from "react-final-form";
import {FormFieldProps, Form, Label, Select, SelectProps} from "semantic-ui-react";

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {
    onChangeCallback?: (value: string) => void
}

const SelectStringInput: FunctionComponent<IProps> = ({
                                                          input,
                                                          width,
                                                          onChangeCallback,
                                                          meta: {error, touched},
                                                          ...rest
                                                      }) => {

    return (
        <Form.Field error={touched && !!error} width={width}>
            <Select
                value={input.value}
                onChange={(e, data) => {
                    input.onChange(data.value)
                    if (onChangeCallback) {
                        onChangeCallback(data.value as string)                        
                    }
                }}
                input={input}
                {...rest as SelectProps}
            />
            {touched && error && (
                <Label basic color='red'>
                    {error}
                </Label>
            )}
        </Form.Field>
    )
}


export default SelectStringInput;
