import React, {FunctionComponent, useContext} from 'react';
import MyPieChart from "../../../../../core/common/charts/MyPieChart";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../core/stores/rootStore";
import {formatRsd} from "../../../../../core/common/util/format";
import {summator} from "../../../../../core/common/util/array";
import {
    profitabilityIncomeGroupByBrandListTableTrackingId,
    profitabilityIncomeGroupByClientCategoryTableTrackingId
} from "../../income/ProfitabilityDashboardIncomeSection";

interface IProps {
}

const ProfitabilityDashboardDifInPriceDiagram: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {SHIT_getFilteredRowsById} = rootStore.tablesStore
    const {incomeView} = rootStore.profitabilityStore


    const filteredRows = incomeView === 'brand' ?
        SHIT_getFilteredRowsById(profitabilityIncomeGroupByBrandListTableTrackingId) ?? [] :
        incomeView === 'clientCategory' ?
            SHIT_getFilteredRowsById(profitabilityIncomeGroupByClientCategoryTableTrackingId) ?? [] :
            []

    const colors = incomeView === 'brand' ?
        filteredRows.map(row => row.brand.color) :
        incomeView === 'clientCategory' ?
            filteredRows.map(row => row.clientCategoryColor) :
            []

    const yAccessor = incomeView === 'brand' ? 'brand.name' :
        incomeView === 'clientCategory' ? 'clientCategory' : ''

    return (<>

        <MyPieChart
            data={filteredRows}
            colors={colors}
            xAccessor={yAccessor}
            yAccessor='differenceInPrice'
            title={[
                'RUC',
                formatRsd(summator(filteredRows, 'differenceInPrice'))]}/>
    </>);
};

export default observer(ProfitabilityDashboardDifInPriceDiagram)
