import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {ClientEmployee, IClientEmployee} from "../models/clients";
import agent from "../api/agent";
import { v4 as uuid } from 'uuid'

export default class ClientEmployeesStore {
    rootStore: RootStore
    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }
    
    @observable clientEmployeesRegistry = new Map<string, IClientEmployee>()
    @observable loading = false
    @observable lastRequestId = ''
    @observable predicate = new Map<string, string>()
    
    @computed get axiosParams() {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }
    
    @computed get clientEmployeesArray() {
        return Array.from(this.clientEmployeesRegistry.values())
    }
    
    @action setPredicate = (predicate: 'clientId', value: string) => this.predicate.set(predicate, value)
    
    @action clearPredicates = () => this.clientEmployeesRegistry.clear()
    
    @action loadClientEmployees = async () => {
        const lastRequestId = uuid()
        this.lastRequestId = lastRequestId
        this.loading = true
        try {   
            const employees = await agent.ClientEmployees.list(this.axiosParams)
            runInAction(() => {
                if (lastRequestId === this.lastRequestId) {
                    this.clientEmployeesRegistry.clear()
                    employees.forEach(employee => {
                        this.clientEmployeesRegistry.set(employee.id, new ClientEmployee(employee))
                    })
                }                 
            })
        } catch (error) {
            console.log(error)
            this.clientEmployeesRegistry.clear()
        } finally {
            runInAction(() => {
                if (lastRequestId === this.lastRequestId) {
                    this.loading = false                    
                }
            })
        }
    }
}