import {RootStore} from "./rootStore";
import {action, makeObservable, observable} from "mobx";

export default class ModalStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }
    
    @observable.shallow modal = {
        open: false,
        body: null,
        size: 'mini' as 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
    }
    
    @action openModal = (content: any, size: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen' = 'mini') => {
        this.modal.open = true
        this.modal.body = content
        this.modal.size = size
    }
    
    @action closeModal = () => {
        this.modal.open = false
        this.modal.body = null
        this.modal.size = 'mini' 
    }
}