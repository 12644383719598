import {IClient} from "./clients";
import {IUser} from "./user";
import moment from "moment";

export interface IClientScheduleComment {
    id: string
    clientScheduleId: string
    content: string
    user: IUser
    createdAt: Date
    updatedAt: Date
}

export interface IClientSchedule {
    id: string
    client: IClient
    year: string
    value: number
    realizationValue: number
    comments: IClientScheduleComment[]
    indebtedEmployee?: IUser
    note: string
}

export interface IClientScheduleFormValues {
    id: string
    clientId: string
    indebtedEmployeeId: string
    year: string
    value: string
    note: string
}

export class ClientScheduleFormValues implements IClientScheduleFormValues {
    id = ''
    clientId = ''
    indebtedEmployeeId = ''
    value = ''
    year = moment().format('YYYY')
    note = ''
    
    constructor(clientSchedule?: IClientSchedule) {
        if (clientSchedule) {
            this.id = clientSchedule.id
            this.clientId = clientSchedule.client.id!
            this.indebtedEmployeeId = clientSchedule.indebtedEmployee?.id ?? ''
            this.value = clientSchedule.value.toString()
            this.year = clientSchedule.year
            this.note = clientSchedule.note
        }
    }
}