import React, {FunctionComponent, useContext} from 'react'
import {Button, Icon} from "semantic-ui-react";
import {IClientOrder} from "../../../core/models/clientsOrders";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";

interface OwnProps {
    clientOrder: IClientOrder
}

type Props = OwnProps

const ClientOrderContentDocumentDownloadButton: FunctionComponent<Props> = ({  clientOrder}) => {
    const rootStore = useContext(RootStoreContext)
    const { loadClientOrderDoc, loadingContentDocId } = rootStore.clientOrdersStore
    
    
  return <Button
      floated='right'
      icon
      color='blue'
      labelPosition='left'
      loading={clientOrder.id === loadingContentDocId}
      onClick={() => loadClientOrderDoc(clientOrder.id, 'content')}
  >
      <Icon name='file'/>
      Dokument sadržaja
  </Button>
}

export default observer(ClientOrderContentDocumentDownloadButton)
