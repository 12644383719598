import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, reaction, runInAction} from "mobx";
import {IClient} from "../models/clients";
import {IBrand} from "../models/brands";
import {BillOrder, IBillOrder} from "../models/billOrders";
import {IProduct} from "../models/products";
import {getPercentageRestValue, getPercentageValue} from "../common/util/myMath";
import {dateFromStringOrNull} from "../common/util/util";
import agent from "../api/agent";
import {BillCreationType, IBillFormValues} from "../models/bills";
import {CreationTypes, Types} from "../common/options/bill";
import {ITraffic} from "../models/traffics";
import {toast} from "react-toastify";
import {IBillInstallment} from "../models/billInstallments";
import {v4 as uuid} from 'uuid'
import moment from 'moment'
import {history} from "../../index";

interface summaryPrice {
    baseWithoutDiscount: number,
    baseWithDiscount: number,
    discount: number,
    taxedWithoutDiscount: number,
    taxedWithDiscount: number,
    taxValue: number,
    manualBase: number,
    manualTaxed: number,
    manualTaxValue: number
}

export default class BillsCartStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

        reaction(
            () => this.client,
            (client) => {
                if (this.loading) return


                if (client === null) {
                    this.orders.clear()
                    this.selectedProductDiscount = 0
                } else {
                    if (this.selectedProduct) {
                        this.selectedProductDiscount = this.getPriceForClient(this.selectedProduct.id, this.client!)?.discount ?? 0
                    }
                    const oldOrders = this.ordersArray
                    this.orders.clear()
                    oldOrders.forEach(order => {
                        this.addOrder(order)
                    })
                }
            })

        reaction(
            () => this.installmentsCount,
            (value) => {
                if (this.loading) return

                this.initInstallments()
            })

        reaction(
            () => this.actualSummaryPriceValue,
            (value) => {
                if (this.loading) return

                if (this.hirePurchase) {
                    this.recalculateInstallments()
                }
            })

        reaction(
            () => this.hirePurchase,
            (hirePurchase) => {
                if (this.loading) return

                if (hirePurchase && this.installments.size < 2) {
                    this.installmentsCount = 2
                }
            })

        reaction(
            () => this.type,
            (type) => {
                if (!this.editMode) {
                    this.idString = rootStore.billsStore.getSuggestion(this.traffic!.id, type, this.isGratis) ?? ''
                }
            })

        reaction(
            () => this.isGratis,
            (isGratis) => {
                if (isGratis) {
                    this.isOnSale = false
                    this.onSaleNote = ''                    
                }

                if (!this.editMode) {
                    this.idString = rootStore.billsStore.getSuggestion(this.traffic!.id, this.type, isGratis) ?? ''
                }
            })

        reaction(
            () => this.traffic,
            (traffic) => {
                if (!this.editMode) {
                    this.idString = rootStore.billsStore.getSuggestion(traffic!.id, this.type, this.isGratis) ?? ''
                }
            })

        reaction(
            () => this.isOnSale,
            (isOnSale) => {
                if (isOnSale) {
                    this.isGratis = false   
                }
            }
        )

    }

    @observable editMode = false
    @observable loading = false
    @observable type: number = 0
    @observable idString: string = ''
    @observable traffic: ITraffic | null = null
    @observable client: IClient | null = null
    @observable brand: IBrand | null = null
    @observable discount: number = 0
    @observable date: Date | null = new Date()
    @observable payday: Date | null = new Date()
    @observable orders = new Map<string, IBillOrder>()
    @observable installments = new Map<string, IBillInstallment>()
    @observable installmentsCount = 1
    @observable isGratis = false
    @observable isOnSale = false
    @observable onSaleNote = ''
    @observable note = ''
    @observable hirePurchase = false
    @observable creationType: number = BillCreationType.Cart

    @observable selectedProduct: IProduct | null = null

    @observable selectedBrandId: string = ''
    @observable selectedProductId: string = ''
    @observable selectedProductSku: string = ''
    @observable selectedProductCount: number = 1
    @observable selectedProductDiscount: number = 0

    @observable submitting = false
    @observable errors: any[] = []

    @observable waitingFileUpload = true

    @observable manualFile: Blob | null = null
    @observable manualFileChanged = false
    @observable manualBillValue: number = 0
    @observable manualBillBasePrice: number = 0

    @action setManualFile = (file: Blob | null) => {
        this.manualFile = file
        this.manualFileChanged = true
    }


    @action resetStore = () => {
        const currentTraffic = this.rootStore.trafficsStore.currentTraffic

        this.loading = false
        this.type = 0
        this.idString = this.rootStore.billsStore.getSuggestion(currentTraffic!.id, 0, false)!
        this.traffic = currentTraffic
        this.client = null
        this.brand = null
        this.discount = 0
        this.date = new Date()
        this.payday = new Date()
        this.orders = new Map<string, IBillOrder>()
        this.orders.clear()
        this.installments = new Map<string, IBillInstallment>()
        this.installments.clear()
        this.isGratis = false
        this.note = ''
        this.selectedProduct = null
        this.selectedBrandId = ''
        this.selectedProductId = ''
        this.selectedProductSku = ''
        this.selectedProductCount = 1
        this.selectedProductDiscount = 0
        this.submitting = false
        this.installmentsCount = 1
        this.hirePurchase = false
        this.errors = []
        this.waitingFileUpload = true
        this.creationType = BillCreationType.Cart
        this.manualFile = null
        this.manualFileChanged = false
        this.manualBillValue = 0
        this.manualBillBasePrice = 0
    }


    @computed get summaryPrice(): summaryPrice {
        const baseWithoutDiscount = this.ordersArray.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.summaryPriceWithDiscount
        }, 0)
        const baseWithDiscount = getPercentageRestValue(baseWithoutDiscount, this.discount)
        const discount = baseWithoutDiscount - baseWithDiscount

        const summaryPdvRatePercentage = this.rootStore.globalSettingsStore.pdvTaxParameters.summaryRatePercentage

        const taxedWithoutDiscount = getPercentageValue(baseWithoutDiscount, summaryPdvRatePercentage)
        const taxedWithDiscount = getPercentageValue(baseWithDiscount, summaryPdvRatePercentage)
        const taxValue = taxedWithDiscount - baseWithDiscount

        const manualBase = this.manualBillValue
        const manualTaxed = getPercentageValue(manualBase, summaryPdvRatePercentage)
        const manualTaxValue = manualTaxed - manualBase


        return {
            baseWithoutDiscount,
            baseWithDiscount,
            discount,
            taxedWithoutDiscount,
            taxedWithDiscount,
            taxValue,
            manualBase,
            manualTaxed,
            manualTaxValue
        }
    }

    @computed get installmentsValueSameAsBillValue() {
        return this.actualSummaryPriceValue.toFixed(2) === this.installmentsValue.toFixed(2)
    }

    @computed get actualSummaryPriceValue() {
        if (this.creationType === CreationTypes.Cart && this.type === Types.Specification) {
            return this.summaryPrice.baseWithDiscount
        } else if (this.creationType === CreationTypes.Cart && this.type === Types.Invoice) {
            return this.summaryPrice.taxedWithDiscount
        } else if (this.creationType === CreationTypes.Manual && this.type === Types.Specification) {
            return this.summaryPrice.manualBase
        } else if (this.creationType === CreationTypes.Manual && this.type === Types.Invoice) {
            return this.summaryPrice.manualTaxed
        }

        return 0
    }

    @computed get isManualCreationType() {
        return this.creationType === CreationTypes.Manual
    }

    @computed get selectedProductPrice() {
        return this.getPriceForClient(this.selectedProduct?.id!, this.client!)
    }

    getPriceForClient(productId: string, client: IClient) {
        const product = this.rootStore.productsStore.productsRegistry.get(productId) ?? null

        if (product && client) {
            if (client.pricelistCategoryIsSelling) {
                let discount = 0
                let defaultPrice = product.prices.filter(price => price.isDefault)[0] ?? null

                if (defaultPrice) {
                    let sellingPrice = product.prices.filter(price => price.isSelling)[0] ?? null
                    discount = 100 - (sellingPrice!.value! / defaultPrice.value! * 100)

                    return {
                        discount,
                        price: defaultPrice
                    }
                } else {
                    let sellingPrice = product.prices.filter(price => price.isSelling)[0] ?? null
                    discount = 0

                    return {
                        discount,
                        price: sellingPrice
                    }
                }
            } else {
                let specificPrice = product.prices.filter(price => price.pricelistId === client.pricelistCategoryId)[0] ?? null

                if (specificPrice) {
                    return {
                        discount: 0,
                        price: specificPrice
                    }
                } else {
                    let discount = 0
                    let defaultPrice = product.prices.filter(price => price.isDefault)[0] ?? null

                    if (defaultPrice) {
                        let sellingPrice = product.prices.filter(price => price.isSelling)[0] ?? null
                        discount = 100 - (sellingPrice!.value! / defaultPrice.value! * 100)

                        return {
                            discount,
                            price: defaultPrice
                        }
                    } else {
                        let sellingPrice = product.prices.filter(price => price.isSelling)[0] ?? null
                        discount = 0

                        return {
                            discount,
                            price: sellingPrice
                        }
                    }
                }
            }

        }

        return {
            discount: 0,
            price: null
        }
    }

    @computed get ordersArray() {
        return Array.from(this.orders.values())
    }

    @computed get isCartEmpty() {
        return (this.ordersArray.length === 0)
    }

    @computed get installmentsArray() {
        return Array.from(this.installments.values())
    }

    @computed get installmentsValue() {
        return this.installmentsArray.reduce((previousValue, current) => {
            return previousValue + current.value
        }, 0)
    }

    @computed get installmentsPercentage() {
        return this.installmentsArray.reduce((previousValue, current) => {
            return previousValue + current.percentage
        }, 0)
    }

    @action setSelectedBrandId = (id: string) => {
        this.selectedBrandId = id
        this.selectedProduct = null
        this.selectedProductId = ''
        this.selectedProductSku = ''
        this.selectedProductDiscount = 0

    }

    @action setSelectedProductId = (id: string) => {
        this.selectedProductId = id
        const product = this.rootStore.productsStore.productsRegistry.get(id) ?? null
        if (product) {
            this.selectedProductDiscount = this.getPriceForClient(product.id, this.client!)?.discount ?? 0
            this.selectedProduct = product
            this.selectedBrandId = product.brandId.toString()
            this.selectedProductSku = product.sku.toString()
        } else {
            this.selectedProduct = null
            this.selectedBrandId = ''
            this.selectedProductSku = ''
            this.selectedProductDiscount = 0
        }
    }

    @action setSelectedProductSku = (sku: string) => {
        this.selectedProductSku = sku
        const product = this.rootStore.productsStore.productsByNameArray.filter(product => product.sku === sku)[0] ?? null
        if (product) {
            this.selectedProductDiscount = this.getPriceForClient(product.id, this.client!)?.discount ?? 0
            this.selectedProduct = product
            this.selectedBrandId = product.brandId.toString()
            this.selectedProductId = product.id.toString()
        } else {
            this.selectedProduct = null
            this.selectedBrandId = ''
            this.selectedProductId = ''
            this.selectedProductDiscount = 0
        }
    }

    @action setSelectedProductCount = (count: number) => {
        if (count > 0) {
            this.selectedProductCount = count
        }
    }

    @action setSelectedProductDiscount = (discount: number) => {
        if (discount >= 0 && discount <= 100) {
            this.selectedProductDiscount = discount
        }
    }

    @action setClient = (id: string) => {
        this.client = this.rootStore.clientsStore.getClient(id)!
    }

    @action setType = (type: number) => {
        this.type = type
    }

    @action clearSelection = () => {
        this.selectedProduct = null
        this.selectedProductId = ''
        this.selectedProductSku = ''
        this.selectedBrandId = ''
        this.selectedProductCount = 1
        this.selectedProductDiscount = 0
    }

    @action removeOrder = (id: string) => {
        this.orders.delete(id)
    }

    @action addOrderFromSelected = () => {
        if (!this.selectedProductPrice.price?.value) return


        // const { price, discount } = this.selectedProductPrice
        const newOrder = new BillOrder(this.selectedProduct!,
            this.selectedProductCount, this.selectedProductDiscount,
            this.selectedProductPrice?.price.value)
        this.orders.set(newOrder.id, newOrder)
        this.clearSelection()
    }

    @action addOrder = (order: IBillOrder) => {

        const price = this.getPriceForClient(order.productId!, this.client!)!.price!.value! ?? null
        if (!price) return

        const newOrder =
            new BillOrder(this.rootStore.productsStore.productsRegistry.get(order.productId)!,
                order.count,
                order.discount,
                price)
        this.orders.set(newOrder.id, newOrder)
    }

    @action setDiscount = (discount: number) => {
        if (discount >= 0 && discount <= 100) {
            this.discount = discount
        }
    }

    @action setDate = (date: string) => {
        this.date = dateFromStringOrNull(date)
    }

    @action setPayday = (payday: string) => {
        this.payday = dateFromStringOrNull(payday)
    }

    @action setIsGratis = (isGratis: boolean) => this.isGratis = isGratis

    @action setIsOnSale = (isOnSale: boolean) => this.isOnSale = isOnSale

    @action setOnSaleNote = (note: string) => this.onSaleNote = note
    
    @action setNote = (note: string) => this.note = note

    @action setTraffic = async (id: number) => {
        const traffic = await this.rootStore.trafficsStore.trafficsRegistry.get(id) ?? null
        runInAction(() => {
            this.traffic = traffic
        })

    }

    @action setIdString = (idString: string) => this.idString = idString

    get validate(): boolean {
        let valid = true

        if (this.creationType === CreationTypes.Cart) {
            if (this.ordersArray.length === 0) {
                toast.error('Korpa je prazna')
                valid = false
            }
        } else if (this.creationType === CreationTypes.Manual) {
            if (!this.editMode && this.manualFile === null) {
                toast.error('Fajl je obavezan')
                valid = false
            }
        }
        
        if (this.isOnSale && !this.onSaleNote) {
            toast.error('Proj akcijskog prometa je obavezan')
            valid = false
        }

        return valid
    }

    @action createBill = async () => {
        this.submitting = true
        this.errors = []
        try {
            if (!this.validate) {
                this.submitting = false
                return
            }

            const newBill: IBillFormValues = {
                id: uuid(),
                idString: this.idString!,
                brandId: this.brand?.id!,
                clientId: this.client?.id!,
                date: this.date!,
                payday: this.payday!,
                creationType: this.creationType,
                discountRate: this.discount,
                hirePurchase: this.hirePurchase,
                isGratis: this.isGratis,
                isOnSale: this.isOnSale,
                onSaleNote: this.onSaleNote,
                note: this.note,
                trafficId: this.traffic?.id ?? this.rootStore.trafficsStore.getDefault().id,
                type: this.type,
                billOrders: this.ordersArray,
                value: this.type === Types.Specification ? this.summaryPrice.baseWithDiscount : Types.Invoice ? this.summaryPrice.taxedWithDiscount : NaN,
                billInstallments: this.installmentsArray,
                waitingFileUpload: this.waitingFileUpload,
                manualFileChanged: this.manualFileChanged,
                manualBillValue: this.manualBillValue,
                manualBillBasePrice: this.manualBillBasePrice
            }

            if (this.creationType === CreationTypes.Cart) {
                const blob = await agent.Bills.createCart(newBill)

                const url = URL.createObjectURL(blob)
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/bills`)
                window.location.href = url
            } else {
                await agent.Bills.createCart(newBill)
                await agent.Bills.uploadFileManual(newBill.id, this.manualFile!)
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/bills`)
            }
            
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }

    }

    @action editBill = async () => {
        this.submitting = true
        this.errors = []
        try {
            if (!this.validate) {
                this.submitting = false
                return
            }

            const editedBill: IBillFormValues = {
                id: this.rootStore.billsStore.bill?.id!,
                idString: this.idString!,
                brandId: this.brand?.id!,
                clientId: this.client?.id!,
                date: this.date!,
                payday: this.payday!,
                creationType: this.creationType,
                discountRate: this.discount,
                hirePurchase: this.hirePurchase,
                isGratis: this.isGratis,
                isOnSale: this.isOnSale,
                onSaleNote: this.onSaleNote,
                note: this.note,
                trafficId: this.traffic?.id ?? this.rootStore.trafficsStore.getDefault().id,
                type: this.type,
                billOrders: this.ordersArray,
                value: this.type === Types.Specification ? this.summaryPrice.baseWithDiscount : Types.Invoice ? this.summaryPrice.taxedWithDiscount : NaN,
                billInstallments: this.installmentsArray,
                waitingFileUpload: this.waitingFileUpload,
                manualFileChanged: this.manualFileChanged,
                manualBillValue: this.manualBillValue,
                manualBillBasePrice: this.manualBillBasePrice
            }

            if (this.creationType === CreationTypes.Cart) {
                const blob = await agent.Bills.editCart(editedBill)
                const url = URL.createObjectURL(blob)
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/bills`)
                window.location.href = url
            } else {
                await agent.Bills.editCart(editedBill)
                if (this.manualFileChanged) {
                    await agent.Bills.uploadFileManual(editedBill.id, this.manualFile!)
                }
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/bills`)
            }
        } catch (error) {
            runInAction(() => {
                // const errorsArray = Object.values(error?.data?.errors)
                // errorsArray.forEach((err: any) => toast.error(err.toString()))
            })
        } finally {
            this.submitting = false
        }

    }

    @action increaseInstallments = () => {
        this.installmentsCount++
    }

    @action decreaseInstallments = () => {
        if (this.installmentsCount > 2)
            this.installmentsCount--
    }

    @action initInstallments = () => {
        const value = this.actualSummaryPriceValue
        const installmentValue = value / this.installmentsCount
        const installmentValueRest = value - (installmentValue * this.installmentsCount)

        const installmentRate = 100 / this.installmentsCount

        this.installments.clear()

        for (let i = 0; i < this.installmentsCount; i++) {
            const newInstallment: IBillInstallment = {
                value: installmentValue,
                billId: uuid(),
                id: uuid(),
                traffic: this.rootStore.trafficsStore.currentTraffic!,
                billIdString: this.idString,
                note: '',
                ordinal: i + 1,
                paid: false,
                date: new Date(moment().add(i, 'months').format('YYYY-M-DD')),
                percentage: installmentRate,
                installmentsCount: this.installmentsCount,
                client: null
            }
            if (i === 0) {
                newInstallment.value = newInstallment.value + installmentValueRest
            }

            this.installments.set(newInstallment.id, newInstallment)
        }
    }

    @action recalculateInstallments = () => {
        let installmentsValueDec = 0
        const summaryValueDec = this.actualSummaryPriceValue

        this.installmentsArray.forEach(installment => {
            const normalizedValueDec = this.actualSummaryPriceValue * installment.percentage / 100
            const updatedInstallment: IBillInstallment = {
                ...installment,
                value: normalizedValueDec
            }
            this.installments.set(installment.id, updatedInstallment)
            installmentsValueDec += normalizedValueDec
        })

        const rest = summaryValueDec - installmentsValueDec
        const firstInstallment = this.installmentsArray[0] ?? null
        if (firstInstallment) {
            firstInstallment.value = firstInstallment.value + rest

            this.installments.set(firstInstallment.id, firstInstallment)
        }
        this.formatInstallmentsValues()
    }

    @action updateInstallmentValue = (id: string, value: number) => {
        if (value < 0) return

        const oldInstallment: IBillInstallment = this.installments.get(id)!
        const percentage = value / this.actualSummaryPriceValue * 100

        this.installments.set(id, {...oldInstallment, value, percentage})
    }

    @action updateInstallmentPercentage = (id: string, percentage: number) => {
        if (percentage < 0 || percentage > 100) return

        const percentageCoef = percentage / 100

        const oldInstallment: IBillInstallment = this.installments.get(id)!
        const value = percentageCoef * this.actualSummaryPriceValue

        this.installments.set(id, {...oldInstallment, value, percentage: percentage})
    }

    @action updateInstallmentDate = (id: string, date: string) => {
        const oldInstallment: IBillInstallment = this.installments.get(id)!

        this.installments.set(id, {...oldInstallment, date: dateFromStringOrNull(date)})
    }

    @action setHirePurchase = (hirePurchase: boolean) => this.hirePurchase = hirePurchase

    @action loadBill = async (id: string) => {
        this.loading = true
        this.resetStore()
        try {
            const bill = await this.rootStore.billsStore.loadBill(id) ?? null

            if (bill === null)
                throw new Error('Bill doesn\'t exist')

            const paidInstallment = bill.billInstallments.filter(installment => installment.paid)[0] ?? null
            if (paidInstallment !== null) {
                toast.warning('Postoje plaćene rate vezane za ovaj račun. Prilikom izmene plaćene rate će biti obrisane')
            }

            runInAction(() => {
                this.type = bill.type
                this.idString = bill.idString
                this.traffic = bill.traffic
                this.client = bill.client
                this.brand = bill.brand
                this.discount = bill.discountRate
                this.date = bill.date
                this.payday = bill.payday

                this.installmentsCount = bill.billInstallments.length // Obavezno prvo ovo jer postoji reakcija na count => init
                // this.installments.clear()
                this.isGratis = bill.isGratis
                
                this.isOnSale = bill.isOnSale
                this.onSaleNote = bill.onSaleNote
                
                this.note = bill.note
                this.hirePurchase = bill.hirePurchase
                this.creationType = bill.creationType
            })
            this.installments.clear()
            bill.billOrders.forEach(order => this.orders.set(order.id, order))
            bill.billInstallments.forEach(installment =>
                this.installments.set(installment.id, {
                    ...installment,
                    percentage: installment.value / bill.value * 100
                })
            )

            this.manualBillValue = bill.baseValue
            this.manualBillBasePrice = bill.manualBillBasePrice


        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }

    }

    @action formatInstallmentsValues = () => {
        this.installmentsArray.forEach(installment => {
            let updatedInstallment = installment
            updatedInstallment.value = Number(updatedInstallment.value.toFixed(2))

            this.installments.set(installment.id, updatedInstallment)
        })
    }

    @action setWaitingFileUpload = (waitingFileUpload: boolean) => this.waitingFileUpload = waitingFileUpload

    @action setBrand = (brandId: number) => {
        this.brand = this.rootStore.brandsStore.brandsArray?.filter(brand => brand.id === brandId)[0] ?? null
    }

    @action setCreationType = (creationType: BillCreationType.Cart | BillCreationType.Manual) => this.creationType = creationType

    @action toggleCreationType = () => {
        this.creationType = this.creationType === BillCreationType.Cart ? BillCreationType.Manual : BillCreationType.Cart
    }

    @action setIsManualCreationType = (isManualCreationType: boolean) => {
        this.creationType = isManualCreationType ? BillCreationType.Manual : BillCreationType.Cart
    }

    @action setEditMode = (editMode: boolean) => this.editMode = editMode

    @action setActualIdStringSuggestion = () => {
        this.idString = this.rootStore.billsStore.getSuggestion(this.traffic!.id, this.type, this.isGratis)!
    }

    @action setManualBillValue = (value: number) => {
        this.manualBillValue = value
    }

    @action setManualBillBasePrice = (value: number) => {
        this.manualBillBasePrice = value
    }
}