import {FilterProps} from "react-table";
import React from "react";
import {Grid, Input} from "semantic-ui-react";

export interface INumberFilter {
    min?: number,
    max?: number
}

export const NumberFilter = ({
                                           column: {
                                               filterValue,
                                               preFilteredRows,
                                               filteredRows,
                                               setFilter,
                                               filter
                                           }
                                       }: FilterProps<INumberFilter>,
) => {

    return (
            <Grid>
                <Grid.Column width={16}>
                    <Input
                        type='number'
                        fluid
                        value={filterValue?.max}
                        onChange={(event, data) => setFilter({...filterValue, max: data.value})}
                        placeholder='Max'
                    />
                    <Input
                        type='number'
                        fluid
                        value={filterValue?.min}
                        onChange={(event, data) => setFilter({...filterValue, min: data.value})}
                        placeholder='Min'
                    />
                </Grid.Column>
            </Grid>
    );
}