import moment from "moment";
import {ICloudFile} from "./cloudFiles";
import {IUser} from "./user";

export interface IDocumentationDocument {
    id: string
    documentationId: string
    name: string
    type: string
    note: string
    file?: ICloudFile
    createdAt: Date
    updatedAt: Date
}

export class DocumentationDocument implements IDocumentationDocument {
    documentationId: string;
    id: string;
    name: string;
    note: string;
    type: string;
    file?: ICloudFile
    createdAt: Date
    updatedAt: Date
    
    constructor(documentFormValues: IDocumentationDocumentFormValues) {
        this.documentationId = documentFormValues.documentationId
        this.id = documentFormValues.id
        this.name = documentFormValues.name
        this.note = documentFormValues.note
        this.type = documentFormValues.type
        this.createdAt = new Date()
        this.updatedAt = new Date()
    }
    
}

export interface IDocumentationDocumentFormValues {
    id: string
    documentationId: string
    name: string
    type: string
    note: string
    file: Blob | null
}

export class DocumentationDocumentFormValues implements IDocumentationDocumentFormValues {
    documentationId: string = ''
    file: Blob | null = null
    id: string = ''
    name: string = ''
    note: string = ''
    type: string = ''
}

export interface IDocumentation {
    id: string
    folder: IDocumentationFolder
    category: string
    name: string
    description: string
    note: string
    documents: IDocumentationDocument[]
    date: Date
}

export class Documentation implements IDocumentation {
    id: string
    folder: IDocumentationFolder
    category: string
    name: string
    description: string
    note: string
    documents: IDocumentationDocument[]
    date: Date
    
    constructor(documentationFormValues: IDocumentationFormValues, folder: IDocumentationFolder) {
        this.id = documentationFormValues.id
        this.category = documentationFormValues.category
        this.date = new Date(documentationFormValues.date)
        this.description = documentationFormValues.description
        this.name = documentationFormValues.name
        this.note = documentationFormValues.note
        this.documents = []
        
        this.folder = folder
    }
}

export interface IDocumentationFormValues {
    id: string
    folderId: string
    private: boolean
    category: string
    name: string
    description: string
    note: string
    date: string
}

export class DocumentationFormValues implements IDocumentationFormValues{
    category: string = ''
    folderId: string = ''
    private = false
    date: string = moment().format('YYYY-MM-DD')
    description: string =''
    id: string = ''
    name: string = ''
    note: string = ''
    
    constructor(documentation?: IDocumentation) {
        if (documentation) {
            this.folderId = documentation.folder.id
            this.private = documentation.folder.private
            this.category = documentation.category
            this.date = moment(documentation.date).format('YYYY-MM-DD')
            this.description = documentation.description
            this.id = documentation.id
            this.name = documentation.name
            this.note = documentation.note
        }
    }
}

export interface IDocumentationFolder {
    id: string
    name: string
    private: boolean
    order: string
    user: IUser
}