import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IClientActivity, IClientActivityFormValues} from "../models/clientActivities";
import agent from "../api/agent";
import {history} from "../../index";
import {stringNumberSort} from "../common/util/string";
import { v4 as uuid } from 'uuid'


export default class ClientActivitiesStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
        
    }
    
    @observable clientActivitiesRegistry = new Map<string, IClientActivity>()
    @observable clientActivity: IClientActivity | null = null
    @observable loading = false
    @observable submitting = false
    @observable deleting = false
    @observable deletingId: string | null = null
    @observable loadingId: string | null = null
    @observable predicate = new Map()
    @observable yearsWithAny: string[] | null = null
    @observable loadingYearsWithAny = false
    @observable year = ''
    @observable lastRequestId = ''
    
    @action setYear = (year: string) => this.year = year

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @computed get axiosParams () {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }
    
    @computed get clientActivitiesArray() {
        return Array.from(this.clientActivitiesRegistry.values())
    }

    @action loadYearsWithAny = async () => {
        this.loadingYearsWithAny = true
        try {
            const yearsWithAny = await agent.ClientActivities.yearsWithAny()
            runInAction(() => {
                this.yearsWithAny = stringNumberSort(yearsWithAny)
            })
            return yearsWithAny
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingYearsWithAny = false
            })
        }
    }
    
    @action loadClientActivity = async (id: string) => { 
        this.loading = true
        try {
            const clientActivity = await agent.ClientActivities.details(id)
            runInAction(() => {
                this.clientActivity = clientActivity            
            })
            return clientActivity
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action loadClientActivities = async () => {
        this.loading = true
        const requestId = uuid()
        this.lastRequestId = requestId
        try {
            const clientActivities = await agent.ClientActivities.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.clientActivitiesRegistry.clear()
                    clientActivities.forEach(clientActivity => {
                        this.clientActivitiesRegistry.set(clientActivity.id, clientActivity)
                    })   
                }
            })
        } catch (error) {
            console.log(error)
            this.clientActivitiesRegistry.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loading = false                    
                }
            })
        }
    }
    
    @action createClientActivity = async (clientActivitiesFormValues: IClientActivityFormValues, file?: Blob | null) => {
        this.submitting = true
        try {   
            await agent.ClientActivities.create(clientActivitiesFormValues)
            if (file) {
                await agent.ClientActivities.postDoc(clientActivitiesFormValues.id, file)
            }
            // const newClientActivity: IClientActivity = {
            //     id: clientActivitiesFormValues.id,
            //     type: clientActivitiesFormValues.type,
            //     observation: clientActivitiesFormValues.observation,
            //     hasDocument: (file !== null),
            //     date: new Date(clientActivitiesFormValues.date),
            //     content: clientActivitiesFormValues.content,
            //     cost: Number(clientActivitiesFormValues.cost),
            //     client: this.rootStore.clientsStore.getClient(clientActivitiesFormValues.clientId)!,
            //     participants: cl
            // }
            // runInAction(() => {
            //     this.clientActivitiesRegistry.set(newClientActivity.id, newClientActivity)
            // })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientActivities`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editClientActivity = async (clientActivitiesFormValues: IClientActivityFormValues, file?: Blob | null) => {
        this.submitting = true
        // const updatedClientActivity: IClientActivity = {
        //     id: clientActivitiesFormValues.id,
        //     type: clientActivitiesFormValues.type,
        //     observation: clientActivitiesFormValues.observation,
        //     hasDocument: (file !== null),
        //     date: new Date(clientActivitiesFormValues.date),
        //     content: clientActivitiesFormValues.content,
        //     cost: Number(clientActivitiesFormValues.cost),
        //     client: this.rootStore.clientsStore.getClient(clientActivitiesFormValues.clientId)!
        // }
        try {
            await agent.ClientActivities.edit(clientActivitiesFormValues)
            if (file) {
                await agent.ClientActivities.postDoc(clientActivitiesFormValues.id, file)
            }
            const clientActivity = await agent.ClientActivities.details(clientActivitiesFormValues.id)
            runInAction(() => {
                this.clientActivitiesRegistry.set(clientActivitiesFormValues.id, clientActivity)
                this.clientActivity = clientActivity
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action loadClientActivityDoc = async (id: string) => {
        this.loadingId = id
        try {
            const file = await agent.ClientActivities.getDoc(id)
            window.open(URL.createObjectURL(file))
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingId = null
            })
        }
    }
    
    @action deleteClientActivity = async (id: string) => {
        this.deleting = true
        this.deletingId = id
        try {
            await agent.ClientActivities.delete(id)
            runInAction(() => {
                this.clientActivitiesRegistry.delete(id)
                if (this.clientActivity?.id === id) {
                    this.clientActivity = null
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingId = null
            })
        }
    }

    @action loadClientActivitiesToRegistry = (activities: IClientActivity[]) => {
        this.clientActivitiesRegistry.clear()
        activities.forEach(activity => {
            this.clientActivitiesRegistry.set(activity.id, activity)
        })
    }
}