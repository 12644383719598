import {IClientSchedule} from "../../models/clientSchedules";

export const getClientScheduleColorNameByRealization = (clientSchedule: IClientSchedule) => {
    const realizationPercentage = clientSchedule.realizationValue / clientSchedule.value * 100
    
    if (realizationPercentage >= 100) {
        return 'blue'
    } else if (realizationPercentage >= 80) {
        return 'teal'
    } else if (realizationPercentage >= 60) {
        return 'green'
    } else if (realizationPercentage >= 40) {
        return 'yellow'
    } else if (realizationPercentage >= 20) {
        return 'orange'
    } else if (realizationPercentage >= 1) {
        return 'red'
    } else {
        return 'grey'
    }
}