import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {RootStore} from "./rootStore";
import {
    ClientTour,
    IClientTour, IClientTourFormValues,
} from "../models/clientTours";
import agent from "../api/agent";
import {v4 as uuid} from 'uuid'

export default class ClientToursStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }

    @observable clientTour: IClientTour | null = null
    @observable clientToursRegistry = new Map<string, IClientTour>()
    @observable lastRequestId = ''
    @observable loading = false
    @observable submitting = false
    @observable predicate = new Map()
    
    @computed get axiosParams() {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get clientToursArray() {
        return Array.from(this.clientToursRegistry.values())
    }
    
    @action loadClientTours = async () => {
        this.loading = true
        const requestId = uuid()
        this.lastRequestId = requestId
        try {
            const clientTours = await agent.ClientTours.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.clientToursRegistry.clear()
                    clientTours.forEach(tour => {
                        this.clientToursRegistry.set(tour.id, new ClientTour(tour))
                    })                    
                }
            })
        } catch (error) {
            console.log(error)
            this.clientToursRegistry.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loading = false   
                }
            })
        }
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @action setPredicate = (predicate: string, value: string) => {
        this.predicate.set(predicate, value)
    }

    @action removePredicate = (predicate: string) => {
        this.predicate.delete(predicate)
    }

    @action setDateTimePredicate = (value: string | null, type: 'start' | 'end', update = false) => {
        if (type === 'start') {
            if (value) {
                this.setPredicate('startDateTime', value)
            } else {
                this.removePredicate('startDateTime')
            }
        } else {
            if (value) {
                this.setPredicate('endDateTime', value)
            } else {
                this.removePredicate('endDateTime')
            }
        }
        
        if (update) {
            this.loadClientTours()
        }
    }


    @action loadClientTour = async (id: string) => {
        this.loading = true
        try {
            const clientTour = await agent.ClientTours.details(id)
            runInAction(() => {
                this.clientTour = new ClientTour(clientTour)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action createClientTour = async (clientTourFormValues: IClientTourFormValues) => {
        this.submitting = true
        try {
            await agent.ClientTours.create(clientTourFormValues)
            const createdClientTour = await agent.ClientTours.details(clientTourFormValues.id)
            runInAction(() => {
                this.clientToursRegistry.set(createdClientTour.id, new ClientTour(createdClientTour))
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editClientTour = async (clientTourFormValues: IClientTourFormValues) => {
        this.submitting = true
        this.setClientTourFlag(clientTourFormValues.id, 'updating', true)
        try {
            await agent.ClientTours.edit(clientTourFormValues)
            const updatedClientTour = await agent.ClientTours.details(clientTourFormValues.id)
            runInAction(() => {
                this.clientToursRegistry.set(clientTourFormValues.id, new ClientTour(updatedClientTour))
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
                this.setClientTourFlag(clientTourFormValues.id, 'updating', false)
            })
        }
    }

    @action setClientTourFlag = (id: string, flagType: 'updating' | 'deleting', flagValue: boolean) => {
        if (this.clientTour?.id === id) {
            if (flagType === 'updating') {
                this.clientTour.flags.updating = flagValue
            } else if (flagType === 'deleting') {
                this.clientTour.flags.deleting = flagValue
            }
        }
        
        const tour = this.clientToursRegistry.get(id) ?? null
        if (!tour) return -1
        
        if (flagType === 'updating') {
            this.clientToursRegistry.set(id, {
                ...tour,
                flags: {
                    ...tour.flags,
                    updating: flagValue
                }
            })
        } else if (flagType === 'deleting') {
            this.clientToursRegistry.set(id, {
                ...tour,
                flags: {
                    ...tour.flags,
                    deleting: flagValue
                }
            })
        }
    }
    
    @action updateClientTour = async (clientTourFormValues: any) => {
        this.setClientTourFlag(clientTourFormValues.id, 'updating', true)
        try {
            await agent.ClientTours.update(clientTourFormValues)
            const tour = await agent.ClientTours.details(clientTourFormValues.id)
            runInAction(() => {
                this.clientToursRegistry.set(tour.id, new ClientTour(tour))
            })
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.setClientTourFlag(clientTourFormValues.id, 'updating', false)

            })
        }
    }

    @action deleteClientTour = async (id: string) => {
        this.setClientTourFlag(id, 'deleting', true)
        try {
            await agent.ClientTours.delete(id)
            runInAction(() => {
                this.clientToursRegistry.delete(id)
            })
        } catch (error) {
            console.log(error)

            throw error
        }
    }

}