import React, { FunctionComponent } from 'react';
import {Header} from "semantic-ui-react";
import {formatRsd} from "../../util/format";
import _ from 'lodash'

interface IProps {
    row: any,
    type: 'money' | 'count'
    name: string
    accessor: string
}

const SumTableHeader: FunctionComponent<IProps> = ({
    row,
    name,
    type,
    accessor
                                                   }) => {

    const {
        sum
    } = row.filteredRows.reduce((total: { sum: number }, current: any) => {
        return {
            sum: total.sum + _.get(current.original, accessor) ?? NaN
                // current.original[accessor as keyof typeof current.original],
        }
    }, {
        sum: 0
    })
    
  return (<>
      <Header sub>{name}</Header>
      {type === 'money' ?
          <strong>Suma: {formatRsd(sum)}</strong> :
          <strong>Ukupno: {sum % 1 !== 0 ? sum.toFixed(2) : sum}</strong>}
  </>)
}

export default SumTableHeader;
