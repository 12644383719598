import React, {FunctionComponent, useContext} from 'react';
import Table from "../../../../core/common/tables/Table";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import {IBillOrder} from "../../../../core/models/billOrders";
import {Cell} from "react-table";
import BillCartOrdersListProductInfo from "./BillCartOrdersListProductInfo";
import {formatRsd} from "../../../../core/common/util/format";
import {Button, Label, List, Message} from "semantic-ui-react";

interface IProps {
}

const BillCartOrdersList: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        ordersArray: orders,
        removeOrder,
        isCartEmpty
    } = rootStore.billsCartStore

    const columns = React.useMemo(
        () => [
            {
                Header: 'Artikal',
                accessor: 'productName',
                Cell: ({row: {original: order}}: Cell<IBillOrder>) =>
                    <BillCartOrdersListProductInfo
                        brandName={order.productBrandName}
                        brandColor={order.productBrandColor}
                        productName={order.productName}
                        productSku={order.productSku}/>
            },
            {
                Header: 'Količina',
                accessor: 'count',
                Cell: ({row: {original: order}}: Cell<IBillOrder>) =>
                    <span>
                        {order.count} kom.
                    </span>
            },
            {
                Header: 'Rabat',
                accessor: 'discount',
                Cell: ({row: {original: order}}: Cell<IBillOrder>) =>
                    <span>
                        {order.discount}%
                    </span>
            },
            {
                Header: 'Cena',
                accessor: 'singlePriceWithoutDiscount',
                Cell: ({row: {original: order}}: Cell<IBillOrder>) =>
                    <List divided>
                        <List.Item>
                            Cena po komadu:
                            <Label basic horizontal>
                                {formatRsd(order.singlePriceWithoutDiscount)}
                            </Label>
                        </List.Item>
                        <List.Item>
                            Cena po komadu sa rabatom:
                            <Label basic horizontal>
                                {formatRsd(order.singlePriceWithDiscount)}
                            </Label>
                        </List.Item>
                        <List.Item>
                            Ukupno sa rabatom:
                            <Label basic horizontal>
                                {formatRsd(order.summaryPriceWithDiscount)}
                            </Label>
                        </List.Item>
                    </List>
            },
            {
                Header: 'Kontrole',
                Cell: ({row: {original: order}}: Cell<IBillOrder>) => (
                    <Button.Group>
                        <Button type='button' onClick={() => removeOrder(order.id)} icon='trash alternate' color='red'/>
                    </Button.Group>)
            },

        ],
        [removeOrder]
    )

    if (isCartEmpty) return (
        <Message
            color='orange'
            icon='shopping cart'
            header='Korpa je prazna'
            content='Dodaj proizvode u korpu.'
        />
    )

    return (
        <Table
            columns={columns}
            data={orders}
        />
    );
};

export default observer(BillCartOrdersList)
