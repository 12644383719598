import React, {FunctionComponent, useContext, useEffect} from 'react';

import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../core/stores/rootStore";
import ProductsList from "./list/ProductsList";
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import autoTable from "jspdf-autotable";
import {IPrice, IProduct} from "../../core/models/products";
import {formatEur, formatRsd} from "../../core/common/util/format";
import {PdfService} from "../../core/common/pdf/PdfService";
import {useNavbarSelectedItem} from "../nav/utils/index.js";
import BreadcrumbNavigation from "../nav/BreadcrumbNavigation";

const TRACKING_ID = 'productsList'

interface IProps {
}

const ProductsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {clearPredicate, setPredicate, loading} = rootStore.productsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    useNavbarSelectedItem('products')

    useEffect(() => {
        setPredicate('bzv', 'all')

        return () => {
            removeFromRegistry(TRACKING_ID)
        }
    }, [clearPredicate, setPredicate, removeFromRegistry])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        const priceFormatter = (price: IPrice) => {
            if (price.isBase) {
                return formatEur(price.value!)
            } else {
                return formatRsd(price.value!)
            }
        }

        doc.text(`Tabela proizvoda`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                ['#', 'Proizvod', 'Opis', 'Cene']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((product: IProduct, index) => ([
                index + 1,
                `${product.brandName}\n${product.sku}\n${product.name}`,
                product.description,
                product.prices.reduce((total, current) => `${total}\n\n${current.pricelistName}: ${priceFormatter(current)}`, '')
            ])) ?? []
        })

        doc.save("Proizvodi.pdf")
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <BreadcrumbNavigation
                        items={[
                            {
                                text: 'Proizvodi',
                                active: true
                            }
                        ]}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/products/create`}
                        floated='right'
                        color='blue'
                        icon
                        labelPosition='left'>
                        <Icon name='add'/>
                        Dodaj proizvod
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment.Group>
                        <Segment color='blue' attached='top' clearing>
                            <Button
                                disabled={loading}
                                icon
                                labelPosition='left'
                                floated='right'
                                color='teal'
                                onClick={() => exportPdf()}>
                                <Icon name='file pdf'/>
                                PDF
                            </Button>
                        </Segment>
                        <Segment attached='bottom' loading={loading}>
                            <ProductsList trackingId={TRACKING_ID}/>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>

    );
};

export default observer(ProductsDashboard)
