import React, {FunctionComponent, useContext} from 'react';
import UserPostsDashboard from "../../userPosts/dashboard/UserPostsDashboard";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";

interface IProps {}

const HomeAuthorizedDashboard: FunctionComponent<IProps> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const { isAnyNonClientRole } = rootStore.userStore
  
  return  isAnyNonClientRole ? 
      <UserPostsDashboard /> :
      <div>
        TODO Ceka se
      </div>
}

export default observer(HomeAuthorizedDashboard)
