import { FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive'


interface IProps {
    children: any
}

const NotMobile: FunctionComponent<IProps> = ({children}) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
};

export default NotMobile
