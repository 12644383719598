import {useContext, useEffect} from "react";
import {RootStoreContext} from "./stores/rootStore";
import {useLocation} from "react-router-dom";

const currentMonthTrafficInterval = 1000 * 60 * 15

export const useInitApp = () => {
    const rootStore = useContext(RootStoreContext);
    const {setAppLoaded, token, initApp} = rootStore.commonStore;
    const {getUser} = rootStore.userStore;
    const location = useLocation()

    const {loadDocumentationFolders} = rootStore.documentationsStore
    const {
        createHubConnection: createClientOrdersHubConnection,
        stopHubConnection: stopClientOrdersHubConnection
    } = rootStore.clientOrdersStore
    const {
        createHubConnection: createUserPostsHubConnection,
        stopHubConnection: stopUserPostsHubConnection
    } = rootStore.userPostsStore
    const {
        createHubConnection: createClientGoalsHubConnection,
        stopHubConnection: stopClientGoalsHubConnection
    } = rootStore.clientGoalsStore
    const {
        createHubConnection: createEmployeeGoalsHubConnection,
        stopHubConnection: stopEmployeeGoalsHubConnection
    } = rootStore.employeeGoalsStore
    const {
        createHubConnection: createClientSchedulesHubConnection,
        stopHubConnection: stopClientSchedulesHubConnection
    } = rootStore.clientScheduleStore
    const {getUnreadByMeCount} = rootStore.userPostsStore
    const { getCurrentMonthTraffic } = rootStore.globalSettingsStore

    const initialTrafficIdFromPath = Number(location.pathname.split('/')[1])

    const createAllSignalrConnections = () => {
        createClientOrdersHubConnection()
        createUserPostsHubConnection()
        createClientGoalsHubConnection()
        createEmployeeGoalsHubConnection()
        createClientSchedulesHubConnection()
    }

    const stopAllSignalrConnections = () => {
        stopClientOrdersHubConnection()
        stopUserPostsHubConnection()
        stopClientGoalsHubConnection()
        stopEmployeeGoalsHubConnection()
        stopClientSchedulesHubConnection()
    }

    // Sync current month traffic
    useEffect(() => {
        const interval = setInterval(() => {
            getCurrentMonthTraffic()
        }, currentMonthTrafficInterval);

        return () => clearInterval(interval);
    }, [getCurrentMonthTraffic]);

    useEffect(() => {
        initApp(initialTrafficIdFromPath)

        if (token) {
            getUser()
                .then(() => {
                    createAllSignalrConnections()

                    return Promise.all([
                        loadDocumentationFolders(),
                        getUnreadByMeCount()
                    ])
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setAppLoaded()
                })
        } else {
            setAppLoaded();
        }

        return () => {
            stopAllSignalrConnections()
        }
        // eslint-disable-next-line
    }, [
        getUser,
        setAppLoaded,
        token,
        initApp,
        initialTrafficIdFromPath,
        getUnreadByMeCount,
        loadDocumentationFolders,
    ])
}