import React, {FunctionComponent, useContext} from 'react'
import {Button, Comment, Form, Header, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import moment from "moment";
import {Field, Form as FinalForm} from "react-final-form";
import TextAreaInput from "../../../core/common/form/TextAreaInput";
import {stringPreview} from "../../../core/common/util/string";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {IEmployeeGoalComment} from "../../../core/models/employeeGoals";
import {observer} from "mobx-react-lite";

interface OwnProps {
    comment: IEmployeeGoalComment,
    compact?: boolean
}

type Props = OwnProps

const EmployeeGoalSingleComment: FunctionComponent<Props> = ({comment, compact}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        hubConnectionStatusColor,
        editingCommentId,
        editComment,
        setEditingCommentId,
        deleteComment,
    } = rootStore.employeeGoalsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {user} = rootStore.userStore
    const {openModal, closeModal} = rootStore.modalStore


    return <Comment
        key={comment.id}>
        <Comment.Avatar src={comment.user.image || '/assets/user.png'}/>
        <Comment.Content>
            <Comment.Author as={Link}
                            to={`/${currentTraffic?.id}/profile/${comment.user.username}`}>{comment.user.displayName}</Comment.Author>
            <Comment.Metadata>
                <div>{moment(comment.createdAt).calendar()} {comment.createdAt !== comment.updatedAt ? `izmenjen ${moment(comment.updatedAt).calendar()}` : ''} </div>
            </Comment.Metadata>
            {editingCommentId === comment.id ?
                <Segment clearing>
                    <FinalForm
                        onSubmit={(values) => editComment(comment.employeeGoalId, comment.id, values)}
                        render={({handleSubmit, submitting, form}) => (
                            <Form onSubmit={() => handleSubmit()!.then(() => form.reset())}>
                                <Field
                                    name='content'
                                    defaultValue={comment.content}
                                    component={TextAreaInput}
                                    rows={2}
                                    placeholder='Izmeni komentar'
                                />
                                <Button
                                    size={compact ? 'mini' : undefined}
                                    floated='right'
                                    disabled={hubConnectionStatusColor !== 'green'}
                                    loading={submitting}
                                    content='Izmeni'
                                    labelPosition='left'
                                    icon='edit'
                                    color='orange'
                                />
                                <Button
                                    size={compact ? 'mini' : undefined}
                                    floated='right'
                                    content='Odustani'
                                    onClick={() => setEditingCommentId(null)}
                                />
                            </Form>
                        )}
                    />
                </Segment> :
                <Comment.Text>{comment.content}</Comment.Text>
            }

            {hubConnectionStatusColor !== 'red' &&
            <Comment.Actions>
                {comment.user.id === user?.id && editingCommentId !== comment.id &&
                <Comment.Action
                    onClick={() => setEditingCommentId(comment.id)}
                >Izmeni</Comment.Action>}
                {comment.user.id === user?.id &&
                <Comment.Action onClick={() => openModal(
                    <Segment basic clearing>
                        <Header textAlign='center' as='h2'>
                            Brisanje komentara
                            <Icon color='red' name='warning circle'/>
                        </Header>
                        <p style={{textAlign: 'center'}}>
                            Da li zaista želiš da obrišeš
                            komentar <strong>{stringPreview(comment.content, 200)} ?</strong>
                        </p>
                        <Button
                            color='red'
                            floated='right'
                            onClick={(event: any) => {
                                event.target.className += ' loading'
                                deleteComment(comment.id)
                                    .then(() => closeModal())
                            }}>
                            <Icon name='checkmark'/> Da
                        </Button>
                        <Button onClick={() => closeModal()} floated='right'>
                            <Icon name='remove'/> Ne
                        </Button>
                    </Segment>, 'small'
                )}>
                    Obriši
                </Comment.Action>}
            </Comment.Actions>}

        </Comment.Content>
    </Comment>;
}

export default observer(EmployeeGoalSingleComment)
