import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import Table from "../../../core/common/tables/Table";
import {Cell, FilterProps} from "react-table";
import {IBillOrderAnalyzeEntity} from "../../../core/models/billOrders";
import {formatRsd} from "../../../core/common/util/format";
import ClientDetailedCell from "../../clients/list/components/ClientDetailedCell";
import BrandBasicCell from "../../brands/BrandBasicCell";
import ClientDetailedFilter from "../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import {Header} from "semantic-ui-react";
import SumTableHeader from "../../../core/common/tables/headers/SumTableHeader";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";
import MultiselectFilter from "../../../core/common/tables/filters/multiselect/MultiselectFilter";

interface IProps {
    trackingId?: string
}

const TrafficAnalysisByClientAndBrandList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {groupByClientAndBrand, mode} = rootStore.trafficAnalyseStore
    const { loadBrandsIfNull, brandsAsOptions } = rootStore.brandsStore
    
    useEffect(() => {
        loadBrandsIfNull()
    }, [
        loadBrandsIfNull
    ])

    const columns = useMemo(() => [
        {
            Header: 'Klijent',
            accessor: 'client.name',
            id: 'client',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) => <ClientDetailedCell
                client={analyseEntity.client!}/>,
            Filter: ClientDetailedFilter,
            filter: 'clientDetailedFilter'
        },
        {
            Header: 'Brend',
            accessor: 'brand.name',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) => <BrandBasicCell
                brand={analyseEntity.brand!}/>,
            Filter: (d: FilterProps<any>) => MultiselectFilter(d, [...brandsAsOptions, {
                key: 'N/A - Ručni račun',
                value: 'N/A - Ručni račun',
                text: 'N/A - Ručni račun'
            }], 'Brend'),
            filter: 'multiselectFilter'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'count'} name={'Količina'} accessor={'count'}/>,
            accessor: 'count',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) => <span>{analyseEntity.count}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Nabavna cena u RSD'}
                                                  accessor={'summaryBasePriceInRsd'}/>,
            accessor: 'summaryBasePriceInRsd',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.summaryBasePriceInRsd)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Bez popusta'}
                                                  accessor={'summaryPriceWithoutDiscount'}/>,
            accessor: 'summaryPriceWithoutDiscount',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.summaryPriceWithoutDiscount)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Sa popustom'}
                                                  accessor={'summaryPriceWithDiscountWithBillDiscount'}/>,
            accessor: 'summaryPriceWithDiscountWithBillDiscount',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.summaryPriceWithDiscountWithBillDiscount)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'RUC'}
                                                  accessor={'differenceInPrice'}/>,
            accessor: 'differenceInPrice',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.differenceInPrice)}</span>,
            id: 'differenceInPrice',
            hide: mode === 'gratis',
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            id: 'diffInPriceCoef',
            Header: (row: any) => {
                const {
                    differenceInPrice,
                    summaryPriceWithDiscountWithBillDiscount
                } = row.filteredRows.reduce((total: { differenceInPrice: number, summaryPriceWithDiscountWithBillDiscount: number }, current: any) => {
                    return {
                        differenceInPrice: total.differenceInPrice + current.original.differenceInPrice,
                        summaryPriceWithDiscountWithBillDiscount: total.summaryPriceWithDiscountWithBillDiscount + current.original.summaryPriceWithDiscountWithBillDiscount
                    }
                }, {
                    differenceInPrice: 0,
                    summaryPriceWithDiscountWithBillDiscount: 0
                })

                return (
                    <div>
                        <Header sub>Koeficijent RUC-a</Header>
                        <strong>Ukupno: {(differenceInPrice / summaryPriceWithDiscountWithBillDiscount).toFixed(2)}</strong>
                    </div>
                )
            }, accessor: (d: IBillOrderAnalyzeEntity) => d.differenceInPrice / d.summaryPriceWithDiscountWithBillDiscount,
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{(analyseEntity.differenceInPrice / analyseEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2)}</span>,
            hide: mode === 'gratis',
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            hide: mode === 'gratis',
            id: 'margin',
            Header: (row: any) => {
                const {
                    summaryPriceWithDiscountWithBillDiscount,
                    summaryBasePriceInRsd
                } = row.filteredRows.reduce((total: { summaryPriceWithDiscountWithBillDiscount: number, summaryBasePriceInRsd: number }, current: any) => {
                    return {
                        summaryPriceWithDiscountWithBillDiscount: total.summaryPriceWithDiscountWithBillDiscount + current.original.summaryPriceWithDiscountWithBillDiscount,
                        summaryBasePriceInRsd: total.summaryBasePriceInRsd + current.original.summaryBasePriceInRsd
                    }
                }, {
                    summaryPriceWithDiscountWithBillDiscount: 0,
                    summaryBasePriceInRsd: 0
                })

                return (
                    <div>
                        <Header sub>Marža</Header>
                        <strong>Prosek: {(summaryPriceWithDiscountWithBillDiscount / summaryBasePriceInRsd).toFixed(2)}</strong>
                    </div>
                )
            },
            accessor: (d: IBillOrderAnalyzeEntity) => d.summaryPriceWithDiscountWithBillDiscount / d.summaryBasePriceInRsd,
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{(analyseEntity.summaryPriceWithDiscountWithBillDiscount / analyseEntity.summaryBasePriceInRsd).toFixed(2)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        }
    ], [
        mode,
        brandsAsOptions
    ])

    return (<Table
        trackingId={trackingId}
        data={groupByClientAndBrand}
        columns={columns}
    />)
}

export default observer(TrafficAnalysisByClientAndBrandList)
