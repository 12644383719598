import React, { FunctionComponent } from 'react';
import {IClient} from "../../../../core/models/clients";

interface IProps {
    client: IClient
    hidePrivate?: boolean
    hidePublic?: boolean
}

const ClientTableNotesCell: FunctionComponent<IProps> = ({ client: { privateNote, publicNote}, hidePrivate, hidePublic }) => {
  
  return (
      <div>
          {!hidePrivate &&
          <div>{privateNote}</div>}
          {!hidePublic &&
          <div>{publicNote}</div>}
      </div>
  );
};

export default ClientTableNotesCell;
