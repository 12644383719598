import moment from "moment";

export const formatDateOrDefault = (date: Date | string | null, polarity: 'start' | 'end') => {
    if (!moment(date).isValid()) {
        if (polarity === 'start') {
            return 'oduvek'
        } else {
            return moment().format('l')
        }
    }
    
    return moment(date).format('l')
}

export const DATE_TIME_SERIALIZABLE_FORMAT_STRING = 'YYYY-MM-DD[T]HH:mm:ss'
export const DATE_SERIALIZABLE_FORMAT_STRING = 'YYYY-MM-DD'

export const DATE_TIME_HUMAN_FORMAT_STRING = 'DD.MM.YYYY. HH:mm:ss'
export const DATE_TIME_HUMAN_FORMAT_STRING_WO_SECONDS = 'DD.MM.YYYY. HH:mm'

export const momentCalendarWithoutTime = (date: Date | string | moment.Moment) => {
    return moment(date).calendar().split(' u ')[0].toUpperCase()
}

export const dateToLocaleStringOrAccentYesterdayTodayAndTomorrow = (date: Date | string | moment.Moment) => {
    const momentDate = moment(date)
    
    if (momentDate.isBetween(moment().subtract(2, 'day'), moment().add(2, 'day'), 'day')) {
        return momentCalendarWithoutTime(momentDate)
    } else {
        return momentDate.format('l')
    }
}