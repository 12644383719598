import {IClient} from "../../models/clients";

export const getClientRankByStatusAndType = (client: IClient) => {

    if (client.type === 'Aktivan') {
        switch (client.status) {
            case 'Prioritet 1':
                return 0
            case 'Prioritet 2':
                return 1
            case 'Prioritet 3':
                return 2
            case 'Prioritet 4':
                return 3
            case 'Prioritet 5':
                return 4
            case 'Zanemarljiv':
                return 5
        }
    } else {
        switch (client.type) {
            case 'Akvizicija':
                return 6
            case 'Potencijalni':
                return 7
            case 'Pasivan':
                return 8
        }
    }

    return 26

}