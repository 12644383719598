import {IUser} from "./user";
import moment from "moment"
import {ICloudFile} from "./cloudFiles";

export enum EmployeeGoalStatus {
    inProgress = 0,
    partlyMet = 1,
    met = 2,
    failed = 4
}

export enum EmployeeGoalType {
    classic = 0,
    problem = 1,
    private = 2
}

export interface IEmployeeGoalCategoryWithProperties {
    name: string
    type: EmployeeGoalType
    status: EmployeeGoalStatus
}

export const employeeGoalStatusToString = (employeeGoal: IEmployeeGoal) => {
    switch (employeeGoal.status) {
        case EmployeeGoalStatus.inProgress:
            return 'Aktivan'
        case EmployeeGoalStatus.partlyMet:
            return 'Delimično ispunjen'
        case EmployeeGoalStatus.met:
            return 'Ispunjen'
        case EmployeeGoalStatus.failed:
            return 'Neispunjen'
    }
}

export interface IEmployeeGoalParticipant {
    id: string
    user: IUser
    isMain: boolean
}

export interface IEmployeeGoalComment {
    id: string
    employeeGoalId: string
    employeeGoalType: EmployeeGoalType
    employeeGoalName: string
    employeeGoalColor: 'red' | 'blue' | 'orange'
    content: string
    user: IUser
    createdAt: Date
    updatedAt: Date
}

export interface IEmployeeGoalDocument {
    id: string
    employeeGoalId: string
    name: string
    file?: ICloudFile
}

export interface IEmployeeGoalDocumentFormValues {
    id: string
    employeeGoalId: string
    name: string
    file: Blob | null
}

export interface IEmployeeGoal {
    id: string
    type: EmployeeGoalType
    category: string
    name: string
    status: EmployeeGoalStatus
    color: 'red' | 'blue' | 'orange'
    statusNote: string
    date: Date | null
    completedDate: Date | null
    comments: IEmployeeGoalComment[]
    participants: IEmployeeGoalParticipant[]
    documents: IEmployeeGoalDocument[]
    createdAt: Date
    updatedAt: Date

    flags?: {
        updating: boolean,
        uploading: boolean,
        deleting: boolean
    }
}

export class EmployeeGoal implements IEmployeeGoal {
    category: string;
    type: EmployeeGoalType
    color: "red" | "blue" | "orange"
    status: EmployeeGoalStatus
    statusNote: string
    comments: IEmployeeGoalComment[];
    completedDate: Date | null;
    createdAt: Date;
    date: Date | null;
    documents: IEmployeeGoalDocument[];
    flags?: { updating: boolean; uploading: boolean; deleting: boolean };
    id: string;
    name: string;
    participants: IEmployeeGoalParticipant[];
    updatedAt: Date;
    
    constructor(employeeGoal: IEmployeeGoal) {
        this.id = employeeGoal.id
        this.type = employeeGoal.type
        this.name = employeeGoal.name
        this.category = employeeGoal.category
        this.color = employeeGoal.color
        this.statusNote = employeeGoal.statusNote ?? ''
        this.date = employeeGoal.date
        this.completedDate = employeeGoal.completedDate
        this.createdAt = employeeGoal.createdAt
        this.updatedAt = employeeGoal.updatedAt
        this.status =  employeeGoal.status
        if (employeeGoal.flags) {
            this.flags = {
                deleting: employeeGoal.flags.deleting,
                updating: employeeGoal.flags.updating,
                uploading: employeeGoal.flags.uploading
            }            
        } else {
            this.flags = {
                deleting: false,
                updating: false,
                uploading: false
            }
        }

        
        this.comments = employeeGoal.comments
        this.documents = employeeGoal.documents
        this.participants = employeeGoal.participants
    }
}

export interface IEmployeeGoalFormValues {
    id: string
    type: EmployeeGoalType
    category: string
    name: string
    color: 'red' | 'blue' | 'orange'
    statusNote: string
    date: string | null
    completedDate: string | null

    participants: string[]
    mainParticipant: string | null

    initialCommentContent: string
}

export class EmployeeGoalFormValues implements IEmployeeGoalFormValues {
    type: EmployeeGoalType = EmployeeGoalType.classic
    category = ''
    color: 'red' | 'blue' | 'orange' = 'blue'
    statusNote: string = ''
    date = moment().format('YYYY-MM-DD')
    id = ''
    name = ''
    completedDate = ''

    participants: string[] = []
    mainParticipant: string | null = null

    initialCommentContent = ''

    constructor(employeeGoal?: IEmployeeGoal) {
        if (employeeGoal) {
            this.type = employeeGoal.type
            this.category = employeeGoal.category
            this.color = employeeGoal.color
            this.statusNote = employeeGoal.statusNote ?? ''
            this.date = employeeGoal.date ? moment(employeeGoal.date).format('YYYY-MM-DD') : ''
            this.id = employeeGoal.id
            this.name = employeeGoal.name
            this.completedDate = employeeGoal.completedDate ? moment(employeeGoal.completedDate).format('YYYY-MM-DD') : ''

            this.participants = employeeGoal.participants.map(p => p.user.id)
            this.mainParticipant = employeeGoal.participants.filter(p => p.isMain)[0]?.user.id ?? null
        }
    }

}

export interface IEmployeeGoalFilter {
    name: string,
    colors: string[],
    categories: string[],
    // met: 'all' | 'met' | 'notMetYet' | 'expired'
}

export class EmployeeGoalFilter implements IEmployeeGoalFilter {
    // met: "all" | "met" | "notMetYet" | "expired" = 'all'
    categories: string[] = []
    colors: string[] = []
    name: string = ''
    
}

export const getEmployeeGoalColorSerbianText = (employeeGoal: IEmployeeGoal) => {
    switch (employeeGoal.color) {
        case 'red':
            return 'Prioritetno'
        case 'orange':
            return 'U planu'
        case 'blue':
            return 'Redovna aktivnost'

    }
}