import React, {FunctionComponent, useContext} from 'react';
import {IUser} from "../../../core/models/user";
import {Item} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";

interface IProps {
    user: IUser
}

const UsersTableBasicCell: FunctionComponent<IProps> = ({user}) => {
    const rootStore = useContext(RootStoreContext)
    const { currentTraffic } = rootStore.trafficsStore

    return (
        <Item.Group >
            <Item >
                <Item.Image size='mini' avatar src={user.image || '/assets/user.png'}/>
                <Item.Content >
                    <Item.Header as={Link} to={`/${currentTraffic?.id}/profile/${user.username}`}>{user.displayName}</Item.Header>
                    <Item.Meta>{user.username}</Item.Meta>
                    <Item.Description>
                        <a href={`mailto:${user.email}`}>{user.email}</a>
                    </Item.Description>
                </Item.Content>
            </Item></Item.Group>);
};

export default observer(UsersTableBasicCell)
