import {IProduct} from "./products";
import moment from "moment";

export interface IRequiredProduct {
    id: string
    product?: IProduct
    count: number
    date: Date
    isOther: boolean
    otherContent: string
    note: string
}

export interface IRequiredProductFormValues {
    id: string
    productId?: string
    count: number
    date: string
    isOther: boolean
    otherContent: string
    note: string
}

export class RequiredProductFormValues implements IRequiredProductFormValues {
    count = 1
    id = ''
    productId = ''
    date = moment(new Date()).format('YYYY-MM-DD')
    isOther = false
    otherContent = ''
    note = ''

    constructor(requiredProduct?: IRequiredProduct) {
        if (requiredProduct) {
            this.id = requiredProduct.id
            this.count = requiredProduct.count
            this.date = moment(requiredProduct.date).format('YYYY-MM-DD')
            this.isOther = requiredProduct.isOther
            this.otherContent = requiredProduct.otherContent
            this.note = requiredProduct.note

            if (!requiredProduct.isOther) {
                this.productId = requiredProduct.product!.id
            }
        }
    }
}