import React, {FunctionComponent, useContext} from 'react';
import {Segment, Header, Comment, Divider} from 'semantic-ui-react';
import {RootStoreContext} from '../../../core/stores/rootStore';
import {observer} from 'mobx-react-lite';
import {IClientGoal} from "../../../core/models/clientGoals";
import ClientGoalCommentForm from "../form/ClientGoalCommentForm";
import ClientGoalSingleComment from "./ClientGoalSingleComment";

interface IProps {
    clientGoal: IClientGoal
}

const ClientGoalDetailedChat: FunctionComponent<IProps> = ({clientGoal}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        sortedComments,
    } = rootStore.clientGoalsStore
    

    return (
        <div>
            <Segment
                textAlign='center'
                attached='top'
                inverted
                color='teal'
                style={{border: 'none'}}
            >
                <Header>Komentari</Header>
            </Segment>
            <Segment>
                <Comment.Group>

                    <ClientGoalCommentForm clientGoalId={clientGoal.id}/>

                    <Divider hidden/>

                    {sortedComments && sortedComments.map((comment) => (
                        <ClientGoalSingleComment key={comment.id} comment={comment}/>
                    ))}
                </Comment.Group>
            </Segment>
        </div>
    );
};

export default observer(ClientGoalDetailedChat);