export interface IPricelist {
    id: number
    name: string
    isBase: boolean
    isDefault: boolean
    isSelling: boolean
}

export interface IPricelistFormValues  {
    id: number | null
    name: string | null
}

export class PricelistFormValues implements IPricelistFormValues {
    
    constructor(pricelist?: IPricelist) {
        if (pricelist) {
            this.id = pricelist.id
            this.name = pricelist.name
        }
    }
    
    id: number | null = null
    name: string | null = null
}
