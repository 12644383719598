export enum Types {
    'Specification',
    'Invoice'
}

export enum CreationTypes {
    'Cart',
    'Manual'
}

export const typesOptions = [
    { key: 0, text: 'Specifikacija', value: Types.Specification },
    { key: 1, text: 'Faktura', value: Types.Invoice }
];

export const creationTypes = [
    { key: 0, text: 'Korpa', value: CreationTypes.Cart },
    { key: 1, text: 'Manuelno', value: CreationTypes.Manual }
];