import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IUser} from "../models/user";
import agent from "../api/agent";

export default class UsersStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }
    
    @observable users = new Map<string, IUser>()
    @observable loadingUsers = false
    @observable loadingRoles = false
    @observable addingUserToRole: [string | null, string | null] = [null, null]
    @observable removingUserToRole: [string | null, string | null] = [null, null]
    @observable roles: string[] | null = null
    @observable deletingId: string | null = null

    @computed get usersArray() {
        return Array.from(this.users.values())
    }
    
    @computed get employeesArray() {
        return this.usersArray.filter(x => x.roles.includes('Employee'))
    }

    @computed get anyRolesUsersArray() {
        return this.usersArray.filter(x => x.roles.length > 0)
    }
    
    @action loadUsers = async (local = false) => {
        this.loadingUsers = true
        try {
            if (!local || this.users.size === 0)  {
                this.users.clear()
                const users = await agent.Users.list()
                runInAction(() => {
                    users.forEach(user => {
                        this.users.set(user.id, user)
                    })
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingUsers = false
            })
        }
    }
    
    @action addRole = async (userId: string, roleName: string) => {
        this.addingUserToRole = [userId, roleName]
        try {   
            await agent.Users.addRole(userId, roleName)
            runInAction(() => {
                const user = this.users.get(userId)!
                this.users.set(userId, { ...user, roles: [...user.roles, roleName] })
                if (userId === this.rootStore.userStore.user?.id) {
                    this.rootStore.userStore.addRoleToArray(roleName)
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.addingUserToRole = [null, null]
            })
        }
    }

    @action removeRole = async (userId: string, roleName: string) => {
        this.removingUserToRole = [userId, roleName]
        try {
            await agent.Users.removeRole(userId, roleName)
            runInAction(() => {
                const user = this.users.get(userId)!
                this.users.set(userId, { ...user, roles: user.roles.filter(r => r !== roleName) })
                if (userId === this.rootStore.userStore.user?.id) {
                    this.rootStore.userStore.removeRoleFromArray(roleName)
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.removingUserToRole = [null, null]
            })
        }
    }

    @action loadRoles = async () => {
        this.loadingRoles = true
        try {
            const roles = await agent.Users.rolesList()
            runInAction(() => {
                this.roles = roles
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingRoles = false
            })
        }
    }
    
    @action deleteUser = async (id: string) => {
        this.deletingId = id
        try {   
            await agent.Users.remove(id)
            runInAction(() => {
                this.users.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deletingId = null
            })
        }
    }

}