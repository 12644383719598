import React, {useContext, useState} from 'react';
import {Tab, Header, Card, Image, Button, Grid, SemanticWIDTHS} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import {RootStoreContext} from "../../../core/stores/rootStore";
import PhotoUploadWidget from "../../../core/common/photoUpload/PhotoUploadWidget";
import Responsive from "../../../core/common/responsive/Responsive";

const ProfilePhotos = () => {
    const rootStore = useContext(RootStoreContext);
    const {
        profile,
        isCurrentUser,
        uploadPhoto,
        uploadingPhoto,
        setMainPhoto,
        deletePhoto,
        loading
    } = rootStore.profileStore;
    const [addPhotoMode, setAddPhotoMode] = useState(false);
    const [target, setTarget] = useState<string | undefined>(undefined);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(
        undefined
    );

    const handleUploadImage = (photo: Blob) => {
        uploadPhoto(photo).then(() => setAddPhotoMode(false));
    };

    const photos = (photosInRow: SemanticWIDTHS) => {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={16} style={{paddingBottom: 0}}>
                        <Header floated='left' icon='image' content='Fotografije'/>
                        {isCurrentUser && (
                            <Button
                                onClick={() => setAddPhotoMode(!addPhotoMode)}
                                floated='right'
                                basic
                                content={addPhotoMode ? 'Odustani' : 'Dodaj Fotografiju'}
                            />
                        )}
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {addPhotoMode ? (
                            <PhotoUploadWidget
                                uploadPhoto={handleUploadImage}
                                loading={uploadingPhoto}
                            />
                        ) : (
                            <Card.Group itemsPerRow={photosInRow}>
                                {profile &&
                                profile.photos.map(photo => (
                                    <Card key={photo.id}>
                                        <Image src={photo.url}/>
                                        {isCurrentUser && (
                                            <Button.Group fluid widths={2}>
                                                <Button
                                                    onClick={e => {
                                                        setMainPhoto(photo);
                                                        setTarget(e.currentTarget.name);
                                                    }}
                                                    name={photo.id}
                                                    disabled={photo.isMain}
                                                    loading={loading && target === photo.id}
                                                    basic
                                                    positive
                                                    content='Profilna'
                                                />
                                                <Button
                                                    name={photo.id}
                                                    disabled={photo.isMain}
                                                    onClick={(e) => {
                                                        deletePhoto(photo);
                                                        setDeleteTarget(e.currentTarget.name)
                                                    }}
                                                    loading={loading && deleteTarget === photo.id}
                                                    basic
                                                    negative
                                                    icon='trash'
                                                />
                                            </Button.Group>
                                        )}
                                    </Card>
                                ))}
                            </Card.Group>
                        )}
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        )    
    }
    
    return <>
        <Responsive.Desktop>
            {photos(5)}
        </Responsive.Desktop>
        <Responsive.Tablet>
            {photos(3)}
        </Responsive.Tablet>
        <Responsive.Mobile>
            {photos(1)}
        </Responsive.Mobile>
    </>
    
};

export default observer(ProfilePhotos);