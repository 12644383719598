import React, {FunctionComponent} from 'react';
import {FieldRenderProps} from "react-final-form";
import {FormFieldProps, Form, Label, Input} from "semantic-ui-react";

interface IProps extends FieldRenderProps<number, HTMLElement>, FormFieldProps {
    suggestions?: string[],
    labelTop?: boolean,
    icon?: string
}

const NumberInput: FunctionComponent<IProps> = ({
                                                    input,
                                                    width,
                                                    placeholder,
                                                    label,
                                                    labelPosition,
                                                    meta: {error, touched},
                                                    suggestions,
                                                    labelTop,
                                                    icon,
                                                    min,
                                                    max,
                                                    ...rest
                                                }) => {
    return (
        <Form.Field error={touched && !!error} width={width}>
            {labelTop &&
            <Label content={label}/>}
            <Input
                type='number'
                icon={icon}
                min={min}
                max={max}
                label={labelTop ? null : label} labelPosition={labelPosition} autoComplete='off'
                list={`suggestions-${input.name}`} {...input}
                placeholder={placeholder}/>
            {suggestions &&
            <datalist id={`suggestions-${input.name}`}>
                {suggestions.map((suggestion, index) => (
                    <option key={index} value={suggestion}>{suggestion}</option>
                ))}
            </datalist>
            }
            {touched && error && (
                <Label basic color='red'>
                    {error}
                </Label>
            )}
        </Form.Field>
    );
};

export default NumberInput;
