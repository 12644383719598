import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";
import {ClientEmployee, IClientEmployee} from "../../../../core/models/clients";
import moment from "moment";
import {Button, Header, Icon, Input, Message, Segment, Table, TextArea} from "semantic-ui-react";

interface IProps {
    clientId: string
}

const ClientDetailsEmployees: FunctionComponent<IProps> = ({clientId}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        client,
        editingEmployeeId,
        editClientEmployee,
        addClientEmployee,
        submittingEmployee,
        removingEmployeeId,
        removeClientEmployee
    } = rootStore.clientsStore
    const { openModal, closeModal } = rootStore.modalStore
    const { isEmployee } = rootStore.userStore
    const { loading, loadClientEmployees, setPredicate, clearPredicates } = rootStore.clientEmployeesStore

    const [editingEmployee, setEditingEmployee] = useState<IClientEmployee | null>(null)

    useEffect(() => {
        clearPredicates()
        setPredicate('clientId', clientId)
        
        loadClientEmployees()
        
        return () => {
            clearPredicates()
        }
    }, [
        clearPredicates,
        setPredicate,
        clientId,
        loadClientEmployees
    ])
    
    if (client?.employees?.length === 0 && editingEmployee === null)
        return isEmployee ? (
            <Segment clearing textAlign='center'>
                <Header color='blue' as='h2' content='Lista zaposlenih je prazna'
                        subheader='Dodaj zaposlene klikom na dugme "Dodaj"'
                />
                <Button
                    size='big'
                    inverted
                    content='Dodaj'
                    onClick={() => setEditingEmployee(new ClientEmployee())}
                    color='blue'/>
            </Segment>
        ) : <Message
            icon='exclamation'
            color={"orange"}
            header='Lista zaposlenih je prazna'
            content='Pokušaj da osvežiš stranicu'/>

    return (
        <>
            {editingEmployee?.id !== '' && isEmployee &&
            <Segment clearing attached='top' loading={loading}>
                <Button
                    onClick={() => setEditingEmployee(new ClientEmployee())}
                    floated='right'
                    color='blue'
                    icon
                    labelPosition='left'>
                    <Icon name='add'/>
                    Dodaj zaposlenog
                </Button>
            </Segment>}
            <Segment attached>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={4}>Ime</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Pozicija</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Datum rodjenja</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Broj telefona</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Komentar</Table.HeaderCell>
                            {isEmployee &&
                            <Table.HeaderCell width={2}>Kontrole</Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {editingEmployee?.id === '' &&
                        <Table.Row>
                            <Table.Cell>
                                <Input
                                    placeholder='Ime'
                                    fluid
                                    disabled={submittingEmployee}
                                    value={editingEmployee.name}
                                    onChange={(event, data) => setEditingEmployee({
                                        ...editingEmployee,
                                        name: data.value
                                    })}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input
                                    fluid
                                    disabled={submittingEmployee}
                                    value={editingEmployee.job}
                                    onChange={(event, data) => setEditingEmployee({
                                        ...editingEmployee,
                                        job: data.value
                                    })}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input
                                    type='date'
                                    fluid
                                    disabled={submittingEmployee}
                                    value={moment(editingEmployee.birthday).format('YYYY-MM-DD')}
                                    onChange={(event, data) => setEditingEmployee({
                                        ...editingEmployee,
                                        birthday: data.value
                                    })}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input
                                    fluid
                                    disabled={submittingEmployee}
                                    value={editingEmployee.telNumber}
                                    onChange={(event, data) => setEditingEmployee({
                                        ...editingEmployee,
                                        telNumber: data.value
                                    })}/>
                            </Table.Cell>
                            <Table.Cell>
                                <TextArea
                                    disabled={submittingEmployee}
                                    value={editingEmployee.note}
                                    onChange={(event, data) => setEditingEmployee({
                                        ...editingEmployee,
                                        note: data.value as string
                                    })}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Button.Group>
                                    <Button
                                        loading={submittingEmployee}
                                        icon='add'
                                        color='blue'
                                        content='Dodaj'
                                        onClick={() => addClientEmployee(editingEmployee).then(() => setEditingEmployee(null))}
                                    />
                                    <Button
                                        disabled={submittingEmployee}
                                        icon='x'
                                        onClick={() => setEditingEmployee(null)}
                                    />
                                </Button.Group>
                            </Table.Cell>
                        </Table.Row>}

                        {client?.employees?.map(employee => employee.id === editingEmployee?.id ?
                            <Table.Row key={employee.id}>
                                <Table.Cell>
                                    <Input
                                        fluid
                                        disabled={editingEmployeeId === employee.id}
                                        value={editingEmployee.name}
                                        onChange={(event, data) => setEditingEmployee({
                                            ...editingEmployee,
                                            name: data.value
                                        })}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Input
                                        fluid
                                        disabled={editingEmployeeId === employee.id}
                                        value={editingEmployee.job}
                                        onChange={(event, data) => setEditingEmployee({
                                            ...editingEmployee,
                                            job: data.value
                                        })}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Input
                                        type='date'
                                        fluid
                                        disabled={editingEmployeeId === employee.id}
                                        value={moment(editingEmployee.birthday).format('YYYY-MM-DD')}
                                        onChange={(event, data) => setEditingEmployee({
                                            ...editingEmployee,
                                            birthday: data.value
                                        })}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Input
                                        fluid
                                        disabled={editingEmployeeId === employee.id}
                                        value={editingEmployee.telNumber}
                                        onChange={(event, data) => setEditingEmployee({
                                            ...editingEmployee,
                                            telNumber: data.value
                                        })}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <TextArea
                                        disabled={editingEmployeeId === employee.id}
                                        value={editingEmployee.note}
                                        onChange={(event, data) => setEditingEmployee({
                                            ...editingEmployee,
                                            note: data.value as string
                                        })}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button.Group>
                                        <Button
                                            loading={editingEmployeeId === employee.id}
                                            icon='save'
                                            color='blue'
                                            content='Primeni'
                                            onClick={() => editClientEmployee(editingEmployee).then(() => setEditingEmployee(null))}
                                        />
                                        <Button
                                            disabled={editingEmployeeId === employee.id}
                                            icon='x'
                                            onClick={() => setEditingEmployee(null)}
                                        />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row> :
                            <Table.Row key={employee.id}>
                                <Table.Cell>{employee.name}</Table.Cell>
                                <Table.Cell>{employee.job}</Table.Cell>
                                <Table.Cell>{moment(employee.birthday).format('l')}</Table.Cell>
                                <Table.Cell>{employee.telNumber}</Table.Cell>
                                <Table.Cell>{employee.note}</Table.Cell>
                                {isEmployee &&
                                <Table.Cell>
                                    <Button.Group>
                                        <Button
                                            icon='edit'
                                            color='orange'
                                            onClick={() => setEditingEmployee(employee)}
                                        />
                                        <Button
                                            loading={removingEmployeeId === employee.id}
                                            icon='trash'
                                            color='red'
                                            onClick={() => openModal(
                                                <Segment basic clearing>
                                                    <Header textAlign='center' as='h2'>
                                                        Brisanje zaposlenog
                                                        <Icon color='red' name='warning circle'/>
                                                    </Header>
                                                    <p style={{textAlign: 'center'}}>
                                                        Da li zaista želiš da obrišeš zaposlenog <strong>{employee.name} ?</strong>
                                                    </p>
                                                    <Button color='red' floated='right' onClick={(event: any, data) => {
                                                        event.target.className += ' loading'
                                                        removeClientEmployee(employee.id)
                                                            .then(() => closeModal())
                                                    }}>
                                                        <Icon name='checkmark'/> Da
                                                    </Button>
                                                    <Button onClick={() => closeModal()} floated='right'>
                                                        <Icon name='remove'/> Ne
                                                    </Button>
                                                </Segment>, 'small'
                                            )}
                                        />
                                    </Button.Group>
                                </Table.Cell>}
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Segment>
        </>
    )
}

export default observer(ClientDetailsEmployees)
