
export interface ITrueFalseAllFilter  {
    value?: 'only' | 'excluded' | 'all'
}

export const trueFalseAllFilter = (rows: any, id: any, filterValue: ITrueFalseAllFilter, root = false) => {
    return rows.filter((row: any) => {
        const rowValue = row.values[id]
        
        if (filterValue.value === 'only') {
            return rowValue
        }

        if (filterValue.value === 'excluded') {
            return !rowValue
        }
        
        return true
    })
}
