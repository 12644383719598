import React, {FunctionComponent} from 'react'
import SuperAdminRoute from "./SuperAdminRoute";
import UsersDashboard from "../../../features/users/dashboard/UsersDashboard";
import AnyNonClientRoleRoute from "./AnyNonClientRoleRoute";
import HomeAuthorizedDashboard from "../../../features/home/authorized/HomeAuthorizedDashboard";
import UserPostDetailsRouteWrapper from "../../../features/userPosts/details/UserPostDetailsRouteWrapper";
import TrafficManagerAndEmployeeRoute from "./TrafficManagerAndEmployeeRoute";
import ClientsDashboard from "../../../features/clients/dashboard/ClientsDashboard";
import ClientsEmployees from "../../../features/clients/dashboard/ClientsEmployees";
import EmployeeRoute from "./EmployeeRoute";
import ClientForm from "../../../features/clients/form/ClientForm";
import ClientDetailsLayout from "../../../features/clients/details/ClientDetailsLayout";
import RegionsDashboard from "../../../features/regions/dashboard/RegionsDashboard";
import RegionForm from "../../../features/regions/form/RegionForm";
import RegionDetails from "../../../features/regions/details/RegionDetails";
import ProductsDashboard from "../../../features/products/ProductsDashboard";
import TrafficManagerRoute from "./TrafficManagerRoute";
import ProductForm from "../../../features/products/form/ProductForm";
import BrandsDashboard from "../../../features/brands/dashboard/BrandsDashboard";
import BrandForm from "../../../features/brands/form/BrandForm";
import BrandDetails from "../../../features/brands/BrandDetails";
import BillsDashboard from "../../../features/bills/BillsDashboard";
import BillCart from "../../../features/bills/cart/BillCart";
import BillDetails from "../../../features/bills/details/BillDetails";
import PaymentsDashboard from "../../../features/payments/dashboard/PaymentsDashboard";
import PaymentForm from "../../../features/payments/form/PaymentForm";
import ProfilePage from "../../../features/user/profile/ProfilePage";
import ClientOrdersDashboard from "../../../features/clientOrders/dashboard/ClientOrdersDashboard";
import ClientOrderForm from "../../../features/clientOrders/form/ClientOrderForm";
import ClientOrdersDetails from "../../../features/clientOrders/details/ClientOrdersDetails";
import OnHoldProductsDashboard from "../../../features/onHoldProducts/dashboard/OnHoldProductsDashboard";
import OnHoldProductForm from "../../../features/onHoldProducts/form/OnHoldProductForm";
import PricelistsDashboard from "../../../features/pricelists/dashboard/PricelistsDashboard";
import PricelistForm from "../../../features/pricelists/form/PricelistForm";
import TrafficAnalysisDashboard from "../../../features/trafficAnalysis/dashboard/TrafficAnalysisDashboard";
import ReceivablesDashboard from "../../../features/receivables/dashboard/ReceivablesDashboard";
import GeneralExpensesDashboard from "../../../features/expenses/generalExpenses/dashboard/GeneralExpensesDashboard";
import GeneralExpenseForm from "../../../features/expenses/generalExpenses/form/GeneralExpenseForm";
import ProfitabilityDashboard from "../../../features/profitability/dashboard/ProfitabilityDashboard";
import ClientInitialStatesDashboard from "../../../features/clientInitialStates/dashboard/ClientInitialStatesDashboard";
import ClientInitialStateForm from "../../../features/clientInitialStates/form/ClientInitialStateForm";
import ClientInitialStatesGenerate from "../../../features/clientInitialStates/generate/ClientInitialStatesGenerate";
import GlobalSettingsDashboard from "../../../features/globalSettings/dashboard/GlobalSettingsDashboard";
import ClientActivitiesDashboard from "../../../features/clientActivities/dashboard/ClientActivitiesDashboard";
import ClientActivityForm from "../../../features/clientActivities/form/ClientActivityForm";
import ClientActivityDetails from "../../../features/clientActivities/details/ClientActivityDetails";
import ClientSchedulesDashboard from "../../../features/clientSchedule/dashboard/ClientSchedulesDashboard";
import ClientScheduleFormRouteWrapper from "../../../features/clientSchedule/form/ClientScheduleFormRouteWrapper";
import ClientScheduleDetails from "../../../features/clientSchedule/details/ClientScheduleDetails";
import ClientToursDashboard from "../../../features/clientTours/dashboard/ClientToursDashboard";
import ClientTourDetailsRouteWrapper from "../../../features/clientTours/details/ClientTourDetailsRouteWrapper";
import ClientTourFormRouteWrapper from "../../../features/clientTours/form/ClientTourFormRouteWrapper";
import ClientGoalsDashboard from "../../../features/clientGoals/dashboard/ClientGoalsDashboard";
import ClientGoalForm from "../../../features/clientGoals/form/ClientGoalForm";
import ClientGoalDetails from "../../../features/clientGoals/details/ClientGoalDetails";
import EmployeeGoalsDashboard from "../../../features/employeeGoals/dashboard/EmployeeGoalsDashboard";
import EmployeeGoalForm from "../../../features/employeeGoals/form/EmployeeGoalForm";
import EmployeeGoalDetails from "../../../features/employeeGoals/details/EmployeeGoalDetails";
import ProductConsumptionsDashboard from "../../../features/productConsumptions/dashboard/ProductConsumptionsDashboard";
import ProductConsumptionForm from "../../../features/productConsumptions/form/ProductConsumptionForm";
import ProductConsumptionDetails from "../../../features/productConsumptions/ProductConsumptionDetails";
import DocumentationsDashboard from "../../../features/documentations/dashboard/DocumentationsDashboard";
import DocumentationForm from "../../../features/documentations/form/DocumentationForm";
import DocumentationDetails from "../../../features/documentations/details/DocumentationDetails";
import TrafficsDashboard from "../../../features/traffics/dashboard/TrafficsDashboard";
import TrafficForm from "../../../features/traffics/TrafficForm";
import CommentsSectionDashboard from "../../../features/commentsSection/dashboard/CommentsSectionDashboard";
import PostExpressDocumentsDashboard
    from "../../../features/clientOrders/postExpressDocuments/dashboard/PostExpressDocumentsDashboard";
import {Route, Switch} from "react-router-dom";
import UserResetPasswordForm from "../../../features/user/forms/UserResetPasswordForm";
import NotFound from "../NotFound";
import RequiredProductsDashboard from "../../../features/requiredProducts/dashboard/RequiredProductsDashboard";
import RequiredProductForm from "../../../features/requiredProducts/form/RequiredProductForm";

interface OwnProps {
    location: any
}

type Props = OwnProps

const RoutesSwitcher: FunctionComponent<Props> = ({location}) => {

    return <Switch>
        <SuperAdminRoute exact path='/:traffic/users' component={UsersDashboard}/>

        <AnyNonClientRoleRoute path='/:traffic/dashboard' component={HomeAuthorizedDashboard}/>

        {/*     UserPosts   */}
        <AnyNonClientRoleRoute
            path='/:traffic/userPosts/:id'
            component={UserPostDetailsRouteWrapper}/>

        {/*     Clients     */}
        <TrafficManagerAndEmployeeRoute exact path='/:traffic/clients'
                                        component={ClientsDashboard}/>
        <TrafficManagerAndEmployeeRoute exact path='/:traffic/clients/employees'
                                        component={ClientsEmployees}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/clients/create', '/:traffic/clients/manage/:id/edit']}
            component={ClientForm}/>

        {/*     Client Details      */}
        {/*     Bills       */}
        <TrafficManagerAndEmployeeRoute
            path='/:traffic/clients/:id/:currentTab?'
            component={ClientDetailsLayout}
        />
        {/*     Payments       */}
        {/*<TrafficManagerAndEmployeeRoute*/}
        {/*    key={location.key}*/}
        {/*    path='/:traffic/clients/:id/:currentTab'*/}
        {/*    render={props => <ClientDetailsLayout {...{...props, currentTab: 'payments' }} />}*/}
        {/*    component={ClientDetailsLayout}*/}
        {/*/>*/}


        {/*     Regions     */}
        <AnyNonClientRoleRoute exact path='/:traffic/regions'
                               component={RegionsDashboard}/>
        <SuperAdminRoute
            key={location.key}
            path={['/:traffic/regions/create', '/:traffic/regions/manage/:id/edit']}
            component={RegionForm}
        />
        <AnyNonClientRoleRoute path='/:traffic/regions/:id' component={RegionDetails}/>


        {/*     Products    */}
        <EmployeeRoute exact path='/:traffic/products' component={ProductsDashboard}/>
        <TrafficManagerRoute
            key={location.key}
            path={['/:traffic/products/create', '/:traffic/products/manage/:id/edit']}
            component={ProductForm}
        />
        {/*<EmployeeRoute path='/:traffic/products/:id' component={ProductDetails}/>*/}


        {/*     Brands      */}
        <SuperAdminRoute exact path='/:traffic/brands'
                         component={BrandsDashboard}/>
        <SuperAdminRoute
            key={location.key}
            path={['/:traffic/brands/create', '/:traffic/brands/manage/:id/edit']}
            component={BrandForm}
        />
        <AnyNonClientRoleRoute path='/:traffic/brands/:id' component={BrandDetails}/>


        {/*     Bills       */}
        <EmployeeRoute exact path='/:traffic/bills' component={BillsDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/bills/createCart', '/:traffic/bills/manage/:id/editCart']}
            component={BillCart}
        />
        <EmployeeRoute path='/:traffic/bills/:id' component={BillDetails}/>

        <EmployeeRoute exact path='/:traffic/payments' component={PaymentsDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/payments/create', '/:traffic/payments/manage/:id/edit']}
            component={PaymentForm}
        />
        {/*<EmployeeRoute path='/:traffic/payments/:id' component={PaymentDetails}/>*/}

        <AnyNonClientRoleRoute path='/:traffic/profile/:username' component={ProfilePage}/>

        <EmployeeRoute exact path='/:traffic/clientOrders' component={ClientOrdersDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/clientOrders/create', '/:traffic/clientOrders/manage/:id/edit']}
            component={ClientOrderForm}
        />
        <EmployeeRoute path='/:traffic/clientOrders/:id' component={ClientOrdersDetails}/>

        {/*     OnHoldProducts      */}
        <EmployeeRoute exact path='/:traffic/onHoldProducts'
                       component={OnHoldProductsDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/onHoldProducts/create', '/:traffic/onHoldProducts/manage/:id/edit']}
            component={OnHoldProductForm}
        />
        {/*<EmployeeRoute path='/:traffic/onHoldProducts/:id' component={OnHoldProductsDetails}/>*/}

        {/*     RequiredProducts      */}
        <EmployeeRoute exact path='/:traffic/requiredProducts'
                       component={RequiredProductsDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/requiredProducts/add', '/:traffic/requiredProducts/manage/:id/edit']}
            component={RequiredProductForm}
        />

        <SuperAdminRoute exact path='/:traffic/pricelists' component={PricelistsDashboard}/>
        <SuperAdminRoute
            key={location.key}
            path={['/:traffic/pricelists/create', '/:traffic/pricelists/manage/:id/edit']}
            component={PricelistForm}
        />

        <TrafficManagerRoute
            exact
            path='/:traffic/trafficAnalysis'
            component={TrafficAnalysisDashboard}/>

        <TrafficManagerRoute
            exact
            path='/:traffic/receivables'
            component={ReceivablesDashboard}/>

        <TrafficManagerRoute
            exact
            path='/:traffic/generalExpenses'
            component={GeneralExpensesDashboard}/>
        <TrafficManagerRoute
            key={location.key}
            path={['/:traffic/generalExpenses/create', '/:traffic/generalExpenses/manage/:id/edit']}
            component={GeneralExpenseForm}/>
        {/*<TrafficManagerRoute path='/:traffic/generalExpenses/:id' component={GeneralExpenseDetails}/>*/}

        <TrafficManagerRoute
            exact
            path='/:traffic/profitability'
            component={ProfitabilityDashboard}/>

        <TrafficManagerRoute exact path='/:traffic/clientInitialStates'
                             component={ClientInitialStatesDashboard}/>
        <TrafficManagerRoute
            key={location.key}
            path={['/:traffic/clientInitialStates/create', '/:traffic/clientInitialStates/manage/:id/edit']}
            component={ClientInitialStateForm}
        />
        <TrafficManagerRoute path='/:traffic/clientInitialStates/generate'
                             component={ClientInitialStatesGenerate}/>

        <SuperAdminRoute exact path='/:traffic/globalSettings'
                         component={GlobalSettingsDashboard}/>

        <EmployeeRoute exact path='/:traffic/clientActivities'
                       component={ClientActivitiesDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/clientActivities/create', '/:traffic/clientActivities/manage/:id/edit']}
            component={ClientActivityForm}
        />
        <EmployeeRoute path='/:traffic/clientActivities/:id'
                       component={ClientActivityDetails}/>

        <EmployeeRoute exact path='/:traffic/clientSchedules'
                       component={ClientSchedulesDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/clientSchedules/create', '/:traffic/clientSchedules/manage/:id/edit']}
            component={ClientScheduleFormRouteWrapper}
        />
        <EmployeeRoute
            exact
            path='/:traffic/clientSchedules/:id'
            component={ClientScheduleDetails}/>
        <EmployeeRoute
            exact
            path='/:traffic/clientSchedules/:clientId/:year'
            component={ClientScheduleDetails}/>


        {/*     Client Tours    */}
        <EmployeeRoute exact path='/:traffic/clientTours'
                       component={ClientToursDashboard}/>

        <EmployeeRoute exact path='/:traffic/clientTours/:id'
                       component={ClientTourDetailsRouteWrapper}/>

        <EmployeeRoute
            exact path='/:traffic/clientTours/manage/:id/edit'
            component={ClientTourFormRouteWrapper}/>

        {/*     Client goals    */}

        <EmployeeRoute exact path='/:traffic/clientGoals'
                       component={ClientGoalsDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/clientGoals/create', '/:traffic/clientGoals/manage/:id/edit']}
            component={ClientGoalForm}
        />
        <EmployeeRoute path='/:traffic/clientGoals/:id'
                       component={ClientGoalDetails}/>

        <EmployeeRoute exact path='/:traffic/employeeGoals'
                       component={EmployeeGoalsDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/employeeGoals/create', '/:traffic/employeeGoals/manage/:id/edit']}
            component={EmployeeGoalForm}
        />
        <EmployeeRoute path='/:traffic/employeeGoals/:id'
                       component={EmployeeGoalDetails}/>
        {/*     ProductConsumptions     */}
        <TrafficManagerRoute exact path='/:traffic/productConsumptions'
                             component={ProductConsumptionsDashboard}/>
        <TrafficManagerRoute
            key={location.key}
            path={['/:traffic/productConsumptions/create', '/:traffic/productConsumptions/manage/:id/edit']}
            component={ProductConsumptionForm}
        />
        <TrafficManagerRoute path='/:traffic/productConsumptions/:id'
                             component={ProductConsumptionDetails}/>

        <EmployeeRoute exact path='/:traffic/documentationFolder/:folderId'
                       component={DocumentationsDashboard}/>
        <EmployeeRoute
            key={location.key}
            path={['/:traffic/documentations/create', '/:traffic/documentations/manage/:id/edit']}
            component={DocumentationForm}
        />
        <EmployeeRoute path='/:traffic/documentations/:id'
                       component={DocumentationDetails}/>

        {/*     Traffics    */}
        <SuperAdminRoute exact path='/:traffic/traffics'
                         component={TrafficsDashboard}/>
        <SuperAdminRoute
            key={location.key}
            path={['/:traffic/traffics/create', '/:traffic/traffics/manage/:id/edit']}
            component={TrafficForm}/>

        <EmployeeRoute
            exact
            path='/:traffic/commentsSection'
            component={CommentsSectionDashboard}/>

        <EmployeeRoute
            path='/:traffic/postExpressDocuments'
            component={PostExpressDocumentsDashboard}/>

        <Route
            path='/:traffic/resetPassword'
            component={UserResetPasswordForm}
        />

        <Route path='/notfound' component={NotFound}/>
        <Route component={NotFound}/>
    </Switch>
}

export default RoutesSwitcher
