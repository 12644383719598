
export interface IMultiselectFilter  {
    selected?: any[]
}

export const multiselectFilter = (rows: any, id: any, filterValue: IMultiselectFilter) => {
    return rows.filter((row: any) => {
        const rowValue = row.values[id]
        
        if (filterValue.selected) {
            
            if (filterValue.selected.length === 0) {
                return true
            }
            
            if (Array.isArray(rowValue)) {
                if (rowValue.some( (r: any) => filterValue.selected!.includes(r))) {
                    return true
                }
            } else {
                if (filterValue.selected.includes(rowValue)) {
                    return true
                }
            }
        } 

        return false
    })
}
