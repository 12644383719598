import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import TextInput from "../../../core/common/form/TextInput";
import SelectStringInput from "../../../core/common/form/SelectStringInput";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {combineValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../core/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import {IPrice, IProductFormValues, ProductFormValues} from "../../../core/models/products";
import TextAreaInput from "../../../core/common/form/TextAreaInput";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
    brandId: isRequired({message: 'Brend je obavezan'}),
    sku: isRequired({message: 'Šifra je obavezna'})
});

interface DetailParams {
    id: string;
}

const ProductForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {loadProduct, editProduct, loading: loadingProduct, createProduct, submitting} = rootStore.productsStore
    const {brandsArray: brands, loadBrands, loading: loadingBrands} = rootStore.brandsStore
    const {loadPricelists, loading: loadingPricelists} = rootStore.pricelistsStore
    const {currentTraffic} = rootStore.trafficsStore

    const [product, setProduct] = useState<IProductFormValues>(new ProductFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem(match.params.id ? '' : 'productCreate')

    useEffect(() => {
        loadBrands()
        loadPricelists()
            .then(pricelists => {
                if (match.params.id) {
                    setLoading(true)
                    loadProduct(match.params.id)
                        .then(product => {
                            setProduct(new ProductFormValues(pricelists!, product!))
                            setLoading(false)
                        })
                } else {
                    setProduct(new ProductFormValues(pricelists!))
                    setLoading(false)
                }
            })
    }, [
        loadProduct,
        match.params.id,
        loadBrands,
        loadPricelists,
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const product = {...values, brandId: Number(values.brandId)}

        product.prices = product.prices
            .filter((price: IPrice) => (price.value !== null && price.value))
            .map((price: IPrice) => ({
                pricelistId: price.pricelistId,
                value: Number(price.value)
            }))

        if (!product.id) {
            let newProduct = {
                ...product,
                id: uuid()
            }
            return createProduct(newProduct)
        } else {
            return editProduct(product)
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Proizodi',
                            linkWithoutCurrentTrafficId: 'products'
                        },
                        {
                            text: match.params.id ? 'Izmena proizvoda' : 'Dodavanje proizvoda',
                            active: true
                        }
                    ]}
                    />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm onSubmit={(values: any) => handleFinalFormSubmit(values)
                        .catch(error => ({
                                [FORM_ERROR]: error
                            })
                        )
                    }
                               validate={validate}
                               initialValues={product}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit
                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={(loading || loadingProduct || loadingBrands || loadingPricelists)}>
                                       <Field
                                           name='name'
                                           placeholder='Naziv'
                                           value={product!.name}
                                           component={TextInput}
                                       />
                                       <Field
                                           name='sku'
                                           placeholder='Šifra'
                                           value={product!.sku}
                                           component={TextInput}
                                       />
                                       <Field
                                           name='description'
                                           placeholder='Opis'
                                           value={product!.description}
                                           component={TextAreaInput}
                                       />
                                       {brands && <Field
                                           name='brandId'
                                           placeholder='Brend'
                                           value={product!.brandId.toString()}
                                           options={brands.map(brand => ({
                                               key: brand.id,
                                               text: brand.name,
                                               value: brand.id.toString()
                                           }))}
                                           component={SelectStringInput}/>}
                                       {product?.prices.map((x, index) => {
                                           if (x.isBase) {
                                               return {
                                                   value: x,
                                                   rank: 0
                                               }
                                           } else if (x.isDefault) {
                                               return {
                                                   value: x,
                                                   rank: 1
                                               }
                                           } else {
                                               return {
                                                   value: x,
                                                   rank: index + 10
                                               }
                                           }
                                       })
                                           //@ts-ignore
                                           .sort((a: any, b: any) => a.rank > b.rank)
                                           //@ts-ignore
                                           .map(({value: price}, index) => (
                                               <Form.Field key={price.id}>
                                                   <label>{price.pricelistName}</label>
                                                   <Field
                                                       key={price.id}
                                                       name={`prices[${index}].value`}
                                                       placeholder={price.pricelistName}
                                                       value={product.prices[index].value}
                                                       label={price.isBase ? undefined : {basic: true, content: 'RSD'}}
                                                       labelPosition='right'
                                                       icon={price.isBase ? 'euro' : undefined}
                                                       // @ts-ignore
                                                       component={TextInput}/>
                                               </Form.Field>
                                           ))}
                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loadingProduct || loadingPricelists || loadingBrands || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(!product!.id) ? 'Dodaj' : 'Izmeni'}
                                       />
                                       <Button
                                           onClick={() => history.push(`/${currentTraffic?.id}/products`)}
                                           disabled={loading || loadingProduct || loadingPricelists || loadingBrands}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(ProductForm)
