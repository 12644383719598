import React, {FunctionComponent, useContext, useMemo} from 'react';
import Table from "../../../core/common/tables/Table";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";
import {Cell} from "react-table";
import {IPayment} from "../../../core/models/payments";
import {formatRsd} from "../../../core/common/util/format";
import moment from 'moment';
import {Button, Header, Icon, Message, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {DateFilter} from "../../../core/common/tables/filters/date/DateFilter";
import ClientDetailedFilter from "../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import ClientDetailedCell from "../../clients/list/components/ClientDetailedCell";
import SumTableHeader from "../../../core/common/tables/headers/SumTableHeader";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";

interface IProps {
    hideClient?: boolean
    trackingId?: string
}

const PaymentsList: FunctionComponent<IProps> = ({hideClient, trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {paymentsArray: payments, deletePayment, deleting, deletingId} = rootStore.paymentsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {currentTraffic} = rootStore.trafficsStore
    const { isEmployee } = rootStore.userStore
    
    const columns = useMemo(() => [
        {
            id: 'client',
            Header: 'Klijent',
            Cell: ({row: {original: payment}}: Cell<IPayment>) => (<ClientDetailedCell client={payment.client}/>),
            accessor: 'client.name',
            hide: hideClient,
            Filter: ClientDetailedFilter,
            filter: 'clientDetailedFilter'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} name='Iznos' type='money' accessor='value' />,
            Cell: ({row: {original: payment}}: Cell<IPayment>) => formatRsd(payment.value),
            accessor: 'value',
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            id: 'date',
            Header: 'Datum',
            accessor: 'date',
            Cell: ({row: {original: payment}}: Cell<IPayment>) => moment(payment.date).format('l'),
            Filter: DateFilter,
            filter: 'dateFilter'
        },
        {
            Header: 'Napomena',
            accessor: 'note'
        },
        {
            Header: 'Dodao',
            Cell: ({row: {original: payment}}: Cell<IPayment>) => (<div>
                <div>{payment.user.displayName}</div>
                <div>{payment.user.email}</div>
            </div>)
        },
        {
            Header: 'Kontrole',
            hide: !isEmployee,
            id: 'controls',
            Cell: ({row: {original: payment}}: Cell<IPayment>) => (
                <Button.Group>
                    <Button as={Link} to={`/${currentTraffic?.id}/payments/manage/${payment.id}/edit`} icon='edit'
                            color='yellow'/>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje uplate
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš uplatu klijenta <strong>{payment.client.name} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any) => {
                                event.target.className += ' loading'
                                deletePayment(payment.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={(deletingId === payment.id) && deleting}/>
                </Button.Group>)
        }
    ], [
        closeModal, 
        openModal, 
        deletePayment, 
        deleting, 
        deletingId, 
        currentTraffic, 
        hideClient,
        isEmployee
    ])

    if (payments.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje uplate po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>
    
    return (
        <>
            <Table
                trackingId={trackingId}                
                columns={columns}
                data={payments}/>
        </>
    );
};

export default observer(PaymentsList)
