import {IClient} from "./clients";

export interface IRegion {
    id: number | null,
    name: string,
    longitude: number,
    latitude: number,
    isDefault: boolean
    clients: IClient[]
    clientsCount: number
}

export class Region implements IRegion{
    clients: IClient[] = []
    clientsCount = 0
    id: number | null = null
    latitude: number = 0
    longitude: number = 0
    name: string
    isDefault: boolean = false
    
    constructor(name: string) {
        this.name = name
    }
}

export interface IRegionFormValues {
    id: number
    name: string
    longitude: number
    latitude: number
}

export class RegionFormValues implements IRegionFormValues {
    id = NaN
    name = ''
    longitude = 0
    latitude = 0
    
    constructor(region?: IRegion) {
        if (region) {
            this.id = region.id!
            this.latitude = region.latitude
            this.longitude = region.longitude
            this.name = region.name
        }
        
    }
}