import React, {FunctionComponent, useContext, useEffect} from 'react';
import {RootStoreContext} from "../../../core/stores/rootStore";
import Table from "../../../core/common/tables/Table";
import {Cell, FilterProps} from "react-table";
import {observer} from "mobx-react-lite";
import {IProduct, IProductsFilter} from "../../../core/models/products";
import ProductsTableBasicInfoCell from "./ProductsTableBasicInfoCell";
import ProductsTablePricesCell from "./ProductsTablePricesCell";
import {Link} from "react-router-dom";
import {Button, Header, Icon, Message, Segment} from "semantic-ui-react";
import {ProductsDetailedFilter} from "../../../core/common/tables/filters/products/detailed/ProductsDetailedFilter";
import {productsDetailedFilter} from "../../../core/common/tables/filters/products/detailed/productsDetailedFilter";

interface IProps {
    trackingId?: string
}

const ProductsList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        productsByNameArray: products,
        deletingId,
        deleting,
        deleteProduct
    } = rootStore.productsStore
    const { openModal, closeModal } = rootStore.modalStore 
    const { currentTraffic } = rootStore.trafficsStore
    const { brandsArray: brands, loadBrandsIfNull } = rootStore.brandsStore
    const { isTrafficManager } = rootStore.userStore
    
    useEffect(() => {
        loadBrandsIfNull()
    }, [
        loadBrandsIfNull
    ])
    
    const columns = React.useMemo(
        () => [
            {
                id: 'product',
                Header: 'Proizvod',
                accessor: 'name',
                Cell: ({row: {original: product}}: Cell<IProduct>) =>
                    (<ProductsTableBasicInfoCell product={product}/>),
                Filter: (d: FilterProps<any>) => ProductsDetailedFilter(d, brands),
                filter: (rows: any, id: any, filterValue: IProductsFilter) => productsDetailedFilter(rows, id, filterValue, true),
            },
            {
                Header: 'Opis',
                accessor: 'description'
            },
            {
                Header: 'Cene',
                accessor: 'prices',
                Cell: ({row: {original: product}}: Cell<IProduct>) => (
                    <ProductsTablePricesCell prices={product.prices}/>),
                Filter: <></>,
                disableSortBy: true
            },
            {
                id: 'controls',
                hide: !isTrafficManager,
                Header: 'Kontrole',
                Cell: ({row: {original: product}}: Cell<IProduct>) => (
                    <Button.Group>
                        <Button as={Link} to={`/${currentTraffic?.id}/products/manage/${product.id}/edit`} icon='edit' color='yellow'/>
                        <Button onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje proizvoda
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš proizvod <strong>{product.name} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any, data) => {
                                    event.target.className += ' loading'
                                    deleteProduct(product.id!)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={(deletingId === product.id) && deleting}/>
                    </Button.Group>)
            }

        ],
        [closeModal, deleteProduct, deleting, deletingId, openModal, currentTraffic, brands, isTrafficManager]
    )

    if (products.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje proizvodi po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>
    
    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={products}/>
    )
};

export default observer(ProductsList)
