import React, {useContext, useMemo} from 'react';
import {Cell} from 'react-table'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import Table from "../../../../core/common/tables/Table";
import {IClient, IClientFilter} from "../../../../core/models/clients";
import {formatRsd} from "../../../../core/common/util/format";
import ClientProgress from "../../commom/ClientProgress";
import SumTableHeader from "../../../../core/common/tables/headers/SumTableHeader";
import ClientDetailedCell from "./ClientDetailedCell";
import ClientDetailedFilter from "../../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import {NumberFilter} from "../../../../core/common/tables/filters/number/NumberFilter";
import {clientDetailedFilter} from "../../../../core/common/tables/filters/clients/logic/clientDetailedFilter";
import {Header, Message} from "semantic-ui-react";
import {numberToStringToFixedOrInt} from "../../../../core/common/util/string";
import {EditClientPaymentNote} from "./EditClientPaymentNote";

interface IClientsReceivablesListProps {
    trackingId?: string,
    withInitialState: boolean
}

const ClientsReceivablesList = ({trackingId, withInitialState}: IClientsReceivablesListProps) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clientsArray: clients,
    } = rootStore.clientsStore
    
    const columns = useMemo(
        () => [
            {
                id: 'client',
                Header: 'Klijent',
                accessor: 'name',
                Cell: ({row: {original: client}}: Cell<IClient>) => (<ClientDetailedCell client={client}/>),
                Filter: ClientDetailedFilter,
                filter: (rows: any, id: any, filterValue: IClientFilter) => clientDetailedFilter(rows, id, filterValue, true)
            },
            {
                id: withInitialState ? 'financialState.balanceWithInitialState' : 'financialState.balance',
                Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Saldo'}
                                                      accessor={withInitialState ? 'financialState.balanceWithInitialState' : 'financialState.balance'}/>,
                accessor: (d: IClient) => withInitialState ? d.financialState?.balanceWithInitialState : d.financialState?.balance,
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => (
                    <div>
                        {withInitialState ?
                            formatRsd(financialState?.balanceWithInitialState ?? NaN) :
                            formatRsd(financialState?.balance ?? NaN)}
                    </div>
                ),
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                id: withInitialState ? 'financialState.toPaidWithInitialState' : 'financialState.toPaid',
                Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Dospelo'}
                                                      accessor={withInitialState ? 'financialState.toPaidWithInitialState' : 'financialState.toPaid'}/>,
                accessor: (d: IClient) => withInitialState ? d.financialState?.toPaidWithInitialState : d.financialState?.toPaid,
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => (
                    <div>
                        {withInitialState ?
                            formatRsd(financialState?.toPaidWithInitialState ?? NaN) :
                            formatRsd(financialState?.toPaid ?? NaN)}
                    </div>
                ),
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                id: 'realization',
                accessor: (d: IClient) => {

                    const placed = d.financialState?.placedBaseValue
                    const scheduled = d.financialState?.scheduleValue

                    if (!placed || !scheduled) {
                        return 0
                    }

                    return placed / scheduled
                },
                Header: (row: any) => {

                    const filteredRows: IClient[] = row.filteredRows.map((row: any) => row.original)

                    const sum = filteredRows.reduce((previousValue, currentValue) => {
                            const placed = currentValue.financialState?.placedBaseValue ?? 0
                            const scheduled = currentValue.financialState?.scheduleValue ?? 0

                            let percentage = 0

                            if (scheduled !== 0) {
                                percentage = placed / scheduled * 100
                                if (percentage > 100) {
                                    percentage = 100
                                }
                            }

                            return {
                                placed: previousValue.placed + (scheduled === 0 ? 0 : placed),
                                scheduled: previousValue.scheduled + (scheduled === 0 ? 0 : scheduled),
                                percentageSum: previousValue.percentageSum + (scheduled === 0 ? 0 : percentage),
                                withScheduleCount: previousValue.withScheduleCount + (scheduled === 0 ? 0 : 1)
                            }
                        },
                        {
                            placed: 0,
                            scheduled: 0,
                            percentageSum: 0,
                            withScheduleCount: 0
                        })

                    return <>
                        <Header sub>
                            Ostvarenje
                        </Header>
                        <strong>
                            {formatRsd(sum.placed)} / {formatRsd(sum.scheduled)} [{numberToStringToFixedOrInt(sum.percentageSum / sum.withScheduleCount, 2)}%]
                        </strong>
                    </>
                },
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => <ClientProgress
                    value={financialState?.placedBaseValue} total={financialState?.scheduleValue}/>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                accessor: "id",
                Header: <>Napomena naplate</>,
                Cell: ({ row: { original: client } }: Cell<IClient>) => <EditClientPaymentNote client={client} />
            }
            // {
            //     id: 'rank',
            //     accessor: 'id',
            //     Header: 'Rang',
            //     Cell: ({row: {original: client}}: Cell<IClient>) => <Header
            //         color={clientStatusToColorName(client.status!)} as='h4'
            //         content={`${alphabet[getClientRankByStatusAndType(client)].toUpperCase()}${client.financialState?.placedBaseValue}`}/>,
            //     Filter: <></>,
            //     sortType: 'clientRank',
            // }
        ],
        [
            withInitialState]
    )

    if (clients.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje klijenti po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={clients}
        />
    );
};

export default observer(ClientsReceivablesList)
