import React, {ChangeEvent, FunctionComponent, useContext, useState} from 'react';
import {Form as FinalForm, Field} from 'react-final-form'
import {Button, Form, Header, InputOnChangeData} from "semantic-ui-react";
import TextInput from "../../core/common/form/TextInput";
import {RootStoreContext} from "../../core/stores/rootStore";
import {IUserForgetPasswordFormValues, IUserFormValues} from "../../core/models/user";
import {FORM_ERROR} from "final-form";
import {combineValidators, composeValidators, isRequired} from "revalidate";
import ErrorMessage from "../../core/common/form/ErrorMessage";
import {isValidEmail} from "../../core/common/validators/general";
import {toast} from "react-toastify";

interface IProps {
}


const validateForget = combineValidators({
    recoveryEmail: composeValidators(
        isRequired({message: 'E-mail je obavezan'}),
        isValidEmail()
    )(),
})

const validateLogin = combineValidators({
    email: composeValidators(
        isRequired({message: 'E-mail je obavezan'}),
        isValidEmail()
    )(),
    password: isRequired({message: 'Lozinka je obavezna'})
})

const LoginForm: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {login, forgetPassword} = rootStore.userStore

    const [forgetPasswordMode, setForgetPasswordMode] = useState(false)

    return forgetPasswordMode
        ? <FinalForm
            onSubmit={(values: IUserForgetPasswordFormValues) =>
                forgetPassword(values)
                    .then(() => {
                        toast.info('Token za oparavak lozinke poslat na email adresu')
                        setForgetPasswordMode(false)
                    })
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))}
            validate={validateForget}
            render={({
                         handleSubmit,
                         submitting,
                         form: {
                             change
                         },
                         submitError,
                         invalid,
                         pristine,
                         dirtySinceLastSubmit,
                     }) => (
                <Form onSubmit={handleSubmit} error>
                    <Header as='h2' content='Zaboravljena lozinka' color='blue'/>
                    <Field
                        name='recoveryEmail'
                        component={TextInput}
                        placeholder='Email'
                        onChange={(event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                            change('recoveryEmail', data.value.trim())
                        }}
                    />

                    {submitError && !dirtySinceLastSubmit &&
                    <ErrorMessage error={submitError} text='Pogrešan e-mail ime i/ili lozinka'/>}

                    <Form.Field>
                        <Button
                            type='button'
                            disabled={submitting}
                            onClick={() => setForgetPasswordMode(false)}
                            content='Nazad'
                            fluid/>
                    </Form.Field>

                    <Form.Field>
                        <Button
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            color='blue'
                            content='Pošalji token'
                            loading={submitting}
                            fluid/>
                    </Form.Field>
                    
                </Form>
            )}
        />
        : <FinalForm
            onSubmit={(values: IUserFormValues) => login(values).catch(error => ({
                [FORM_ERROR]: error
            }))}
            validate={validateLogin}
            render={({
                         handleSubmit,
                         submitting,
                         form: {
                             change
                         },
                         submitError,
                         invalid,
                         pristine,
                         dirtySinceLastSubmit,
                     }) => (
                <Form onSubmit={handleSubmit} error>
                    <Header as='h2' content='Logovanje na Aspective.' color='blue'/>
                    <Field
                        name='email'
                        component={TextInput}
                        placeholder='Email'
                        onChange={(event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                            change('email', data.value.trim())
                        }}
                    />

                    <Field name='password' component={TextInput} placeholder='Lozinka' type='password'/>

                    <Form.Field>
                        <Header
                            content='Zaboravio si lozinku?'
                            as='a'
                            color='blue'
                            onClick={() => setForgetPasswordMode(true)}
                        />
                    </Form.Field>

                    {submitError && !dirtySinceLastSubmit &&
                    <ErrorMessage error={submitError} text='Pogrešan e-mail ime i/ili lozinka'/>}
                    <Button disabled={(invalid && !dirtySinceLastSubmit) || pristine} color='blue' content='Uloguj se'
                            loading={submitting} fluid/>
                </Form>
            )}
        />

}

export default LoginForm
