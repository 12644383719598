import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
import {Button, Grid, Icon, Loader} from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroller";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";
import UserPostsList from "../lists/UserPostsList";
import UserPostForm from "../form/UserPostForm";
import UserPostsFilters from "./UserPostsFilters";

interface OwnProps {
}

type Props = OwnProps

const UserPostsDashboard: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadUserPosts,
        loading,
        setPage,
        page,
        totalPages,
        setPredicate,
        clearRegistry,
        clearPredicate,
    } = rootStore.userPostsStore


    const [createNewPostMode, setCreateNewPostMode] = useState(false)
    const [loadingNext, setLoadingNext] = useState(false);


    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadUserPosts().then(() => setLoadingNext(false));
    };


    useEffect(() => {
        // clearPredicate()

        loadUserPosts()

        return () => {
            clearPredicate()
            clearRegistry()
        }

    }, [
        setPredicate,
        clearPredicate,
        clearRegistry,
        loadUserPosts
    ])

    return (
        <Grid>
            <Grid.Row centered>
                {!createNewPostMode &&
                <Grid.Column textAlign='center' width={16}>
                    <Button
                        size='big'
                        onClick={() => setCreateNewPostMode(prevState => !prevState)}
                        icon
                        color='blue'
                        labelPosition='left'
                    >
                        <Icon name='edit'/>
                        Dodaj post
                    </Button>
                </Grid.Column>}

                <Grid.Column computer={10} tablet={12} mobile={16}>
                    {createNewPostMode &&
                    <UserPostForm
                        onSubmit={() => {
                            setCreateNewPostMode(false)
                        }}
                        onCancel={() => {
                            setCreateNewPostMode(false)
                        }}
                    />}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
                <Grid.Column textAlign='center' computer={10} tablet={12} mobile={16}>
                    <UserPostsFilters/>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
                <Grid.Column computer={10} tablet={12} mobile={16}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleGetNext}
                        hasMore={!loadingNext && page + 1 < totalPages}
                        initialLoad={false}
                    >
                        {loading && page === 0 ?
                            <Loader
                                style={{marginTop: 50}}
                                active={true} content={'Učitavanje postova...'}/> :
                            <UserPostsList/>}
                    </InfiniteScroll>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row textAlign='center'>
                <Grid.Column>
                    {!loadingNext && page + 1 < totalPages &&
                    <Button
                        onClick={() => handleGetNext()}
                        content='Učitaj još'
                        color='blue'
                    />}
                    <Loader active={loadingNext}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default observer(UserPostsDashboard)
