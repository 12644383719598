import React, {FunctionComponent} from 'react'
import {IClientGoal} from "../../../../core/models/clientGoals";
import {Grid} from "semantic-ui-react";
import ClientGoalDatesCellPlannedDate from "./ClientGoalDatesCellPlannedDate";
import ClientGoalDatesCellCompletedDate from "./ClientGoalDatesCellCompletedDate";

interface OwnProps {
    clientGoal: IClientGoal
}

type Props = OwnProps

const ClientGoalDatesCell: FunctionComponent<Props> = ({clientGoal}) => {

    return <Grid>
        <Grid.Column width={16} style={{ margin: 0, paddingBottom: 3 }}>
            <ClientGoalDatesCellPlannedDate clientGoal={clientGoal}/>
        </Grid.Column>
        <Grid.Column width={16} style={{margin: 0, paddingTop: 3 }}>
            <ClientGoalDatesCellCompletedDate clientGoal={clientGoal}/>
        </Grid.Column>
    </Grid>
}

export default ClientGoalDatesCell
