import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction, toJS} from "mobx";
import {IPricelist, IPricelistFormValues} from "../models/pricelists";
import agent from "../api/agent";
import {history} from "../../index";

export default class PricelistsStore {
    rootStore: RootStore
    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }
    
    @observable pricelists = new Map<number, IPricelist>()
    @observable pricelist: IPricelist | null = null
    @observable loading = false
    @observable deleting = false
    @observable deletingId: number | null = null
    @observable submitting = false
        
    @computed get pricelistArrayWithoutSpecials() {
        return this.pricelistsArray
            .filter(pl => !pl.isDefault && !pl.isBase)
    }
    
    @computed get pricelistsArray() {
        const pricelistsUnsortedArray = Array.from(this.pricelists.values())
        let pricelistsArray = pricelistsUnsortedArray.filter(p => !p.isBase && !p.isDefault && !p.isSelling)
        const defaultPricelist = pricelistsUnsortedArray.filter(p => p.isDefault)[0] ?? null
        const basePricelist = pricelistsUnsortedArray.filter(p => p.isBase)[0] ?? null
        const sellingPricelist = pricelistsUnsortedArray.filter(p => p.isSelling)[0] ?? null
        if (sellingPricelist) {
            pricelistsArray.unshift(sellingPricelist)
        }
        if (defaultPricelist) {
            pricelistsArray.unshift(defaultPricelist)
        }
        if (basePricelist) {
            pricelistsArray.unshift(basePricelist)
        }
        
        return pricelistsArray
    }
    
    
    @action loadPricelists = async () => {
        this.loading = true
        this.pricelists.clear()
        try {
            const pricelists = await agent.Pricelists.list()
            runInAction(() => {
                pricelists.forEach(pricelist => {
                    this.pricelists.set(pricelist.id, pricelist)                    
                })
            })
            return toJS(this.pricelistsArray)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action getPricelists = async () => {
        if (this.pricelists === null) {
            await this.loadPricelists()
        }
        
        return this.pricelistsArray
    }
    
    @action deletePricelist = async (id: number) => {
        this.deleting = true
        this.deletingId = id
        try {
            await agent.Pricelists.delete(id)
            runInAction(() => {
                this.pricelists.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingId = null
            })
        }
    }
    
    @action createPricelist = async (pricelist: IPricelistFormValues) => {
        this.submitting = true
        try {
            const createdPricelistId = await agent.Pricelists.create(pricelist)
            const createdPricelist:IPricelist = {
                isSelling: false,
                isDefault: false,
                isBase: false,
                name: pricelist.name!,
                id: createdPricelistId
            }
            runInAction(() => {
                this.pricelists.set(createdPricelistId, createdPricelist)
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/pricelists`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action editPricelist = async (pricelist: IPricelistFormValues) => {
        this.submitting = true
        try {
            await agent.Pricelists.edit(pricelist)
            const updatedPricelist = await agent.Pricelists.details(pricelist.id!)
            runInAction(() => {
                this.pricelist = updatedPricelist
                this.pricelists.set(pricelist.id!, updatedPricelist)
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/pricelists`)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action loadPricelist = async (id: number) => {
        this.loading = true
        try {
            const pricelist = await agent.Pricelists.details(id)
            
            runInAction(() => {
                this.pricelist = pricelist                
            })

            return toJS(pricelist)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
}