import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import DocumentationsList from "../lists/DocumentationsList";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import {RouteComponentProps} from "react-router";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface DetailParams {
    folderId: string
}

const DocumentationsDashboard: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {
    const rootStore = useContext(RootStoreContext)
    const {loadDocumentations, loading, documentationFoldersRegistry} = rootStore.documentationsStore
    const {currentTraffic} = rootStore.trafficsStore

    useNavbarSelectedItem('documentations')

    useEffect(() => {
        loadDocumentations(match.params.folderId)
    }, [
        loadDocumentations,
        match.params.folderId
    ])

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>

                    <BreadcrumbNavigation
                        items={[
                            {
                                text: 'Dokumentacija'
                            },
                            {
                                text: documentationFoldersRegistry.get(match.params.folderId)?.name ?? 'N/A',
                                active: true
                            }
                        ]}
                    />

                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/documentations/create?initialFolderId=${match.params.folderId}`}
                        floated='right'
                        color='blue'
                        icon
                        labelPosition='left'>
                        <Icon name='add'/>
                        Dodaj dokumentaciju
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment color='blue' loading={loading}>
                        <DocumentationsList key={match.params.folderId}/>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default observer(DocumentationsDashboard)
