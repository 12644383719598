import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, reaction, runInAction, toJS} from "mobx";
import {IProduct, Product} from "../models/products";
import agent from "../api/agent";
import {history} from "../../index";
import {toast} from "react-toastify";

export default class ProductsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.loadProducts()
            })
    }

    @observable productsRegistry = new Map<string, IProduct>()
    @observable loading = false
    @observable product: IProduct | null = null
    @observable submitting = false
    @observable deleting = false
    @observable deletingId: string | null = null
    @observable predicate = new Map()

    @computed get productsByNameArray () {
        return Array.from(this.productsRegistry.values()).sort((a, b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
    }


    @computed get axiosParams () {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @action setPredicate = (key: string, value: string) => {
        this.predicate.clear()
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }
    
    @action loadProductsToRegistry = (products: IProduct[]) => {
        this.productsRegistry.clear()
        products.forEach(product => {
            this.productsRegistry.set(product.id, product)
        })        
    }

    @action loadProducts = async () => {
        this.loading = true
        this.productsRegistry.clear()
        try {
            const products = await agent.Products.list(this.axiosParams)
            runInAction(() => {
                this.loadProductsToRegistry(products)
                products.forEach(pro => {
                    const selling = pro.prices.filter(pri => pri.isSelling)[0] ?? null
                    const defaultPrice = pro.prices.filter(pri => pri.isDefault)[0] ?? null
                    
                    if (!selling) {
                        console.log(`NEMA SELLING:  ${pro.brandName} ** ${pro.name} ** ${pro.sku}`)
                    }

                    if (defaultPrice) {
                        const discount = 100 - (selling!.value! / defaultPrice.value! * 100)
                        
                        if (discount !== 50) {
                            console.log(`RABAT nije 50 vec ${discount}  : ${pro.brandName} ** ${pro.name} ** ${pro.sku}`)
                        }
                    }
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false                
            })
        }
    }
    
    @action loadProduct = async (id: string) => {
        this.loading = true
        try {
            const product: any = await agent.Products.details(id)
            const pricelists: any = await this.rootStore.pricelistsStore.getPricelists()
            runInAction(() => {
                this.product = new Product(product, pricelists)
            })
            return toJS(this.product)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action createProduct = async (productFormData: IProduct) => {
        this.submitting = true
        try {
            await agent.Products.create(productFormData)
            const newProduct = await agent.Products.details(productFormData.id) 
            runInAction(() => {
                this.productsRegistry.set(newProduct.id, newProduct)
                // this.loadProduct(productFormData.id)                
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/products`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }   
    
    @action editProduct = async (productFormData: IProduct) => {
        this.submitting = true
        try {
            await agent.Products.edit(productFormData)
            const updatedProduct = await agent.Products.details(productFormData.id)
            runInAction(() => {
                this.productsRegistry.set(updatedProduct.id, updatedProduct)
                this.product = updatedProduct
            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/products`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action deleteProduct = async (id: string) => {
        this.deletingId = id
        this.deleting = true
        try {
            await agent.Products.delete(id)
            runInAction(() => {
                this.productsRegistry.delete(id)
            })
        } catch (error) {
            console.log(error)
            toast.error('Greška prilikom brisanja proizvoda')
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingId = null
            })
        }
    }
    
}