import React, {FunctionComponent, useContext, useEffect} from 'react';
import BillsList from "../../../bills/list/BillsList";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import {IBrand} from "../../../../core/models/brands";
import {formatRsd} from "../../../../core/common/util/format";
import {Header, List, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {billUsedBrandsWithValue} from "../../../../core/common/bills";
import {ONLY} from "../../../../core/common/filters/3sFilter";

interface IProps {
    clientId: string,
    year: string
}

const ClientDetailsGratis: FunctionComponent<IProps> = ({ clientId, year }) => {
    const rootStore = useContext(RootStoreContext)
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore
    const {currentTraffic} = rootStore.trafficsStore
    const { loadBills, setPredicate, clearPredicate, loading } = rootStore.billsStore
    
    useEffect(() => {
        clearPredicate()
        setPredicate('clientId', clientId)
        setPredicate('startDate', `${year}-01-01`)
        setPredicate('endDate', `${year}-12-31`)
        setPredicate('gratis', ONLY)
        loadBills()

        return () => {
            removeFromRegistry('clientDetailsGratis')
            clearPredicate()
        }
    }, [
        removeFromRegistry,
        setPredicate,
        clientId,
        year,
        loadBills,
        clearPredicate
    ])


    return (<Segment loading={loading}>

        <List divided verticalAlign='middle'>
            {billUsedBrandsWithValue(SHIT_getFilteredRowsById('clientDetailsGratis') ?? [])
                .map((brand: IBrand) => (<List.Item>
                    <List.Content floated='right'>
                        {formatRsd(brand.value!)}
                    </List.Content>
                    <List.Content>
                        <Link to={`/${currentTraffic?.id}/brands/${brand.id}`}><Header as='h3' content={brand.name}
                                                                                       style={{color: brand.color}}/></Link>
                    </List.Content>
                </List.Item>))}
        </List>
        <BillsList gratis='only' hideClient trackingId='clientDetailsGratis' />
    </Segment>)

};

export default observer(ClientDetailsGratis)
