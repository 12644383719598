import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {
    EmployeeGoal,
    EmployeeGoalStatus,
    EmployeeGoalType, IEmployeeGoalCategoryWithProperties,
    IEmployeeGoal,
    IEmployeeGoalComment,
    IEmployeeGoalDocument,
    IEmployeeGoalDocumentFormValues,
    IEmployeeGoalFormValues,
} from "../models/employeeGoals";
import agent from "../api/agent";
import {IUser} from "../models/user";
import _ from 'lodash'
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {SemanticCOLORS} from "semantic-ui-react";
import {toast} from "react-toastify";
import {v4 as uuid} from 'uuid'


export default class EmployeeGoalsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable employeeGoal: IEmployeeGoal | null = null
    @observable employeeGoalsRegistry = new Map<string, EmployeeGoal>()
    @observable lastRequestId = ''
    @observable loading = false
    @observable submitting = false
    @observable.ref hubConnection: HubConnection | null = null;
    @observable hubConnectionStatusColor: SemanticCOLORS | undefined = undefined
    @observable editingCommentId: string | null = null
    @observable deletingCommentId: string | null = null
    @observable metingId: string | null = null
    @observable unmetingId: string | null = null
    @observable predicate = new Map<string, string>()
    @observable employeeGoalsCategories: IEmployeeGoalCategoryWithProperties[] | null = null
    @observable loadingEmployeeGoalsCategories = false
    @observable documentDeletingId = ''
    @observable downloadingDocumentId = ''
    @observable submittingDocument = false
    @observable firstFilter: 'all' | 'current' | 'met' | 'failed' = 'current'
    @observable secondFilter: 'currentAll' | 'currentBasic' | 'currentPartlyMet' | 'currentExpired' = 'currentAll'

    @action setHubConnectionStatusColor = (color: SemanticCOLORS | undefined) => this.hubConnectionStatusColor = color

    @computed get axiosParams() {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get employeeGoalsArray() {
        return Array.from(this.employeeGoalsRegistry.values())
            .sort((a, b) => {
                // kod aktuelnih se uzima u obzir date a kod ostalih completed date
                // sortiranje ide: bezDatuma, stariji datum, noviji datum
                const aDate = a.status === EmployeeGoalStatus.inProgress
                    ? a.date
                    : a.completedDate
                const bDate = b.status === EmployeeGoalStatus.inProgress
                    ? b.date
                    : b.completedDate

                if (aDate === bDate) {
                    const currentUserCoefDiff =
                        (a.participants.filter(p => p.user.id === this.rootStore.userStore.user?.id)[0] ? 1 : 0) -
                        (b.participants.filter(p => p.user.id === this.rootStore.userStore.user?.id)[0] ? 1 : 0)

                    if (currentUserCoefDiff === 0) {
                        const colorCoefDiff =
                            (a.color === 'red' ? 2 : a.color === 'orange' ? 1 : 0) -
                            (b.color === 'red' ? 2 : a.color === 'orange' ? 1 : 0)

                        if (colorCoefDiff < 0) {
                            return 1
                        } else {
                            return -1
                        }

                    } else if (currentUserCoefDiff < 0) {
                        return 1
                    } else {
                        return -1
                    }
                }

                return 0
            })
    }

    @computed get employeeGoalsFilteredClassic() {
        return this.employeeGoalsArray.filter(x => x.type === EmployeeGoalType.classic)
    }

    @computed get employeeGoalsFilteredProblem() {
        return this.employeeGoalsArray.filter(x => x.type === EmployeeGoalType.problem)
    }

    @computed get employeeGoalsFilteredPrivate() {
        return this.employeeGoalsArray.filter(x => x.type === EmployeeGoalType.private)
    }

    @computed get sortedComments() {
        if (!this.employeeGoal) return []

        return this.employeeGoal.comments.slice().reverse()
    }

    categoryNamesFilterByProperties = (type: EmployeeGoalType, status?: EmployeeGoalStatus) => Array.from(new Set((this.employeeGoalsCategories ?? [])
        .filter(category => {
            if (category.type !== type) {
                return false
            }

            if (status !== undefined && status !== category.status) {
                return false
            }

            return true
        })
        .map(cat => cat.name)))


    startHubConnection = async () => {
        if (this.hubConnection?.state !== 'Connected') {
            this.hubConnection!
                .start()
                .then(() => {
                    console.log(this.hubConnection!.state)
                    this.setHubConnectionStatusColor('green')
                    // toast.success('Hub uspešno konektovan', { autoClose: 1000 })
                })
                .catch((error) => {
                    console.log('Error establishing connection: ', error)
                    this.setHubConnectionStatusColor('red')
                    // toast.error('Hub neuspešno konektovan', { autoClose: 1000 })
                    setTimeout(() => {
                        // toast.warning('Pokušaj rekonekcije na hub', { autoClose: 1000 })
                        // this.setHubConnectionStatusColor('yellow')
                        this.startHubConnection(/*employeeGoalId*/)
                    }, 5000);
                })
        }
    }

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @action setFirstFilter = (filter: 'all' | 'current' | 'met' | 'failed') => {
        this.firstFilter = filter
        // this.clearPredicate()
        if (filter === 'current') {
            this.setPredicate(this.secondFilter, 'true')
        } else if (filter !== 'all') {
            this.setPredicate(filter, 'true')
        }
    }

    @action setSecondFilter = (filter: 'currentAll' | 'currentBasic' | 'currentPartlyMet' | 'currentExpired') => {
        this.secondFilter = filter
        // this.clearPredicate()
        this.setPredicate(filter, 'true')
    }

    @action setPredicateFromFilter = (commit?: boolean) => {
        if (this.firstFilter === 'current') {
            this.setPredicate(this.secondFilter, 'true')
        } else {
            this.setPredicate(this.firstFilter, 'true')
        }

        if (commit) {
            this.loadEmployeeGoals()
        }
    }

    @action setEditingCommentId = (id: string | null) => this.editingCommentId = id

    @action createHubConnection = (/*employeeGoalId: string*/) => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_EMPLOYEE_GOAL_CHAT_URL!, {
                accessTokenFactory: () => this.rootStore.commonStore.token!
            })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection?.onclose((error) => {
            this.setHubConnectionStatusColor('red')
            // toast.error('Konekcija sa Hub-om izgubljena', { autoClose: 1000 })
            if (error) {
                setTimeout(() => {
                    // this.setHubConnectionStatusColor('yellow')
                    this.startHubConnection(/*employeeGoalId*/)
                }, 5000)
            } else {
                this.setHubConnectionStatusColor(undefined)
            }
        })

        this.startHubConnection()

        this.hubConnection.onreconnected(() => {
            this.setHubConnectionStatusColor('green')
            console.log('Reconnected')
        })

        this.hubConnection.onreconnecting(() => {
            // this.setHubConnectionStatusColor('yellow')
            console.log('Reconnecting')
        })

        this.hubConnection.on('ReceiveEmployeeGoalComment', (employeeGoalComment: IEmployeeGoalComment) => {
            runInAction(() => {
                this.employeeGoal?.comments.push(employeeGoalComment)

                const employeeGoalFormRegistry = this.employeeGoalsRegistry.get(employeeGoalComment.employeeGoalId) ?? null


                if (employeeGoalFormRegistry) {
                    this.employeeGoalsRegistry.set(employeeGoalComment.employeeGoalId, {
                        ...employeeGoalFormRegistry,
                        comments: [
                            ...employeeGoalFormRegistry.comments,
                            employeeGoalComment
                        ]
                    })
                }
            })
        })

        this.hubConnection.on('ReceiveUpdatedEmployeeGoalComment', (employeeGoalComment: IEmployeeGoalComment) => {
            runInAction(() => {
                if (this.employeeGoal) {
                    this.employeeGoal.comments = this.employeeGoal?.comments.map(comment => comment.id === employeeGoalComment.id ? employeeGoalComment : comment)
                }

                const employeeGoalFormRegistry = this.employeeGoalsRegistry.get(employeeGoalComment.employeeGoalId) ?? null

                if (employeeGoalFormRegistry) {
                    this.employeeGoalsRegistry.set(employeeGoalComment.employeeGoalId, {
                        ...employeeGoalFormRegistry,
                        comments: employeeGoalFormRegistry.comments.map(comment => comment.id === employeeGoalComment.id ? employeeGoalComment : comment)
                    })
                }
            })
        })

        this.hubConnection.on('ReceiveDeletedEmployeeGoalComment', (payload: { employeeGoalId: string, commentId: string }) => {
            runInAction(() => {
                if (this.employeeGoal) {
                    this.employeeGoal.comments = this.employeeGoal?.comments.filter(comment => comment.id !== payload.commentId)
                }

                const employeeGoalFromRegistry = this.employeeGoalsRegistry.get(payload.employeeGoalId) ?? null
                if (employeeGoalFromRegistry) {
                    this.employeeGoalsRegistry.set(payload.employeeGoalId,
                        {
                            ...employeeGoalFromRegistry,
                            comments: employeeGoalFromRegistry.comments.filter(comment => comment.id !== payload.commentId)
                        })
                }
            })
        })
    }

    @action stopHubConnection = () => {
        if (this.hubConnection) {
            this.hubConnection!.stop()
                .then(() => console.log('Connection stopped'))
                .catch((error) => console.log(error))
        }
    }

    getCommentators = (employeeGoal: IEmployeeGoal): IUser[] => {
        if (!employeeGoal) {
            return []
        }

        return _.uniqBy(employeeGoal.comments.map(comment => comment.user), user => user.id)
    }

    @action loadEmployeeGoal = async (id: string) => {
        this.loading = true
        const employeeGoal = await agent.EmployeeGoals.details(id)
        try {
            runInAction(() => {
                this.employeeGoal = employeeGoal
            })
            return employeeGoal
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action loadEmployeeGoals = async () => {
        const requestId = uuid()
        this.lastRequestId = requestId
        this.loading = true
        try {
            const employeeGoals = await agent.EmployeeGoals.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.employeeGoalsRegistry.clear()
                    this.loadEmployeeGoalsToRegistry(employeeGoals)
                }
            })
        } catch (error) {
            console.log(error)

            const type: EmployeeGoalType = this.predicate.get('type') ? Number(this.predicate.get('type')) : EmployeeGoalType.classic
            const errorMessage = type === EmployeeGoalType.problem
                ? 'Greška prilikom pribavljanja uočenih problema'
                : 'Greška prilikom pribavljanja tekućih obaveza'
            toast.error(errorMessage)
            this.employeeGoalsRegistry.clear()

        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loading = false
                }
            })
        }
    }

    @action createEmployeeGoal = async (employeeGoalFormValues: IEmployeeGoalFormValues) => {
        this.submitting = true
        try {
            await agent.EmployeeGoals.create(employeeGoalFormValues)
            runInAction(() => {
                // this.employeeGoalsRegistry.set(newEmployeeGoal.id, new EmployeeGoalWithFlags(newEmployeeGoal))
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action setEmployeeGoalFlagIfGoalIsInRegistry = (flag: 'updating' | 'uploading' | 'deleting', id: string, value: boolean) => {
        const employeeGoalFromRegistry = this.employeeGoalsRegistry.get(id)
        if (employeeGoalFromRegistry) {

            switch (flag) {
                case 'updating':
                    this.employeeGoalsRegistry.set(id, {
                        ...employeeGoalFromRegistry,
                        flags: {
                            ...employeeGoalFromRegistry.flags!,
                            updating: value
                        }
                    })
                    break
                case 'uploading':
                    this.employeeGoalsRegistry.set(id, {
                        ...employeeGoalFromRegistry,
                        flags: {
                            ...employeeGoalFromRegistry.flags!,
                            uploading: value
                        }

                    })
                    break
                case 'deleting':
                    this.employeeGoalsRegistry.set(id, {
                        ...employeeGoalFromRegistry,
                        flags: {
                            ...employeeGoalFromRegistry.flags!,
                            deleting: value
                        }

                    })
                    break
            }
        }
    }

    @action setEmployeeGoalFlagIfCurrent = (flag: 'updating' | 'uploading' | 'deleting', id: string, value: boolean) => {
        if (this.employeeGoal?.id === id) {

            switch (flag) {
                case 'updating':
                    this.employeeGoal = {
                        ...this.employeeGoal,
                        flags: {
                            ...this.employeeGoal.flags!,
                            updating: value
                        }
                    }
                    break
                case 'uploading':
                    this.employeeGoal = {
                        ...this.employeeGoal,
                        flags: {
                            ...this.employeeGoal.flags!,
                            updating: value
                        }
                    }
                    break
                case 'deleting':
                    this.employeeGoal = {
                        ...this.employeeGoal,
                        flags: {
                            ...this.employeeGoal.flags!,
                            deleting: value
                        }
                    }
                    break
            }
        }
    }

    @action setFlagIfCurrentAndIfInRegistry = (flag: 'updating' | 'uploading' | 'deleting', id: string, value: boolean) => {
        this.setEmployeeGoalFlagIfCurrent(flag, id, value)
        this.setEmployeeGoalFlagIfGoalIsInRegistry(flag, id, value)
    }

    @action editEmployeeGoal = async (employeeGoalFormValues: IEmployeeGoalFormValues) => {
        this.submitting = true
        this.setFlagIfCurrentAndIfInRegistry('updating', employeeGoalFormValues.id, true)
        try {
            await agent.EmployeeGoals.edit(employeeGoalFormValues)
            const updatedEmployeeGoal = await agent.EmployeeGoals.details(employeeGoalFormValues.id)
            runInAction(() => {
                this.employeeGoal = updatedEmployeeGoal
                this.employeeGoalsRegistry.set(updatedEmployeeGoal.id, updatedEmployeeGoal)
                // history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/employeeGoals/${updatedEmployeeGoal.id}`) // cant do this here because use edit function in client goals list to update participants
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
                this.setFlagIfCurrentAndIfInRegistry('updating', employeeGoalFormValues.id, false)
            })
        }
    }

    @action deleteEmployeeGoal = async (id: string) => {
        this.setFlagIfCurrentAndIfInRegistry('deleting', id, true)
        try {
            await agent.EmployeeGoals.delete(id)
            runInAction(() => {
                this.employeeGoalsRegistry.delete(id)
                if (this.employeeGoal?.id === id) {
                    this.employeeGoal = null
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.setFlagIfCurrentAndIfInRegistry('deleting', id, false)
            })
        }
    }

    @action addComment = async (id: string, values: any) => {
        values.employeeGoalId = id
        try {
            await this.hubConnection!.invoke('SendComment', values)
        } catch (error) {
            console.log(error)
        }
    }

    @action editComment = async (employeeGoalId: string, commentId: string, values: any) => {
        values.employeeGoalId = employeeGoalId
        values.id = commentId
        try {
            await this.hubConnection!.invoke('EditComment', values)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingCommentId = null
            })
        }
    }

    @action deleteComment = async (id: string) => {
        const request = {
            id
        }
        try {
            await this.hubConnection!.invoke('DeleteComment', request)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingCommentId = null
            })
        }
    }

    @action loadEmployeeGoalsToRegistry = (goals: IEmployeeGoal[]) => {
        this.employeeGoalsRegistry.clear()
        goals.forEach(goal => {
            this.employeeGoalsRegistry.set(goal.id, new EmployeeGoal(goal))
        })
    }

    @action setEmployeeGoalStatus = async (metFormValues: { id: string, status: EmployeeGoalStatus, statusNote: string }) => {
        this.metingId = metFormValues.id
        try {
            await agent.EmployeeGoals.setStatus(metFormValues)
            runInAction(() => {
                if (this.employeeGoal && this.employeeGoal.id === metFormValues.id) {
                    this.employeeGoal.status = metFormValues.status
                    this.employeeGoal.statusNote = (metFormValues.status === EmployeeGoalStatus.partlyMet || metFormValues.status === EmployeeGoalStatus.failed) ?
                        metFormValues.statusNote : ''
                }
                const goalInRegistry = this.employeeGoalsRegistry.get(metFormValues.id) ?? null
                if (goalInRegistry) {
                    this.employeeGoalsRegistry.set(metFormValues.id, {
                        ...goalInRegistry,
                        status: metFormValues.status,
                        statusNote: (metFormValues.status === EmployeeGoalStatus.partlyMet || metFormValues.status === EmployeeGoalStatus.failed) ?
                            metFormValues.statusNote : ''
                    })
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Greška prilikom ažuriranja statusa')
        } finally {
            runInAction(() => {
                this.metingId = null
            })
        }
    }

    @action loadEmployeeGoalsCategories = async (local: boolean = false) => {
        this.loadingEmployeeGoalsCategories = true
        try {
            let categories = (!local || !this.employeeGoalsCategories) ?
                await agent.EmployeeGoals.categories() :
                this.employeeGoalsCategories

            runInAction(() => {
                this.employeeGoalsCategories = categories
            })

            return categories
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingEmployeeGoalsCategories = false
            })
        }
    }

    @action updateDate = async (id: string, dateType: 'completed' | 'planed', date: string | null) => {
        this.setFlagIfCurrentAndIfInRegistry('updating', id, true)
        try {
            if (dateType === 'completed') {
                await agent.EmployeeGoals.updateCompletedDate(id, date)
            } else {
                await agent.EmployeeGoals.updatePlanedDate(id, date)
            }
            runInAction(() => {
                if (this.employeeGoal?.id === id) {
                    if (dateType === 'completed') {
                        this.employeeGoal.completedDate = date ? new Date(date) : null
                    } else {
                        this.employeeGoal.date = date ? new Date(date) : null
                    }
                }
                let employeeGoalInRegistry = this.employeeGoalsRegistry.get(id)
                if (employeeGoalInRegistry) {
                    if (dateType === 'completed') {
                        this.employeeGoalsRegistry.set(id, {
                            ...employeeGoalInRegistry,
                            completedDate: date ? new Date(date) : null
                        })
                    } else {
                        this.employeeGoalsRegistry.set(id, {
                            ...employeeGoalInRegistry,
                            date: date ? new Date(date) : null
                        })
                    }
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.setFlagIfCurrentAndIfInRegistry('updating', id, false)
            })
        }
    }

    @action addDocument = async (employeeGoalDocumentFormValues: IEmployeeGoalDocumentFormValues) => {
        this.submittingDocument = true
        this.setFlagIfCurrentAndIfInRegistry('uploading', employeeGoalDocumentFormValues.employeeGoalId, true)
        try {
            await agent.EmployeeGoalDocuments.create(employeeGoalDocumentFormValues)
            runInAction(() => {
                    const newEmployeeGoalDocument: IEmployeeGoalDocument = {
                        id: employeeGoalDocumentFormValues.id,
                        employeeGoalId: employeeGoalDocumentFormValues.employeeGoalId,
                        name: employeeGoalDocumentFormValues.name
                    }
                    if (this.employeeGoal) {
                        this.employeeGoal.documents.push(newEmployeeGoalDocument)
                    }

                    const employeeGoalFromRegistry = this.employeeGoalsRegistry.get(employeeGoalDocumentFormValues.employeeGoalId) ?? null
                    if (employeeGoalFromRegistry) {
                        this.employeeGoalsRegistry.set(employeeGoalDocumentFormValues.employeeGoalId,
                            {
                                ...employeeGoalFromRegistry,
                                documents: [
                                    ...employeeGoalFromRegistry.documents,
                                    newEmployeeGoalDocument
                                ]
                            })
                    }
                }
            )
        } catch
            (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submittingDocument = false
                this.setFlagIfCurrentAndIfInRegistry('uploading', employeeGoalDocumentFormValues.employeeGoalId, false)
            })
        }
    }

    @action getDocument = async (id: string) => {
        this.downloadingDocumentId = id
        try {
            const file = await agent.EmployeeGoalDocuments.getFile(id)
            window.open(URL.createObjectURL(file))
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.downloadingDocumentId = ''
            })
        }
    }

    @action deleteDocument = async (employeeGoalDocument: IEmployeeGoalDocument) => {
        this.documentDeletingId = employeeGoalDocument.id
        try {
            await agent.EmployeeGoalDocuments.delete(employeeGoalDocument.id)
            runInAction(() => {
                if (this.employeeGoal) {
                    this.employeeGoal.documents = this.employeeGoal.documents
                        .filter(document => document.id !== employeeGoalDocument.id)
                }

                const employeeGoal = this.employeeGoalsRegistry.get(employeeGoalDocument.employeeGoalId)
                if (employeeGoal) {
                    this.employeeGoalsRegistry.set(employeeGoalDocument.employeeGoalId, {
                        ...employeeGoal,
                        documents: employeeGoal.documents.filter(x => x.id !== employeeGoalDocument.id)
                    })
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.documentDeletingId = ''
            })
        }
    }

}
