import React, {useContext, useEffect} from 'react';
import {Grid} from "semantic-ui-react";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {RouteComponentProps} from "react-router";
import LoadingComponent from "../../../core/layout/LoadingComponent";
import {observer} from "mobx-react-lite";
import DocumentationDetailsHeader from "./DocumentationDetailsHeader";
import DocumentsDetailsDocuments from "./DocumentsDetailsDocuments";
import DocumentationDetailsDescription from "./DocumentationDetailsDescription";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {toJS} from "mobx";

interface DetailParams {
    id: string
}




const DocumentationDetails: React.FC<RouteComponentProps<DetailParams>> = ({
                                                                               match,
                                                                               history
                                                                           }) => {

    const rootStore = useContext(RootStoreContext)
    const {loadDocumentation, loading, documentation, setAddingDocumentMode} = rootStore.documentationsStore
    const {currentTraffic} = rootStore.trafficsStore

    useEffect(() => {
        setAddingDocumentMode(false)
        loadDocumentation(match.params.id)
    }, [
        loadDocumentation,
        match.params.id,
        setAddingDocumentMode
    ])

    if (loading) return <LoadingComponent content='Učitavanje dokumentacije...'/>;

    if (!documentation) return <h2>Dokumentacija nije pornadjen</h2>

    return (<Grid>
        <Grid.Column width={16}>
            <BreadcrumbNavigation 
                items={[
                    {
                        text: 'Dokumentacija'
                    },
                    {
                        text: documentation.folder.name,
                        linkWithoutCurrentTrafficId: `documentationFolder/${documentation.folder.id}`
                    },
                    {
                        text: documentation.name,
                        active: true
                    }
                ]}
            />
        </Grid.Column>
        <Grid.Column computer={10} tablet={10} mobile={16}>
            <DocumentationDetailsHeader documentation={documentation} currentTraffic={currentTraffic!} />
            <DocumentationDetailsDescription documentation={documentation} />
        </Grid.Column>
        <Grid.Column computer={6} tablet={6} mobile={16}>
            <DocumentsDetailsDocuments documents={toJS(documentation.documents)} />
        </Grid.Column>
    </Grid>);
};

export default observer(DocumentationDetails)
