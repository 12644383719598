import React, {FunctionComponent, useContext, useEffect} from 'react';
import {RootStoreContext} from "../../../core/stores/rootStore";
import BrandsList from "../lists/BrandsList";
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface IProps {
}

const BrandsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loadBrands, loading} = rootStore.brandsStore
    const {currentTraffic} = rootStore.trafficsStore

    useNavbarSelectedItem('brands')

    useEffect(() => {
        loadBrands()

    }, [
        loadBrands,
    ])

    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Brendovi',
                            active: true
                        }
                    ]}
                />

                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/brands/create`}
                    floated='right'
                    color='blue'
                    icon
                    labelPosition='left'>
                    <Icon name='add'/>
                    Dodaj brend
                </Button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Segment color='blue' loading={loading}>
                    <BrandsList/>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>)
}

export default observer(BrandsDashboard)
