import {RootStore} from "./rootStore";
import {action, makeObservable, observable, reaction, runInAction} from "mobx";

export default class CommonStore {
    rootStore: RootStore
    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
        
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token)                    
                } else {
                    window.localStorage.removeItem('jwt')
                }

            }
        )
    }
    
    @observable token: string | null = window.localStorage.getItem('jwt')
    @observable appLoaded = false
    
    @action setToken = (token: string | null) => {
        window.localStorage.setItem('jwt', token!)
        this.token = token
    }
    
    @action setAppLoaded = () => {
        this.appLoaded = true
    }
    
    @action initApp = async (trafficId?: number) => {
        this.appLoaded = false
        try {
            await Promise.all([
                this.rootStore.trafficsStore.loadTraffics(),
                this.rootStore.globalSettingsStore.loadGlobalSettings()
            ])
            
            document.title = this.rootStore.globalSettingsStore.siteName
            
            if (trafficId) {
                await this.rootStore.trafficsStore.setCurrentTraffic(trafficId)  
            } else {
                await this.rootStore.trafficsStore.setCurrentToDefault()                
            }            
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                // this.appLoaded = false
            })
        }
    }
    
}