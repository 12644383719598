import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {
    BillOrderAnalyzeEntity,
    IBillOrderAnalyzeEntity,
    IPeriodAnalyzeEntity,
    MONTH_YEAR_FORMAT,
    YEAR_FORMAT
} from "../models/billOrders";
import {IGeneralExpense} from "../models/generalExpenses";
import {IProductConsumptionItem} from "../models/productConsumptions";
import {IClientActivity} from "../models/clientActivities";
import agent from "../api/agent";
import {groupBy} from "../common/util/array";
import {IExpenseEntity} from "../models/expenses";
import moment from "moment";
import {v4 as uuid} from 'uuid'
import {getNiceColorFromStingAsciiSum} from "../common/util/colors";
import {calculatePercentage} from "../common/util/myMath";


// Helpers

const groupToAnalyseEntityArray = (array: any[]): IBillOrderAnalyzeEntity[] => array
    .map(p => p[1]).map((group: IBillOrderAnalyzeEntity[]) =>
        group.reduce((previousValue: IBillOrderAnalyzeEntity, currentValue, index) => {
                if (index > 0) {
                    return {
                        ...previousValue,
                        summaryBasePriceInRsd: previousValue.summaryBasePriceInRsd + currentValue.summaryBasePriceInRsd,
                        count: previousValue.count + currentValue.count,
                        summaryPriceWithoutDiscount: previousValue.summaryPriceWithoutDiscount + currentValue.summaryPriceWithoutDiscount,
                        summaryPriceWithDiscount: previousValue.summaryPriceWithDiscount + currentValue.summaryPriceWithDiscount,
                        summaryPriceWithDiscountWithBillDiscount: previousValue.summaryPriceWithDiscountWithBillDiscount + currentValue.summaryPriceWithDiscountWithBillDiscount,
                        differenceInPrice: previousValue.differenceInPrice + currentValue.differenceInPrice,
                    } as IBillOrderAnalyzeEntity
                } else {
                    return previousValue as IBillOrderAnalyzeEntity
                }
            },
            {
                clientCategoryColor: getNiceColorFromStingAsciiSum(group[0].client.category ?? 'a6s1da6s'),
                clientCategory: group[0].client.category,
                brand: {
                    color: (group[0].product.brandColor && group[0].product.brandColor.length > 0) ? group[0].product.brandColor : getNiceColorFromStingAsciiSum(group[0].product.brandName),
                    name: group[0].product.brandName,
                    id: group[0].product.brandId
                },
                billDate: group[0].billDate,
                client: group[0].client,
                product: group[0].product,
                yearString: moment(group[0].billDate).format(YEAR_FORMAT),
                monthYearString: moment(group[0].billDate).format(MONTH_YEAR_FORMAT),
                summaryBasePriceInRsd: group[0].summaryBasePriceInRsd,
                count: group[0].count,
                summaryPriceWithoutDiscount: group[0].summaryPriceWithoutDiscount,
                summaryPriceWithDiscount: group[0].summaryPriceWithDiscount,
                summaryPriceWithDiscountWithBillDiscount: group[0].summaryPriceWithDiscountWithBillDiscount,
                differenceInPrice: group[0].differenceInPrice,
            } as IBillOrderAnalyzeEntity
        ))

export default class ProfitabilityStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable gratisOrdersRegistry = new Map<string, IBillOrderAnalyzeEntity>()
    @observable generalExpensesRegistry = new Map<string, IGeneralExpense>()
    @observable productConsumptionsItemsRegistry = new Map<string, IProductConsumptionItem>()
    @observable clientActivitiesRegistry = new Map<string, IClientActivity>()
    @observable expenseTrafficsBillOrdersRegistry = new Map<string, IBillOrderAnalyzeEntity>()
    @observable incomeTrafficsBillOrdersRegistry = new Map<string, IBillOrderAnalyzeEntity>()
    @observable incomeManualBillsAsBillOrderDetailedRegistry = new Map<string, IBillOrderAnalyzeEntity>()
    @observable manualGratisAsBillOrderDetailedRegistry = new Map<string, IBillOrderAnalyzeEntity>()
    @observable manualBillsExpenseTrafficsAsBillOrderDetailedRegistry = new Map<string, IBillOrderAnalyzeEntity>()

    @observable lastRequestId = uuid()

    @observable allLoading = false

    @observable commonPredicate = new Map<string, string>()
    @observable gratisOrdersPredicate = new Map<string, string>()
    @observable generalExpensesPredicate = new Map<string, string>()
    @observable productConsumptionsItemsPredicate = new Map<string, string>()
    @observable clientActivitiesPredicate = new Map<string, string>()
    @observable expenseTrafficsBillOrdersPredicate = new Map<string, string>()
    @observable incomeTrafficsBillOrdersPredicate = new Map<string, string>()
    @observable incomeManualBillsAsBillOrderDetailedPredicate = new Map<string, string>()
    @observable manualGratisAsBillOrderDetailedPredicate = new Map<string, string>()
    @observable manualBillsExpenseTrafficsAsBillOrderDetailedPredicate = new Map<string, string>()

    @observable incomeView: 'brand' | 'clientCategory' = 'brand'
    @observable startDateFilter = ''
    @observable endDateFilter = ''
    @observable manualBillsFilter = true
    @observable trafficFilter: number[] = []

    @observable showDiagramsExtensions = false // Todo ovo mozda staviti da se cuva u LocalStorage

    @computed get commonAxiosParams() {
        const params = new URLSearchParams()
        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get gratisOrdersAxiosParams() {
        const params = new URLSearchParams()

        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        params.append('gratis', 'only')
        this.gratisOrdersPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        if (this.trafficFilter.length > 0) {
            this.trafficFilter.forEach(traffic => {
                params.append('byTraffic', traffic.toString())
            })
        }

        return params
    }

    @computed get generalExpensesAxiosParams() {
        const params = new URLSearchParams()

        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        this.generalExpensesPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get productConsumptionsAxiosParams() {
        const params = new URLSearchParams()

        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        this.productConsumptionsItemsPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get clientActivitiesAxiosParams() {
        const params = new URLSearchParams()

        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        this.clientActivitiesPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get expenseTrafficsBillOrdersAxiosParams() {
        const params = new URLSearchParams()

        params.append('trafficExpense', 'only')
        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        this.expenseTrafficsBillOrdersPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        if (this.trafficFilter.length > 0) {
            this.trafficFilter.forEach(traffic => {
                params.append('byTraffic', traffic.toString())
            })
        }

        return params
    }

    @computed get incomeTrafficsBillOrdersAxiosParams() {
        const params = new URLSearchParams()

        params.append('manual', 'excluded')
        params.append('trafficExpense', 'excluded')
        params.append('gratis', 'excluded')
        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        this.incomeTrafficsBillOrdersPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        if (this.trafficFilter.length > 0) {
            this.trafficFilter.forEach(traffic => {
                params.append('byTraffic', traffic.toString())
            })
        }

        return params
    }

    @computed get incomeManualBillsAxiosParams() {
        const params = new URLSearchParams()

        params.append('manual', 'only')
        params.append('expenseTraffics', 'excluded')
        params.append('gratis', 'excluded')

        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        this.incomeManualBillsAsBillOrderDetailedPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        if (this.trafficFilter.length > 0) {
            this.trafficFilter.forEach(traffic => {
                params.append('byTraffic', traffic.toString())
            })
        }

        return params
    }

    @computed get manualGratisAxiosParams() {
        const params = new URLSearchParams()

        params.append('manual', 'only')
        params.append('gratis', 'only')

        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        this.manualGratisAsBillOrderDetailedPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        if (this.trafficFilter.length > 0) {
            this.trafficFilter.forEach(traffic => {
                params.append('byTraffic', traffic.toString())
            })
        }

        return params
    }

    @computed get manualBillsExpenseTrafficsAxiosParams() {
        const params = new URLSearchParams()

        params.append('manual', 'only')
        params.append('expenseTraffics', 'only')
        params.append('gratis', 'excluded')

        this.commonPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        this.manualBillsExpenseTrafficsAsBillOrderDetailedPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        if (this.trafficFilter.length > 0) {
            this.trafficFilter.forEach(traffic => {
                params.append('byTraffic', traffic.toString())
            })
        }

        return params
    }

    @computed get gratisOrdersArray() {
        let array = Array.from(this.gratisOrdersRegistry.values())

        if (this.manualBillsFilter) {
            array = [
                ...array,
                ...Array.from(this.manualGratisAsBillOrderDetailedRegistry.values())
            ]
        }

        return array
    }

    @computed get generalExpensesArray() {
        return Array.from(this.generalExpensesRegistry.values())
    }

    @computed get productConsumptionsItemsArray() {
        return Array.from(this.productConsumptionsItemsRegistry.values())
    }

    @computed get clientActivitiesArray() {
        return Array.from(this.clientActivitiesRegistry.values())
    }

    @computed get expenseTrafficsBillOrdersArray() {
        let array = Array.from(this.expenseTrafficsBillOrdersRegistry.values())

        if (this.manualBillsFilter) {
            array = [
                ...array,
                ...Array.from(this.manualBillsExpenseTrafficsAsBillOrderDetailedRegistry.values())
            ]
        }

        return array
    }

    @computed get incomeTrafficsBillOrdersArray() {
        let array = Array.from(this.incomeTrafficsBillOrdersRegistry.values())

        if (this.manualBillsFilter) {
            array = [...array,
                ...Array.from(this.incomeManualBillsAsBillOrderDetailedRegistry.values())]
        }

        return array
    }

    @computed get integratedGratisExpenses() {
        return this.gratisOrdersArray.reduce((total: IExpenseEntity, current) => ({
                ...total,
                cost: total.cost + current.summaryBasePriceInRsd
            }),
            {
                cost: 0,
                name: 'Gratisi',
                color: getNiceColorFromStingAsciiSum('Gratisi')
            } as IExpenseEntity)
    }

    @computed get integratedGeneralExpenses() {
        return this.generalExpensesArray.reduce((total: IExpenseEntity, current) => ({
                ...total,
                cost: total.cost + current.value
            }),
            {
                cost: 0,
                name: 'Opšti troškovi',
                color: getNiceColorFromStingAsciiSum('Opšti troškovi')
            } as IExpenseEntity)
    }

    @computed get integratedProductConsumptionsExpenses() {
        return this.productConsumptionsItemsArray.reduce((total: IExpenseEntity, current) => ({
                ...total,
                cost: total.cost + current.summaryRsdValue
            }),
            {
                cost: 0,
                name: 'Troškovi rashoda proizvoda',
                color: getNiceColorFromStingAsciiSum('Troškovi rashoda proizvoda')
            } as IExpenseEntity)
    }

    @computed get integratedClientActivitiesExpenses() {
        return this.clientActivitiesArray.reduce((total: IExpenseEntity, current) => ({
                ...total,
                cost: total.cost + current.cost
            }),
            {
                cost: 0,
                name: 'Aktivnosti',
                color: getNiceColorFromStingAsciiSum('Aktivnosti')
            } as IExpenseEntity)
    }

    @computed get integratedExpensesTraffics() {
        return this.expenseTrafficsBillOrdersArray.reduce((total: IExpenseEntity, current) => ({
                ...total,
                cost: total.cost + current.summaryBasePriceInRsd
            }),
            {
                cost: 0,
                name: 'Trošak prometi',
                color: getNiceColorFromStingAsciiSum('Trošak prometi')
            } as IExpenseEntity)
    }

    @computed get summaryBaseValueOfIncomeBillOrders() {
        return this.incomeTrafficsBillOrdersArray.reduce((total: IExpenseEntity, current) => ({
            ...total, cost: total.cost + current.summaryBasePriceInRsd
        }), {
            cost: 0,
            name: 'Nabavna vrednost',
            color: getNiceColorFromStingAsciiSum('Nabavna vrednost')
        } as IExpenseEntity)
    }

    @computed get integratedAllExpenses() {
        return [
            this.summaryBaseValueOfIncomeBillOrders,
            this.integratedGratisExpenses,
            this.integratedGeneralExpenses,
            this.integratedProductConsumptionsExpenses,
            this.integratedClientActivitiesExpenses,
            this.integratedExpensesTraffics
        ]
    }

    @computed get gratisOrdersGroupByBrand() {
        return groupToAnalyseEntityArray(
            groupBy(this.gratisOrdersArray, (g: IBillOrderAnalyzeEntity) => g.product.brandId)
        )

    }

    @computed get incomeTrafficsGroupByClientCategory() {
        return groupToAnalyseEntityArray(
            groupBy(this.incomeTrafficsBillOrdersArray, g => g.client.category)
        )
    }

    @computed get incomeTrafficsGroupByBrand() {
        return groupToAnalyseEntityArray(
            groupBy(this.incomeTrafficsBillOrdersArray, (g: IBillOrderAnalyzeEntity) => g.product.brandId)
        )
    }

    @computed get incomeTrafficsGroupByMonthsAndClientCategory() {
        return groupToAnalyseEntityArray(
            groupBy(this.incomeTrafficsBillOrdersArray, g => `${moment(g.billDate).format(MONTH_YEAR_FORMAT)}-${g.client.category}`)
        )
    }

    // @computed get incomeTrafficsGroupByYearsAndClientCategory() {
    //     return groupToAnalyseEntityArray(
    //         groupBy(this.incomeTrafficsBillOrdersArray, g => `${moment(g.billDate).format(YEAR_FORMAT)}-${g.client.category}`)
    //     )
    // }

    @computed get gratisExpenseByMonth() {
        return groupBy(this.gratisOrdersArray, el => moment(el.billDate).format(MONTH_YEAR_FORMAT))
            .map(group => ({
                monthYearString: group[0],
                value: group[1].reduce((total: number, current: IBillOrderAnalyzeEntity) => total + current.summaryBasePriceInRsd, 0)
            }))
    }

    @computed get generalExpensesByMonth() {
        return groupBy(this.generalExpensesArray, el => moment(el.date).format(MONTH_YEAR_FORMAT))
            .map(group => ({
                monthYearString: group[0],
                value: group[1].reduce((total: number, current: IGeneralExpense) => total + current.value, 0)
            }))
    }

    @computed get productConsumptionsByMonth() {
        return groupBy(this.productConsumptionsItemsArray, el => moment(el.productConsumptionDate).format(MONTH_YEAR_FORMAT))
            .map(group => ({
                monthYearString: group[0],
                value: group[1].reduce((total: number, current: IProductConsumptionItem) => total + current.summaryRsdValue, 0)
            }))
    }

    @computed get clientActivitiesByMonth() {
        return groupBy(this.clientActivitiesArray, el => moment(el.date).format(MONTH_YEAR_FORMAT))
            .map(group => ({
                monthYearString: group[0],
                value: group[1].reduce((total: number, current: IClientActivity) => total + current.cost, 0)
            }))
    }

    @computed get expenseTrafficsBillOrdersByMonth() {
        return groupBy(this.expenseTrafficsBillOrdersArray, el => moment(el.billDate).format(MONTH_YEAR_FORMAT))
            .map(group => ({
                monthYearString: group[0],
                value: group[1].reduce((total: number, current: IBillOrderAnalyzeEntity) => total + current.summaryBasePriceInRsd, 0)
            }))
    }

    //  Distinct periods of all participants (both income and expense)
    @computed get periods() {
        return [
            ...this.incomeTrafficsBillOrdersArray.map(el => moment(el.billDate).format(MONTH_YEAR_FORMAT)),
            ...this.gratisExpenseByMonth.map(el => el.monthYearString),
            ...this.generalExpensesByMonth.map(el => el.monthYearString),
            ...this.productConsumptionsByMonth.map(el => el.monthYearString),
            ...this.clientActivitiesByMonth.map(el => el.monthYearString),
            ...this.expenseTrafficsBillOrdersByMonth.map(el => el.monthYearString)
        ].filter((value, index, self) => self.indexOf(value) === index)
    }

    @computed get incomeTrafficsGroupByMonths()  {
        return groupToAnalyseEntityArray(
            groupBy(this.incomeTrafficsBillOrdersArray, g => moment(g.billDate).format(MONTH_YEAR_FORMAT))
        )
    }
    
    
    @computed get periodsArray(): IPeriodAnalyzeEntity[] {
        return this.periods.map(period => {
            
            const income = this.incomeTrafficsGroupByMonths.filter(el => el.monthYearString === period)[0] ?? null
            const gratis = this.gratisExpenseByMonth.filter(el => el.monthYearString === period)[0] ?? null
            const generalExpense = this.generalExpensesByMonth.filter(el => el.monthYearString === period)[0] ?? null
            const productConsumptions = this.productConsumptionsByMonth.filter(el => el.monthYearString === period)[0] ?? null
            const clientActivities = this.clientActivitiesByMonth.filter(el => el.monthYearString === period)[0] ?? null
            const expenseTrafficsBillOrders = this.expenseTrafficsBillOrdersByMonth.filter(el => el.monthYearString === period)[0] ?? null
            
            
            const expense: number = [
                gratis?.value ?? 0,
                income?.summaryBasePriceInRsd ?? 0,
                generalExpense?.value ?? 0,
                productConsumptions?.value ?? 0,
                clientActivities?.value ?? 0,
                expenseTrafficsBillOrders?.value ?? 0
            ].reduce((total, current) => total + current, 0)
            
            const profit = (income?.summaryPriceWithDiscountWithBillDiscount ?? 0) - expense
            
            return ({
                monthYearString: period,
                // billDate: income?.billDate ?? new Date(),
                
                summaryPriceWithDiscountWithBillDiscount: income?.summaryPriceWithDiscountWithBillDiscount ?? 0,
                clientCategoriesShare:
                    this.incomeTrafficsGroupByMonthsAndClientCategory
                        .filter(x => x.monthYearString === period)
                        .map(clientCat => ({
                            category: clientCat.clientCategory ?? 'N/A',
                            value: clientCat.summaryPriceWithDiscountWithBillDiscount,
                            percentage: calculatePercentage(clientCat.summaryPriceWithDiscountWithBillDiscount, 
                                income.summaryPriceWithDiscountWithBillDiscount)
                        }))
                        .sort((a, b) => a.value > b.value ? -1 : 1),
                differenceInPrice: income?.differenceInPrice ?? 0,
                
                profit,
                expense
            
                
            }) as IPeriodAnalyzeEntity
            
        })

            
        
            // .map(entity => ({
            //     ...entity,
            //     profit: 1,
            //     expense: 1,
            //     clientCategoriesShare:
            //         this.incomeTrafficsGroupByMonthsAndClientCategory
            //             .filter(x => x.monthYearString === entity.monthYearString)
            //             .map(clientCat => ({
            //                 category: clientCat.clientCategory ?? 'N/A',
            //                 value: clientCat.summaryPriceWithDiscountWithBillDiscount,
            //                 percentage: calculatePercentage(clientCat.summaryPriceWithDiscountWithBillDiscount, entity.summaryPriceWithDiscountWithBillDiscount)
            //             }))
            //             .sort((a, b) => a.value > b.value ? -1 : 1)
            // }))

        // .sort((a, b) =>
        //     a.monthYearString < b.monthYearString ? 1 : -1)
    }

    // @computed get incomeTrafficsGroupByYears() {
    //     return groupToAnalyseEntityArray(
    //         groupBy(this.incomeTrafficsBillOrdersArray, g => moment(g.billDate).format(YEAR_FORMAT))
    //     )
    //         .map(entity => ({
    //             ...entity,
    //             profit: 1,
    //             expense: 1,
    //             clientCategoriesShare: this.incomeTrafficsGroupByYearsAndClientCategory
    //                 .filter(x => x.yearString === entity.yearString)
    //                 .map(clientCat => ({
    //                     category: clientCat.clientCategory ?? 'N/A',
    //                     value: clientCat.summaryPriceWithDiscountWithBillDiscount,
    //                     percentage: calculatePercentage(clientCat.summaryPriceWithDiscountWithBillDiscount, entity.summaryPriceWithDiscountWithBillDiscount)
    //                 }))
    //                 .sort((a, b) => a.value > b.value ? -1 : 1)
    //         }))
    //
    //
    // }

    //  Setters

    @action setTrafficFilter = (traffics: number[]) => {
        this.trafficFilter = traffics
    }

    @action setTrafficFilterToCurrent = () => {
        this.trafficFilter = [this.rootStore.trafficsStore.currentTraffic!.id]
    }

    @action setTrafficFilterAndUpdate = (traffics: number[]) => {
        this.setTrafficFilter(traffics)
        this.loadAll()
    }

    @action clearCommonPredicate = () => {
        this.commonPredicate.clear()
    }

    @action clearGratisOrdersPredicate = () => {
        this.gratisOrdersPredicate.clear()
    }

    @action clearGeneralExpensesPredicate = () => {
        this.generalExpensesPredicate.clear()
    }

    @action clearProductConsumptionsItemsPredicate = () => {
        this.productConsumptionsItemsPredicate.clear()
    }

    @action clearClientActivitiesPredicate = () => {
        this.clientActivitiesPredicate.clear()
    }

    @action clearExpenseTrafficsBillOrdersPredicate = () => {
        this.expenseTrafficsBillOrdersPredicate.clear()
    }

    @action clearIncomeTrafficsBillOrdersPredicate = () => {
        this.incomeTrafficsBillOrdersPredicate.clear()
    }

    @action clearAllPredicates = () => {
        this.clearCommonPredicate()
        this.clearGratisOrdersPredicate()
        this.clearGeneralExpensesPredicate()
        this.clearProductConsumptionsItemsPredicate()
        this.clearClientActivitiesPredicate()
        this.clearExpenseTrafficsBillOrdersPredicate()
        this.clearIncomeTrafficsBillOrdersPredicate()
    }

    @action setIncomeView = (view: 'brand' | 'clientCategory') => this.incomeView = view

    @action setStartDateFilter = (startDate: string) => {
        if (startDate.length === 0) {
            this.startDateFilter = ''
            this.commonPredicate.delete('startDate')

            return true
        }

        if (moment(startDate).isValid()) {
            this.startDateFilter = startDate
            this.commonPredicate.set('startDate', startDate)

            return true
        }
        return false
    }

    @action setDateRangeFilter = (start: string | null, end: string | null, commit = false) => {
        this.setStartDateFilter(start ?? '')
        this.setEndDateFilter(end ?? '')

        if (commit) {
            this.loadAll()
        }
    }

    @action setStartDateFilterAndUpdate = (startDate: string) => {
        if (this.setStartDateFilter(startDate)) {
            this.loadAll()
        }
    }

    @action setEndDateFilter = (endDate: string) => {
        if (endDate.length === 0) {
            this.endDateFilter = ''
            this.commonPredicate.delete('endDate')

            return true
        }

        if (moment(endDate).isValid()) {
            this.endDateFilter = endDate
            this.commonPredicate.set('endDate', endDate)

            return true
        }
        return false
    }

    @action setEndDateFilterAndUpdate = (endDate: string) => {
        if (this.setEndDateFilter(endDate)) {
            this.loadAll()
        }
    }

    @action setManualBillsFilter = (manualBillsFilter: boolean) => this.manualBillsFilter = manualBillsFilter

    @action setManualBillsFilterAndUpdate = (manualBillsFilter: boolean) => {
        this.setManualBillsFilter(manualBillsFilter)
        this.loadAll()
    }

    @action setShowDiagramExtensions = (show: boolean) => this.showDiagramsExtensions = show

    //  Load section

    @action loadAll = async () => {
        this.allLoading = true
        const requestId = uuid()
        this.lastRequestId = requestId
        try {

            await Promise.all([
                this.loadGratisOrders(requestId),
                this.loadGeneralExpenses(requestId),
                this.loadProductConsumptionsItems(requestId),
                this.loadClientActivities(requestId),
                this.loadExpenseTrafficsBillOrders(requestId),
                this.loadIncomeTrafficsBillOrders(requestId),
                this.loadIncomeManualBillsConditionally(requestId),
                this.loadManualGratisConditionally(requestId),
                this.loadManualBillsExpenseTrafficsConditionally(requestId)
            ])

            runInAction(() => {

            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.allLoading = false
                }
            })
        }
    }

    @action loadGratisOrders = async (requestId: string) => {
        // this.gratisOrdersLoading = true
        try {
            const gratisOrders = await agent.BillOrders.list(this.gratisOrdersAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.gratisOrdersRegistry.clear()
                    gratisOrders.forEach(gratisOrder => {
                        this.gratisOrdersRegistry.set(gratisOrder.id, gratisOrder)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.gratisOrdersRegistry.clear()
        } finally {
            runInAction(() => {
                // this.gratisOrdersLoading = true
            })
        }
    }

    @action loadGeneralExpenses = async (requestId: string) => {
        // this.generalExpensesLoading = true
        try {
            const generalExpenses = await agent.GeneralExpenses.list(this.generalExpensesAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.generalExpensesRegistry.clear()
                    generalExpenses.forEach(generalExpense => {
                        this.generalExpensesRegistry.set(generalExpense.id, generalExpense)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.generalExpensesRegistry.clear()
        } finally {
            runInAction(() => {
                // this.generalExpensesLoading = false
            })
        }
    }

    @action loadProductConsumptionsItems = async (requestId: string) => {
        // this.productConsumptionsItemsLoading = true
        try {
            const productConsumptionsItems = await agent.ProductConsumptionItems.list(this.productConsumptionsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.productConsumptionsItemsRegistry.clear()
                    productConsumptionsItems.forEach(productConsumptionItem => {
                        this.productConsumptionsItemsRegistry.set(productConsumptionItem.id, productConsumptionItem)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.productConsumptionsItemsRegistry.clear()
        } finally {
            runInAction(() => {
                // this.productConsumptionsItemsLoading = false
            })
        }
    }

    @action loadClientActivities = async (requestId: string) => {
        // this.clientActivitiesLoading = true
        try {
            const clientActivities = await agent.ClientActivities.list(this.clientActivitiesAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.clientActivitiesRegistry.clear()
                    clientActivities.forEach(clientActivity => {
                        this.clientActivitiesRegistry.set(clientActivity.id, clientActivity)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.clientActivitiesRegistry.clear()
        } finally {
            runInAction(() => {
                // this.clientActivitiesLoading = false
            })
        }
    }


    @action loadExpenseTrafficsBillOrders = async (requestId: string) => {
        // this.expenseTrafficsBillOrdersLoading = true
        try {
            const expenseTrafficsBillOrders = await agent.BillOrders.list(this.expenseTrafficsBillOrdersAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.expenseTrafficsBillOrdersRegistry.clear()
                    expenseTrafficsBillOrders.forEach(expenseTrafficsBillOrder => {
                        this.expenseTrafficsBillOrdersRegistry.set(expenseTrafficsBillOrder.id, expenseTrafficsBillOrder)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.expenseTrafficsBillOrdersRegistry.clear()
        } finally {
            runInAction(() => {
                // this.expenseTrafficsBillOrdersLoading = false
            })
        }
    }

    @action loadIncomeTrafficsBillOrders = async (requestId: string) => {
        // this.incomeTrafficsBillOrdersLoading = true
        try {
            const incomeTrafficsBillOrders = await agent.BillOrders.list(this.incomeTrafficsBillOrdersAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.incomeTrafficsBillOrdersRegistry.clear()
                    incomeTrafficsBillOrders.forEach(incomeTrafficsBillOrder => {
                        this.incomeTrafficsBillOrdersRegistry.set(incomeTrafficsBillOrder.id, incomeTrafficsBillOrder)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.incomeTrafficsBillOrdersRegistry.clear()
        } finally {
            runInAction(() => {
                // this.incomeTrafficsBillOrdersLoading = false
            })
        }
    }

    @action loadIncomeManualBills = async (requestId: string) => {
        // this.incomeManualBillsAsBillOrderDetailedLoading = true
        try {
            const incomeManualBills = await agent.Bills.list(this.incomeManualBillsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.incomeManualBillsAsBillOrderDetailedRegistry.clear()
                    incomeManualBills.forEach(bill => {
                        this.incomeManualBillsAsBillOrderDetailedRegistry.set(bill.id, new BillOrderAnalyzeEntity(bill))
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.incomeManualBillsAsBillOrderDetailedRegistry.clear()
        } finally {
            runInAction(() => {
                // this.incomeManualBillsAsBillOrderDetailedLoading = false
            })
        }
    }

    @action loadIncomeManualBillsConditionally = async (requestId: string) => {
        if (this.manualBillsFilter) {
            await this.loadIncomeManualBills(requestId)
        }
    }

    @action loadManualGratis = async (requestId: string) => {
        // this.manualGratisAsBillOrderDetailedLoading = true
        try {
            const manualGratisBills = await agent.Bills.list(this.manualGratisAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.manualGratisAsBillOrderDetailedRegistry.clear()
                    manualGratisBills.forEach(bill => {
                        this.manualGratisAsBillOrderDetailedRegistry.set(bill.id, new BillOrderAnalyzeEntity(bill))
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.manualGratisAsBillOrderDetailedRegistry.clear()
        } finally {
            runInAction(() => {
                // this.manualGratisAsBillOrderDetailedLoading = false
            })
        }
    }

    @action loadManualGratisConditionally = async (requestId: string) => {
        if (this.manualBillsFilter) {
            await this.loadManualGratis(requestId)
        }
    }

    @action loadManualBillsExpenseTraffics = async (requestId: string) => {
        // this.manualBillsExpenseTrafficsAsBillOrderDetailedLoading = true
        try {
            const manualBillsExpenseTraffics = await agent.Bills.list(this.manualBillsExpenseTrafficsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId)
                    this.manualBillsExpenseTrafficsAsBillOrderDetailedRegistry.clear()
                manualBillsExpenseTraffics.forEach(bill => {
                    this.manualBillsExpenseTrafficsAsBillOrderDetailedRegistry.set(bill.id, new BillOrderAnalyzeEntity(bill))
                })
            })
        } catch (error) {
            console.log(error)
            this.manualBillsExpenseTrafficsAsBillOrderDetailedRegistry.clear()
        } finally {
            runInAction(() => {
                // this.manualBillsExpenseTrafficsAsBillOrderDetailedLoading = false
            })
        }
    }

    @action loadManualBillsExpenseTrafficsConditionally = async (requestId: string) => {
        if (this.manualBillsFilter) {
            await this.loadManualBillsExpenseTraffics(requestId)
        }
    }
}