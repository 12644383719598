import React, {FunctionComponent} from 'react';
import {FieldRenderProps} from "react-final-form";
import {FormFieldProps, Form, Label} from "semantic-ui-react";
import {DatePicker, DatePickerProps} from "antd";
import moment from "moment";


interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {
}


const DateInput: FunctionComponent<IProps> = ({
                                                  id = null,
                                                  input,
                                                  width,
                                                  // placeholder,
                                                  meta: {error, touched},
                                                  ...rest 
                                              }) => {

    return (
        <Form.Field error={touched && !!error} width={width}>
            <DatePicker
                style={{border: 0, padding: 0}}
                onChange={(date, dateString) => input.onChange(date ? moment(date).format('YYYY-MM-DD') : '')}
                format='l'
                onBlur={input.onBlur}
                value={input.value ? moment(input.value) : null}
                {...rest as DatePickerProps}
            />
            {touched && error && (
                <Label basic color='red'>
                    {error}
                </Label>
            )}
        </Form.Field>);
}

export default DateInput