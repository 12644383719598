import {FilterProps} from "react-table";
import React from "react";
import {DropdownItemProps, Grid, Select} from "semantic-ui-react";
import {IMultiselectFilter} from "./multiselectFilter";

export interface IMultiSelectFilter {
    selected?: any[]
}

const MultiselectFilter = ({
                                      column: {
                                          filterValue,
                                          preFilteredRows,
                                          filteredRows,
                                          setFilter,
                                          filter
                                      }
                                  }: FilterProps<IMultiselectFilter>,
                                  filterValues: DropdownItemProps[],
                                  placeholder: string
) => {
    
    return (
        <>
            <Grid>
                <Grid.Column width={16}>
                    <Select options={filterValues}
                            fluid
                            multiple
                            clearable
                            search
                            value={filterValue?.value}
                            onChange={(event, data) => setFilter({ ...filterValue, selected: data.value})}
                            placeholder={placeholder}/>
                </Grid.Column>
            </Grid>
        </>
    )
}



export default MultiselectFilter