import {FunctionComponent} from 'react';
import { useMediaQuery } from 'react-responsive'


interface IProps {
    children: any
}

export const useDesktop = () => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    
    return isDesktop
}

const Desktop: FunctionComponent<IProps> = ({children}) => {
    const isDesktop = useDesktop()
    
    return isDesktop ? children : null
};

export default Desktop;
