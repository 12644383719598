import {IClient} from "./clients";
import {IUser} from "./user";
import moment from "moment";

export interface IPayment {
    id: string
    client: IClient
    user: IUser
    date: Date
    value: number
    note: string
}

export interface IPaymentFormValues {
    id: string
    clientId: string
    date: string | null
    value: string
    note: string
}

export class PaymentFormValues implements IPaymentFormValues {
    constructor(payment?: IPayment) {
        if (payment) {
            this.id = payment.id
            this.clientId = payment.client.id!
            this.date = moment(payment.date).format('YYYY-MM-DD')
            this.note = payment.note
            this.value = payment.value.toString()
            
        }
    }
    
    clientId: string = ''
    string = moment(new Date()).format('YYYY-MM-DD')
    id: string = ''
    note: string = ''
    value = ''
    date: string | null = moment(new Date()).format('YYYY-MM-DD')
    
}