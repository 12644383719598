import {Button, Header, Icon, Segment} from "semantic-ui-react";
import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../core/stores/rootStore";
import {IClientOrder} from "../../../../../core/models/clientsOrders";

const _ClientOrderItemDeleteModal = ({clientOrder}: { clientOrder: IClientOrder }) => {
    const rootStore = useContext(RootStoreContext);
    const {deleteClientOrder} = rootStore.clientOrdersStore
    const { closeModal } = rootStore.modalStore;
    
    return (<Segment basic clearing>
        <Header textAlign='center' as='h2'>
            Brisanje narudžbine
            <Icon color='red' name='warning circle'/>
        </Header>
        <p style={{textAlign: 'center'}}>
            Da li zaista želiš da obrišeš narudžbinu <strong>{clientOrder.orderNumber} ?</strong>
        </p>
        <Button color='red' floated='right' onClick={(event: any, data) => {
            event.target.className += ' loading'
            deleteClientOrder(clientOrder.id)
                .then(() => closeModal())
        }}>
            <Icon name='checkmark'/> Da
        </Button>
        <Button onClick={() => closeModal()} floated='right'>
            <Icon name='remove'/> Ne
        </Button>
    </Segment>)
}

export const ClientOrderItemDeleteModal = observer(_ClientOrderItemDeleteModal);