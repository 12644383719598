import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, reaction, runInAction} from "mobx";
import {IBill, IBillIdStringSuggestion} from "../models/bills";
import agent from "../api/agent";
import { v4 as uuid } from 'uuid'

export default class BillsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
        
        reaction(() => this.bills.values(),
            () => {
                this.rootStore.clientsStore.updateFinancialState()
            })
    }

    @observable bills = new Map<string, IBill>()
    @observable smallBills: IBill[] = []
    @observable bill: IBill | null = null
    @observable loading = false
    @observable smallBillsLoading = false
    @observable loadingBillId: null | string = null
    @observable deleting = false
    @observable deletingBillId: string | null = null
    @observable predicate = new Map()
    @observable smallBillsPredicate = new Map()
    @observable idStringSuggestions = new Map<number, IBillIdStringSuggestion>()
    @observable loadingIdStringSuggestions = false
    @observable onSaleNotes: string[] | null = null
    @observable loadingOnSaleNotes = false
    @observable lastRequestId = ''


    @computed get billsRegistrySize() {
        return this.bills.size
    }
    
    @computed get billsArray() {
        return Array.from(this.bills.values())
    }

    @computed get billsArrayGratisExcluded() {
        return Array.from(this.bills.values()).filter(b => !b.isGratis)
    }

    @computed get billsArrayGratisOnly() {
        return Array.from(this.bills.values()).filter(b => b.isGratis)
    }

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @computed get axiosParams () {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @action setSmallBillsPredicate = (key: string, value: string) => {
        this.smallBillsPredicate.set(key, value)
    }

    @action clearSmallBillsPredicate = () => {
        this.smallBillsPredicate.clear()
    }

    @computed get smallBillsAxiosParams () {
        const params = new URLSearchParams()
        this.smallBillsPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }
    
    @action loadBill = async (id: string) => {
        this.loading = true
        try {
            const bill = await agent.Bills.details(id)
            runInAction(() => {
                this.bill = bill
            })
            return bill
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action loadBills = async () => {
        this.loading = true
        const requestId = uuid()
        this.lastRequestId = requestId
        try {
            const bills = await agent.Bills.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.bills.clear()
                    bills.forEach(bill => this.bills.set(bill.id, bill))   
                }
            })
        } catch (error) {
            console.log(error)
            this.bills.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loading = false   
                }
            })
        }
    }

    @action loadSmallBills = async () => {
        this.smallBillsLoading = true
        this.smallBills = []
        try {
            const smallBills = await agent.Bills.smallList(this.smallBillsAxiosParams)
            runInAction(() => {
                this.smallBills = smallBills
            })
            return smallBills
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.smallBillsLoading = false
            })
        }
    }
        
    @action getBillDoc = async (id: string, newTab: boolean = false) => {
        this.loadingBillId = id
        try {
            const file = await agent.Bills.doc(id)
            const url = URL.createObjectURL(file)
            if (newTab) {
                window.open(url)
            } else {
                window.location.href = url   
            }
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingBillId = null                
            })
        }
    }
    
    @action deleteBill = async (id: string) => {
        this.deleting = true
        this.deletingBillId = id
        try {
            await agent.Bills.delete(id)
            runInAction(() => {
                this.bills.delete(id)                
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingBillId = null               
            })
        }
    }
    
    @action loadIdStringSuggestions = async () => {
        this.loadingIdStringSuggestions = true
        this.idStringSuggestions.clear()
        try {
            const suggestions = await agent.Bills.billIdStringSuggestion()
            runInAction(() => {
                suggestions.forEach(suggestion => {
                    this.idStringSuggestions.set(suggestion.trafficId, suggestion)
                })  
            })
        } catch (error) {
            
        } finally {
            runInAction(() => {
                this.loadingIdStringSuggestions = false
            })
        }
    }
    
    @action getSuggestion = (trafficId: number, type: number, gratis: boolean) => {
        const suggestion = this.idStringSuggestions.get(trafficId) ?? null
        
        if (suggestion === null) {
            return ''
        }
        
        if(type === 0 && !gratis) {
            return suggestion.specification
        } else if (type === 0 && gratis) {
            return suggestion.specificationGratis
        } else if (type === 1 && !gratis) {
            return suggestion.invoice
        } else if (type === 1 && gratis) {
            return suggestion.invoiceGratis
        } else {
            return ''
        }
    }

    @action loadBillsToRegistry = (bills: IBill[]) => {
        this.bills.clear()
        bills.forEach(bill => {
            this.bills.set(bill.id, bill)
        })
    }
    
    @action loadSingleBillToRegistry = (bill: IBill) => {
        this.bills.set(bill.id, bill)
    }
    
    @action loadOnSaleNotes = async (local = false) => {
        this.loadingOnSaleNotes = true
        try {
            if (!local || this.onSaleNotes === null)  {
                const onSalesNotes = await agent.Bills.onSaleNotes()
                runInAction(() => {
                    this.onSaleNotes = onSalesNotes
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingOnSaleNotes = false
            })
        }
    }
}