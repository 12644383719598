import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import { v4 as uuid } from 'uuid'
import {
    Documentation, DocumentationDocument,
    IDocumentation,
    IDocumentationDocumentFormValues, IDocumentationFolder,
    IDocumentationFormValues
} from "../models/documentations";
import agent from "../api/agent";
import {history} from "../../index";

export default class DocumentationsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable documentationsRegistry = new Map<string, IDocumentation>()
    @observable loading = false
    @observable documentationDeletingId: string | null = null
    @observable documentDeletingId: string | null = null
    @observable submittingDocumentation = false
    @observable submittingDocument = false
    @observable documentation: IDocumentation | null = null
    @observable downloadingDocumentId: string | null = null
    @observable addingDocumentMode = false
    //categories
    @observable loadingDocumentationCategories = false
    @observable documentationCategories: string[] | null = null
    @observable documentationCategoriesFolderId = ''
    //folders
    @observable currentFolderId = ''
    @observable documentationFoldersRegistry = new Map<string, IDocumentationFolder>()
    @observable loadingDocumentationFolders = false

    @computed get documentationsArray() {
        return Array.from(this.documentationsRegistry.values())
    }

    @computed get documentationFoldersArray() {
        return Array.from(this.documentationFoldersRegistry.values())
            .sort((a, b) => a.order > b.order ? 1 : -1)
    }

    @computed get documentationFoldersWithOriginalOrVirtualPrivateArray() {    // if there is no private folder, insert virtual one
         const privateFolder = this.documentationFoldersArray.filter(x => x.private)[0] ?? null
        
        return privateFolder === null ?
            [
                ...this.documentationFoldersArray,
                {
                    id: uuid(),
                    private: true,
                    name: 'Lična dokumentacija',
                    order: 201,
                    user: this.rootStore.userStore.user
                }
            ] : this.documentationFoldersArray
    }

    @action createDocumentation = async (documentationFormValues: IDocumentationFormValues) => {
        this.submittingDocumentation = true
        try {
            await agent.Documentations.create(documentationFormValues)
            const folder = this.documentationFoldersRegistry.get(documentationFormValues.folderId) ?? null
            if (!folder && documentationFormValues.private) {
                await this.loadDocumentationFolders() // if private folder just created
            }
            runInAction(() => {
                if (folder) {
                    this.documentationsRegistry.set(documentationFormValues.id, new Documentation(documentationFormValues, folder))
                }
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/documentationFolder/${documentationFormValues.folderId}`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submittingDocumentation = false
            })
        }
    }

    @action editDocumentation = async (documentationFormValues: IDocumentationFormValues) => {
        this.submittingDocumentation = true
        try {
            await agent.Documentations.edit(documentationFormValues)
            runInAction(() => {
                const folder = this.documentationFoldersRegistry.get(documentationFormValues.folderId) ?? null
                if (folder) {
                    this.documentationsRegistry.set(documentationFormValues.id, new Documentation(documentationFormValues, folder))
                }
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/documentations/${documentationFormValues.id}`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submittingDocumentation = false
            })
        }
    }

    @action loadDocumentation = async (id: string) => {
        this.loading = true
        try {
            const documentation = await agent.Documentations.details(id)
            runInAction(() => {
                this.documentation = documentation
            })

            return documentation
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action loadDocumentations = async (folderId: string) => {
        this.currentFolderId = folderId
        this.loading = true
        try {
            const documentations = await agent.Documentations.list(folderId)
            runInAction(() => {
                if (this.currentFolderId === folderId) {
                    this.documentationsRegistry.clear()
                    documentations.forEach(documentation => {
                        this.documentationsRegistry.set(documentation.id, documentation)
                    })
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                if (this.currentFolderId === folderId) {
                    this.loading = false                    
                }
            })
        }
    }

    @action deleteDocumentation = async (id: string) => {
        this.documentationDeletingId = id
        try {
            await agent.Documentations.delete(id)
            runInAction(() => {
                this.documentationsRegistry.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.documentationDeletingId = null
            })
        }
    }

    @action getFile = async (id: string) => {
        this.downloadingDocumentId = id
        try {
            const file = await agent.DocumentationDocuments.getFile(id)
            window.open(URL.createObjectURL(file))
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.downloadingDocumentId = null
            })
        }
    }

    @action deleteDocument = async (id: string) => {
        this.documentDeletingId = id
        try {
            await agent.DocumentationDocuments.delete(id)
            runInAction(() => {
                if (this.documentation) {
                    this.documentation.documents = this.documentation.documents.filter(document => document.id !== id)
                }

            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.documentDeletingId = null
            })
        }
    }

    @action setAddingDocumentMode = (addingDocumentMode: boolean) => this.addingDocumentMode = addingDocumentMode

    @action addDocument = async (documentFormValues: IDocumentationDocumentFormValues, documentationId?: string) => {
        this.submittingDocument = true
        try {
            await agent.DocumentationDocuments.create(documentFormValues)
            runInAction(() => {
                    const newDocument = new DocumentationDocument(documentFormValues)
                    if (this.documentation) {
                        this.documentation.documents.push(newDocument)
                    }

                    if (documentationId) {
                        const documentation = this.documentationsRegistry.get(documentationId)

                        if (documentation) {
                            this.documentationsRegistry.set(documentationId,
                                {
                                    ...documentation,
                                    documents: [
                                        ...documentation!.documents,
                                        newDocument
                                    ]
                                })
                        }
                    }

                    this.addingDocumentMode = false
                }
            )
        } catch
            (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submittingDocument = false
            })
        }
    }

    @action loadDocumentationCategories = async (folderId: string = '', local: boolean = false) => {
        
        
        this.loadingDocumentationCategories = true
        try {

            if (!(folderId === this.documentationCategoriesFolderId) || (!local || !this.documentationCategories)) {
                const categories = await agent.Documentations.categories(folderId)
                
                runInAction(() => {
                    this.documentationCategories = categories
                    this.documentationCategoriesFolderId = folderId
                })
                
                return categories
            } else {
                return this.documentationCategories
            }
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingDocumentationCategories = false
            })
        }
    }

    @action loadDocumentationFolders = async () => {
        this.loadingDocumentationFolders = true
        this.documentationFoldersRegistry.clear()
        try {
            const folders = await agent.DocumentationFolders.list()
            runInAction(() => {
                folders.forEach(folder => {
                    this.documentationFoldersRegistry.set(folder.id, folder)
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingDocumentationFolders = false
            })
        }
    }
}