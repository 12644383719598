import React, {FunctionComponent, useState} from 'react';
import {VictoryLabel, VictoryPie} from "victory";
import {formatRsd} from "../util/format";
import {semanticColorToHash} from "../util/semanticUi";

interface IProps {
    data: any[],
    colors: string[],
    xAccessor: ((row: any) => any) | string,
    yAccessor: ((row: any) => any) | string,
    type?: 'money' | 'count',
    title: string | string[]
    titleColor?: string
}

const MyPieChart: FunctionComponent<IProps> = ({
                                                   data,
                                                   colors,
                                                   xAccessor,
                                                   yAccessor,
                                                   type = 'money',
                                                   title,
                                                   titleColor = semanticColorToHash('teal'),
                                               }) => {

    const [centerLabel, setCenterLabel] = useState<string[] | null>(null)

    const labara = ({datum, data}: any) => {
        const total = data.reduce((total: number, current: any) => total + current._y, 0)

        const value = type === 'money' ? formatRsd(datum._y) : datum._y

        return [
            datum.xName,
            value.toString(),
            `(${(datum._y / total * 100).toFixed(2)}%)`
        ]
    }

    return (<svg viewBox="0 0 400 400">
        <VictoryPie
            labels={() => null}
            standalone={false}
            animate={{
                duration: 500
            }}
            innerRadius={100}
            data={data}
            x={xAccessor}
            y={yAccessor}
            colorScale={colors}
            events={[{
                target: "data",
                eventHandlers: {
                    onMouseOver: (a) => [{
                            target: 'data',
                            mutation: (props) => {

                                setCenterLabel(labara(props))

                                return {
                                    style: Object.assign({},
                                        props.style,
                                        {
                                            // fill: "tomato",
                                            fillOpacity: 0.5, stroke: "white", strokeWidth: 5

                                        }),
                                }
                            }
                        },
                            {
                                target: "labels",
                                mutation: () => ({active: true})
                            }
                        ]
                    ,
                    onMouseOut: () => {
                        setCenterLabel(null)

                        return [
                            {
                                target: "data",
                                mutation: () => {
                                }
                            }, {
                                target: "labels",
                                mutation: () => ({active: false})
                            }
                        ];
                    }
                }
            }]}
        />
        <VictoryLabel
            textAnchor="middle"
            style={{fontSize: 20, fontWeight: 'bold', fill: titleColor}}
            x={200} y={200}
            text={centerLabel ?? title}
        />
    </svg>)
}

export default MyPieChart
