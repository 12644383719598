import React, {FunctionComponent} from 'react'
import {Menu, Segment} from "semantic-ui-react"
import Responsive from "../common/responsive/Responsive";

interface IProps {
    // contentLoading?: boolean,
    header?: React.ReactNode,
    menuItems: React.ReactNode,

}

const ChildMenuLayout: FunctionComponent<IProps> = ({
                                                        children,
                                                        // contentLoading,
                                                        header,
                                                        menuItems
                                                    }) => {

    const menu = (mobile = false) => <Segment
        as={Menu}
        fluid
        vertical={mobile}
        style={{padding: '0px', marginBottom: 0}}
        color='blue'>
        {menuItems}
    </Segment>

    return (<>
        {header}
        <Responsive.Mobile>
            {menu(true)}
        </Responsive.Mobile>
        <Responsive.NotMobile>
            {menu()}
        </Responsive.NotMobile>
        {children}
    </>)
}

export default ChildMenuLayout
