import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {combineValidators, composeValidators, ConfiguredCombinedValidator, isRequired} from "revalidate";
import {isDecimal, isNumberGreaterThan} from "../../../core/common/validators/general";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {v4 as uuid} from "uuid";
import {Button, Form, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import {FORM_ERROR} from "final-form";
import SelectStringInput from "../../../core/common/form/SelectStringInput";
import TextInput from "../../../core/common/form/TextInput";
import ErrorMessage from "../../../core/common/form/ErrorMessage";
import {observer} from "mobx-react-lite";
import {ClientScheduleFormValues, IClientScheduleFormValues} from "../../../core/models/clientSchedules";
import {generateYearsOptions} from "../../../core/common/util/options";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import TextAreaInput from "../../../core/common/form/TextAreaInput";
import {history} from "../../../index";

interface IProps {
    clientScheduleId?: string,
    partialForm?: boolean,
    partialFormFields?: {
        clientId?: boolean,
        year?: boolean,
        indebtedEmployeeId?: boolean,
        value?: boolean,
        note?: boolean,
        validate?: ConfiguredCombinedValidator
    },
    onSubmitSuccess?: () => void,
    onSubmitFailed?: () => void,
    onCancel?: () => void,
}

const validate = combineValidators({
    value: composeValidators(
        isRequired({message: 'Iznos je obavezan'}),
        isDecimal(2),
        isNumberGreaterThan(0)({message: 'Vrednost mora biti pozitivna'})
    )(),
    year: isRequired({message: 'Godina je obavezana'}),
    clientId: isRequired({message: 'Klijent je obavezan'})
});

const ClientScheduleForm: FunctionComponent<IProps> = ({
                                                           clientScheduleId,
                                                           partialForm,
                                                           partialFormFields,
                                                           onSubmitSuccess,
                                                           onSubmitFailed,
                                                           onCancel
                                                       }) => {

    const rootStore = useContext(RootStoreContext);

    const {loadClients, clientsArray: clients, loading: loadingClients} = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loadClientSchedule,
        createClientSchedule,
        editClientSchedule,
        submitting
    } = rootStore.clientScheduleStore
    const {employeesArray: employees, loadUsers} = rootStore.usersStore

    const [clientSchedule, setClientSchedule] = useState<IClientScheduleFormValues>(new ClientScheduleFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem(clientScheduleId ? '' : 'clientSchedulesCreate')

    useEffect(() => {
        if (clientScheduleId) {
            setLoading(true)
            loadClientSchedule({
                id: clientScheduleId
            })
                .then(clientSchedule => setClientSchedule(new ClientScheduleFormValues(clientSchedule)))
                .finally(() => setLoading(false))
        }

        loadUsers(true)
        loadClients(true)

    }, [
        loadClients,
        loadUsers,
        loadClientSchedule,
        clientScheduleId,
        partialForm
    ]);

    const handleFinalFormSubmit = (values: any) => {
        const clientSchedule = {...values, value: Number(values.value)}

        if (!clientSchedule.id) {
            let newClientSchedule = {
                ...clientSchedule,
                id: uuid()
            }

            return createClientSchedule(newClientSchedule)
        } else {
            return editClientSchedule(clientSchedule)
                .then(() => {
                    if (onSubmitSuccess) {
                        onSubmitSuccess()
                    } else {
                        history.push(`/${currentTraffic?.id}/clientSchedules`)
                    }
                })
        }
    }

    return (
        <Segment clearing color='blue'>
            <FinalForm
                onSubmit={(values: IClientScheduleFormValues) => handleFinalFormSubmit(values).catch(error => ({
                        [FORM_ERROR]: error
                    })
                )}
                validate={partialForm ? partialFormFields?.validate : validate}
                initialValues={clientSchedule}
                render={({
                             handleSubmit,
                             invalid,
                             pristine,
                             submitError,
                             dirtySinceLastSubmit,
                             form
                         }) => (
                    <Form onSubmit={handleSubmit}
                          loading={loading || loadingClients}>

                        {(!partialForm || partialFormFields!.clientId) &&
                        <Form.Field>
                            <label>Klijent</label>
                            <Field
                                name='clientId'
                                search
                                placeholder='Klijent'
                                value={clientSchedule.clientId}
                                options={clients.map(client => ({
                                    key: client.id,
                                    value: client.id,
                                    text: client.name
                                }))}
                                component={SelectStringInput}/>
                        </Form.Field>}


                        {(!partialForm || partialFormFields!.year) &&
                        <Form.Field>
                            <label>Godina</label>
                            <Field
                                name='year'
                                placeholder='Godina'
                                value={clientSchedule.year}
                                options={generateYearsOptions(2010, 2050)}
                                component={SelectStringInput}/>
                        </Form.Field>}

                        {(!partialForm || partialFormFields!.indebtedEmployeeId) &&
                        <Form.Field>
                            <label>Zaduženi</label>
                            <Field
                                name='indebtedEmployeeId'
                                placeholder='Zaduženi'
                                clearable
                                value={clientSchedule.indebtedEmployeeId}
                                component={SelectStringInput}
                                options={employees.map(user => ({
                                    key: user.id,
                                    value: user.id,
                                    text: `${user.displayName} - [${user.email}]`
                                }))}
                            />
                        </Form.Field>}

                        {(!partialForm || partialFormFields!.value) &&
                        <Form.Field>
                            <label>Iznos</label>
                            <Field
                                labelPosition='right'
                                label={{basic: true, content: 'RSD'}}
                                name='value'
                                placeholder='Iznos'
                                value={clientSchedule.value.toString()}
                                component={TextInput}/>
                        </Form.Field>}

                        {(!partialForm || partialFormFields!.note) &&
                        <Form.Field>
                            <label>Napomena</label>
                            <Field
                                name='note'
                                placeholder='Napomena...'
                                component={TextAreaInput}
                                value={clientSchedule.note}
                            />
                        </Form.Field>}


                        {(submitError && !dirtySinceLastSubmit) &&
                        <ErrorMessage error={submitError}/>}
                        <Button
                            loading={submitting}
                            disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                            floated='right'
                            color='blue'
                            type='submit'
                            content={(!clientSchedule.id) ? 'Dodaj' : 'Izmeni'}
                        />
                        <Button
                            onClick={() => {
                                if (onCancel) {
                                    onCancel()
                                } else {
                                    history.push(`/${currentTraffic?.id}/clientSchedules`)
                                }
                            }}
                            disabled={loading || loadingClients}
                            floated='right'
                            type='button'
                            content='Odustani'/>
                    </Form>
                )}
            />
        </Segment>
    )
}

export default observer(ClientScheduleForm)