import React, {FunctionComponent, useContext} from 'react'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import {IPostExpressDocument} from "../../../../core/models/postExpressDocuments";
import {Button, Icon} from "semantic-ui-react";

interface OwnProps {
    postExpressDocument: IPostExpressDocument
}

type Props = OwnProps

const PostExpressDocumentDownload: FunctionComponent<Props> = ({
                                                                   postExpressDocument
                                                               }) => {
    const rootStore = useContext(RootStoreContext)
    const {downloadingDocumentId, getFile} = rootStore.postExpressDocumentsStore

    return <Button
        icon
        size='mini'
        color='yellow'
        onClick={() => getFile(postExpressDocument.id)}
        loading={downloadingDocumentId === postExpressDocument.id}>
        <Icon name='mail'/>
    </Button>
}

export default observer(PostExpressDocumentDownload)
