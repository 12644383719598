import moment from "moment";

export const combineDateAndTime = (date: Date, time: Date) => {
    const dateString = date.toISOString().split('T')[0]
    const timeString = time.toISOString().split('T')[1]
    
    return new Date(dateString + 'T' + timeString)    
}

export const dateFromStringOrNull = (dateString: string) => {
    const newDate = moment(dateString, 'YYYY-M-DD')
    if (newDate.isValid()) {
        return new Date(dateString)
    } else {
        return  null
    }
}