import React, {FunctionComponent, useContext, useState} from 'react'
import {Form, Grid, Header, Icon, Item, Label, Popup, Segment, Select} from "semantic-ui-react";
import {Link} from 'react-router-dom'
import {RootStoreContext} from "../../../core/stores/rootStore";
import {
    ClientGoalFormValues,
    ClientGoalStatus, getClientGoalColorSerbianText,
    IClientGoal,
    IClientGoalFormValues
} from "../../../core/models/clientGoals";
import {stringToColor} from "../../../core/common/util/semanticUi";
import moment from "moment";
import {observer} from "mobx-react-lite";
import {clientGoalsColorsOptions} from "../../../core/common/options/clientGoals";
import ClientGoalMet from "./ClientGoalMet";

interface OwnProps {
    clientGoal: IClientGoal
}

type Props = OwnProps

const ClientGoalIdentity: FunctionComponent<Props> = ({clientGoal}) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {editClientGoal} = rootStore.clientGoalsStore

    const [editMode, setEditMode] = useState(false)
    const [showEditIcon, setShowEditIcon] = useState(false)

    const handleColorChange = (color: 'red' | 'blue' | 'orange') => {
        const clientGoalFormValues: IClientGoalFormValues = {
            ...new ClientGoalFormValues(clientGoal),
            color,
            date: clientGoal.date ? moment(clientGoal.date).format('YYYY-MM-DD') : null,
            completedDate: clientGoal.completedDate ? moment(clientGoal.completedDate).format('YYYY-MM-DD') : null
        }

        return editClientGoal(clientGoalFormValues)
            // .then(() => setEditMode(false))
            .catch(error => {
                console.log(error)
            })
    }

    return <Segment.Group>
        <Segment
            clearing
            attached='top'
            inverted={!editMode}
            color={clientGoal.color}
            style={{padding: editMode ? undefined : 5}}
            onMouseEnter={() => !editMode && setShowEditIcon(true)}
            onMouseLeave={() => !editMode && setShowEditIcon(false)}
        >
            {editMode
                ? <Form as={Segment}>
                    <Label
                        basic
                        style={{border: 0}}
                        attached='top right'>
                        <Icon
                            style={{cursor: 'pointer'}}
                            name='x'
                            circular
                            size='small'
                            onClick={() => setEditMode(false)}
                        />
                    </Label>

                    <Form.Field>
                        <Select
                            loading={clientGoal.flags?.updating ?? false}
                            disabled={clientGoal.flags?.updating ?? false}
                            placeholder='Boja'
                            value={clientGoal.color}
                            options={clientGoalsColorsOptions}
                            onChange={((event, data) => handleColorChange(data.value as 'red' | 'orange' | 'blue'))}
                        />
                    </Form.Field>

                    <Form.Field>
                        <ClientGoalMet
                            clientGoal={clientGoal}
                            fluid
                        />
                    </Form.Field>
                </Form>

                : <Grid>
                    <Grid.Column width={12}>
                        {getClientGoalColorSerbianText(clientGoal.color)}
                    </Grid.Column>
                    <Grid.Column 
                        width={4}
                        textAlign='right'>
                        <Icon
                            inverted
                            style={{visibility: showEditIcon ? 'visible' : 'hidden', cursor: 'pointer'}}
                            name='pencil'
                            onClick={() => setEditMode(true)}
                            // color='orange'
                        />
                    </Grid.Column>
                </Grid>}
        </Segment>

        <Segment
            attached='bottom'
            style={{margin: 0}}
        >
            {/*{(clientGoal.isMet || clientGoal.isPartlyMet) &&*/}
            <Item.Header
                as={Header}
                color={stringToColor(clientGoal.color)}>
                <Label
                    attached='top right'
                    basic
                    style={{border: 0, backgroundColor: 'transparent'}}>

                    {/*TODO {isCancel  && <Icon size='big' color='red' name='x'/>}*/}

                    {clientGoal.status === ClientGoalStatus.met ?
                        <Icon size='big' color={'green'} name='check'/> :
                        clientGoal.status === ClientGoalStatus.partlyMet ?
                            <Popup
                                header='Obrazloženje'
                                content={clientGoal.statusNote}
                                trigger={
                                    <Icon size='big' color={'grey'} name='minus'/>}/> :
                            clientGoal.status === ClientGoalStatus.failed ?
                                <Popup
                                    header='Obrazloženje'
                                    content={clientGoal.statusNote}
                                    trigger={<Icon size='big' color='red' name='x'/>}/> : null
                    }

                </Label>
            </Item.Header>
            {/*}*/}
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Link
                            to={`/${currentTraffic?.id}/clientGoals/${clientGoal.id}`}>
                            <Header
                                color={stringToColor(clientGoal.color)}>
                                {clientGoal.name}
                            </Header>
                        </Link>
                        <Item.Meta>{clientGoal.category}</Item.Meta>
                        <Item.Extra as='small'>Kreiran: {moment(clientGoal.createdAt).format('l')}</Item.Extra>
                    </Item.Content>
                </Item>
            </Item.Group>
        </Segment>
    </Segment.Group>

}

export default observer(ClientGoalIdentity)
