import React, {FunctionComponent, useContext} from 'react'
import {ICommentSectionItem} from "../../../core/models/commentsSection";
import {Divider, Grid, Header, Label, Segment} from "semantic-ui-react";
import {Link} from 'react-router-dom'
import UserIdentity from "../../users/commom/UserIdentity";
import ClientTableBasicCell from "../../clients/list/components/ClientTableBasicCell";
import moment from "moment";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";

interface OwnProps {
    commentSectionItem: ICommentSectionItem
}

type Props = OwnProps

const CommentSectionItem: FunctionComponent<Props> = ({
                                                          commentSectionItem: {
                                                              color,
                                                              title,
                                                              commentator,
                                                              link,
                                                              content,
                                                              category,
                                                              type,
                                                              createdAt,
                                                              client,
                                                              updatedAt
                                                          }
                                                      }) => {
    
    const rootStore = useContext(RootStoreContext)
    const { currentTraffic } = rootStore.trafficsStore

    return <Link to={{
        pathname: link,
        hash: '#comments'
    }}>
        <Segment raised style={{margin: 20}}>
            <Label attached='top left' content={type} color={color}/>
            {category && <Label attached='top right'>{category}</Label>}
            <Grid>
                <Grid.Row>
                    <Grid.Column textAlign='center'>
                        <Header as='h4' color='grey'>{title}</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <Grid.Column textAlign='left'>
                        {commentator && <>
                            {commentator.image 
                        ? <UserIdentity user={commentator} /> 
                            : <Link to={`/${currentTraffic?.id}/profile/${commentator.id}`}>{commentator.displayName}</Link>}
                        </>}
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        {client &&
                        <ClientTableBasicCell client={client}/>}
                    </Grid.Column>
                </Grid.Row>
                <Divider/>
                <Grid.Row>
                    <Grid.Column>
                        <p style={{color: '#333333'}}>{content}</p>
                    </Grid.Column>
                </Grid.Row>
                <Divider/>
                <Grid.Row>
                    <Grid.Column width={16} textAlign='right'>
                        <p style={{color: '#333333'}}>{moment(createdAt).calendar()} {!moment(createdAt).isSame(updatedAt) && `, izmenjen ${moment(updatedAt).calendar()}`}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </Link>
}

export default observer(CommentSectionItem)
