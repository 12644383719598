import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import OnHoldProductsList from "../list/OnHoldProductsList";
import {Button, Divider, Grid, Icon, Menu, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import OnHoldProductsGroupedByProductList from "../list/OnHoldProductsGroupedByProductList";
import {Link} from "react-router-dom";
import autoTable from "jspdf-autotable";
import {IOnHoldProduct} from "../../../core/models/onHoldProduct";
import moment from "moment";
import {PdfService} from "../../../core/common/pdf/PdfService";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import ShortcutButton from "../../commom/buttons/ShortcutButton";
import {useResponsive} from "../../../core/common/responsive/Responsive";

const GROUPED_BY_PRODUCT_TRACKING_ID = 'onHoldProductsGroupedByProductList'
const CLASSIC_TRACKING_ID = 'onHoldProductsList'

interface IProps {
}

const OnHoldProductsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loading, loadOnHoldProducts} = rootStore.onHoldProductsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    const [activeTab, setActiveTab] = useState<'byClient' | 'groupedByProduct'>('byClient')
    
    const { isMobile } = useResponsive()
    
    useNavbarSelectedItem('onHoldProductsDashboard')

    useEffect(() => () => {
        removeFromRegistry(CLASSIC_TRACKING_ID)
        removeFromRegistry(GROUPED_BY_PRODUCT_TRACKING_ID)
    }, [
        removeFromRegistry
    ])

    useEffect(() => {
        loadOnHoldProducts()
    }, [
        loadOnHoldProducts,
    ])

    const exportPdf = () => {
        const doc = new PdfService('landscape').doc

        const filteredRows = activeTab === 'byClient' ? SHIT_getFilteredRowsById(CLASSIC_TRACKING_ID) ?? [] :
            activeTab === 'groupedByProduct' ? SHIT_getFilteredRowsById(GROUPED_BY_PRODUCT_TRACKING_ID) ?? [] :
                []

        const head = activeTab === 'byClient' ? ['#', 'Proizvod', 'Klijent', 'Količina', 'Datum'] :
            activeTab === 'groupedByProduct' ? ['#', 'Proizvod', 'Količina', 'Datum'] :
                []

        const body = activeTab === 'byClient' ?
            filteredRows.map((onHoldProduct: IOnHoldProduct, index) => ([
                index + 1,
                `${onHoldProduct.product!.brandName}\n${onHoldProduct.product!.sku}\n${onHoldProduct.product!.name}`,
                onHoldProduct.client.name,
                onHoldProduct.count,
                moment(onHoldProduct.date).format('l')
            ])) :
            activeTab === 'groupedByProduct' ?
                filteredRows.map((onHoldProduct: IOnHoldProduct, index) => ([
                    index + 1,
                    `${onHoldProduct.product!.brandName}\n${onHoldProduct.product!.sku}\n${onHoldProduct.product!.name}`,
                    onHoldProduct.count,
                    moment(onHoldProduct.date).format('l')
                ])) :
                []

        doc.text(`Tabela proizvoda na čekanju ${activeTab === 'groupedByProduct' ? 'grupisanih po proizvodima' : ''}`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                head
            ],
            body
        })

        doc.save("ProizvodiNaČekanju.pdf")
    }

    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Proizvodi na čekanju',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <ShortcutButton
                    fluid={isMobile}
                    text='Traženi proizvodi'
                    link={`/${currentTraffic?.id}/requiredProducts`}
                />
                {isMobile
                && <Divider hidden fitted />}
                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/onHoldProducts/create`}
                    floated='right'
                    color='blue'
                    fluid={isMobile}
                    icon
                    labelPosition='left'>
                    <Icon name='add'/>
                    Dodaj proizvod na čekanju
                </Button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Segment.Group>
                        <Segment clearing>
                            <Button
                                disabled={loading}
                                icon
                                labelPosition='left'
                                floated='right'
                                color='teal'
                                onClick={() => exportPdf()}>
                                <Icon name='file pdf'/>
                                PDF
                            </Button>
                        </Segment>
                        <Segment loading={loading}>
                            <Menu tabular={!isMobile} vertical={isMobile} fluid style={{marginBottom: 0}} >
                                <Menu.Item
                                    content='Po klijentima'
                                    active={activeTab === 'byClient'}
                                    onClick={() => setActiveTab('byClient')}
                                />
                                <Menu.Item
                                    content='Grupisano po proizvodima'
                                    active={activeTab === 'groupedByProduct'}
                                    onClick={() => setActiveTab('groupedByProduct')}
                                />
                            </Menu>
                            {activeTab === 'byClient'
                                ? <OnHoldProductsList trackingId={CLASSIC_TRACKING_ID}/> :
                                activeTab === 'groupedByProduct'
                                    ? <OnHoldProductsGroupedByProductList
                                        trackingId={GROUPED_BY_PRODUCT_TRACKING_ID}/>
                                    : null}
                        </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid.Row>
    </Grid>);
};

export default observer(OnHoldProductsDashboard)
