import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import agent from "../api/agent";
import {ClientOrderCompleteness, IClientOrder, IClientOrderFormValues} from "../models/clientsOrders";
import moment from "moment";
import {history} from "../../index";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {toast} from "react-toastify";
import {groupBy} from "../common/util/array";
import {SemanticCOLORS} from "semantic-ui-react";
import {EXCLUDED, INCLUDED, ONLY, TS_FILTER} from "../common/filters/3sFilter";
import {v4 as uuid} from 'uuid'

const LIMIT = 8

const DEFAULT_START_DATE = moment().startOf('year').format('YYYY-MM-DD')
const DEFAULT_END_DATE = moment().endOf('year').format('YYYY-MM-DD')

export default class ClientOrdersStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }

    @observable clientOrder: IClientOrder | null = null
    @observable loading = false
    @observable submitting = false
    @observable clientOrders = new Map<string, IClientOrder>()
    @observable clientOrdersCount = 0;
    @observable page = 0;
    // @observable predicate = new Map();
    @observable editingId: null | string = null
    @observable deleting = false
    @observable deletingId: string | null = null
    @observable.ref hubConnection: HubConnection | null = null;
    @observable newClientOrders: IClientOrder[] = []

    @observable lastRequestId = ''

    // client filter
    @observable clientIdFilter: string | null = null
    // date range
    @observable startDateFilter: string | null = DEFAULT_START_DATE
    @observable endDateFilter: string | null = DEFAULT_END_DATE
    // first layer filters
    @observable completenessFilter: ClientOrderCompleteness = ClientOrderCompleteness.Uncompleted
    // second layer filters
    @observable deliveredFilter: TS_FILTER = INCLUDED
    @observable billCreatedFilter: TS_FILTER = INCLUDED
    @observable correctionFilter: TS_FILTER = INCLUDED
    @observable postExpressFilter: TS_FILTER = INCLUDED
    @observable urgentFilter: TS_FILTER = INCLUDED
    // special filters
    @observable deliveredStartDateFilter: string | null = null
    @observable deliveredEndDateFilter: string | null = null

    @observable hubConnectionStatusColor: SemanticCOLORS | undefined = undefined

    @observable loadingContentDocId: string | null = null
    @observable loadingBillDocId: string | null = null

    @action setHubConnectionStatusColor = (color: SemanticCOLORS | undefined) => this.hubConnectionStatusColor = color

    @computed get clientOrdersRegistrySize() {
        return this.clientOrders.size
    }

    // getFilterValueByName = (name: 'billCreated'
    //     | 'deliveredStartDate'
    //     | 'deliveredEndDate'
    //     | 'correction'
    //     | 'postExpress') => {
    //     switch (name) {
    //         case 'billCreated':
    //             return this.billCreatedFilter
    //         case 'deliveredStartDate':
    //             return this.deliveredStartDateFilter
    //         case 'deliveredEndDate':
    //             return this.deliveredEndDateFilter
    //         case 'correction':
    //             return this.correctionFilter
    //         case 'postExpress':
    //             return this.postExpressFilter
    //         default:
    //             return undefined
    //     }
    // }

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);

        if (this.startDateFilter) {
            params.append('startDate', this.startDateFilter)
        }

        if (this.endDateFilter) {
            params.append('endDate', this.endDateFilter)
        }

        // clientId
        if (this.clientIdFilter) {
            params.append('clientId', this.clientIdFilter)
        }

        //  first layer filters
        params.append('completeness', this.completenessFilter.toString())

        //  second filters
        if (this.deliveredFilter) {
            params.append('delivered', this.deliveredFilter)
        }

        if (this.billCreatedFilter) {
            params.append('billCreated', this.billCreatedFilter)
        }

        if (this.correctionFilter) {
            params.append('correction', this.correctionFilter)
        }

        if (this.postExpressFilter) {
            params.append('postExpress', this.postExpressFilter)
        }

        if (this.postExpressFilter) {
            params.append('urgent', this.urgentFilter)
        }

        // special filters
        if (this.deliveredStartDateFilter) {
            params.append('deliveredStartDate', this.deliveredStartDateFilter)
        }

        if (this.deliveredEndDateFilter) {
            params.append('deliveredEndDate', this.deliveredEndDateFilter)
        }


        // this.predicate.forEach((value, key) => {
        //     params.append(key, value)
        // })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.clientOrdersCount / LIMIT);
    }

    @computed get clientOrdersArray() {
        return Array.from(this.clientOrders.values())
    }

    @computed get frontFilteredClientOrdersArray() {
        return this.clientOrdersArray.filter(clientOrder => {

            switch (this.completenessFilter) {
                case ClientOrderCompleteness.Completed:
                    if (!(clientOrder.deliveredDate && clientOrder.billCreated && !clientOrder.correction && !clientOrder.postExpress)) return false;
                    break;
                case ClientOrderCompleteness.Uncompleted:
                    if (clientOrder.deliveredDate && clientOrder.billCreated && !clientOrder.correction && !clientOrder.postExpress) return false;
                    break;
            }

            // second layer filters
            if (this.deliveredFilter !== INCLUDED) {
                if (this.deliveredFilter === EXCLUDED && clientOrder.deliveredDate) return false;
                if (this.deliveredFilter === ONLY && !clientOrder.deliveredDate) return false;
            }

            if (this.billCreatedFilter !== INCLUDED) {
                if (this.billCreatedFilter === EXCLUDED && clientOrder.billCreated) return false;
                if (this.billCreatedFilter === ONLY && !clientOrder.billCreated) return false;
            }

            if (this.correctionFilter !== INCLUDED) {
                if (this.correctionFilter === EXCLUDED && clientOrder.correction) return false;
                if (this.correctionFilter === ONLY && !clientOrder.correction) return false;
            }

            if (this.postExpressFilter !== INCLUDED) {
                if (this.postExpressFilter === EXCLUDED && clientOrder.postExpress) return false;
                if (this.postExpressFilter === ONLY && !clientOrder.postExpress) return false;
            }

            if (this.urgentFilter !== INCLUDED) {
                if (this.urgentFilter === EXCLUDED && clientOrder.urgent) return false;
                if (this.urgentFilter === ONLY && !clientOrder.urgent) return false;
            }

            // special filters
            if (this.deliveredStartDateFilter) {
                if (!clientOrder.deliveredDate) return false;

                if (moment(clientOrder.deliveredDate).isBefore(this.deliveredStartDateFilter)) return false;
            }

            if (this.deliveredEndDateFilter) {
                if (!clientOrder.deliveredDate) return false;

                if (moment(clientOrder.deliveredDate).isAfter(this.deliveredEndDateFilter)) return false;
            }

            return true;
        })
    }

    @computed get clientOrdersDateGroupsArray() {
        let array = this.frontFilteredClientOrdersArray

        if (this.clientIdFilter) {
            array = array.filter(order => order.client.id === this.clientIdFilter)
        }

        return this.groupClientOrdersByDate(array);
    }

    @computed get newClientOrdersIds() {
        return this.newClientOrders.map(clientOrder => clientOrder.id)
    }

    groupClientOrdersByDate(orders: IClientOrder[]) {
        const sortedOrders = orders.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )

        return groupBy(sortedOrders, (op: IClientOrder) => moment(op.date).format('l'))
    }

    @action setPage = (page: number) => {
        this.page = page;
    }

    @action resetPagingAndClearRegistry = () => {
        this.page = 0
        this.clientOrders.clear()
    }

    @action resetPagingClearRegistryAndLoadClientOrders = () => {
        this.resetPagingAndClearRegistry()
        this.loadClientOrders()
    }

    // @action clearPredicate = () => {
    //     this.predicate.clear()
    // }

    // @action clearFilters = () => {
    //     filtersArray.forEach(filter => {
    //         this.predicate.delete(filter)
    //     })
    // }

    @action setClientIdFilter = (clientId: string | null, commit = false) => {
        this.clientIdFilter = clientId

        if (commit) {
            this.resetPagingClearRegistryAndLoadClientOrders()
        }
    }


    @action setCompletenessFilter = (value: ClientOrderCompleteness, commit = false) => {
        this.resetSecondLayerAndDateRangeFilters()

        this.completenessFilter = value

        if (commit) {
            this.resetPagingClearRegistryAndLoadClientOrders()
        }
    }

    @action clearSecondLayerFilters = () => {
        this.deliveredFilter = INCLUDED
        this.billCreatedFilter = INCLUDED
        this.correctionFilter = INCLUDED
        this.postExpressFilter = INCLUDED
    }

    @action setSecondFilter = (filterName: 'deliveredFilter'
                                   | 'billCreatedFilter'
                                   | 'correctionFilter'
                                   | 'postExpressFilter'
                                   | 'urgentFilter',
                               value: TS_FILTER, commit = false) => {
        switch (filterName) {
            case 'deliveredFilter':
                this.deliveredFilter = value
                break
            case 'billCreatedFilter':
                this.billCreatedFilter = value
                break
            case 'correctionFilter':
                this.correctionFilter = value
                break
            case 'postExpressFilter':
                this.postExpressFilter = value
                break
            case 'urgentFilter':
                this.urgentFilter = value
                break
        }

        if (commit) {
            this.resetPagingClearRegistryAndLoadClientOrders()
        }
    }

    // @action setPredicate = (predicate: string, value: string | Date) => {
    //     if (predicate !== 'all') {
    //         this.predicate.set(predicate, value)
    //     }
    // }

    // @action deletePredicate = (predicate: string) => this.predicate.delete(predicate)

    // @action setSpecialUncompletedFilter = (uncompletedFilter: boolean, commit = false) => {
    //     this.uncompletedSpecialFilter = uncompletedFilter
    //     this.predicate.set('uncompleted', uncompletedFilter.toString())
    //
    //     if (commit) {
    //         this.commitFilters()
    //     }
    // }

    startHubConnection = async () => {
        if (this.hubConnection?.state !== 'Connected') {
            this.hubConnection!
                .start()
                .then(() => {
                    console.log(this.hubConnection!.state)
                    this.setHubConnectionStatusColor('green')
                })
                .catch((error) => {
                    console.log('Error establishing connection: ', error)
                    this.setHubConnectionStatusColor('red')
                    setTimeout(() => {
                        this.setHubConnectionStatusColor('yellow')
                        this.startHubConnection()
                    }, 5000);
                })
        }
    }

    @action createHubConnection = (/*activityId: string*/) => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_CLIENT_ORDERS_URL!, {
                accessTokenFactory: () => this.rootStore.commonStore.token!
            })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection?.onclose((error) => {
            this.setHubConnectionStatusColor('red')
            if (error) {
                setTimeout(() => {
                    this.startHubConnection()
                }, 5000)
            }
        })

        this.startHubConnection()

        this.hubConnection.onreconnected(() => {
            this.setHubConnectionStatusColor('green')
            console.log('Reconnected')
        })

        this.hubConnection.onreconnecting(() => {
            console.log('Reconnecting')
        })

        this.hubConnection.on('CreatedClientOrder', (clientOrder: IClientOrder) => {
            runInAction(() => {
                if (clientOrder.createdBy.id !== this.rootStore.userStore.user!.id) {
                    toast.info(`${clientOrder.createdBy.displayName} je kreirao porudžbinu [${clientOrder.orderNumber}]`)
                    this.newClientOrders.push(clientOrder)
                }
                this.clientOrder = clientOrder

                this.clientOrders.set(clientOrder.id, clientOrder)
            })
        })

        this.hubConnection.on('EditedClientOrder', (clientOrder: IClientOrder) => {
            runInAction(() => {
                this.clientOrder = clientOrder

                this.clientOrders.set(clientOrder.id, clientOrder)
            })
        })

        this.hubConnection.on('DeletedClientOrder', (id: string) => {
            runInAction(() => {
                this.newClientOrders = this.newClientOrders.filter(clientOrder => clientOrder.id !== id)
                this.clientOrders.delete(id)
            })
        })

        this.hubConnection.on('CreateClientOrder', message => {
            toast.info(message)
        })
    }

    @action stopHubConnection = () => {
        if (this.hubConnection) {
            this.hubConnection!.stop()
                .then(() => console.log('Connection stopped'))
                .catch((error) => console.log(error))
        }
    }


    @action loadClientOrders = async () => {
        // this.clientOrders.clear() ovo ne sme zbog Infinity loading !!!
        this.newClientOrders = []

        const requestId = uuid()
        this.lastRequestId = requestId

        this.loading = true
        try {
            const clientsOrdersEnvelope = await agent.ClientOrders.list(this.axiosParams)
            const {clientOrders, clientOrdersCount} = clientsOrdersEnvelope
            runInAction(() => {

                if (this.lastRequestId === requestId) {
                    clientOrders.forEach(clientOrder => {
                        this.clientOrders.set(clientOrder.id, clientOrder)
                    })

                    this.clientOrdersCount = clientOrdersCount
                }
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loading = false
                }
            })
        }
    }

    @action loadClientOrder = async (id: string) => {
        this.loading = true
        try {
            const clientOrder: any = await agent.ClientOrders.details(id)
            runInAction(() => {
                this.clientOrder = clientOrder
            })

            return clientOrder
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action createClientOrder = async (clientOrderFormValues: IClientOrderFormValues, billFile?: Blob | null, contentFile?: Blob | null) => {
        this.submitting = true
        try {
            await this.hubConnection!.invoke('CreateClientOrder', clientOrderFormValues)

            const params = new URLSearchParams()
            params.append('removeOrderIfFail', 'true')

            if (billFile) {
                await agent.ClientOrders.postBillDoc(clientOrderFormValues.id, billFile, params)
            }
            if (contentFile) {
                await agent.ClientOrders.postContentDoc(clientOrderFormValues.id, contentFile, params)
            }
            if (billFile || contentFile) {
                await this.hubConnection!.invoke('ClientOrderDocAdded', clientOrderFormValues.id)
            }

            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientOrders`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editClientOrder = async (clientOrderFormValues: IClientOrderFormValues, billFile?: Blob | null, contentFile?: Blob | null) => {
        this.submitting = true

        const clientOrderFormValuesWithFixedDeliveredDate: IClientOrderFormValues = {
            ...clientOrderFormValues,
            deliveredDate: clientOrderFormValues.deliveredDate
                ? clientOrderFormValues.deliveredDate
                : undefined
        }

        try {
            await this.hubConnection!.invoke('EditClientOrder', clientOrderFormValuesWithFixedDeliveredDate)
            if (billFile) {
                await agent.ClientOrders.postBillDoc(clientOrderFormValues.id, billFile)
            }
            if (contentFile) {
                await agent.ClientOrders.postContentDoc(clientOrderFormValues.id, contentFile)
            }
            if (billFile || contentFile) {
                await this.hubConnection!.invoke('ClientOrderDocAdded', clientOrderFormValues.id)
            }

            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientOrders/${clientOrderFormValues.id}`)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action setBillCreated = async (id: string, billCreated: boolean) => {
        this.editingId = id
        try {
            await this.hubConnection!.invoke('SetBillCreated', {id, billCreated})
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingId = null
            })
        }
    }

    @action setDelivered = async (id: string, deliveredDate: string) => {
        this.editingId = id
        try {
            await this.hubConnection!.invoke('SetDelivered', {
                id,
                deliveredDate: deliveredDate ? deliveredDate : undefined
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingId = null
            })
        }
    }

    @action setCorrection = async (id: string, correction: boolean) => {
        this.editingId = id
        try {
            await this.hubConnection!.invoke('SetCorrection', {id, correction})
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingId = null
            })
        }
    }

    @action setPostExpress = async (id: string, postExpress: boolean) => {
        this.editingId = id
        try {
            await this.hubConnection!.invoke('SetPostExpress', {id, postExpress})
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingId = null
            })
        }
    }

    @action setUrgent = async (id: string, urgent: boolean) => {
        this.editingId = id
        try {
            await this.hubConnection!.invoke('SetUrgent', {id, urgent})
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingId = null
            })
        }
    }

    @action deleteClientOrder = async (id: string) => {
        this.deleting = true
        this.deletingId = id
        try {
            await this.hubConnection!.invoke('DeleteClientOrder', id)
            runInAction(() => {
                this.clientOrders.delete(id)
                this.newClientOrders = this.newClientOrders.filter(clientOrder => clientOrder.id !== id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingId = null
            })
        }
    }

    @action loadClientOrdersToRegistry = (clientOrders: IClientOrder[]) => {
        this.clientOrders.clear()
        clientOrders.forEach(order => {
            this.clientOrders.set(order.id, order)
        })
    }

    @action setDate = (type: 'startDate' | 'endDate', value: string) => {
        if (type === 'startDate') {
            this.startDateFilter = value
        } else {
            this.endDateFilter = value
        }
    }

    @action setDateAndUpdate = (type: 'startDate' | 'endDate', value: string) => {
        this.page = 0
        this.clientOrders.clear()

        this.setDate(type, value)

        this.loadClientOrders()
    }

    @action setDateRange = (startDate: string | null, endDate: string | null) => {
        this.startDateFilter = startDate
        this.endDateFilter = endDate
    }

    @action setDateRangeAndUpdate = (startDate: string | null, endDate: string | null) => {
        this.page = 0
        this.clientOrders.clear()

        this.setDateRange(startDate, endDate)

        this.loadClientOrders()
    }

    // @action setFilter = (name: 'billCreated' | 'correction' | 'postExpress', value: TS_FILTER | undefined, commit = false) => {
    //     switch (name) {
    //         case "billCreated":
    //             this.billCreatedFilter = value
    //             break
    //         case "correction":
    //             this.correctionFilter = value
    //             break
    //         case "postExpress":
    //             this.postExpressFilter = value
    //             break
    //     }
    //
    //     this.predicate.set(name, value)
    //
    //     if (commit) {
    //         this.commitFilters()
    //     }
    // }

    @action resetSecondLayerFilters = (commit = false) => {
        this.deliveredFilter = INCLUDED
        this.billCreatedFilter = INCLUDED
        this.correctionFilter = INCLUDED
        this.postExpressFilter = INCLUDED

        if (commit) {
            this.loadClientOrders()
        }
    }

    @action resetDeliveredDateRangeFilter = (commit = false) => {
        this.deliveredStartDateFilter = null
        this.deliveredEndDateFilter = null

        if (commit) {
            this.loadClientOrders()
        }
    }

    @action resetSecondLayerAndDateRangeFilters = (commit = false) => {
        this.resetSecondLayerFilters()
        this.resetDeliveredDateRangeFilter()

        if (commit) {
            this.loadClientOrders()
        }
    }

    @action setDeliveredDateRangeFilter = (start: string | null, end: string | null, commit = false) => {
        this.deliveredStartDateFilter = start
        this.deliveredEndDateFilter = end

        this.resetPagingClearRegistryAndLoadClientOrders()
    }

    @action loadClientOrderDoc = async (id: string, documentType: 'bill' | 'content') => {
        if (documentType === 'bill') {
            this.loadingBillDocId = id
        } else {
            this.loadingContentDocId = id
        }
        try {
            const file = documentType === 'bill' ?
                await agent.ClientOrders.billDoc(id) :
                await agent.ClientOrders.contentDoc(id)
            window.open(URL.createObjectURL(file))
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                if (documentType === 'bill') {
                    this.loadingBillDocId = null
                } else {
                    this.loadingContentDocId = null
                }
            })
        }
    }
}
