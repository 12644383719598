import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {SemanticCOLORS} from "semantic-ui-react";
import {isPostReadByUser, IUserPost, IUserPostFormValues, UserPost} from "../models/userPosts";
import agent from "../api/agent";
import moment from "moment";
import {toast} from "react-toastify";
import {getSignalRHubErrorMessageFromResponse} from "../common/signalR";

const LIMIT = 8

export default class UserPostsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }


    @observable userPost: IUserPost | null = null
    @observable userPostsRegistry = new Map<string, IUserPost>()
    @observable submitting = false
    @observable loading = false
    @observable loadingSinglePost = false
    @observable.ref hubConnection: HubConnection | null = null;
    @observable hubConnectionStatusColor: SemanticCOLORS | undefined = undefined
    @observable predicate = new Map();
    @observable userPostsCount = 0;
    @observable page = 0;
    @observable submittingComment = false
    @observable deletingPostId: string | null = null
    
    @observable unreadByMeCount = 0

    @action setPostFlag = (postId: string, flagType: 'updating', value: boolean) => {
        const userPost = this.userPostsRegistry.get(postId) ?? null

        if (userPost === null)
            return

        if (flagType === 'updating') {
            this.userPostsRegistry.set(postId, {
                ...userPost,
                flags: {
                    ...userPost.flags,
                    updating: value
                }
            })
        }
    }

    @computed get userPostsArray() {
        let array = Array.from(this.userPostsRegistry.values())
            .sort((a, b) => {
                const aBeforeB = moment(a.createdAt).isBefore(b.updatedAt)

                if (aBeforeB) {
                    return 1
                } else {
                    return -1
                }
            })

        const isReadByMeFilter = this.predicate.get('isReadByMe') ?? null

        if (isReadByMeFilter && isReadByMeFilter !== 'included')
            array = array
                .filter(post => {
                    switch (isReadByMeFilter) {
                        case 'only':
                            return isPostReadByUser(post, this.rootStore.userStore.user!.id)
                        case 'excluded':
                            return !isPostReadByUser(post, this.rootStore.userStore.user!.id)
                        default:
                            return true
                    }
                })

        return array
    }

    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);

        // if (this.startDate) {
        //     params.append('startDate', this.startDate)
        // }
        //
        // if (this.endDate) {
        //     params.append('endDate', this.endDate)
        // }

        this.predicate.forEach((value, key) => {
            params.append(key, value)
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.userPostsCount / LIMIT);
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }
    
    @computed get isReadByMeFilterValue() {
        const isReadByMe = this.predicate.get('isReadByMe') ?? null
        
        if (isReadByMe) {
            return isReadByMe
        } else {
            return 'included'
        }
    }

    @action clearRegistry = () => {
        this.userPostsRegistry.clear()
    }

    @action resetUserPosts = () => {
        this.userPostsCount = 0
        this.page = 0
        this.clearRegistry()
    }

    @action setPredicate = (predicate: string, value: string | Date, loadUserPosts = false) => {
        this.predicate.set(predicate, value)

        if (loadUserPosts) {
            this.loadUserPosts(true)
        }
    }

    @action setPage = (page: number) => {
        this.page = page;
    }

    @action setHubConnectionStatusColor = (color: SemanticCOLORS | undefined) => this.hubConnectionStatusColor = color


    startHubConnection = async () => {
        if (this.hubConnection?.state !== 'Connected') {
            this.hubConnection!
                .start()
                .then(() => {
                    console.log(this.hubConnection!.state)
                    this.setHubConnectionStatusColor('green')
                })
                .catch((error) => {
                    console.log('Error establishing connection: ', error)
                    this.setHubConnectionStatusColor('red')
                    setTimeout(() => {
                        this.setHubConnectionStatusColor('yellow')
                        this.startHubConnection()
                    }, 5000);
                })
        }
    }

    @action createHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_USER_POSTS_URL!, {
                accessTokenFactory: () => this.rootStore.commonStore.token!
            })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection?.onclose((error) => {
            this.setHubConnectionStatusColor('red')
            if (error) {
                setTimeout(() => {
                    this.startHubConnection()
                }, 5000)
            }
        })

        this.startHubConnection()

        this.hubConnection.onreconnected(() => {
            this.setHubConnectionStatusColor('green')
            console.log('Reconnected')
        })

        this.hubConnection.onreconnecting(() => {
            console.log('Reconnecting')
        })

        this.hubConnection.on('UserPostCreated', (userPost: IUserPost) => {
            runInAction(() => {
                this.userPostsRegistry.set(userPost.id, userPost)
                this.getUnreadByMeCount()
            })
        })

        this.hubConnection.on('UserPostUpdated', (userPost: IUserPost) => {
            runInAction(() => {
                this.userPostsRegistry.set(userPost.id, userPost)
                this.getUnreadByMeCount()
            })
        })

        this.hubConnection.on('UserPostDeleted', (id: string) => {
            runInAction(() => {
                this.userPostsRegistry.delete(id)
                this.getUnreadByMeCount()
            })
        })


    }

    @action stopHubConnection = () => {
        if (this.hubConnection) {
            this.hubConnection!.stop()
                .then(() => console.log('Connection stopped'))
                .catch((error) => console.log(error))
        }
    }

    @action loadUserPost = async (id: string) => {
        this.loadingSinglePost = true
        try {
            const userPost: IUserPost = await agent.UserPosts.details(id)
            runInAction(() => {
                this.userPost = userPost
            })

            return userPost
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingSinglePost = false
            })
        }
    }

    @action createUserPost = async (userPostFormValues: IUserPostFormValues) => {
        this.submitting = true
        try {
            await this.hubConnection!.invoke('CreateUserPost', userPostFormValues)
        } catch (error) {
            console.log(error)
            const message = getSignalRHubErrorMessageFromResponse(error)

            if (message) {
                toast.error(message)
            }

            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editUserPost = async (userPostFormValues: IUserPostFormValues) => {
        this.submitting = true
        try {
            await this.hubConnection!.invoke('EditUserPost', userPostFormValues)
        } catch (error) {
            console.log(error)
            const message = getSignalRHubErrorMessageFromResponse(error)

            if (message) {
                toast.error(message)
            }

            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action loadUserPosts = async (reset = false) => {
        if (reset) {
            this.resetUserPosts()
        }
        this.loading = true
        try {
            const userPostsEnvelope = await agent.UserPosts.list(this.axiosParams)
            const {userPosts, count} = userPostsEnvelope
            runInAction(() => {
                userPosts.forEach(userPost => {
                    this.userPostsRegistry.set(userPost.id, new UserPost(userPost))
                })
                this.userPostsCount = count
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action setCurrentUserReadPost = async (postId: string, value: boolean) => {
        this.setPostFlag(postId, 'updating', true)
        try {
            await this.hubConnection!.invoke('SetCurrentUserReadPost', {
                userPostId: postId,
                isRead: value
            })
        } catch (error) {
            console.log(error)
            const message = getSignalRHubErrorMessageFromResponse(error)

            if (message) {
                toast.error(message)
            }

            // throw error
        } finally {
            runInAction(() => {
                this.setPostFlag(postId, 'updating', false)
            })
        }
    }

    @action createUserPostComment = async (postId: string, content: string) => {
        this.submittingComment = true
        try {
            await this.hubConnection!.invoke('CreateUserPostComment', {
                postId,
                content
            })
        } catch (error) {
            console.log(error)
            const message = getSignalRHubErrorMessageFromResponse(error)

            if (message) {
                toast.error(message)
            }

            throw error
        } finally {
            runInAction(() => {
                this.submittingComment = false
            })
        }
    }

    @action deleteUserPostComment = async (commentId: string) => {
        try {
            await this.hubConnection!.invoke('DeleteUserPostComment', {commentId})
        } catch (error) {
            console.log(error)
            const message = getSignalRHubErrorMessageFromResponse(error)

            if (message) {
                toast.error(message)
            }

            throw error
        }
    }

    @action updateUserPostComment = async (id: string, content: string) => {
        this.submittingComment = true
        try {
            await this.hubConnection!.invoke('UpdateUserPostComment', {id, content})
        } catch (error) {
            console.log(error)
            const message = getSignalRHubErrorMessageFromResponse(error)

            if (message) {
                toast.error(message)
            }

            throw error
        } finally {
            runInAction(() => {
                this.submittingComment = false
            })
        }
    }

    @action deleteUserPost = async (id: string) => {
        this.deletingPostId = id
        try {
            await this.hubConnection!.invoke('DeleteUserPost', {id})
        } catch (error) {
            console.log(error)
            const message = getSignalRHubErrorMessageFromResponse(error)

            if (message) {
                toast.error(message)
            }

            throw error
        } finally {
            runInAction(() => {
                this.deletingPostId = null
            })
        }
    }

    @action getUnreadByMeCount = async () => {
        try {
            const unreadByMeCount = await agent.UserPosts.unreadByUserCount()
            runInAction(() => {
                this.unreadByMeCount = unreadByMeCount
            })
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}