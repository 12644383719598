import {IClient, IClientFilter} from "../../../../../models/clients";

export const clientDetailedFilter = (rows: any, id: any, filterValue: IClientFilter, root = false) => {
    return rows.filter((row: any) => {
        const client: IClient = root ? row.original : row.original.client
        
        if (filterValue.regions.length > 0 && !filterValue.regions.includes(client.regionId!)) {
            return false
        }

        if (filterValue.categories.length > 0 && !filterValue.categories.includes(client.category!)) {
            return false
        }

        if (filterValue.statusList.length > 0 && !filterValue.statusList.includes(client.status!)) {
            return false
        }

        if (filterValue.types.length > 0 && !filterValue.types.includes(client.type!)) {
            return false
        }

        if (filterValue.agent.length > 0 && !filterValue.agent.includes(client.agent?.id || "")) {
            return false
        }

        if (filterValue.name) {
            return String(client.name)
                .toLowerCase()
                .includes(String(filterValue.name).toLowerCase())
        }
        
        if (filterValue.focus === 'only') {
            return client.isInFocus
        } else if (filterValue.focus === 'excluded') {
            return !client.isInFocus
        }

        return true
    })
}
