export const filterCounter = (filterValue: any) => {
    return Object.entries(filterValue)
        .reduce((total: number, element: any[]) => {
            if (Array.isArray(element[1])) {
                return total + element[1].length
            } else if (element[1] && element[1] !== 'included' && element[1] !== 'all') {
                return total + 1
            } else {
                return total
            }

        }, 0)
}