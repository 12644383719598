import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';
import {RootStoreContext} from "../../../core/stores/rootStore";
import LoadingComponent from "../../../core/layout/LoadingComponent";
import ProfileContent from "./ProfileContent";
import ProfileHeader from "./ProfileHeader";

interface RouteParams {
    username: string;
}

interface IProps extends RouteComponentProps<RouteParams> {}

const ProfilePage: React.FC<IProps> = ({ match }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        loadingProfile,
        profile,
        loadProfile,
        isCurrentUser,
        loading,
        setActiveTab
    } = rootStore.profileStore;

    useEffect(() => {
        loadProfile(match.params.username);
    }, [loadProfile, match]);

    if (loadingProfile) return <LoadingComponent content='Učitavanje proila...' />;

    return (
        <Grid>
            <Grid.Column width={16}>
                <ProfileHeader
                    profile={profile!}
                    isCurrentUser={isCurrentUser}
                    loading={loading}
                />
                <ProfileContent setActiveTab={setActiveTab} />
            </Grid.Column>
        </Grid>
    );
};

export default observer(ProfilePage);