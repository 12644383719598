import axios, {AxiosResponse} from 'axios';
import {history} from '../..';
import {toast} from 'react-toastify';
import {
    IUser,
    IUserChangePasswordFormValues,
    IUserForgetPasswordFormValues,
    IUserFormValues,
    IUserResetPasswordFormValues
} from '../models/user';
import {FinancialState, IClient, IClientComment, IClientEmployee, IClientFormValues} from "../models/clients";
import {IRegion, IRegionFormValues} from "../models/region";
import {IProduct} from "../models/products";
import {IBrand, IBrandFormValues} from "../models/brands";
import {IPricelist, IPricelistFormValues} from "../models/pricelists";
import {IBill, IBillFormValues, IBillIdStringSuggestion} from "../models/bills";
import {ITraffic, ITrafficFormValues} from "../models/traffics";
import {IBillInstallment} from "../models/billInstallments";
import {IPayment, IPaymentFormValues} from "../models/payments";
import {IGlobalSetting} from "../models/globalSettings";
import {IClientOrder, IClientOrderFormValues, IClientOrdersEnvelope} from "../models/clientsOrders";
import {IOnHoldProduct, IOnHoldProductFormValues} from "../models/onHoldProduct";
import {
    IClientInitialState,
    IClientInitialStateFormValues
} from "../models/clientInitialStates";
import {IClientActivity, IClientActivityFormValues} from "../models/clientActivities";
import {IClientSchedule, IClientScheduleFormValues} from "../models/clientSchedules";
import {
    ClientGoalStatus,
    IClientGoal, IClientGoalCategoryWithProperties, IClientGoalComment,
    IClientGoalDocumentFormValues,
    IClientGoalFormValues
} from "../models/clientGoals";
import {IBillOrderAnalyzeEntity} from "../models/billOrders";
import {IGeneralExpense, IGeneralExpenseFormValues} from "../models/generalExpenses";
import {
    IProductConsumption,
    IProductConsumptionFormValues,
    IProductConsumptionItem
} from "../models/productConsumptions";
import {
    IDocumentation,
    IDocumentationDocumentFormValues,
    IDocumentationFolder,
    IDocumentationFormValues
} from "../models/documentations";
import {IPhoto, IProfile} from "../models/profile";
import {
    IClientTour, IClientTourFormValues
} from "../models/clientTours";
import {IUserPost, IUserPostComment, IUserPostsEnvelope} from "../models/userPosts";
import {
    EmployeeGoalStatus, IEmployeeGoal, IEmployeeGoalComment,
    IEmployeeGoalDocumentFormValues,
    IEmployeeGoalFormValues
} from "../models/employeeGoals";
import {IPostExpressDocument, IPostExpressDocumentFormValues} from "../models/postExpressDocuments";
import {
    IRequiredProduct,
    IRequiredProductFormValues,
} from "../models/requiredProducts";

const MAX_RANDOM_SLEEP_TIME = 0

axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem('jwt');
        if (token) config.headers.Authorization = `Bearer ${token}`
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

axios.interceptors.response.use(undefined, error => {

    if (error.message === 'Network Error' && !error.response) {
        toast.error('Problem sa mrežom - proverite da li je API server pokrenut!')
    }

    // const { status, data, config, headers } = error?.response
    let status = null
    let data = null
    let config = null
    let headers = null

    try {
        status = error.response.status
        data = error.response.data
        config = error.response.config
        headers = error.response.headers
    } catch (error) {
        console.log(error)
    }


    if (status === 404) {
        history.push('/notfound');
    }
    if (status === 401 && headers['www-authenticate']?.startsWith('Bearer error="invalid_token", error_description="The token expired at')) {
        window.localStorage.removeItem('jwt');
        history.push('/')
        toast.warning('Tvoja sesija je istekla, molim te uloguj se opet')
    }
    if (status === 403) {
        // history.push('/');
        // toast.info('Neovlaščen pristup')
    }
    if (status === 401) {
        history.push('/');
        // toast.info('Neovlaščen pristup')
    }
    if (
        status === 400 &&
        config.method === 'get' &&
        data.errors.hasOwnProperty('id')
    ) {
        history.push('/notfound');
    }
    if (status === 400) {
        toast.error('Neispravan zahtev!');

        console.log(data)
    }
    if (status === 500) {
        toast.error('Greška na serveru!');
    }
    throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const sleep = (ms: number) => (response: AxiosResponse) =>
    new Promise<AxiosResponse>(resolve =>
        setTimeout(() => resolve(response), Math.random() * ms)
    );

const requests = {
    get: (url: string, params: URLSearchParams = new URLSearchParams()) =>
        axios
            .get(url, {params})
            .then(sleep(MAX_RANDOM_SLEEP_TIME))
            .then(responseBody),
    getFile: (url: string, params: URLSearchParams = new URLSearchParams()) =>
        axios
            .get(url, {params, responseType: 'blob'})
            .then(sleep(MAX_RANDOM_SLEEP_TIME))
            .then(responseBody),

    post: (url: string, body: {}) =>
        axios
            .post(url, body)
            .then(sleep(MAX_RANDOM_SLEEP_TIME))
            .then(responseBody),
    postRetFile: (url: string, body: {}) =>
        axios
            .post(url, body, {responseType: 'blob'})
            .then(sleep(MAX_RANDOM_SLEEP_TIME))
            .then(responseBody),
    put: (url: string, body: {}) =>
        axios
            .put(url, body)
            .then(sleep(MAX_RANDOM_SLEEP_TIME))
            .then(responseBody),
    putRetFile: (url: string, body: {}) =>
        axios
            .put(url, body, {responseType: 'blob'})
            .then(sleep(MAX_RANDOM_SLEEP_TIME))
            .then(responseBody),
    del: (url: string, params: URLSearchParams = new URLSearchParams()) =>
        axios
            .delete(url, {params})
            .then(sleep(MAX_RANDOM_SLEEP_TIME))
            .then(responseBody),
    postForm: (url: string, file: Blob, params?: URLSearchParams) => {
        let formData = new FormData();
        formData.append('File', file);
        return axios
            .post(url, formData, {
                headers: {'Content-type': 'multipart/form-data'},
                params
            })
            .then(responseBody);
    },
    postFormData: (url: string, formData: FormData) => {
        return axios
            .post(url, formData, {
                headers: {'Content-type': 'multipart/form-data'}
            })
            .then(responseBody);
    }
};

const User = {
    current: (): Promise<IUser> => requests.get('/user'),
    login: (user: IUserFormValues): Promise<IUser> =>
        requests.post(`/user/login`, user),
    register: (user: IUserFormValues): Promise<IUser> =>
        requests.post(`/user/register`, user),
    changePassword: (userChangePasswordFormValues: IUserChangePasswordFormValues) =>
        requests.post('/user/changePassword', userChangePasswordFormValues),
    resetPassword: (userResetPasswordFormValues: IUserResetPasswordFormValues) =>
        requests.post('/user/resetPassword', userResetPasswordFormValues),
    forgetPassword: (userForgetPasswordFormValues: IUserForgetPasswordFormValues) =>
        requests.post('/user/forgetPassword', userForgetPasswordFormValues)
};

const Clients = {
    list: (params?: URLSearchParams): Promise<IClient[]> => requests.get('/clients', params),
    details: (id: string, params?: URLSearchParams): Promise<IClient> => requests.get(`/clients/${id}`, params),
    financialState: (id: string, year: string): Promise<FinancialState> => requests.get(`/clients/${id}/financialState/${year}`),
    create: (client: IClientFormValues) => requests.post('/clients', client),
    edit: (client: IClientFormValues) => requests.put(`/clients/${client.id}`, client),
    delete: (id: string) => requests.del(`/clients/${id}`),
    categories: (): Promise<string[]> => requests.get('/clients/categories'),
    focus: (id: string) => requests.post(`/clients/focus/${id}`, {}),
    unfocus: (id: string) => requests.del(`/clients/focus/${id}`),
    generateClientCardPDF: (id: string, startDate: string, endDate: string, params?: URLSearchParams): Promise<Blob> =>
        requests.getFile(`/clients/generateCard/${id}/${startDate}/${endDate}`, params),
    generateClientsBalancesWithUnpaidBillsPDF: (params?: URLSearchParams): Promise<Blob> =>
        requests.getFile(`/clients/generateBalancesWithNoPaidBills`, params)
}

const Regions = {
    getAll: (): Promise<IRegion[]> => requests.get('/regions'),
    details: (id: number): Promise<IRegion> => requests.get(`/regions/${id}`),
    create: (region: IRegionFormValues): Promise<number> => requests.post('/regions', region),
    delete: (id: number) => requests.del(`/regions/${id}`),
    edit: (region: IRegionFormValues) => requests.put(`/regions/${region.id}`, region)
}

const Products = {
    list: (params?: URLSearchParams): Promise<IProduct[]> => requests.get('/products', params),
    details: (id: string): Promise<IProduct> => requests.get(`/products/${id}`),
    create: (product: IProduct) => requests.post('/products', product),
    edit: (product: IProduct) => requests.put(`/products/${product.id}`, product),
    delete: (id: string) => requests.del(`/products/${id}`)
}

const Brands = {
    list: (params?: URLSearchParams): Promise<IBrand[]> => requests.get('/brands', params),
    details: (id: number): Promise<IBrand> => requests.get(`/brands/${id}`),
    create: (brandFormValues: IBrandFormValues): Promise<number> => requests.post('/brands', brandFormValues),
    edit: (brandFormValues: IBrandFormValues) => requests.put(`/brands/${brandFormValues.id}`, brandFormValues),
    delete: (id: number) => requests.del(`/brands/${id}`)
}

const Pricelists = {
    details: (id: number): Promise<IPricelist> => requests.get('/pricelists/' + id),
    list: (): Promise<IPricelist[]> => requests.get('/pricelists'),
    delete: (id: number) => requests.del(`/pricelists/${id}`),
    create: (pricelist: IPricelistFormValues): Promise<number> => requests.post('/pricelists', pricelist),
    edit: (pricelist: IPricelistFormValues): Promise<number> => requests.put('/pricelists/' + pricelist.id, pricelist)

}

const Bills = {
    createCart: (bill: IBillFormValues): Promise<Blob> => requests.postRetFile('/bills', bill),
    details: (id: string): Promise<IBill> => requests.get(`/bills/${id}`),
    list: (params?: URLSearchParams): Promise<IBill[]> => requests.get('/bills', params),
    smallList: (params?: URLSearchParams): Promise<IBill[]> => requests.get('/bills/small', params),
    editCart: (bill: IBillFormValues) => requests.putRetFile(`/bills/${bill.id}`, bill),
    doc: (id: string): Promise<Blob> => requests.getFile(`/bills/${id}/doc`),
    delete: (id: string) => requests.del(`/bills/${id}`),
    billIdStringSuggestion: (): Promise<IBillIdStringSuggestion[]> => requests.get('/bills/utils/idStringSuggestion'),
    uploadFileManual: (id: string, file: Blob) => requests.postForm(`/bills/uploadFileManual/${id}`, file),
    onSaleNotes: (): Promise<string[]> => requests.get('/bills/onSaleNotes')
}

const BillsInstallments = {
    list: (params?: URLSearchParams): Promise<IBillInstallment[]> => requests.get('/billsInstallments', params),
    paid: (id: string) => requests.put(`/billsInstallments/${id}/paid`, {}),
    unpaid: (id: string) => requests.put(`/billsInstallments/${id}/unpaid`, {})
}

const Traffics = {
    list: (): Promise<ITraffic[]> => requests.get('/traffics'),
    create: (trafficFormValues: ITrafficFormValues): Promise<number> => requests.post('/traffics', trafficFormValues),
    edit: (trafficFormValues: ITrafficFormValues) => requests.put(`/traffics/${trafficFormValues.id}`, trafficFormValues),
    delete: (id: number) => requests.del(`/traffics/${id}`),
    makeDefault: (id: Number) => requests.put(`/traffics/${id}/makeDefault`, {}),
    details: (id: number): Promise<ITraffic> => requests.get(`/traffics/${id}`)
}

const Payments = {
    create: (payment: IPaymentFormValues) => requests.post('/payments', payment),
    details: (id: string): Promise<IPayment> => requests.get(`/payments/${id}`),
    list: (params?: URLSearchParams): Promise<IPayment[]> => requests.get('/payments', params),
    delete: (id: string) => requests.del(`/payments/${id}`),
    edit: (payment: IPaymentFormValues) => requests.put(`/payments/${payment.id}`, payment),
}

const Users = {
    list: (): Promise<IUser[]> => requests.get('/users'),
    addRole: (userId: string, roleName: string) => requests.post(`/users/role/${userId}/${roleName}`, {}),
    removeRole: (userId: string, roleName: string) => requests.del(`/users/role/${userId}/${roleName}`),
    rolesList: () => requests.get('/users/roles'),
    remove: (id: string) => requests.del(`/users/${id}`)
}

const GlobalSettings = {
    list: (): Promise<IGlobalSetting[]> => requests.get('/globalSettings'),
    edit: (key: string, value: string) => requests.put('/globalSettings', {key, value})
}

const ClientOrders = {
    details: (id: string): Promise<IClientOrder> => requests.get(`/clientOrders/${id}`),
    create: (clientOrderFormValues: IClientOrderFormValues) => requests.post('/clientOrders', clientOrderFormValues),
    list: (params?: URLSearchParams): Promise<IClientOrdersEnvelope> => requests.get('/clientOrders', params),
    edit: (clientOrderFormValues: IClientOrderFormValues) => requests.put(`/clientOrders/${clientOrderFormValues.id}`, clientOrderFormValues),
    delete: (id: string) => requests.del(`/clientOrders/${id}`),
    setBillCreate: (id: string, billCreated: boolean) => {
        if (billCreated) {
            return requests.post(`/clientOrders/${id}/billCreate`, {})
        } else {
            return requests.post(`/clientOrders/${id}/billUncreate`, {})
        }
    },
    // setDelivered: (id: string, delivered: boolean) => {
    //     if (delivered) {
    //         return requests.post(`/clientOrders/${id}/deliver`, {})
    //     } else {
    //         return requests.post(`/clientOrders/${id}/undeliver`, {})
    //     }
    // },
    setDelivered: (id: string, deliveredDate: string) => requests.post(`/clientOrders/${id}/setDelivered`, {
        id,
        deliveredDate
    }),
    postBillDoc: (id: string, file: Blob, params?: URLSearchParams) => requests.postForm(`/clientOrders/${id}/uploadBillDocument`, file, params),
    postContentDoc: (id: string, file: Blob, params?: URLSearchParams) => requests.postForm(`/clientOrders/${id}/uploadContentDocument`, file, params),
    billDoc: (id: string): Promise<Blob> => requests.getFile(`/clientOrders/${id}/billDoc`),
    contentDoc: (id: string): Promise<Blob> => requests.getFile(`/clientOrders/${id}/contentDoc`),
}

const OnHoldProducts = {
    details: (id: string): Promise<IOnHoldProduct> => requests.get(`/onHoldProducts/${id}`),
    create: (onHoldProductFormValues: IOnHoldProductFormValues): Promise<string> => requests.post('/onHoldProducts', onHoldProductFormValues),
    edit: (onHoldProductFormValues: IOnHoldProductFormValues) => requests.put(`/onHoldProducts/${onHoldProductFormValues.id}`, onHoldProductFormValues),
    delete: (id: string, params?: URLSearchParams) => requests.del(`/onHoldProducts/${id}`, params),
    list: (params?: URLSearchParams): Promise<IOnHoldProduct[]> => requests.get('/onHoldProducts', params)
}

const RequiredProducts = {
    details: (id: string): Promise<IRequiredProduct> => requests.get(`/requiredProducts/${id}`),
    add: (requiredProductFormValues: IRequiredProductFormValues): Promise<string> => requests.post('/requiredProducts', requiredProductFormValues),
    edit: (requiredProductFormValues: IRequiredProductFormValues) => requests.put(`/requiredProducts/${requiredProductFormValues.id}`, requiredProductFormValues),
    delete: (id: string, params?: URLSearchParams) => requests.del(`/requiredProducts/${id}`, params),
    list: (params?: URLSearchParams): Promise<IRequiredProduct[]> => requests.get('/requiredProducts', params)
}

const ClientInitialStates = {
    list: (): Promise<IClientInitialState[]> => requests.get('/clientInitialStates'),
    yearsWithAny: (): Promise<string[]> => requests.get('/clientInitialStates/yearsWithAny'),
    generate: (year: string) => requests.post(`/clientInitialStates/generate/${year}`, {}),
    details: (id: string): Promise<IClientInitialState> => requests.get(`/clientInitialStates/${id}`),
    create: (clientInitialStateFormValues: IClientInitialStateFormValues) => requests.post('/clientInitialStates', clientInitialStateFormValues),
    edit: (clientInitialStateFormValues: IClientInitialStateFormValues) => requests.put(`/clientInitialStates/${clientInitialStateFormValues.id}`, clientInitialStateFormValues),
    delete: (id: string) => requests.del(`/clientInitialStates/${id}`),
    deleteYear: (year: string) => requests.del(`/clientInitialStates/year/${year}`)
}

const ClientActivities = {
    create: (clientActivityFormValues: IClientActivityFormValues) => requests.post('/clientActivities', clientActivityFormValues),
    edit: (clientActivityFormValues: IClientActivityFormValues) => requests.put(`/clientActivities/${clientActivityFormValues.id}`, clientActivityFormValues),
    list: (params?: URLSearchParams): Promise<IClientActivity[]> => requests.get('/clientActivities', params),
    details: (id: string): Promise<IClientActivity> => requests.get(`/clientActivities/${id}`),
    delete: (id: string) => requests.del(`/clientActivities/${id}`),
    getDoc: (id: string): Promise<Blob> => requests.getFile(`/clientActivities/${id}/doc`),
    postDoc: (id: string, file: Blob) => requests.postForm(`/clientActivities/${id}/doc`, file),
    yearsWithAny: (): Promise<string[]> => requests.get('/clientActivities/yearsWithAny')
}

const ClientSchedules = {
    create: (clientScheduleFormValues: IClientScheduleFormValues) => requests.post('/clientSchedules', clientScheduleFormValues),
    edit: (clientScheduleFormValues: IClientScheduleFormValues) => requests.put(`/clientSchedules/${clientScheduleFormValues.id}`, clientScheduleFormValues),
    list: (params?: URLSearchParams): Promise<IClientSchedule[]> => requests.get('/clientSchedules', params),
    delete: (id: string) => requests.del(`/clientSchedules/${id}`),
    details: (id: string): Promise<IClientSchedule> => requests.get(`/clientSchedules/${id}`),
    detailsByClientAndYear: (clientId: string, year: string): Promise<IClientSchedule> =>
        requests.get(`/clientSchedules/byClientAndYear/${clientId}/${year}`),
    yearsWithAny: (): Promise<string[]> => requests.get('/clientSchedules/yearsWithAny')
}

const ClientGoals = {
    create: (clientGoalFormValues: IClientGoalFormValues) => requests.post('/clientGoals', clientGoalFormValues),
    edit: (clientGoalFormValues: IClientGoalFormValues) => requests.put(`/clientGoals/${clientGoalFormValues.id}`, clientGoalFormValues),
    delete: (id: string) => requests.del(`/clientGoals/${id}`),
    details: (id: string): Promise<IClientGoal> => requests.get(`/clientGoals/${id}`),
    list: (params?: URLSearchParams): Promise<IClientGoal[]> => requests.get('/clientGoals', params),
    setStatus: (metFormValues: { id: string, status: ClientGoalStatus, statusNote: string }) => requests.put(`/clientGoals/${metFormValues.id}/setStatus`, metFormValues),
    categories: (): Promise<IClientGoalCategoryWithProperties[]> => requests.get('/clientGoals/categories'),
    updateCompletedDate: (id: string, date: string | null) => requests.put(`/clientGoals/${id}/updateCompletedDate`, {
        date
    }),
    updatePlanedDate: (id: string, date: string | null) => requests.put(`/clientGoals/${id}/updatePlanedDate`, {
        date
    })

}

const ClientGoalComments = {
    list: (params?: URLSearchParams): Promise<IClientGoalComment[]> => requests.get('/clientGoalComments', params)
}

const ClientGoalDocuments = {
    create: (clientGoalDocumentFormValues: IClientGoalDocumentFormValues) => {
        const formData = new FormData()
        Object.entries(clientGoalDocumentFormValues)
            .forEach(entry => formData.append(entry[0], entry[1]))

        return requests.postFormData('/clientGoalDocuments', formData)
    },
    getFile: (id: string): Promise<Blob> => requests.getFile(`/clientGoalDocuments/${id}/doc`),
    delete: (id: string) => requests.del(`/clientGoalDocuments/${id}`)

}

const EmployeeGoals = {
    create: (employeeGoalFormValues: IEmployeeGoalFormValues) => requests.post('/employeeGoals', employeeGoalFormValues),
    edit: (employeeGoalFormValues: IEmployeeGoalFormValues) => requests.put(`/employeeGoals/${employeeGoalFormValues.id}`, employeeGoalFormValues),
    delete: (id: string) => requests.del(`/employeeGoals/${id}`),
    details: (id: string): Promise<IEmployeeGoal> => requests.get(`/employeeGoals/${id}`),
    list: (params?: URLSearchParams): Promise<IEmployeeGoal[]> => requests.get('/employeeGoals', params),
    setStatus: (metFormValues: { id: string, status: EmployeeGoalStatus, statusNote: string }) => requests.put(`/employeeGoals/${metFormValues.id}/setStatus`, metFormValues),
    categories: () => requests.get('/employeeGoals/categories'),
    updateCompletedDate: (id: string, date: string | null) => requests.put(`/employeeGoals/${id}/updateCompletedDate`, {
        date
    }),
    updatePlanedDate: (id: string, date: string | null) => requests.put(`/employeeGoals/${id}/updatePlanedDate`, {
        date
    })

}

const EmployeeGoalDocuments = {
    create: (employeeGoalDocumentFormValues: IEmployeeGoalDocumentFormValues) => {
        const formData = new FormData()
        Object.entries(employeeGoalDocumentFormValues)
            .forEach(entry => formData.append(entry[0], entry[1]))

        return requests.postFormData('/employeeGoalDocuments', formData)
    },
    getFile: (id: string): Promise<Blob> => requests.getFile(`/employeeGoalDocuments/${id}/doc`),
    delete: (id: string) => requests.del(`/employeeGoalDocuments/${id}`)

}

const EmployeeGoalComments = {
    list: (params?: URLSearchParams): Promise<IEmployeeGoalComment[]> => requests.get('/employeeGoalComments', params)
}

const ClientBrands = {
    add: (brandId: number, clientId: string) => requests.post(`/clientBrands/${brandId}/${clientId}`, {}),
    remove: (brandId: number, clientId: string) => requests.del(`/clientBrands/${brandId}/${clientId}`),
    generateUsedBrands: (clientId?: string) => requests.post('/clientBrands/generateUsedBrands', {clientId}),
    usedByClient: (clientId: string): Promise<IBrand[]> => requests.get(`/clientBrands/${clientId}`)
}

const ClientEmployees = {
    edit: (clientEmployee: IClientEmployee) => requests.put(`/clientEmployees/${clientEmployee.id}`, clientEmployee),
    add: (clientEmployee: IClientEmployee) => requests.post('/clientEmployees', clientEmployee),
    remove: (id: string) => requests.del(`/clientEmployees/${id}`),
    list: (params?: URLSearchParams): Promise<IClientEmployee[]> => requests.get('/clientEmployees', params)
}

const BillOrders = {
    list: (params?: URLSearchParams): Promise<IBillOrderAnalyzeEntity[]> => requests.get('/billOrders', params)
}

const GeneralExpenses = {
    create: (generalExpenseFormValues: IGeneralExpenseFormValues) => requests.post('/generalExpenses', generalExpenseFormValues),
    edit: (generalExpenseFormValues: IGeneralExpenseFormValues) => requests.put(`/generalExpenses/${generalExpenseFormValues.id}`, generalExpenseFormValues),
    delete: (id: string) => requests.del(`/generalExpenses/${id}`),
    details: (id: string): Promise<IGeneralExpense> => requests.get(`/generalExpenses/${id}`),
    list: (params?: URLSearchParams): Promise<IGeneralExpense[]> => requests.get('/generalExpenses', params),
    categories: (): Promise<string[]> => requests.get('/generalExpenses/categories')
}

const ProductConsumptions = {
    create: (productConsumptionFormValues: IProductConsumptionFormValues) => requests.post('/productConsumptions', productConsumptionFormValues),
    edit: (productConsumptionFormValues: IProductConsumptionFormValues) => requests.put(`/productConsumptions/${productConsumptionFormValues.id}`, productConsumptionFormValues),
    delete: (id: string) => requests.del(`/productConsumptions/${id}`),
    details: (id: string): Promise<IProductConsumption> => requests.get(`/productConsumptions/${id}`),
    list: (params?: URLSearchParams): Promise<IProductConsumption[]> => requests.get('/productConsumptions', params)
}

const ProductConsumptionItems = {
    list: (params?: URLSearchParams): Promise<IProductConsumptionItem[]> => requests.get('/productConsumptionItems', params)
}

const Documentations = {
    create: (documentationFormValues: IDocumentationFormValues) => requests.post('/documentations', documentationFormValues),
    edit: (documentationFormValues: IDocumentationFormValues) => requests.put(`/documentations/${documentationFormValues.id}`, documentationFormValues),
    delete: (id: string) => requests.del(`/documentations/${id}`),
    details: (id: string): Promise<IDocumentation> => requests.get(`/documentations/${id}`),
    list: (folderId: string): Promise<IDocumentation[]> => requests.get(`/documentations/folder/${folderId}`),
    categories: (folderId?: string) => {
        const params = new URLSearchParams()
        if (folderId) {
            params.append('folderId', folderId)
        }

        return requests.get('/documentations/categories', params)
    },
}

const DocumentationDocuments = {
    create: (documentationDocumentFormValues: IDocumentationDocumentFormValues) => {
        const formData = new FormData()
        Object.entries(documentationDocumentFormValues)
            .forEach(entry => formData.append(entry[0], entry[1]))

        return requests.postFormData('/documentationDocuments', formData)
    },
    getFile: (id: string): Promise<Blob> => requests.getFile(`/documentationDocuments/${id}/file`),
    delete: (id: string) => requests.del(`/documentationDocuments/${id}`)

}

const DocumentationFolders = {
    list: (params?: URLSearchParams): Promise<IDocumentationFolder[]> => requests.get('/documentationFolders', params)
}

const ClientComments = {
    list: (params?: URLSearchParams): Promise<IClientComment[]> => requests.get('/clientComments', params)
}

const Profiles = {
    get: (username: string): Promise<IProfile> => requests.get(`/profiles/${username}`),
    uploadPhoto: (photo: Blob): Promise<IPhoto> => requests.postForm(`/photos`, photo),
    setMainPhoto: (id: string) => requests.post(`/photos/${id}/setMain`, {}),
    deletePhoto: (id: string) => requests.del(`/photos/${id}`),
    updateProfile: (profile: Partial<IProfile>) =>
        requests.put(`/profiles`, profile)
}

const ClientTours = {
    create: (clientTourFormValues: IClientTourFormValues) => requests.post('/clientTours', clientTourFormValues),
    edit: (clientTourFormValues: IClientTourFormValues) => requests.put(`/clientTours/${clientTourFormValues.id}`, clientTourFormValues),
    update: (clientTourFormValues: IClientTourFormValues) => requests.put(`/clientTours/${clientTourFormValues.id}/update`, clientTourFormValues),
    details: (id: string): Promise<IClientTour> => requests.get(`/clientTours/${id}`),
    delete: (id: string) => requests.del(`/clientTours/${id}`),
    list: (params?: URLSearchParams): Promise<IClientTour[]> => requests.get('/clientTours', params),
}

const UserPosts = {
    list: (params?: URLSearchParams): Promise<IUserPostsEnvelope> => requests.get('/userPosts', params),
    details: (id: string): Promise<IUserPost> => requests.get(`/userPosts/${id}`),
    unreadByUserCount: (): Promise<number> => requests.get('/userPosts/unreadByMeCount')
}

const UserPostsComments = {
    list: (params?: URLSearchParams): Promise<IUserPostComment[]> => requests.get('/userPostsComments', params)
}

const PostExpressDocuments = {
    details: (id: string): Promise<IPostExpressDocument> => requests.get(`/postExpressDocuments/${id}`),
    list: (params?: URLSearchParams): Promise<IPostExpressDocument[]> => requests.get('/postExpressDocuments', params),
    create: (postExpressDocumentFormValues: IPostExpressDocumentFormValues) => {
        const formData = new FormData()
        Object.entries(postExpressDocumentFormValues)
            .forEach(entry => formData.append(entry[0], entry[1]))

        return requests.postFormData('/postExpressDocuments', formData)
    },
    getFile: (id: string): Promise<Blob> => requests.getFile(`/postExpressDocuments/${id}/file`),
    delete: (id: string) => requests.del(`/postExpressDocuments/${id}`)
}

const CurrentMonthTraffic = {
    get: (): Promise<number> => requests.get('/currentMonthTrafficSum')
}

export default {
    User,
    Clients,
    Regions,
    Products,
    Brands,
    Pricelists,
    Bills,
    BillsInstallments,
    Traffics,
    Payments,
    Users,

    GlobalSettings,
    ClientOrders,
    OnHoldProducts,
    RequiredProducts,
    ClientInitialStates,
    ClientActivities,
    ClientSchedules,
    ClientGoals,
    ClientGoalComments,
    ClientGoalDocuments,
    EmployeeGoals,
    EmployeeGoalDocuments,
    EmployeeGoalComments,
    ClientBrands,
    ClientEmployees,
    BillOrders,
    GeneralExpenses,
    ProductConsumptions,
    ProductConsumptionItems,
    Documentations,
    DocumentationDocuments,
    DocumentationFolders,
    ClientComments,
    Profiles,
    ClientTours,
    UserPosts,
    UserPostsComments,
    PostExpressDocuments,
    CurrentMonthTraffic
}
