import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../../core/stores/rootStore";
import LoadingComponent from "../../../core/layout/LoadingComponent";
import {observer} from "mobx-react-lite";
import {Button, Divider, Grid, Icon, Loader} from "semantic-ui-react";
import ClientOrdersList from "../list/ClientOrdersList";
import InfiniteScroll from 'react-infinite-scroller';
import ClientOrdersFilters from "./ClientOrdersFilters";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import {ClientOrderCompleteness} from "../../../core/models/clientsOrders";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {Link} from "react-router-dom";
import PostExpressAddDocument from "../postExpressDocuments/commom/PostExpressAddDocument";
import {useResponsive} from "../../../core/common/responsive/Responsive";

interface IProps {

}

const ClientOrdersDashboard: FunctionComponent<IProps> = () => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadClientOrders,
        loading,
        setPage,
        page,
        totalPages,
        setClientIdFilter,
        loadClientOrdersToRegistry,
        setCompletenessFilter,
        startDateFilter,
        endDateFilter,
        resetPagingClearRegistryAndLoadClientOrders
    } = rootStore.clientOrdersStore
    const {
        loadPostExpressDocuments,
        setDateRangePredicate: setPostExpressDateRangePredicate,
        clearPredicates: clearPostExpressPredicates
    } = rootStore.postExpressDocumentsStore
    const {currentTraffic} = rootStore.trafficsStore

    useNavbarSelectedItem('clientOrders')
    const { isMobile } = useResponsive()
    
    const [loadingNext, setLoadingNext] = useState(false)

    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadClientOrders().then(() => setLoadingNext(false));
    };


    useEffect(() => {
        setClientIdFilter(null)
        setCompletenessFilter(ClientOrderCompleteness.Uncompleted)
        
        resetPagingClearRegistryAndLoadClientOrders()
        // loadClientOrders()

        // return () => {
        //     loadClientOrdersToRegistry([])
        // }

    }, [
        setCompletenessFilter,
        loadClientOrdersToRegistry,
        // loadClientOrders,
        setClientIdFilter,
        resetPagingClearRegistryAndLoadClientOrders
    ])

    useEffect(() => {
        clearPostExpressPredicates()
        setPostExpressDateRangePredicate(startDateFilter, endDateFilter)
        loadPostExpressDocuments()

    }, [
        clearPostExpressPredicates,
        setPostExpressDateRangePredicate,
        loadPostExpressDocuments,
        startDateFilter,
        endDateFilter
    ])

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <BreadcrumbNavigation
                        items={[{
                            text: 'Porudžbine',
                            active: true
                        }]}
                    />
                </Grid.Column>

                <Grid.Column
                    floated={'right'}
                    computer={6} tablet={8} mobile={16}
                >
                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/postExpressDocuments`}
                        icon
                        labelPosition='left'
                        fluid={isMobile}
                    >
                        <Icon name='list'/>
                        PostExpress dokumenta
                    </Button>
                    
                    <PostExpressAddDocument fluid={isMobile} />
                    
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column only='mobile' width={16}>
                    <ClientOrdersFilters vertical={true}/>
                </Grid.Column>
                <Grid.Column computer={10} tablet={12} mobile={16}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleGetNext}
                        hasMore={!loadingNext && page + 1 < totalPages}
                        initialLoad={false}
                    >
                        {loading && page === 0 ?
                            <LoadingComponent content='Učitavanje porudžbina'/> :
                            <ClientOrdersList/>}
                    </InfiniteScroll>
                </Grid.Column>
                <Grid.Column only='computer tablet' computer={6} tablet={4}>
                    <ClientOrdersFilters vertical={true}/>
                </Grid.Column>
                <Grid.Column computer={10} tablet={12} mobile={16} textAlign='center'>
                    <Divider hidden />
                    {(!loadingNext && page + 1 < totalPages) &&
                    <Button
                        onClick={() => handleGetNext()}
                        content='Učitaj još'
                        color='blue'
                    />}
                    <Loader inline active={loadingNext}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>

    )
}

export default observer(ClientOrdersDashboard)