import {createValidator} from "revalidate";

export const isValidEmail = createValidator(
    message => value => {
        if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return message
        }
    },
    'Neispravna e-mail adresa'
)

export const isBetweenNumbers = (min: number, max: number) => createValidator(
    message => value => {
        if (value < min || value > max) {
            return message;
        }
    },

    field => `${field} mora biti u opsegu od ${min} do ${max}`
);

export const isNumberGreaterThan = (number: number) => createValidator(
    message => value => {
        if (value <= number) {
            return message;
        }
    },

    field => `${field} mora biti u veći od ${number}`
);

export const isDecimal = (digits?: number) =>  createValidator(
    message => value => {
        
        const limit = digits ?? 50
        
        let regexString = `^-{0,1}\\d*\\.{0,1}\\d{0,${limit}}$`
        let regex = new RegExp(regexString)
        
        if (value && !regex.test(value)) {
            return message
        }
    },
    digits ? `Mora biti realan broj na ${digits} decimale` : 'Mora biti realan broj'
)

export const arrayNotEmpty = createValidator(
    message => value => {
        
        if (value.length === 0) {
            
            return message
        }
    },
    'Niz ne može biti prazan'
)


export const valueMissing = (value: any): boolean => {
    return value == null || (typeof value === 'string' && value.trim() === '');
}

// export const isRequiredIfMayne = createValidator(
//     (message, condition: (any) => boolean) => (value, allValues) => {
//         if (condition(allValues) && valueMissing(value)) {
//             return message;
//         }
//     },
//
//     field => `${field} is required`,
// );