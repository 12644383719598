export interface ITraffic {
    id: number
    name: string
    description: string,
    isDefault: boolean,
    isExpense: boolean
    billIdStringPrefix: string
}

export class Traffic implements ITraffic {
    billIdStringPrefix: string;
    description: string;
    id: number;
    isDefault: boolean;
    isExpense: boolean;
    name: string;
    
    constructor(trafficFormValues: ITrafficFormValues) {
        this.billIdStringPrefix = trafficFormValues.billIdStringPrefix
        this.description = trafficFormValues.description
        this.id = trafficFormValues.id!
        this.isDefault = trafficFormValues.isDefault
        this.isExpense = trafficFormValues.isExpense
        this.name = trafficFormValues.name
    }
    
}

export interface ITrafficFormValues {
    id: number | null
    name: string
    description: string,
    isDefault: boolean,
    isExpense: boolean
    billIdStringPrefix: string

}

export class TrafficFormValues implements ITrafficFormValues {
    description = ''
    id: number | null = null
    isDefault = false
    isExpense = false
    name = ''
    billIdStringPrefix = ''
    
    constructor(trafficFormValues?: ITrafficFormValues) {
        if (trafficFormValues) {
            this.billIdStringPrefix = trafficFormValues.billIdStringPrefix
            this.description = trafficFormValues.description
            this.id = trafficFormValues.id
            this.isDefault = trafficFormValues.isDefault
            this.isExpense = trafficFormValues.isExpense
            this.name = trafficFormValues.name
        }
    
    }
}