import {SemanticCOLORS} from "semantic-ui-react";

const red = '#DB2828'
const orange = '#F2711C'
const yellow = '#FBBD08'
const olive = '#B5CC18'
const green = '#21BA45'
const teal = '#00B5AD'
const blue = '#2185D0'
const violet = '#6435C9'
const purple = '#A333C8'
const pink = '#E03997'
const brown = '#A5673F'
const grey = '#767676'
const black = '#1B1C1D'

export const stringToColor = (stringColor: string): SemanticCOLORS | undefined => {
    switch (stringColor) {
        case 'red':
            return 'red'
        case 'orange':
            return 'orange'
        case 'yellow':
            return 'yellow'
        case 'olive':
            return 'olive'
        case 'green':
            return 'green'
        case 'teal':
            return 'teal'
        case 'blue':
            return 'blue'
        case 'violet':
            return 'violet'
        case 'purple':
            return 'purple'
        case 'pink':
            return 'pink'
        case 'brown':
            return 'brown'
        case 'grey':
            return 'grey'
        case 'black':
            return 'red'
        default:
            return undefined
    }
}

export const semanticColorToHash = (color: SemanticCOLORS | null | undefined, defaultColor: string = black): string => {
    switch (color) {
        case 'red':
            return red
        case 'orange':
            return orange
        case 'yellow':
            return yellow
        case 'olive':
            return olive
        case 'green':
            return green
        case 'teal':
            return teal
        case 'blue':
            return blue
        case 'violet':
            return violet
        case 'purple':
            return purple
        case 'pink':
            return pink
        case 'brown':
            return brown
        case 'grey':
            return grey
        case 'black':
            return black
        default:
            return defaultColor
    }
} 

export const roleToColorName = (role: string) => {
    switch (role) {
        case 'Employee':
            return 'teal'
        case 'TrafficManager':
            return 'blue'
        case 'SuperAdministrator':
            return 'violet'
        default:
            return 'grey'
    }
}
