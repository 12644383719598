import {FilterProps} from "react-table";
import React from "react";
import {Select} from "semantic-ui-react";
import {BillTypeFilter as BillTypeFilterClass, IBillTypeFilter} from "./billTypeFilter";
import CompactFilterLayout from "../../common/CompactFilterLayout";
import {BillCreationType} from "../../../../../models/bills";

export const BillTypeFilter = ({
                                   column: {
                                       filterValue,
                                       preFilteredRows,
                                       filteredRows,
                                       setFilter,
                                       filter
                                   }
                               }: FilterProps<IBillTypeFilter>,
) => {
    
    return <CompactFilterLayout
        filterValue={filterValue || new BillTypeFilterClass()}
        onClearFilterClick={() => setFilter(new BillTypeFilterClass())}
    >
        <Select
            clearable
            fluid
            button
            basic
            options={[
                {key: 'only', text: 'DA(Gratis)', value: 'only'},
                {key: 'excluded', text: 'NE(Gratis)', value: 'excluded'},
            ]}
            value={filterValue?.gratis}
            placeholder='Gratis'
            onChange={(event, data) => setFilter({
                ...filterValue,
                gratis: data.value,
                onSale: data.value ? null : filterValue.onSale
            })}
        />
        <Select
            clearable
            fluid
            button
            basic
            options={[
                {key: 'only', text: 'DA(Akcijski promet)', value: 'only'},
                {key: 'excluded', text: 'NE(Akcijski promet)', value: 'excluded'},
            ]}
            value={filterValue?.onSale}
            placeholder='Akcijski promet'
            onChange={(event, data) => setFilter({
                ...filterValue,
                onSale: data.value,
                gratis: data.value ? null : filterValue.gratis
            })}
        />
        <Select
            clearable
            fluid
            button
            basic
            options={[
                {key: 'onlyInvoice', text: 'Fakture', value: 'onlyInvoice'},
                {key: 'onlySpecs', text: 'Specifikacije', value: 'onlySpec'},
            ]}
            value={filterValue?.type}
            placeholder='Tip'
            onChange={(event, data) => setFilter({...filterValue, type: data.value})}
        />
        <Select
            clearable
            fluid
            button
            basic
            options={[
                {key: BillCreationType.Cart, text: 'Korpa', value: BillCreationType.Cart},
                {key: BillCreationType.Manual, text: 'Ručno', value: BillCreationType.Manual},
            ]}
            value={filterValue?.creationType}
            placeholder='Način izrade'
            onChange={(event, data) => setFilter({...filterValue, creationType: data.value})}
        />
    </CompactFilterLayout>
}
            
            