import React, {FunctionComponent, useContext, useEffect} from 'react';
import UsersList from "../list/UsersList";
import { Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface IProps {
}

const UsersDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loadRoles, loadUsers, loadingUsers, loadingRoles} = rootStore.usersStore

    useNavbarSelectedItem('usersDashboard')

    useEffect(() => {
        loadUsers()
        loadRoles()
    }, [loadUsers, loadRoles])

    return (<>
        <BreadcrumbNavigation
            items={[
                {
                    text: 'Korisnici',
                    active: true
                }
            ]}
        />
        <Segment color='blue' loading={loadingUsers || loadingRoles}>
            <UsersList/>
        </Segment>
    </>);
};

export default observer(UsersDashboard)
