import React, {FunctionComponent} from 'react'
import ScheduleSectionLayout from "../../scheduleSection/layout/ScheduleSectionLayout";
import {observer} from "mobx-react-lite";
// must go before plugins
// a plugin!
import ClientToursCalendar from "../calendar/ClientToursCalendar";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";

interface OwnProps {}

type Props = OwnProps


const ClientToursDashboard: FunctionComponent<Props> = (props) => {
    
    useNavbarSelectedItem('clientTours')
    
    
    
    return (<ScheduleSectionLayout
        // contentLoading={false}
    >
        <ClientToursCalendar />
    </ScheduleSectionLayout>)
}

export default observer(ClientToursDashboard)
