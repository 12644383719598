import React, {FunctionComponent, useState, useContext, useEffect, useLayoutEffect} from 'react';
import {useSortBy, useTable, usePagination, useRowState, useFilters} from "react-table";
import {Button, Icon, Table as SemanticTable} from 'semantic-ui-react'
import {matchSorter} from 'match-sorter'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../stores/rootStore";
import {DefaultColumnFilter} from "./filters/DefaultColumnFilter";
import {clientDetailedFilter} from "./filters/clients/logic/clientDetailedFilter";
import {ClientFilter, IClient} from "../../models/clients";
import {dateFilter} from "./filters/date/dateFilter";
import moment from "moment";
import {ProductsFilter} from "../../models/products";
import {productsDetailedFilter} from "./filters/products/detailed/productsDetailedFilter";
import {numberFilter} from "./filters/number/numberFilter";
import {trueFalseAllFilter} from "./filters/trueFallseAll/trueFalseAllFilter";
import {getClientRankByStatusAndType} from "../util/clients";
import {multiselectFilter} from "./filters/multiselect/multiselectFilter";
import {billTypeFilter} from "./filters/bills/type/billTypeFilter";
import {ClientGoalFilter} from "../../models/clientGoals";
import {clientGoalFilter} from "./filters/clientGoals/clientGoalFilter";
import {EmployeeGoalFilter} from "../../models/employeeGoals";
import {employeeGoalFilter} from "./filters/employeeGoals/employeeGoalFilter";


interface IProps {
    columns: any[]
    data: any[],
    trackingId?: string,
    maxOpenWith?: number
}


function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
    return matchSorter(rows, filterValue, {keys: [(row: any) => row.values[id]]});
}


const Table: FunctionComponent<IProps> = ({columns, data, trackingId, maxOpenWith}) => {
    const [showFilters, setShowFilters] = useState(true)

    const rootStore = useContext(RootStoreContext)
    const {setFilteredRows, removeFromRegistry} = rootStore.tablesStore
    const {leftSidebarVisible} = rootStore.navbarStore
    // const {user: currentUser} = rootStore.userStore

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
            clientDetailedFilter,
            productsDetailedFilter,
            dateFilter,
            numberFilter,
            trueFalseAllFilter,
            multiselectFilter,
            billTypeFilter,
            clientGoalFilter,
            employeeGoalFilter
        }),
        []
    );

    const sortTypes = React.useMemo(
        () => ({
            numeric: (rowA: any, rowB: any, id: any, desc: any) => Number(rowA.values[id]) - Number(rowB.values[id]) > 0 ? -1 : 1,
            date: (rowA: any, rowB: any, columnId: any, desc: any) => {
                const rowAValue = rowA.values[columnId]
                const rowBValue = rowB.values[columnId]

                if (moment(rowAValue).isAfter(rowBValue, 'day')) {
                    return 1
                } else {
                    return -1
                }
            },
            clientRank: (rowA: any, rowB: any, columnId: any, desc: any) => {
                const clientA: IClient = rowA.original
                const clientB: IClient = rowB.original

                if (getClientRankByStatusAndType(clientA) === getClientRankByStatusAndType(clientB)) {
                    if (clientA.financialState!.placedBaseValue > clientB.financialState!.placedBaseValue) {
                        return -1
                    } else {
                        return 1
                    }
                } else {
                    if (getClientRankByStatusAndType(clientA) < getClientRankByStatusAndType(clientB)) {
                        return -1
                    } else {
                        return 1
                    }
                }
            },
            clientGoalsLastComment: (rowA: any, rowB: any, columnId: any, desc: any) => {
                const clientGoalA: IClient = rowA.original
                const clientGoalB: IClient = rowB.original

                const lastCommentADate = clientGoalA.comments && clientGoalA.comments.length > 0 ? clientGoalA.comments[clientGoalA.comments.length - 1].createdAt : new Date(-8640000000000000)
                const lastCommentBDate = clientGoalB.comments && clientGoalB.comments.length > 0 ? clientGoalB.comments[clientGoalB.comments.length - 1].createdAt : new Date(-8640000000000000)

                if (moment(lastCommentADate).isAfter(lastCommentBDate)) {
                    return 1
                } else {
                    return -1
                }
            },
            clientSchedulesLastComment: (rowA: any, rowB: any, columnId: any, desc: any) => {
                const clientScheduleA: IClient = rowA.original
                const clientScheduleB: IClient = rowB.original

                const lastCommentADate = clientScheduleA.comments && clientScheduleA.comments.length > 0 ? clientScheduleA.comments[clientScheduleA.comments.length - 1].createdAt : new Date(-8640000000000000)
                const lastCommentBDate = clientScheduleB.comments && clientScheduleB.comments.length > 0 ? clientScheduleB.comments[clientScheduleB.comments.length - 1].createdAt : new Date(-8640000000000000)

                if (moment(lastCommentADate).isAfter(lastCommentBDate)) {
                    return 1
                } else {
                    return -1
                }
            },
             }),
        [
        ]
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // Instead of using 'rows', we'll use page,
        // // which has only the rows for the active page
        // // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // filteredRows,
        rows,
        state: {pageIndex, pageSize},

    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 30,
                hiddenColumns: columns
                    .filter(col => col.hide)
                    .map(col => col.id),
                filters: [
                    {
                        id: 'client',
                        value: new ClientFilter()
                    },
                    {
                        id: 'clientGoal',
                        value: new ClientGoalFilter()
                    },
                    {
                        id: 'employeeGoal',
                        value: new EmployeeGoalFilter()
                    },
                    {
                        id: 'product',
                        value: new ProductsFilter()
                    },
                ]
            },
            defaultColumn,
            filterTypes,
            sortTypes,
            autoResetFilters: false
        },
        useFilters,
        useSortBy,
        usePagination,
        useRowState
    )

    useLayoutEffect(() => {
        if (trackingId) setFilteredRows(trackingId, rows.map(f => f.original))
    }, [
        rows,
        setFilteredRows,
        trackingId,
    ])


    useEffect(() => () => {
        if (trackingId)
            removeFromRegistry(trackingId)
    }, [removeFromRegistry, trackingId])

    return (
        <div style={{
            maxWidth: leftSidebarVisible ? screenWidth - 350 : screenWidth - 150, // todo naci bolje resenja za sugavi table overflow
            height: '100%',
            overflowX: 'scroll'
        }}>

            <SemanticTable
                striped
                compact='very'
                size={"small"} id='myTable' celled  {...getTableProps()}>
                <SemanticTable.Header>
                    <>
                        {showFilters ? headerGroups.map((headerGroup) => (
                                <SemanticTable.Row  {...headerGroup.getHeaderGroupProps()}>
                                    <SemanticTable.HeaderCell>
                                        <Icon color='teal' name='filter' onClick={() => setShowFilters(false)}/>
                                    </SemanticTable.HeaderCell>
                                    {headerGroup.headers.map((column, index) => (
                                        <SemanticTable.HeaderCell key={index}>
                                            <div>{column.canFilter ? column.render("Filter") : null}</div>
                                        </SemanticTable.HeaderCell>
                                    ))}

                                </SemanticTable.Row>
                            )) :
                            <Button
                                type='button'
                                basic
                                icon
                                size='mini'
                                onClick={() => setShowFilters(true)}
                            >
                                <Icon.Group>
                                    <Icon
                                        name='filter'
                                        color='teal'/>
                                    <Icon
                                        name='angle down'
                                        corner
                                        color='teal'
                                    />
                                </Icon.Group>


                            </Button>}

                        {headerGroups.map((headerGroup) => (
                            <SemanticTable.Row {...headerGroup.getHeaderGroupProps()}>
                                <SemanticTable.HeaderCell>
                                    #
                                </SemanticTable.HeaderCell>
                                {headerGroup.headers.map((column) => (
                                    // @ts-ignore
                                    <SemanticTable.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())} width={column.cellWidth}>
                                        {column.render('Header')}
                                        <span>
                                        {column.isSorted ? column.isSortedDesc
                                            ? <Icon name='angle up'/>
                                            : <Icon name='angle down'/>
                                            : ''}
                                    </span>
                                    </SemanticTable.HeaderCell>
                                ))}

                            </SemanticTable.Row>
                        ))}
                    </>
                </SemanticTable.Header>

                <SemanticTable.Body {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <SemanticTable.Row color='red' {...row.getRowProps()}>
                                <SemanticTable.Cell>{pageIndex * pageSize + i + 1}</SemanticTable.Cell>
                                {row.cells.map(cell => {
                                    return <SemanticTable.Cell
                                        {...cell.getCellProps()}
                                        style={{
                                            // @ts-ignore
                                            padding: cell.column.zeroPadding ? 0 : undefined // if i need to put label in corner of cell, padding must be zero TODO BUT STILL NOT PROPERLY WORKING
                                        }}
                                    >{cell.render('Cell')}</SemanticTable.Cell>
                                })}
                            </SemanticTable.Row>
                        )
                    })}
                </SemanticTable.Body>
            </SemanticTable>
            <div className="pagination">
                <Button color='blue' onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                        icon='angle double left'/>
                <Button color='blue' onClick={() => previousPage()} disabled={!canPreviousPage} icon='angle left'/>
                <Button color='blue' onClick={() => nextPage()} disabled={!canNextPage} icon='angle right'/>
                <Button color='blue' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                        icon='angle double right'/>
                <span>
          Strana{' '}
                    <strong>
            {pageIndex + 1} od {pageOptions.length}
          </strong>{' '}
        </span>
                <span>
          | Idi na stranu:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{width: '100px'}}
                    />
        </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[5, 10, 20, 30, 50, 100, 500].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Prikaži {pageSize} po strani
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export default observer(Table)
