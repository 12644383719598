import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import Table from "../../../core/common/tables/Table";
import {Message} from "semantic-ui-react";
import {useClientListColumns} from "./useClientListColumns";

interface IProps {
    hideRegion?: boolean,
    hideBalance?: boolean,
    hideProgress?: boolean,
    hideRank?: boolean,
    trackingId?: string,
}

const ClientsList: FunctionComponent<IProps> = ({hideRegion, hideBalance, hideProgress, trackingId, hideRank}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clientsArray: clients,
        loadClientCategoriesIfNull,
    } = rootStore.clientsStore
    const { loadRegionsIfNull } = rootStore.regionsStore
    const { loadUsers } = rootStore.usersStore
    const { columns } = useClientListColumns({ hideRegion, hideBalance, hideProgress })
    
    useEffect(() => {
        loadUsers(true)
        loadRegionsIfNull()
        loadClientCategoriesIfNull()
    }, [
        loadRegionsIfNull,
        loadClientCategoriesIfNull,
        loadUsers
    ])
    
    if (clients.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje klijenti po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>
    
    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={clients}
        />
    );
};

export default observer(ClientsList)
