export const getPercentageValue = (number: number, percente: number) => number / 100 * percente

export const getPercentageRestValue = (number: number, percente: number) => number / 100 * (100 - percente)

export const getRestOfFixed2 = (number: number, count: number) => {
    const uglySingleValue = number / count
    const prettySingleValue = Number(uglySingleValue.toFixed(2))
    const restValue = number - (count * prettySingleValue)
    
    return restValue
}

export const calculatePercentage = (integrant: number, totality: number, disallowOverload = false) => {
    const percentage = integrant / totality * 100
    
    if (disallowOverload && percentage > 100) {
        return 100
    } else {
        return percentage
    }
}