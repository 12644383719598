import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IBillInstallment} from "../models/billInstallments";
import agent from "../api/agent";

export default class BillsInstallmentsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }
        
    @observable billsInstallments = new Map<string, IBillInstallment>()
    @observable loading = false
    @observable paiding = false
    @observable unpaiding = false
    @observable focusId: string | null = null
    @observable predicate = new Map()


    @computed get billsInstallmentsArray() {
        return Array.from(this.billsInstallments.values())
    }
    
    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @computed get axiosParams () {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }
    
    @action loadBillsInstallments = async () => {
        this.loading = true
        this.billsInstallments.clear()
        try {
            const billsInstallments = await agent.BillsInstallments.list(this.axiosParams)
            runInAction(() => {
                billsInstallments.forEach(installment => {
                    this.billsInstallments.set(installment.id, installment)
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action paidBillInstallment = async (id: string) => {
        this.paiding = true
        this.focusId = id
        try {
            await agent.BillsInstallments.paid(id)
            runInAction(() => {
                let installment = this.billsInstallments.get(id)
                installment!.paid = true
                this.billsInstallments.set(id, installment!)
                let bill = this.rootStore.billsStore.bills.get(installment?.billId!)
                bill!.billInstallments = bill!.billInstallments.map(installment => {
                    if (installment.id === id) {
                        return {
                            ...installment,
                            paid: true
                        }
                    } else {
                        return installment
                    }
                })
                
                this.rootStore.billsStore
                    .loadSingleBillToRegistry(bill!)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.paiding = false
                this.focusId = null                
            })

        }
    }

    @action unpaidBillInstallment = async (id: string) => {
        this.unpaiding = true
        this.focusId = id
        try {
            await agent.BillsInstallments.unpaid(id)
            runInAction(() => {
                let installment = this.billsInstallments.get(id)
                installment!.paid = false
                this.billsInstallments.set(id, installment!)

                let bill = this.rootStore.billsStore.bills.get(installment?.billId!)
                bill!.billInstallments = bill!.billInstallments.map(installment => {
                    if (installment.id === id) {
                        return {
                            ...installment,
                            paid: false
                        }
                    } else {
                        return installment
                    }
                })
                
                this.rootStore.billsStore
                    .loadSingleBillToRegistry(bill!)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.unpaiding = false
                this.focusId = null                
            })
        }
    }
}