import {IProduct} from "./products";
import {v4 as uuid} from 'uuid'
import {IBill} from "./bills";
import {IBrand} from "./brands";
import {IClient} from "./clients";
import {getNiceColorFromStingAsciiSum} from "../common/util/colors";
import moment from "moment";

export const MONTH_YEAR_FORMAT = 'MM-YYYY'
export const YEAR_FORMAT = 'YYYY'

export interface IBillOrder {
    id: string
    productId: string
    productName: string
    productSku: string
    productBrandId: number
    productBrandName: string
    productBrandColor: string
    count: number
    discount: number
    singlePriceWithoutDiscount: number
    singlePriceWithDiscount: number
    summaryPriceWithoutDiscount: number
    summaryPriceWithDiscount: number
    // basePrice: number | null
    // basePriceInRsd: number | null
}

// export interface IBillOrderAnalyzeCompact {
//     id: string
//     product: IProduct
//     client: IClient
//     count: number
//     onSaleNote: string
//     summaryPriceWithoutDiscount: number
//     summaryPriceWithDiscount: number
//     summaryPriceWithDiscountWithBillDiscount: number
//     summaryBasePriceInRsd: number
//     differenceInPrice: number
// }

export class BillOrder implements IBillOrder {
    count: number;
    discount: number;
    id: string
    productBrandId: number;
    productBrandColor: string
    productSku: string;
    productBrandName: string;
    productId: string;
    productName: string;
    singlePriceWithDiscount: number;
    singlePriceWithoutDiscount: number;
    summaryPriceWithDiscount: number;
    summaryPriceWithoutDiscount: number;
    // basePrice: number | null = null
    // basePriceInRsd: number | null = null

    constructor(product: IProduct, count: number, discount: number, price: number) {
        this.id = uuid()

        this.count = count
        this.discount = discount
        this.productBrandId = product.brandId
        this.productBrandName = product.brandName
        this.productBrandColor = product.brandColor
        this.productId = product.id
        this.productName = product.name
        this.productSku = product.sku

        this.singlePriceWithoutDiscount = price
        this.singlePriceWithDiscount = price / 100 * (100 - discount)
        this.summaryPriceWithoutDiscount = count * this.singlePriceWithoutDiscount
        this.summaryPriceWithDiscount = count * this.singlePriceWithDiscount

    }

}

export interface IBillOrderAnalyzeEntity {
    id: string
    brand: IBrand
    client: IClient
    product: IProduct
    monthYearString: string
    yearString: string
    
    billDate: Date
    onSaleNote: string | null
    clientCategory: string | null
    clientCategoryColor: string | null
    summaryBasePriceInRsd: number
    count: number
    summaryPriceWithoutDiscount: number
    summaryPriceWithDiscount: number
    summaryPriceWithDiscountWithBillDiscount: number
    differenceInPrice: number
}

export interface IPeriodAnalyzeEntity {
    summaryPriceWithDiscountWithBillDiscount: number
    differenceInPrice: number
    billDate: Date
    monthYearString: string
    yearString: string
    clientCategoriesShare: { 
        category: string, 
        percentage: number, 
        value: number
    }[]
    expense: number
    profit: number
    
}

export class BillOrderAnalyzeEntity implements IBillOrderAnalyzeEntity {
    brand: IBrand;
    client: IClient;
    clientCategory: string | null;
    monthYearString: string
    yearString: string
    
    billDate: Date
    clientCategoryColor: string | null;
    count: number;
    differenceInPrice: number;
    id: string;
    onSaleNote: string | null;
    product: IProduct;
    summaryBasePriceInRsd: number;
    summaryPriceWithDiscount: number;
    summaryPriceWithDiscountWithBillDiscount: number;
    summaryPriceWithoutDiscount: number;
    
    
    constructor(bill: IBill) {
        this.id = bill.id
        this.product = {
            sku: 'N/A - Ručni račun',
            brandName: 'N/A - Ručni račun',
            name: 'N/A - Ručni račun',
            prices: [],
            description: '',
            brandId: -1,
            id: 'N/A - Ručni račun',
            brandColor: '#72ff00'
        }
        this.client = bill.client
        this.brand = bill.brand
        this.clientCategory = bill.client.category
        this.billDate = bill.date
        this.monthYearString = moment(bill.date).format(MONTH_YEAR_FORMAT)
        this.yearString = moment(bill.date).format(YEAR_FORMAT)
        
        this.clientCategoryColor = getNiceColorFromStingAsciiSum(bill.client.category!)
        this.onSaleNote = bill.onSaleNote
        this.summaryBasePriceInRsd = bill.manualBillBasePrice
        this.differenceInPrice = bill.baseValue - bill.manualBillBasePrice
        this.summaryPriceWithDiscountWithBillDiscount = bill.baseValue
        this.summaryPriceWithDiscount = bill.baseValue
        this.summaryPriceWithoutDiscount = bill.baseValue
        this.count = 1
 
    }
}