import React, {FunctionComponent, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {formatRsd} from "../../../../../core/common/util/format";
import {summator} from "../../../../../core/common/util/array";
import {RootStoreContext} from "../../../../../core/stores/rootStore";
import {Grid} from "semantic-ui-react";
import MyPieChart from "../../../../../core/common/charts/MyPieChart";

interface IProps {
  listId: string
}

const ProductConsumptionsAnalysisByBrandCharts: FunctionComponent<IProps> = ({listId}) => {
  const rootStore = useContext(RootStoreContext)
  const { SHIT_getFilteredRowsById } = rootStore.tablesStore
  
  const filteredRows = SHIT_getFilteredRowsById(listId) ?? []

  const charts =
      [
        {
          id: 'count',
          title: [
            'Količina',
            formatRsd(summator(filteredRows, 'count'))],
          data: filteredRows,
          colors: filteredRows.map(row => row.brand.color),
          xAccessor: 'brand.name',
          yAccessor: 'count'
        },
        {
          id: 'base',
          title: [
            'Nabavna cena',
            formatRsd(summator(filteredRows, 'value'))],
          data: filteredRows,
          colors: filteredRows.map(row => row.brand.color),
          xAccessor: 'brand.name',
          yAccessor: 'value'
        }
      ]
  
  return (<Grid textAlign='center'>
    {charts.map(chart => {
        return (<Grid.Column computer={6} tablet={8} mobile={16}>
          <MyPieChart
              title={chart.title}
              data={chart.data}
              colors={chart.colors}
              xAccessor={chart.xAccessor}
              yAccessor={chart.yAccessor}
          />
        </Grid.Column>)
    })}
  </Grid>)
}

export default observer(ProductConsumptionsAnalysisByBrandCharts)
