import React, {FunctionComponent} from 'react';
import {Header} from "semantic-ui-react";
import {formatRsd} from "../../util/format";
import {sumatorIntegrantTotalityWithPercentage} from "../../util/array";
import {numberToStringToFixedOrInt} from "../../util/string";

interface IProps {
    row: any,
    type: 'money' | 'count'
    name: string
    integrantAccessor: string,
    totalityAccessor: string,
    percentageType: 'sum' | 'average',
    disallowAveragePercentageOverload?: boolean
}

const SumPercentageTableHeader: FunctionComponent<IProps> = ({
                                                                 row,
                                                                 name,
                                                                 type,
                                                                 integrantAccessor,
                                                                 totalityAccessor,
                                                                 percentageType,
                                                                 disallowAveragePercentageOverload
                                                             }) => {


    const {
        percentage,
        totality,
        integrant
    } = sumatorIntegrantTotalityWithPercentage(row.filteredRows, integrantAccessor, totalityAccessor, 'average', true)
    
    return (<>
        <Header sub>{name}</Header>
        {type === 'money' ?
            <strong>Suma: {formatRsd(integrant)} / {formatRsd(totality)}</strong> :
            <strong>Ukupno: {numberToStringToFixedOrInt(integrant, 2)} / {numberToStringToFixedOrInt(totality, 2)}</strong>}

        <p>[{numberToStringToFixedOrInt(percentage, 2)}%]</p>
    </>)
}

export default SumPercentageTableHeader
