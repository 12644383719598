import React, {FunctionComponent} from 'react'
import {history} from "../../../index";
import {Button, ButtonProps, Icon, SemanticCOLORS} from "semantic-ui-react";

interface OwnProps {
    link: string,
    text: string,
    color?: SemanticCOLORS,
}

type Props = OwnProps

const ShortcutButton: FunctionComponent<Props & ButtonProps> = ({ link, text, color, ...rest }) => {
    return <Button
        color={color}
        inverted
        animated
        onClick={() => history.push(link)}
        {...rest as ButtonProps}
    >
        <Button.Content visible>
            {text + ' '}
            <Icon name='arrow right'/>
        </Button.Content>
        <Button.Content hidden>
            <Icon name='arrow right'/>
        </Button.Content>
    </Button>
}

export default ShortcutButton

ShortcutButton.defaultProps = {
    color: 'orange'
}