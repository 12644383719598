import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Button, Grid, Input, Message, Segment, Select} from "semantic-ui-react";
import {formatRsd} from "../../../core/common/util/format";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import BillCartOrdersList from "./list/BillCartOrdersList";
import LoadingComponent from "../../../core/layout/LoadingComponent";

interface IProps {
}


const BillCartProducts: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        selectedProductId, setSelectedProductId,
        selectedProductSku, setSelectedProductSku,
        selectedBrandId, setSelectedBrandId,
        selectedProductCount, setSelectedProductCount,
        selectedProductDiscount, setSelectedProductDiscount,
        selectedProductPrice,
        selectedProduct,
        addOrderFromSelected,
        client
    } = rootStore.billsCartStore
    const {productsByNameArray: products, loadProducts, loading: productsLoading} = rootStore.productsStore
    const {brandsArray: brands, loadBrands, loading: brandsLoading} = rootStore.brandsStore

    useEffect(() => {
        loadBrands()
        loadProducts()
    }, [loadProducts, loadBrands])

    const handleAddOrderOnEnter = (e: KeyboardEvent) => {
        if ((e.key === 'Enter') && selectedProduct !== null && client !== null) {
            addOrderFromSelected()
        }
    }
    
    if (productsLoading) return <LoadingComponent content='Učitavanje proizvoda...' />

    if (products.length === 0) 
        return <div style={{marginTop: 50}}>
                <Message warning>
                    <Message.Header>Ne postoje proizvodi u sistemu</Message.Header>
                    <p>Dodaj proizvode pa pokušaj opet.</p>
                </Message>
            </div>
    
    return (<Segment loading={productsLoading || brandsLoading} >
            
                <Grid columns='equal'>
                    <Grid.Row columns={3}>
                        <Grid.Column computer={5} mobile={16}>
                            <Select
                                placeholder='Brend'
                                fluid
                                search
                                value={selectedBrandId}
                                onChange={((event: any, data) => setSelectedBrandId(data.value?.toString()!))}
                                options={[{
                                    key: 0,
                                    value: '',
                                    text: 'Brend'
                                },
                                    ...brands!.map(brand => ({
                                        key: brand.id,
                                        value: brand.id.toString(),
                                        text: brand.name
                                    }))
                                ]}
                            />
                        </Grid.Column>
                        <Grid.Column computer={5} mobile={16}>
                            <Select
                                search
                                placeholder='Naziv'
                                fluid
                                value={selectedProductId}
                                onChange={(event, data) => setSelectedProductId(data.value?.toString()!)}
                                options={[
                                    {
                                        key: 0,
                                        text: 'Proizvod'
                                    },
                                    ...products!
                                        .filter(product =>
                                            selectedBrandId !== '' ? product.brandId.toString() === selectedBrandId : true)
                                        .map(product => ({
                                            key: product.id,
                                            value: product.id,
                                            text: product.name
                                        }))]}
                            />
                        </Grid.Column>
                        <Grid.Column computer={5} mobile={16}>
                            <Input
                                placeholder='Šifra'
                                fluid
                                value={selectedProductSku}
                                onChange={((event, data) => setSelectedProductSku(data.value?.toString()!))}
                                onKeyPress={handleAddOrderOnEnter}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column computer={5} mobile={16}>
                            <Input
                                placeholder='Količina'
                                fluid
                                labelPosition='right'
                                label={{basic: true, content: 'komada'}}
                                type='number'
                                step={1}
                                min={0}
                                max={100}
                                value={selectedProductCount}
                                onChange={(event => setSelectedProductCount(Number(event.target.value)))}
                            />
                        </Grid.Column>
                        <Grid.Column computer={5} mobile={16}>
                            {selectedProductPrice &&
                            <Input
                                placeholder='Popust'
                                fluid
                                min={0}
                                step={1}
                                type='number'
                                labelPosition='right'
                                label={{basic: true, content: 'rabat (%)'}}
                                value={selectedProductDiscount}
                                onChange={(event => setSelectedProductDiscount(Number(event.target.value)))}
                            />}
                        </Grid.Column>
                        <Grid.Column computer={5} mobile={16}>
                            {selectedProductPrice.price &&
                            <Button
                                type='button'
                                floated='right'
                                onClick={() => addOrderFromSelected()}
                                color='blue'
                                icon='add to cart'
                                content='Dodaj'
                                label={formatRsd(selectedProductPrice.price.value!)}
                                labelPosition='left'
                            />}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <BillCartOrdersList/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
        </Segment>
    );
};

export default observer(BillCartProducts)
