import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import ClientActivitiesList from "../list/ClientActivitiesList";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import moment from "moment";
import {Link} from "react-router-dom";
import {PdfService} from "../../../core/common/pdf/PdfService";
import {formatDateOrDefault} from "../../../core/common/util/date";
import autoTable from "jspdf-autotable";
import {formatRsd} from "../../../core/common/util/format";
import {IClientActivity} from "../../../core/models/clientActivities";
import {stringPreview} from "../../../core/common/util/string";
import {convertStringToPlainText} from "../../../core/common/util/draft-js";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {DatePicker} from "antd";

const TRACKING_ID = 'clientActivitiesList'

interface IProps {
}

const ClientActivitiesDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadClientActivities,
        loading,
        setPredicate,
        clearPredicate
    } = rootStore.clientActivitiesStore
    const {currentTraffic} = rootStore.trafficsStore
    const {removeFromRegistry, SHIT_getFilteredRowsById} = rootStore.tablesStore

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))

    useNavbarSelectedItem('clientActivities')

    useEffect(() => () => {
        removeFromRegistry(TRACKING_ID)
    }, [
        removeFromRegistry,
    ])


    useEffect(() => {
        clearPredicate()
        setPredicate('startDate', startDate)
        setPredicate('endDate', endDate)
        loadClientActivities()
    }, [
        loadClientActivities,
        startDate,
        endDate,
        setPredicate,
        clearPredicate])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        doc.text(`Tabela aktivnosit za period ${formatDateOrDefault(startDate, 'start')} - ${formatDateOrDefault(endDate, 'end')}`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                ['#', 'Tip', 'Datum', 'Klijent', 'Sadržaj', 'Zapažanje', 'Trošak', 'Dokument']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((clientActivity: IClientActivity, index) => ([
                index + 1,
                clientActivity.type,
                moment(clientActivity.date).format('l'),
                clientActivity.client.name,
                stringPreview(convertStringToPlainText(clientActivity.content), 200),
                clientActivity.observation,
                formatRsd(clientActivity.cost),
                clientActivity.hasDocument ? 'Da' : 'Ne'
            ])) ?? []
        })

        doc.save("Aktivnosti.pdf")
    }


    return (

        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <BreadcrumbNavigation
                        items={[
                            {
                                text: 'Aktivnosti',
                                active: true
                            }
                        ]}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/clientActivities/create`}
                        floated='right'
                        color='blue'
                        icon
                        labelPosition='left'>
                        <Icon name='add'/>
                        Dodaj aktivnost
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment.Group>
                        <Segment color='blue' attached='top' clearing>

                            <DatePicker.RangePicker
                                // disabled={loading}
                                placeholder={['Početni datum', 'Krajni datum']}
                                format='DD.MM.YYYY.'
                                onChange={(date, dateString) => {
                                    setStartDate(date && date[0] ? moment(date[0]).format('YYYY-MM-DD') : '')
                                    setEndDate(date && date[1] ? moment(date[1]).format('YYYY-MM-DD') : '')

                                }}
                                value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
                            />
                            
                            {/*<Input*/}
                            {/*    label={{basic: true, content: 'Od'}}*/}
                            {/*    type='date'*/}
                            {/*    value={startDate}*/}
                            {/*    onChange={((event, data) => setStartDate(data.value))}*/}
                            {/*/>*/}
                            {/*<Input*/}
                            {/*    label={{basic: true, content: 'Do'}}*/}
                            {/*    type='date'*/}
                            {/*    value={endDate}*/}
                            {/*    onChange={((event, data) => setEndDate(data.value))}*/}
                            {/*/>*/}
                            <Button
                                disabled={loading}
                                icon
                                labelPosition='left'
                                floated='right'
                                color='teal'
                                onClick={() => exportPdf()}>
                                <Icon name='file pdf'/>
                                PDF
                            </Button>
                        </Segment>
                        <Segment loading={loading}>
                            <ClientActivitiesList trackingId={TRACKING_ID}/>
                        </Segment>
                    </Segment.Group>
                </Grid.Column>

            </Grid.Row>
        </Grid>

    );
};

export default observer(ClientActivitiesDashboard)
