import {Input} from "semantic-ui-react";
import React from "react";

export const DefaultColumnFilter = ({column: {filterValue, preFilteredRows, setFilter}}: any) => {
    const count = preFilteredRows.length;
    return (
        <Input
            size='mini'
            fluid
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Pretraži ${count} zapisa...`}
        />
    );
}