import React, {useContext, useEffect} from 'react';
import {Grid} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import {RouteComponentProps} from 'react-router';
import LoadingComponent from '../../../core/layout/LoadingComponent'
import {RootStoreContext} from "../../../core/stores/rootStore";
import EmployeeGoalHeader from "./EmployeeGoalHeader";
import EmployeeGoalDetailedSidebar from "./EmployeeGoalDetailedSidebar";
import Responsive from "../../../core/common/responsive/Responsive";
import EmployeeGoalDetailedInfo from "./EmployeeGoalDetailedInfo";
import EmployeeGoalDetailedChat from "./EmployeeGoalDetailedChat";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {EmployeeGoalType} from "../../../core/models/employeeGoals";

interface DetailParams {
    id: string
}

const EmployeeGoalDetails: React.FC<RouteComponentProps<DetailParams>> = ({
                                                                            match,
                                                                            history
                                                                        }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        employeeGoal,
        loadEmployeeGoal,
        loading
    } = rootStore.employeeGoalsStore
    
    useEffect(() => {
        loadEmployeeGoal(match.params.id)
    }, [loadEmployeeGoal, match.params.id, history]);

    
    if (loading) return <LoadingComponent content={employeeGoal?.type === EmployeeGoalType.problem ? 'Učitavanje uočenog problema...' : 'Učitavanje tekuće obaveze...'}/>
    
    if (!employeeGoal) return <h2>Tekuća obaveza nije pornadjena</h2>

    return (
        <Grid>
            
            <Grid.Column width={16}>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Plan rada'
                        },
                        {
                            text: employeeGoal.type === EmployeeGoalType.problem ? 'Uočeni problemi' : 'Tekuće obaveze',
                            linkWithoutCurrentTrafficId: `employeeGoals?type=${employeeGoal.type}`
                        },
                        {
                            text: employeeGoal.name,
                            active: true
                        }
                    ]} 
                />
            </Grid.Column>
            
            <Grid.Column computer={10} tablet={10} mobile={16}>
                <EmployeeGoalHeader employeeGoal={employeeGoal} />
                <EmployeeGoalDetailedInfo employeeGoal={employeeGoal} />
                <EmployeeGoalDetailedChat employeeGoal={employeeGoal} />
            </Grid.Column>
            <Responsive.NotMobile>
                <Grid.Column width={6}>
                    <EmployeeGoalDetailedSidebar employeeGoal={employeeGoal} />
                </Grid.Column>    
            </Responsive.NotMobile>
        </Grid>
    );
};

export default observer(EmployeeGoalDetails);