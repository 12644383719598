import React, {FunctionComponent, useContext, useState} from 'react';
import {RootStoreContext} from "../../../core/stores/rootStore";
import LoadingComponent from "../../../core/layout/LoadingComponent";
import {Button, Icon, Input, List} from "semantic-ui-react";
import {IGlobalSetting} from "../../../core/models/globalSettings";
import {observer} from "mobx-react-lite";

interface IProps {
}

const GlobalSettingsList: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {globalSettingsArray: globalSettings, submittingKey, editGlobalSetting} = rootStore.globalSettingsStore

    const [editingKey, setEditingKey] = useState<string | null>(null)
    const [editingValue, setEditingValue] = useState<string | null>(null)
    
    const handleStartEditing = (setting: IGlobalSetting) => {
        setEditingKey(setting.key)
        setEditingValue(setting.value)
    }
    
    const handleCancel = () => {
        setEditingKey(null)
        setEditingValue(null)
    }
    
    const handleSubmit = () => {        
        editGlobalSetting(editingKey!, editingValue!)
            .then(() => {
                setEditingKey(null)
                setEditingValue(null)
            })
    }
    
    if (globalSettings.length === 0) return <LoadingComponent content='Učitavanje globalnih podešavanja'/>


    return (
        <List divided verticalAlign='middle'>
            {globalSettings.map(setting => (<List.Item key={setting.id}>
                <List.Header>{setting.displayName}</List.Header>

                {editingKey === setting.key ?
                    <>
                        <List.Content floated='right'>
                            <Button onClick={() => handleCancel()} >Odustani</Button>
                            <Button 
                                icon 
                                color='blue' loading={submittingKey === setting.key}
                                onClick={() => handleSubmit()}
                            >
                                <Icon name='save'/>
                                Primeni
                            </Button>
                        </List.Content>
                        <List.Content>
                            <Input
                                defaultValue={editingValue}
                                fluid
                                onChange={(event, data) => setEditingValue(data.value)}
                            />
                        </List.Content>
                    </> :
                    <>
                        <List.Content floated='right'>
                            <Button 
                                icon 
                                color='orange'
                                onClick={() => handleStartEditing(setting)} >
                                <Icon name='edit'/>
                                Izmeni
                            </Button>
                        </List.Content>
                        <List.Content>{setting.value}</List.Content>
                    </>}
            </List.Item>))}
        </List>
    )

}

export default observer(GlobalSettingsList)
