import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import TextInput from "../../../core/common/form/TextInput";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {combineValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../core/common/form/ErrorMessage";
import {IPricelistFormValues, PricelistFormValues} from "../../../core/models/pricelists";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'})
});

interface DetailParams {
    id: string;
}

const PricelistForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);

    const { currentTraffic } = rootStore.trafficsStore
    const { 
        loadPricelist,
        createPricelist,
        editPricelist,
        submitting
    } = rootStore.pricelistsStore

    const [pricelist, setPricelist] = useState<IPricelistFormValues>(new PricelistFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem(match.params.id ? '' : 'pricelistsCreate')
    
    useEffect(() => {
        if (match.params.id) {
            setLoading(true)
            loadPricelist(Number(match.params.id))
                .then(pricelist => setPricelist(new PricelistFormValues(pricelist)))
                .finally(() => setLoading(false))

        }
    }, [
        loadPricelist,
        match.params.id,
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const pricelist = {...values}

        if (!pricelist.id) {
            return createPricelist(pricelist)
        } else {
            return editPricelist(pricelist)
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Cenovnici',
                            linkWithoutCurrentTrafficId: 'pricelists'
                        },
                        {
                            text: match.params.id ? 'Izmena cenovnika' : 'Dodavanje cenovnika',
                            active: true
                        }
                    ]}
                    />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing>
                    <FinalForm onSubmit={(values: IPricelistFormValues) => handleFinalFormSubmit(values).catch(error => ({
                            [FORM_ERROR]: error
                        })
                    )}
                               validate={validate}
                               initialValues={pricelist}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit
                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={loading}>
                                       <Field
                                           name='name'
                                           placeholder='Naziv'
                                           value={pricelist.name}
                                           component={TextInput}
                                       />
                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(!pricelist.id) ? 'Dodaj' : 'Izmeni'}
                                       />
                                       <Button
                                           onClick={pricelist.id ? () => history.push(`/${currentTraffic?.id}/pricelists/${pricelist.id}`) : () => history.push(`/${currentTraffic?.id}/pricelists`)}
                                           disabled={loading}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(PricelistForm)
