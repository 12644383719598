import React, {FunctionComponent, useContext, useState} from 'react';
import {Button, Grid, Icon, Menu, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import ProductConsumptionsAnalysisByBrandList from "./lists/byBrand/ProductConsumptionsAnalysisByBrandList";
import ProductConsumptionsAnalysisByProductList from "./lists/byProducts/ProductConsumptionsAnalysisByProductList";
import {PdfService} from "../../../core/common/pdf/PdfService";
import {formatDateOrDefault} from "../../../core/common/util/date";
import autoTable from "jspdf-autotable";
import {IProductConsumptionAnalyseEntity} from "../../../core/models/productConsumptions";
import {formatRsd} from "../../../core/common/util/format";

export const PRODUCT_CONSUMPTIONS_ANALYSIS_BY_BRAND_TRACKING_ID = 'productConsumptionsAnalysisByBrand'
export const PRODUCT_CONSUMPTIONS_ANALYSIS_BY_PRODUCT_TRACKING_ID = 'productConsumptionsAnalysisByProduct'


interface IProps {
    startDate: string,
    endDate: string
}

const ProductConsumptionsAnalysisDashboard: FunctionComponent<IProps> = ({startDate, endDate}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        setAnalysisMode,
        loading
    } = rootStore.productConsumptionsStore
    const {SHIT_getFilteredRowsById} = rootStore.tablesStore

    const [currentTab, setCurrentTab] = useState('brands')

    const exportPdf = () => {

        const tableTrackingId =
            currentTab === 'brands' ?
                PRODUCT_CONSUMPTIONS_ANALYSIS_BY_BRAND_TRACKING_ID :
                currentTab === 'products' ?
                    PRODUCT_CONSUMPTIONS_ANALYSIS_BY_PRODUCT_TRACKING_ID :
                    ''

        const doc = new PdfService('portrait').doc

        const head =
            currentTab === 'brands' ?
                [['#', 'Brend', 'Kolicina', 'Nabavna vrednost']] :
            currentTab === 'products' ?
                [['#', 'Proizvod', 'Kolicina', 'Nabavna vrednost']] :
            [[]]

        doc.text(
            `Tabela analize rashoda proizvoda za period ${formatDateOrDefault(startDate, 'start')} - ${formatDateOrDefault(endDate, 'end')}`,
            25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head,
            body: SHIT_getFilteredRowsById(tableTrackingId)
                ?.map((entity: IProductConsumptionAnalyseEntity, index) => ([
                    index + 1,
                    currentTab === 'brands' ? 
                        entity.brand!.name : 
                        currentTab === 'products' ? 
                            `${entity.product?.brandName}\n${entity.product?.sku}\n${entity.product!.name}` :
                            '',
                    entity.count,
                    formatRsd(entity.value)
                ])) ?? []
        })

        doc.save("AnalizaRashodaProizvoda.pdf")
    }

    const tabulator = () => {
        switch (currentTab) {
            case 'brands':
                return <ProductConsumptionsAnalysisByBrandList
                    trackingId={PRODUCT_CONSUMPTIONS_ANALYSIS_BY_BRAND_TRACKING_ID}/>
            case 'products':
                return <ProductConsumptionsAnalysisByProductList
                    trackingId={PRODUCT_CONSUMPTIONS_ANALYSIS_BY_PRODUCT_TRACKING_ID}/>
            default:
                return <>Error with tabulator</>
        }
    }

    const menuColumn = () => (<Grid.Column mobile={16} tablet={4} computer={4}>
        <Menu vertical pointing color='blue' fluid>
            <Menu.Item
                name='Brendovi'
                active={currentTab === 'brands'}
                onClick={() => setCurrentTab('brands')}
            />
            <Menu.Item
                name='Proizvodi'
                active={currentTab === 'products'}
                onClick={() => setCurrentTab('products')}
            />
        </Menu>
    </Grid.Column>)

    return (<Grid>
        <Grid.Row>
            <Grid.Column mobile={16} tablet={12} computer={12}>
                <Segment.Group>
                    <Segment clearing color='blue' attached='top'>
                        <Button
                            color='red'
                            size='mini'
                            circular icon='x'
                            onClick={() => setAnalysisMode(false)}/>
                        <Button
                            disabled={loading}
                            icon
                            labelPosition='left'
                            floated='right'
                            color='teal'
                            onClick={() => exportPdf()} >
                            <Icon name='file pdf'/>
                            PDF
                        </Button>
                    </Segment>
                    <Segment attached='bottom'
                             loading={loading}>
                        {tabulator()}
                    </Segment>
                </Segment.Group>
            </Grid.Column>
            {menuColumn()}
        </Grid.Row>
    </Grid>)

}

export default observer(ProductConsumptionsAnalysisDashboard)
