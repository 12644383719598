import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction, toJS} from "mobx";
import {ITraffic, ITrafficFormValues, Traffic} from "../models/traffics";
import agent from "../api/agent";
import {history} from "../../index";

export default class TrafficsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }
    
    @observable trafficsRegistry = new Map<number, ITraffic>() 
    @observable loading = false
    @observable traffic: ITraffic | null = null
    @observable currentTraffic: ITraffic | null = null
    @observable submitting = false
    @observable deletingId: number | null = null
    @observable defaulting = false
    
    @computed get trafficsArray() {
        return Array.from(this.trafficsRegistry.values())
    }
    
    @action loadTraffics = async (setCurrentToDefault = false) => {
        this.loading = true
        this.trafficsRegistry.clear()
        try {
            const traffics = await agent.Traffics.list()
            runInAction(() => {
                traffics.forEach(traffic => {
                    this.trafficsRegistry.set(traffic.id, traffic)
                    if (setCurrentToDefault && traffic.isDefault) {
                        this.currentTraffic = traffic
                    }
                })
            })
            return toJS(this.currentTraffic)
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.loading = false                
            })
        }
    }
    
    @action loadTraffic = async (id: number) => {
        this.loading = true
        try {   
            const traffic = await agent.Traffics.details(id)
            runInAction(() => {
                this.traffic = traffic
            })
            return traffic
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action getDefault = () => {
        return this.trafficsArray.filter(traffic => traffic.isDefault)[0]
    }
    
    @action setCurrentTraffic = (id: number) => {
        const traffic = this.trafficsRegistry.get(id) ?? null
        
        if (traffic === null) {
            this.setCurrentToDefault()
            history.push('/notfound')
            return null
        }
        
        this.currentTraffic = traffic!
        const queryParams = new URLSearchParams(history.location.search)
        const currentPathname = history.location.pathname
        const splited = currentPathname.split('/')
        let newPath = '/' + this.currentTraffic?.id
        splited.forEach((splited, index) => {
            if (index > 1) {
                newPath += '/' + splited
            }
        })
        if (Array.from(queryParams).length > 0) {
            newPath += '?' + queryParams.toString()
        }
        if(currentPathname !== '/') {
            history.replace(newPath)            
        }
        
    }
    
    @action setCurrentToDefault = () => {
        const defaultTraffic = this.getDefault()
        this.setCurrentTraffic(defaultTraffic.id)
        return defaultTraffic;
    }
    
    @action createTraffic = async (trafficFormValues: ITrafficFormValues) => {
        this.submitting = true
        try {   
            const id = await agent.Traffics.create(trafficFormValues)
            runInAction(() => {
                this.trafficsRegistry.set(id, new Traffic({...trafficFormValues, id}))
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/traffics`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editTraffic = async (trafficFormValues: ITrafficFormValues) => {
        this.submitting = true
        try {
            await agent.Traffics.edit(trafficFormValues)
            runInAction(() => {
                this.trafficsRegistry.set(trafficFormValues.id!, new Traffic(trafficFormValues))
                history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/traffics`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action deleteTraffic = async (id: number) => {
        this.deletingId = id
        try {   
            await agent.Traffics.delete(id)
            runInAction(() => {
                this.trafficsRegistry.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deletingId = null
            })
        }
    }
    
    @action makeDefault = async (id: number) => {
        this.defaulting = true
        try {   
            await agent.Traffics.makeDefault(id)
            runInAction(() => {
                const oldDefault = this.trafficsArray.filter(x => x.isDefault)[0]
                if (oldDefault) {
                    this.trafficsRegistry.set(oldDefault.id, {...oldDefault, isDefault: false})
                }
                const newDefault = this.trafficsRegistry.get(id)
                if (newDefault) {
                    this.trafficsRegistry.set(id, {...newDefault, isDefault: true})
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.defaulting = false    
            })
        }
    }
    
}