import React, {FunctionComponent, useContext, useRef, useState} from 'react';
import {Button, Form, Segment} from "semantic-ui-react";
import {Form as FinalForm, Field} from "react-final-form";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {combineValidators, isRequired} from "revalidate";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../core/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import TextInput from "../../../core/common/form/TextInput";
import {IClientGoalDocumentFormValues} from "../../../core/models/clientGoals";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
});


interface IProps {
    clientGoalId: string,
    onCancel?: () => void,
    onUploadSuccess?: () => void,
    onUploadFailed?: () => void,
    small?: boolean
}

const ClientGoalDocumentForm: FunctionComponent<IProps> = ({
                                                               clientGoalId,
                                                               onCancel,
                                                               onUploadSuccess,
                                                               onUploadFailed,
                                                               small
                                                           }) => {

    const rootStore = useContext(RootStoreContext);
    const {
        addDocument,
        submittingDocument,
    } = rootStore.clientGoalsStore

    const [file, setFile] = useState<Blob | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleFileUpload = (event: any) => {
        const files = event.target.files
        if (files !== null) {
            const file = files[0]

            setFile(file)
        }
    }

    const handleFinalFormSubmit = (values: any) => {

        const clientGoalDocument: IClientGoalDocumentFormValues = {...values}

        clientGoalDocument.id = uuid()
        clientGoalDocument.file = file
        clientGoalDocument.clientGoalId = clientGoalId

        return addDocument(clientGoalDocument)
            .then(() => {
                if (onUploadSuccess) {
                    onUploadSuccess()
                }
            })
            .catch(() => {
                if (onUploadFailed) {
                    onUploadFailed()
                }
            })
    }

    return (
        <Segment clearing>
            <FinalForm
                onSubmit={(values: IClientGoalDocumentFormValues) => handleFinalFormSubmit(values).catch(error => ({
                        [FORM_ERROR]: error
                    })
                )}
                validate={validate}
                render={({
                             handleSubmit,
                             invalid,
                             pristine,
                             submitError,
                             dirtySinceLastSubmit,
                             form
                         }) => (
                    <Form onSubmit={handleSubmit}>

                        <Field
                            placeholder='Naziv'
                            name='name'
                            size={small ? 'mini' : undefined}
                            component={TextInput}
                        />

                        <Form.Field>
                            <Button
                                size={small ? 'mini' : undefined}
                                type='button'
                                color={file !== null ? 'green' : undefined}
                                content="Otpremi fajl"
                                labelPosition="left"
                                icon="file"
                                onClick={() => fileInputRef!.current!.click()}
                            />
                        </Form.Field>

                        <Field
                            name='file'
                            ref={fileInputRef}
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                            component={"input"}
                        />


                        {(submitError && !dirtySinceLastSubmit) &&
                        <ErrorMessage error={submitError}/>}
                        <Button
                            size={small ? 'mini' : undefined}
                            loading={submittingDocument}
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine || !file}
                            floated='right'
                            color='blue'
                            type='submit'
                            content={'Otpremi'}
                        />
                        <Button
                            onClick={() => {
                                if (onCancel) {
                                    onCancel()
                                }
                            }}
                            size={small ? 'mini' : undefined}
                            floated='right'
                            type='button'
                            content='Odustani'
                        />
                    </Form>
                )}
            />
        </Segment>
    );
};

export default observer(ClientGoalDocumentForm)
