import React, {FunctionComponent, useContext, useState} from 'react'
import {ClientGoalStatus, IClientGoal} from "../../../core/models/clientGoals";
import {Button, Divider, Form, Popup, Segment, TextArea} from "semantic-ui-react";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";

interface OwnProps {
    clientGoal: IClientGoal,
    fluid?: boolean
}

type Props = OwnProps

const ClientGoalMet: FunctionComponent<Props> = ({clientGoal, fluid}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        setClientGoalStatus,
        metingId,
        unmetingId
    } = rootStore.clientGoalsStore

    const [statusForm, setStatusForm] = useState<{ active: boolean, status: ClientGoalStatus.partlyMet | ClientGoalStatus.failed | null, note: string }>({
        active: false,
        status: null,
        note: ''
    })

    return statusForm.active ?
        <Segment clearing>
            <Form>
                <Form.Field>
                    <TextArea
                        placeholder='Obrazloženje...'
                        value={statusForm.note}
                        onChange={((event, data) => setStatusForm(prevState => ({
                            ...prevState,
                            note: data.value as string
                        })))}
                    />

                    <Divider hidden/>

                    <Button
                        disabled={!statusForm.note}
                        color='blue'
                        floated='right'
                        loading={clientGoal.id === metingId}
                        onClick={() => setClientGoalStatus({
                            id: clientGoal.id,
                            status: statusForm.status!,
                            statusNote: statusForm.note
                        })
                            .then(() => setStatusForm({active: false, status: null, note: ''}))}
                    >
                        Primeni
                    </Button>
                    <Button
                        onClick={() => setStatusForm({active: false, status: null, note: ''})}
                        floated='right'>
                        Odustani
                    </Button>
                </Form.Field>
            </Form>
        </Segment> :
        <Button.Group size='mini' fluid={fluid}>
            <Button
                disabled={metingId === clientGoal.id || unmetingId === clientGoal.id}
                color={clientGoal.status === ClientGoalStatus.inProgress ? 'blue' : undefined}
                onClick={() => (clientGoal.status !== ClientGoalStatus.inProgress) && setClientGoalStatus({
                    id: clientGoal.id,
                    status: ClientGoalStatus.inProgress,
                    statusNote: ''
                })}
            >U toku</Button>

            <Button
                disabled={metingId === clientGoal.id || unmetingId === clientGoal.id}
                color={clientGoal.status === ClientGoalStatus.met ? 'green' : undefined}
                onClick={() => clientGoal.status !== ClientGoalStatus.met &&
                    setClientGoalStatus({
                        id: clientGoal.id,
                        status: ClientGoalStatus.met,
                        statusNote: ''
                    })}
            >Ispunjen</Button>

            <Popup
                disabled={clientGoal.status !== ClientGoalStatus.partlyMet}
                header='Obrazloženje'
                content={clientGoal.statusNote}
                trigger={<Button
                    onClick={() => clientGoal.status !== ClientGoalStatus.partlyMet && setStatusForm({
                        active: true,
                        status: ClientGoalStatus.partlyMet,
                        note: ''
                    })}
                    disabled={metingId === clientGoal.id || unmetingId === clientGoal.id}
                    color={clientGoal.status === ClientGoalStatus.partlyMet ? 'grey' : undefined}>
                    Delimično
                </Button>}
            />

            <Popup
                disabled={clientGoal.status !== ClientGoalStatus.failed}
                header='Obrazloženje'
                content={clientGoal.statusNote}
                trigger={<Button
                    disabled={metingId === clientGoal.id || unmetingId === clientGoal.id}
                    color={clientGoal.status === ClientGoalStatus.failed ? 'red' : undefined}
                    onClick={() => clientGoal.status !== ClientGoalStatus.failed &&
                        setStatusForm({active: true, status: ClientGoalStatus.failed, note: ''})}
                >Neispunjen</Button>}
            />

        </Button.Group>
}

export default observer(ClientGoalMet)
