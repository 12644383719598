import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {BillOrderAnalyzeEntity, IBillOrderAnalyzeEntity} from "../models/billOrders";
import agent from "../api/agent";
import {groupBy} from "../common/util/array";
import {IBrand} from "../models/brands";
import {IProduct} from "../models/products";
import {Types} from "../common/options/bill";
import moment from "moment";
import {v4 as uuid} from 'uuid'
import {getNiceColorFromStingAsciiSum} from "../common/util/colors";


export default class TrafficAnalyseStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable billOrdersRegistry = new Map<string, IBillOrderAnalyzeEntity>()
    @observable manualBillsAsBillDetailedOrders = new Map<string, IBillOrderAnalyzeEntity>()
    // @observable loadingBillOrders = false
    // @observable loadingManualBills = false

    @observable lastRequestId = ''

    @observable loadingAll = false
    @observable predicate = new Map<string, string>()

    @observable typeFilter: 'all' | Types.Specification | Types.Invoice = 'all'
    @observable startDateFilter = ''
    @observable endDateFilter = ''
    // @observable gratisFilter = false

    @observable mode: 'classic' | 'gratis' | 'onSale' = 'onSale'

    @observable regionFilter: number[] = []
    @observable trafficFilter: number[] = []
    @observable withManualBillsFilter = true

    @action setWithManualBillsFilter = (withManualBills: boolean) => this.withManualBillsFilter = withManualBills

    @action setWithManualBillsFilterAndUpdate = (withManualBillsFilter: boolean) => {
        this.setWithManualBillsFilter(withManualBillsFilter)
        this.loadAll()
    }

    @action setTypeFilter = (type: 'all' | Types.Specification | Types.Invoice) => {
        this.typeFilter = type
        if (type === 'all') {
            this.predicate.delete('invoice')
        } else if (type === Types.Specification) {
            this.predicate.set('invoice', 'excluded')
        } else if (type === Types.Invoice) {
            this.predicate.set('invoice', 'only')
        }
    }

    @action setTypeFilterAndUpdate = (type: 'all' | Types.Specification | Types.Invoice) => {
        this.setTypeFilter(type)
        this.loadAll()
    }

    @action setStartDateFilter = (startDate: string) => {
        if (startDate.length === 0) {
            this.startDateFilter = ''
            this.predicate.delete('startDate')

            return true
        }

        if (moment(startDate).isValid()) {
            this.startDateFilter = startDate
            this.predicate.set('startDate', startDate)

            return true
        }
        return false
    }

    @action setDateRangeFilter = (start: string | null, end: string | null, commit = false) => {
        this.setStartDateFilter(start ?? '')
        this.setEndDateFilter(end ?? '')

        if (commit) {
            this.loadAll()
        }
    }

    @action setStartDateFilterAndUpdate = (startDate: string) => {
        if (this.setStartDateFilter(startDate)) {
            this.loadAll()
        }
    }

    @action setEndDateFilter = (endDate: string) => {
        if (endDate.length === 0) {
            this.endDateFilter = ''
            this.predicate.delete('endDate')

            return true
        }

        if (moment(endDate).isValid()) {
            this.endDateFilter = endDate
            this.predicate.set('endDate', endDate)

            return true
        }
        return false
    }

    @action setEndDateFilterAndUpdate = (endDate: string) => {
        if (this.setEndDateFilter(endDate)) {
            this.loadAll()
        }
    }

    // @action setGratisFilter = (gratisFilter: boolean) => {
    //     this.gratisFilter = gratisFilter
    //     if (gratisFilter) {
    //         this.predicate.set('gratis', 'only')
    //     } else {
    //         this.predicate.set('gratis', 'excluded')    
    //     }
    // }

    @action setMode = (mode: 'classic' | 'gratis' | 'onSale', commit = false) => {
        this.mode = mode
        switch (mode) {
            case 'classic':
                this.setPredicate('gratis', 'excluded')
                this.setPredicate('onSale', 'included')
                break
            case 'gratis':
                this.predicate.set('gratis', 'only')
                this.setPredicate('onSale', 'excluded')
                break
            case 'onSale':
                this.setPredicate('onSale', 'only')
                this.setPredicate('gratis', 'excluded')
                break
        }

        if (commit) {
            this.loadAll()
        }
    }

    // @action setGratisFilterAndUpdate = (gratisFilter: boolean) => {
    //     this.setGratisFilter(gratisFilter)
    //     this.loadAll()
    // }

    @action setRegionFilter = (regions: number[]) => {
        this.regionFilter = regions
        // this.setPredicate('tegions', regions)
    }

    @action setRegionFilterAndUpdate = (regions: number[]) => {
        this.setRegionFilter(regions)
        this.loadAll()
    }

    @action setTrafficFilter = (traffics: number[]) => {
        this.trafficFilter = traffics
        // this.setPredicate('tegions', regions)
    }

    @action setTrafficFilterToCurrent = () => {
        this.trafficFilter = [this.rootStore.trafficsStore.currentTraffic!.id]
        // this.setPredicate('tegions', regions)
    }

    @action setTrafficFilterAndUpdate = (traffics: number[]) => {
        this.setTrafficFilter(traffics)
        this.loadAll()
    }

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @computed get axiosParams() {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        if (this.regionFilter.length > 0) {
            this.regionFilter.forEach(region => {
                params.append('byRegion', region.toString())
            })
        }

        if (this.trafficFilter.length > 0) {
            this.trafficFilter.forEach(traffic => {
                params.append('byTraffic', traffic.toString())
            })
        }

        return params
    }

    @computed get manualBillsAxiosParams() {
        const params = new URLSearchParams(this.axiosParams)
        params.append('manual', 'only')

        return params
    }

    @computed get billOrdersArray() {
        return Array.from(this.billOrdersRegistry.values())
    }

    @computed get manualBillsAsBillDetailedOrdersArray() {
        return Array.from(this.manualBillsAsBillDetailedOrders.values())
    }

    @computed get allArray() {
        return this.withManualBillsFilter ? [...this.billOrdersArray,
                ...this.manualBillsAsBillDetailedOrdersArray] :
            this.billOrdersArray
    }

    @computed get groupByBrand() {
        return groupBy(this.allArray, g => g.product!.brandId)
            .map(p => p[1]).map((group: IBillOrderAnalyzeEntity[]) =>
                group.reduce((previousValue: IBillOrderAnalyzeEntity, currentValue, index) => {
                        if (index > 0) {
                            return {
                                ...previousValue,
                                summaryBasePriceInRsd: previousValue.summaryBasePriceInRsd + currentValue.summaryBasePriceInRsd,
                                count: previousValue.count + currentValue.count,
                                summaryPriceWithoutDiscount: previousValue.summaryPriceWithoutDiscount + currentValue.summaryPriceWithoutDiscount,
                                summaryPriceWithDiscount: previousValue.summaryPriceWithDiscount + currentValue.summaryPriceWithDiscount,
                                summaryPriceWithDiscountWithBillDiscount: previousValue.summaryPriceWithDiscountWithBillDiscount + currentValue.summaryPriceWithDiscountWithBillDiscount,
                                differenceInPrice: previousValue.differenceInPrice + currentValue.differenceInPrice,
                            } as IBillOrderAnalyzeEntity
                        } else {
                            return previousValue as IBillOrderAnalyzeEntity
                        }
                    },
                    {
                        brand: {
                            color: (group[0].product.brandColor && group[0].product.brandColor.length > 0) ? group[0].product.brandColor : getNiceColorFromStingAsciiSum(group[0].product.brandName),
                            name: group[0].product.brandName,
                            id: group[0].product.brandId
                        },
                        summaryBasePriceInRsd: group[0].summaryBasePriceInRsd,
                        count: group[0].count,
                        summaryPriceWithoutDiscount: group[0].summaryPriceWithoutDiscount,
                        summaryPriceWithDiscount: group[0].summaryPriceWithDiscount,
                        summaryPriceWithDiscountWithBillDiscount: group[0].summaryPriceWithDiscountWithBillDiscount,
                        differenceInPrice: group[0].differenceInPrice
                    } as IBillOrderAnalyzeEntity
                ))
    }
    
    @computed get groupByClientCategory() {
        return groupBy(this.allArray, g => g.client.category)
            .map(p => p[1]).map((group: IBillOrderAnalyzeEntity[]) =>
                group.reduce((previousValue: IBillOrderAnalyzeEntity, currentValue, index) => {
                        if (index > 0) {
                            return {
                                ...previousValue,
                                summaryBasePriceInRsd: previousValue.summaryBasePriceInRsd + currentValue.summaryBasePriceInRsd,
                                count: previousValue.count + currentValue.count,
                                summaryPriceWithoutDiscount: previousValue.summaryPriceWithoutDiscount + currentValue.summaryPriceWithoutDiscount,
                                summaryPriceWithDiscount: previousValue.summaryPriceWithDiscount + currentValue.summaryPriceWithDiscount,
                                summaryPriceWithDiscountWithBillDiscount: previousValue.summaryPriceWithDiscountWithBillDiscount + currentValue.summaryPriceWithDiscountWithBillDiscount,
                                differenceInPrice: previousValue.differenceInPrice + currentValue.differenceInPrice,
                            } as IBillOrderAnalyzeEntity
                        } else {
                            return previousValue as IBillOrderAnalyzeEntity
                        }
                    },
                    {
                        clientCategoryColor: getNiceColorFromStingAsciiSum(group[0].client.category ?? 'sdfsdsdf65sdf'),
                        clientCategory: group[0].client.category,
                        summaryBasePriceInRsd: group[0].summaryBasePriceInRsd,
                        count: group[0].count,
                        summaryPriceWithoutDiscount: group[0].summaryPriceWithoutDiscount,
                        summaryPriceWithDiscount: group[0].summaryPriceWithDiscount,
                        summaryPriceWithDiscountWithBillDiscount: group[0].summaryPriceWithDiscountWithBillDiscount,
                        differenceInPrice: group[0].differenceInPrice
                    } as IBillOrderAnalyzeEntity
                ))
    }

    @computed get groupByClient() {
        return groupBy(this.allArray, g => g.client.id)
            .map(p => p[1]).map((group: IBillOrderAnalyzeEntity[]) =>
                group.reduce((previousValue: IBillOrderAnalyzeEntity, currentValue, index) => {
                        if (index > 0) {
                            return {
                                ...previousValue,
                                summaryBasePriceInRsd: previousValue.summaryBasePriceInRsd + currentValue.summaryBasePriceInRsd,
                                count: previousValue.count + currentValue.count,
                                summaryPriceWithoutDiscount: previousValue.summaryPriceWithoutDiscount + currentValue.summaryPriceWithoutDiscount,
                                summaryPriceWithDiscount: previousValue.summaryPriceWithDiscount + currentValue.summaryPriceWithDiscount,
                                summaryPriceWithDiscountWithBillDiscount: previousValue.summaryPriceWithDiscountWithBillDiscount + currentValue.summaryPriceWithDiscountWithBillDiscount,
                                differenceInPrice: previousValue.differenceInPrice + currentValue.differenceInPrice,
                            } as IBillOrderAnalyzeEntity
                        } else {
                            return previousValue as IBillOrderAnalyzeEntity
                        }
                    },
                    {
                        client: group[0].client,
                        summaryBasePriceInRsd: group[0].summaryBasePriceInRsd,
                        count: group[0].count,
                        summaryPriceWithoutDiscount: group[0].summaryPriceWithoutDiscount,
                        summaryPriceWithDiscount: group[0].summaryPriceWithDiscount,
                        summaryPriceWithDiscountWithBillDiscount: group[0].summaryPriceWithDiscountWithBillDiscount,
                        differenceInPrice: group[0].differenceInPrice
                    } as IBillOrderAnalyzeEntity
                ))
    }

    @computed get groupByClientAndBrand() {
        let array1d: any[] = []
        const array2d = groupBy(this.allArray, g => g.client.id)
            .map(p => groupBy(p[1], (f: IBillOrderAnalyzeEntity) => f.product.brandId))
        array2d.forEach(el => {
            el.forEach(el1 => {
                array1d.push(el1[1])
            })
        })

        return array1d
            .map((group: IBillOrderAnalyzeEntity[]) =>
                group.reduce((previousValue: IBillOrderAnalyzeEntity, currentValue, index) => {
                        if (index > 0) {
                            return {
                                ...previousValue,
                                summaryBasePriceInRsd: previousValue.summaryBasePriceInRsd + currentValue.summaryBasePriceInRsd,
                                count: previousValue.count + currentValue.count,
                                summaryPriceWithoutDiscount: previousValue.summaryPriceWithoutDiscount + currentValue.summaryPriceWithoutDiscount,
                                summaryPriceWithDiscount: previousValue.summaryPriceWithDiscount + currentValue.summaryPriceWithDiscount,
                                summaryPriceWithDiscountWithBillDiscount: previousValue.summaryPriceWithDiscountWithBillDiscount + currentValue.summaryPriceWithDiscountWithBillDiscount,
                                differenceInPrice: previousValue.differenceInPrice + currentValue.differenceInPrice,
                            } as IBillOrderAnalyzeEntity
                        } else {
                            return previousValue as IBillOrderAnalyzeEntity
                        }
                    },
                    {
                        brand: {
                            name: group[0].product.brandName,
                            id: group[0].product.brandId,
                            color: (group[0].product.brandColor && group[0].product.brandColor.length > 0) ? group[0].product.brandColor : getNiceColorFromStingAsciiSum(group[0].product.brandName),
                        } as IBrand,
                        client: group[0].client,
                        summaryBasePriceInRsd: group[0].summaryBasePriceInRsd,
                        count: group[0].count,
                        summaryPriceWithoutDiscount: group[0].summaryPriceWithoutDiscount,
                        summaryPriceWithDiscount: group[0].summaryPriceWithDiscount,
                        summaryPriceWithDiscountWithBillDiscount: group[0].summaryPriceWithDiscountWithBillDiscount,
                        differenceInPrice: group[0].differenceInPrice
                    } as IBillOrderAnalyzeEntity
                ))
    }

    @computed get groupByClientAndProduct() {
        let array1d: any[] = []
        const array2d = groupBy(this.allArray, g => g.client.id)
            .map(p => groupBy(p[1], (f: IBillOrderAnalyzeEntity) => f.product.id))
        array2d.forEach(el => {
            el.forEach(el1 => {
                array1d.push(el1[1])
            })
        })

        return array1d
            .map((group: IBillOrderAnalyzeEntity[]) =>
                group.reduce((previousValue: IBillOrderAnalyzeEntity, currentValue, index) => {
                        if (index > 0) {
                            return {
                                ...previousValue,
                                summaryBasePriceInRsd: previousValue.summaryBasePriceInRsd + currentValue.summaryBasePriceInRsd,
                                count: previousValue.count + currentValue.count,
                                summaryPriceWithoutDiscount: previousValue.summaryPriceWithoutDiscount + currentValue.summaryPriceWithoutDiscount,
                                summaryPriceWithDiscount: previousValue.summaryPriceWithDiscount + currentValue.summaryPriceWithDiscount,
                                summaryPriceWithDiscountWithBillDiscount: previousValue.summaryPriceWithDiscountWithBillDiscount + currentValue.summaryPriceWithDiscountWithBillDiscount,
                                differenceInPrice: previousValue.differenceInPrice + currentValue.differenceInPrice,
                            } as IBillOrderAnalyzeEntity
                        } else {
                            return previousValue as IBillOrderAnalyzeEntity
                        }
                    },
                    {
                        product: group[0].product,
                        client: group[0].client,
                        summaryBasePriceInRsd: group[0].summaryBasePriceInRsd,
                        count: group[0].count,
                        summaryPriceWithoutDiscount: group[0].summaryPriceWithoutDiscount,
                        summaryPriceWithDiscount: group[0].summaryPriceWithDiscount,
                        summaryPriceWithDiscountWithBillDiscount: group[0].summaryPriceWithDiscountWithBillDiscount,
                        differenceInPrice: group[0].differenceInPrice
                    } as IBillOrderAnalyzeEntity
                ))
    }

    @computed get groupByOnSaleAndClientAndProduct() {
        //TODO ODAVDE JE UZASSSS NAPISAN KOD, ALI RADI (VALJDA)

        let array1d: any[] = []

        const array2d = groupBy(this.allArray, g => g.client.id)
            .map(p => groupBy(p[1], (f: IBillOrderAnalyzeEntity) => f.product.id))

        array2d.forEach(el => {
            el.forEach(el1 => {
                array1d.push(el1[1])
            })
        })


        let finalArray: any[] = []

        array1d.forEach(clientAndProductGroup => {
            const clientAndProductGroupGroupedByOnSaleNote = groupBy(clientAndProductGroup, (gr: IBillOrderAnalyzeEntity) => gr.onSaleNote)
                .map(g => g[1])

            clientAndProductGroupGroupedByOnSaleNote.forEach(single => {
                finalArray.push(single)
            })
        })

        // TODO DO OVDE

        return finalArray
            .map((group: IBillOrderAnalyzeEntity[]) =>
                group.reduce((previousValue: IBillOrderAnalyzeEntity, currentValue, index) => {
                        if (index > 0) {
                            return {
                                ...previousValue,
                                summaryBasePriceInRsd: previousValue.summaryBasePriceInRsd + currentValue.summaryBasePriceInRsd,
                                count: previousValue.count + currentValue.count,
                                summaryPriceWithoutDiscount: previousValue.summaryPriceWithoutDiscount + currentValue.summaryPriceWithoutDiscount,
                                summaryPriceWithDiscount: previousValue.summaryPriceWithDiscount + currentValue.summaryPriceWithDiscount,
                                summaryPriceWithDiscountWithBillDiscount: previousValue.summaryPriceWithDiscountWithBillDiscount + currentValue.summaryPriceWithDiscountWithBillDiscount,
                                differenceInPrice: previousValue.differenceInPrice + currentValue.differenceInPrice,
                            } as IBillOrderAnalyzeEntity
                        } else {
                            return previousValue as IBillOrderAnalyzeEntity
                        }
                    },
                    {
                        product: group[0].product,
                        client: group[0].client,
                        onSaleNote: group[0].onSaleNote,
                        summaryBasePriceInRsd: group[0].summaryBasePriceInRsd,
                        count: group[0].count,
                        summaryPriceWithoutDiscount: group[0].summaryPriceWithoutDiscount,
                        summaryPriceWithDiscount: group[0].summaryPriceWithDiscount,
                        summaryPriceWithDiscountWithBillDiscount: group[0].summaryPriceWithDiscountWithBillDiscount,
                        differenceInPrice: group[0].differenceInPrice
                    } as IBillOrderAnalyzeEntity
                ))
    }

    @computed get groupByProduct() {
        return groupBy(this.allArray, (g: IBillOrderAnalyzeEntity) => g.product.id)
            .map(p => p[1]).map((group: IBillOrderAnalyzeEntity[]) =>
                group.reduce((previousValue: IBillOrderAnalyzeEntity, currentValue, index) => {
                        if (index > 0) {
                            return {
                                ...previousValue,
                                summaryBasePriceInRsd: previousValue.summaryBasePriceInRsd + currentValue.summaryBasePriceInRsd,
                                count: previousValue.count + currentValue.count,
                                summaryPriceWithoutDiscount: previousValue.summaryPriceWithoutDiscount + currentValue.summaryPriceWithoutDiscount,
                                summaryPriceWithDiscount: previousValue.summaryPriceWithDiscount + currentValue.summaryPriceWithDiscount,
                                summaryPriceWithDiscountWithBillDiscount: previousValue.summaryPriceWithDiscountWithBillDiscount + currentValue.summaryPriceWithDiscountWithBillDiscount,
                                differenceInPrice: previousValue.differenceInPrice + currentValue.differenceInPrice,
                            } as IBillOrderAnalyzeEntity
                        } else {
                            return previousValue as IBillOrderAnalyzeEntity
                        }
                    },
                    {
                        product: {
                            id: group[0].product.id,
                            brandColor: group[0].product.brandColor,
                            brandId: group[0].product.brandId,
                            brandName: group[0].product.brandName,
                            name: group[0].product.name,
                            sku: group[0].product.sku,
                            prices: group[0].product.prices,
                            description: group[0].product.description
                        } as IProduct,
                        summaryBasePriceInRsd: group[0].summaryBasePriceInRsd,
                        count: group[0].count,
                        summaryPriceWithoutDiscount: group[0].summaryPriceWithoutDiscount,
                        summaryPriceWithDiscount: group[0].summaryPriceWithDiscount,
                        summaryPriceWithDiscountWithBillDiscount: group[0].summaryPriceWithDiscountWithBillDiscount,
                        differenceInPrice: group[0].differenceInPrice
                    } as IBillOrderAnalyzeEntity
                ))
    }

    @action loadBillOrders = async (requestId: string) => {
        // this.loadingBillOrders = true
        try {
            const billOrders = await agent.BillOrders.list(this.axiosParams)
            runInAction(() => {
                // avoiding race condition
                if (this.lastRequestId === requestId) {
                    this.billOrdersRegistry.clear()
                    billOrders.forEach(billOrder => {
                        this.billOrdersRegistry.set(billOrder.id, billOrder)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.billOrdersRegistry.clear()
        } finally {
            runInAction(() => {
                // if (this.lastRequestId === requestId) {
                //     this.loadingBillOrders = false   
                // }
            })
        }
    }

    @action loadManualBills = async (requestId: string) => {
        // this.loadingManualBills = true
        try {
            const manualBills = await agent.Bills.list(this.manualBillsAxiosParams)
            runInAction(() => {
                // avoiding race condition
                if (this.lastRequestId === requestId) {
                    this.manualBillsAsBillDetailedOrders.clear()
                    manualBills.forEach(bill => {
                        this.manualBillsAsBillDetailedOrders.set(bill.id, new BillOrderAnalyzeEntity(bill))
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.manualBillsAsBillDetailedOrders.clear()
        } finally {
            runInAction(() => {
                // if (this.lastRequestId === requestId) {
                //     this.loadingManualBills = false                    
                // }
            })
        }
    }

    @action loadManualBillsConditionally = async (requestId: string) => {
        if (this.withManualBillsFilter) {
            await this.loadManualBills(requestId)
        }
    }

    @action loadAll = async () => {
        const requestId = uuid()
        this.lastRequestId = requestId

        this.loadingAll = true
        
        await Promise.all([
            this.loadBillOrders(requestId),
            this.loadManualBillsConditionally(requestId)
        ])
        runInAction(() => {
            // avoiding race condition
            if (this.lastRequestId === requestId) {
                this.loadingAll = false
            }
        })
    }

}