import {Button, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Responsive from "../../../../../core/common/responsive/Responsive";
import ClientOrderContentDocumentDownloadButton from "../../../commom/ClientOrderContentDocumentDownloadButton";
import {ClientOrderItemDeleteModal} from "./ClientOrderItemDeleteModal";
import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../core/stores/rootStore";
import {IClientOrder} from "../../../../../core/models/clientsOrders";

const _ClientOrderItemFooterActions = ({clientOrder}: { clientOrder: IClientOrder }) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {
        deletingId,
        deleting,
    } = rootStore.clientOrdersStore
    const {openModal} = rootStore.modalStore
    
    return <>
        <Button
            as={Link}
            to={`/${currentTraffic?.id}/clientOrders/${clientOrder.id}`}
            floated='right'
            color='blue'
            icon
        >
            <Icon name='eye'/>
            <Responsive.NotMobile>
                Prikaži
            </Responsive.NotMobile>
        </Button>

        {clientOrder.hasContentDocument &&
            <ClientOrderContentDocumentDownloadButton clientOrder={clientOrder}/>}

        <Button
            icon
            as={Link}
            to={`/${currentTraffic?.id}/clientOrders/manage/${clientOrder.id}/edit`}
            floated='right'
            color='orange'
        >
            <Icon name='edit'/>
            <Responsive.NotMobile>
                Izmeni
            </Responsive.NotMobile>
        </Button>

        <Button
            onClick={() => openModal(<ClientOrderItemDeleteModal clientOrder={clientOrder}/>, 'small')}
            color='red'
            icon
            loading={(deletingId === clientOrder.id) && deleting}
            floated='right'>
            <Icon name='trash alternate'/>
            <Responsive.NotMobile>
                Ukloni
            </Responsive.NotMobile>
        </Button>
    </>
}

export const ClientOrderItemFooterActions = observer(_ClientOrderItemFooterActions)