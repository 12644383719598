import React, {FunctionComponent, useContext} from 'react'
import {Button, Icon} from "semantic-ui-react";
import Responsive from "../../../core/common/responsive/Responsive";
import {IClientOrder} from "../../../core/models/clientsOrders";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";

interface OwnProps {
    clientOrder: IClientOrder
}

type Props = OwnProps

const ClientOrderBillDocumentDownloadButton: FunctionComponent<Props> = ({  clientOrder}) => {
    const rootStore = useContext(RootStoreContext)
    const { loadClientOrderDoc, loadingBillDocId } = rootStore.clientOrdersStore
    
    
  return <Button
      size='mini'
      icon
      color='blue'
      labelPosition='left'
      loading={clientOrder.id === loadingBillDocId}
      onClick={() => loadClientOrderDoc(clientOrder.id, 'bill')}
  >
      <Icon name='file'/>
      <Responsive.NotMobile>
          Dokument računa
      </Responsive.NotMobile>
  </Button>
}

export default observer(ClientOrderBillDocumentDownloadButton)
