import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {Brand, IBrand, IBrandFormValues} from "../models/brands";
import agent from "../api/agent";

export default class BrandsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)

    }
    
    @observable brandsRegistry = new Map<number, IBrand>()
    @observable brand: IBrand | null = null
    @observable loading = false
    @observable addingBrandClientIds: [number, string] | null = null
    @observable removingBrandClientIds: [number, string] | null = null
    @observable generatingUsedBrands = false
    @observable submitting = false
    @observable deletingId: number | null = null
    @observable loadingBrandsUsedByClient = false
    
    
    @computed get brandsArray() {
        return Array.from(this.brandsRegistry.values())
    }
    
    @computed get brandsAsOptions() {
        return this.brandsArray.map(brand => ({
            key: brand.name,
            value: brand.name,
            text: brand.name
        }))
    }

    @computed get usedBrandsCount() {
        return this.brandsArray.filter(x => x.used).length
    }
    
    @action loadBrandsIfNull = async () => {
        if (this.brandsRegistry.size === 0) {
            await this.loadBrands()
        }
    }
    
    @action loadBrands = async () => {
        this.loading = true
        this.brandsRegistry.clear()
        try {
            const brands = await agent.Brands.list() 
            runInAction(() => {
                // this.brands = brands
                brands.forEach(brand => {
                    this.brandsRegistry.set(brand.id, brand)
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action loadBrand = async (id: number) => {
        this.loading = true
        try {
            const brand = await agent.Brands.details(id)
            runInAction(() => {
                this.brand = brand
                this.rootStore.productsStore.loadProductsToRegistry(brand.products!)
                this.rootStore.clientsStore.loadToRegistry(brand.clients!)
            })
            return brand
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    
    @action loadBrandsToRegistry = (brands: IBrand[]) => {
        this.brandsRegistry.clear()
        brands.forEach(brand => {
            this.brandsRegistry.set(brand.id, brand)
        })
    }
    
    @action addBrandToClient = async (brandId: number, clientId: string) => {
        this.addingBrandClientIds = [brandId, clientId]
        try {   
            await agent.ClientBrands.add(brandId, clientId)
            runInAction(() => {
                this.brandsRegistry.set(brandId, {...this.brandsRegistry.get(brandId)!, used: true})
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.addingBrandClientIds = null
            })
        }
    }

    @action removeBrandToClient = async (brandId: number, clientId: string) => {
        this.removingBrandClientIds = [brandId, clientId]
        try {
            await agent.ClientBrands.remove(brandId, clientId)
            runInAction(() => {
                this.brandsRegistry.set(brandId, {...this.brandsRegistry.get(brandId)!, used: false})
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.removingBrandClientIds = null
            })
        }
    }

    @action loadBrandsUsedByClient = async (clientId: string) => {
        this.loadingBrandsUsedByClient = true
        try {
            const usedByClient = await agent.ClientBrands.usedByClient(clientId)
            runInAction(() => {
                usedByClient.forEach(brand => {
                    this.brandsRegistry.set(brand.id, brand)
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingBrandsUsedByClient = false
            })
        }
    }
    
    @action generateUsedBrandsForSingleClient = async (clientId: string) => {
        this.generatingUsedBrands = true
        try {   
            await agent.ClientBrands.generateUsedBrands(clientId)
            const usedByClient = await agent.ClientBrands.usedByClient(clientId)
            runInAction(() => {
                usedByClient.forEach(brand => {
                    this.brandsRegistry.set(brand.id, brand)
                })
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.generatingUsedBrands = false
            })
        }
    }
    
    @action createBrand = async (brandFormValues: IBrandFormValues) => {
        this.submitting = true
        try {   
            const id = await agent.Brands.create(brandFormValues)
            runInAction(() => {
                this.brandsRegistry.set(id, new Brand({...brandFormValues, id}))
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action editBrand = async (brandFormValues: IBrandFormValues) => {
        this.submitting = true
        try {
            await agent.Brands.edit(brandFormValues)
            runInAction(() => {
                this.brandsRegistry.set(brandFormValues.id!, new Brand(brandFormValues))
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }
    
    @action deleteBrand = async (id: number) => {
        this.deletingId = id
        try {   
            await agent.Brands.delete(id)
            runInAction(() => {
                this.brandsRegistry.delete(id)
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.deletingId = null
            })
        }
    }
    
}