import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {combineValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../core/common/form/ErrorMessage";
import TextInput from "../../../core/common/form/TextInput";
import {IRegionFormValues, RegionFormValues} from "../../../core/models/region";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
});

interface DetailParams {
    id: string;
}

const RegionForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {
        createRegion,
        loadRegion,
        editRegion,
        submitting
    } = rootStore.regionsStore
    const {currentTraffic} = rootStore.trafficsStore

    const [region, setRegion] = useState<IRegionFormValues>(new RegionFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem('regionsCreate')

    useEffect(() => {
            if (match.params.id) {
                setLoading(true)
                loadRegion(Number(match.params.id))
                    .then(region => setRegion(new RegionFormValues(region)))
                    .finally(() => setLoading(false))

            } else {
                setRegion(new RegionFormValues())
            }

        }, [
            match.params.id,
            loadRegion
        ]
    )


    const handleFinalFormSubmit = (values: any) => {
        const region = {...values}

        if (isNaN(region.id)) {

            return createRegion({...region, id: 0})
                .then(() => {
                    history.push(`/${currentTraffic?.id}/regions`)
                })
        } else {

            return editRegion(region)
                .then(() => {
                    history.push(`/${currentTraffic?.id}/regions`)
                })
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Regioni',
                            linkWithoutCurrentTrafficId: 'regions'
                        },
                        {
                            text: match.params.id ? 'Izmena regiona' : 'Dodavanje regiona',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={8}>
                <Segment clearing color='blue'>
                    <FinalForm onSubmit={(values: IRegionFormValues) => handleFinalFormSubmit(values).catch(error => ({
                            [FORM_ERROR]: error
                        })
                    )}
                               validate={validate}
                               initialValues={region}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit,
                                            form

                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={loading}>

                                       <Field
                                           name='name'
                                           component={TextInput}
                                           value={region.name}
                                       />

                                       {/*<Field*/}
                                       {/*    hidden*/}
                                       {/*    name='color'*/}
                                       {/*    component='input'*/}
                                       {/*    value={brand.color}*/}
                                       {/*/>*/}

                                       {/*<Grid centered padded>*/}
                                       {/*  <Grid.Column>*/}
                                       {/*    <SketchPicker width='98%'*/}
                                       {/*                  color={form.getState().values.color || 'black'}*/}
                                       {/*                  onChange={((color, event) => form.change('color', color.hex) )}*/}
                                       {/*    />*/}
                                       {/*  </Grid.Column>*/}
                                       {/*</Grid>*/}


                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(!region.id) ? 'Dodaj' : 'Izmeni'}
                                       />
                                       <Button
                                           onClick={() => history.push(`/${currentTraffic?.id}/regions`)}
                                           disabled={loading}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(RegionForm)
