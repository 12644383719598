import React, {useContext, useEffect, useState} from 'react'
import {RootStoreContext} from "../../../core/stores/rootStore";
import {observer} from "mobx-react-lite";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import ScheduleSectionLayout from "../../scheduleSection/layout/ScheduleSectionLayout";
import moment from "moment";
import {DATE_SERIALIZABLE_FORMAT_STRING} from "../../../core/common/util/date";
import {Button, Divider, Grid, Header, Icon, Label, Select} from "semantic-ui-react";
import CommentsSectionList from "../list/CommentsSectionList";
import {categoryFiltersCategories, IItemCategory} from "../../../core/stores/commentsSectionStore";

const DEFAULT_START_DATE_FILTER = moment().subtract(7, 'days').format(DATE_SERIALIZABLE_FORMAT_STRING)
const DEFAULT_END_DATE_FILTER = moment().format(DATE_SERIALIZABLE_FORMAT_STRING)

const CommentsSectionDashboard = () => {
    const rootStore = useContext(RootStoreContext)
    const {
        setAllDateRangeParams,
        loadingAll,
        loadAll,
        setCategoryFilters,
        categoryFilter,
        usersFilter,
        setUsersFilter
    } = rootStore.commentsSectionStore
    const { usersArray: users, loadUsers, loadingUsers } = rootStore.usersStore
    
    const [updatedAt, setUpdatedAt] = useState('')

    useNavbarSelectedItem('commentsSection')

    useEffect(() => {
        setAllDateRangeParams(DEFAULT_START_DATE_FILTER, DEFAULT_END_DATE_FILTER, true)
        loadUsers(true)
        setUpdatedNow()
    }, [
        setAllDateRangeParams,
        loadUsers
    ])

    const setUpdatedNow = () => setUpdatedAt(moment().format('DD.MM.YYYY. HH:mm:ss'))

    const handleUpdate = () => {
        setUpdatedNow()
        loadAll()
    }

    return <ScheduleSectionLayout>
        <Grid centered>
            <Divider hidden/>
            <Grid.Row centered>
                <Grid.Column textAlign='center'>
                    <Header as='h3' color='grey'>Komentari u poslednjih 7 dana
                        <Header.Subheader>
                            Ažurirano u {updatedAt}
                        </Header.Subheader>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column textAlign='center'>
                    <Button
                        color='blue'
                        onClick={handleUpdate}
                        disabled={loadingAll}
                    >
                        <Icon name='sync' loading={loadingAll}/> Ažuriraj
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column
                    textAlign='center'
                    computer={10} tablet={12} mobile={16}
                >
                    <Select
                        placeholder='Kategorija'
                        search
                        clearable
                        multiple
                        renderLabel={(item, index, defaultLabelProps) => <Label
                            content={item.text}
                            color={item.color}
                        />}
                        options={categoryFiltersCategories.map(filter => ({
                            key: filter.value,
                            value: filter.value,
                            text: filter.text,
                            color: filter.color
                        }))}
                        onChange={(event, data) =>  setCategoryFilters(data.value as IItemCategory[])}
                        value={categoryFilter}
                    />

                    <Select
                        placeholder='Korisnik'
                        search
                        loading={loadingUsers}
                        clearable
                        multiple
                        options={users.map(user => ({
                            key: user.id,
                            value: user.id,
                            text: user.displayName,
                        }))}
                        onChange={(_, data) =>  setUsersFilter(data.value as string[])}
                        value={usersFilter}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column computer={10} tablet={12} mobile={16}>
                    {!loadingAll &&
                    <CommentsSectionList/>}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </ScheduleSectionLayout>
}

export default observer(CommentsSectionDashboard)
