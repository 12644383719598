import React, {FunctionComponent, useContext} from 'react';
import BillCartInstallmentsList from "./BillCartInstallmentsList";
import {Button, Icon, Item, Label, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../core/stores/rootStore";
import {formatRsd} from "../../../../core/common/util/format";

interface IProps {
}

const BillCartInstallments: FunctionComponent<IProps> = () => {
    const rootStore = useContext(RootStoreContext)
    const {
        increaseInstallments,
        decreaseInstallments,
        installmentsCount,
        installmentsValue,
        installmentsValueSameAsBillValue
    } = rootStore.billsCartStore

    
    return (
        <Segment >
            <Item.Group>
                <Item>
                    <Item.Content >
                        <Item.Header>
                            Suma rata
                            {(!installmentsValueSameAsBillValue) &&
                            <Label basic content='Nije jednaka vrednosti računa!' pointing='left' color='red' />}
                        </Item.Header>
                        <Item.Meta color='red'>{formatRsd(installmentsValue)}</Item.Meta>
                    </Item.Content>
                </Item>
            </Item.Group>
            <Button.Group icon color='teal'>
                <Button onClick={() => increaseInstallments()}>
                    <Icon name='add' />
                </Button>
                <Button onClick={() => decreaseInstallments()} >
                    <Icon name='minus' />
                </Button>
            </Button.Group>
            {installmentsCount > 1 &&
            <BillCartInstallmentsList/>}
        </Segment>
    );
};

export default observer(BillCartInstallments)
