import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Button, Grid, Header, Icon, Segment} from "semantic-ui-react";
import ClientsDashboardMenu from "./ClientsDashboardMenu";
import {observer} from "mobx-react-lite";
import ClientEmployeesList from "../list/employees/ClientEmployeesList";
import {RootStoreContext} from "../../../core/stores/rootStore";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {IClientEmployee} from "../../../core/models/clients";
import moment from "moment";

interface IProps {
}

const ClientsEmployees: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const { loadClientEmployees, loading } = rootStore.clientEmployeesStore
    const { SHIT_getFilteredRowsById, removeFromRegistry } = rootStore.tablesStore
    
    useEffect(() => () => {
        removeFromRegistry('clientEmployeesList')
    }, [
        removeFromRegistry
    ])
    
    useEffect(() => {
        loadClientEmployees()
    }, [
        loadClientEmployees
    ])

    const exportPdf = () => {
        const doc = new jsPDF("landscape", 'px', 'A4')

        doc.text(`Tabela zaposlenih kod klijenata` , 25, 50)
        autoTable(doc,{
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                ['#', 'Ime', 'Klijent', 'Pozicija', 'Rodjendan', 'Broj telefona', 'Komentar']
            ],
            body: SHIT_getFilteredRowsById('clientEmployeesList')?.map((employee: IClientEmployee, index) => ([
                index + 1,
                employee.name,
                employee.client!.name,
                employee.job,
                moment(employee.thisYearBirthday).format('l'),
                employee.telNumber,
                employee.note                
            ])) ?? []
        })

        doc.save("ZaposelniKodKlijenata.pdf")
    }
    
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header 
                        floated='left' 
                        as='h2' 
                        color='blue' 
                        content='Rodjendani'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment.Group>
                        <Segment style={{padding: 0}} attached='top' color='blue'>
                            <ClientsDashboardMenu />
                        </Segment>
                        <Segment attached clearing>
                            <Button
                                disabled={loading}
                                icon
                                labelPosition='left'
                                floated='right'
                                color='teal'
                                onClick={() => exportPdf()}>
                                <Icon name='file pdf' />
                                PDF
                            </Button>
                        </Segment>
                        <Segment attached='bottom' loading={loading}>
                            <ClientEmployeesList trackingId='clientEmployeesList' />
                        </Segment>    
                    </Segment.Group>
                    
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default observer(ClientsEmployees)
