import React, {FunctionComponent, useState} from 'react'
import {Button} from "semantic-ui-react";

interface OwnProps {
    content: string,
    onClick: () => void
}

type Props = OwnProps

const ShowMoreButton: FunctionComponent<Props> = ({content, onClick}) => {
    const [showMore, setShowMore] = useState(false)
    
    return <Button
        basic={!showMore}
        onMouseEnter={() => setShowMore(true)}
        onMouseLeave={() => setShowMore(false)}
        color={showMore ? 'blue' : undefined}
        size='mini'
        icon={showMore ? undefined : 'add'}
        content={showMore ? content : undefined}
        onClick={() => onClick()}/>
}

export default ShowMoreButton
