import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {
    ClientGoal,
    ClientGoalStatus,
    ClientGoalType,
    IClientGoal, IClientGoalCategoryWithProperties,
    IClientGoalComment,
    IClientGoalDocument,
    IClientGoalDocumentFormValues,
    IClientGoalFormValues,
} from "../models/clientGoals";
import agent from "../api/agent";
import {IUser} from "../models/user";
import _ from 'lodash'
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {SemanticCOLORS} from "semantic-ui-react";
import {toast} from "react-toastify";
import {v4 as uuid} from 'uuid'


export default class ClientGoalsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable clientGoal: IClientGoal | null = null
    @observable clientGoalsRegistry = new Map<string, ClientGoal>()
    @observable loading = false
    @observable submitting = false
    @observable.ref hubConnection: HubConnection | null = null;
    @observable hubConnectionStatusColor: SemanticCOLORS | undefined = undefined
    @observable editingCommentId: string | null = null
    @observable deletingCommentId: string | null = null
    @observable metingId: string | null = null
    @observable unmetingId: string | null = null
    @observable predicate = new Map<string, string>()
    @observable clientGoalsCategories: IClientGoalCategoryWithProperties[] | null = null
    @observable loadingClientGoalsCategories = false
    @observable documentDeletingId = ''
    @observable downloadingDocumentId = ''
    @observable submittingDocument = false
    @observable firstFilter: 'all' | 'current' | 'met' | 'failed' | 'currentPartlyMet' = 'current'
    @observable secondFilter: 'currentAll' | 'currentBasic' | 'currentPartlyMet' | 'currentExpired' = 'currentAll'
    @observable lastRequestId = ''

    @action setHubConnectionStatusColor = (color: SemanticCOLORS | undefined) => this.hubConnectionStatusColor = color

    @computed get axiosParams() {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @computed get clientGoalsArray() {
        return Array.from(this.clientGoalsRegistry.values())
            .sort((a, b) => {
                // kod aktuelnih se uzima u obzir date a kod ostalih completed date
                // sortiranje ide: bezDatuma, stariji datum, noviji datum
                const aDate = a.status === ClientGoalStatus.inProgress
                    ? a.date
                    : a.completedDate
                const bDate = b.status === ClientGoalStatus.inProgress
                    ? b.date
                    : b.completedDate


                if (aDate === bDate) {
                    const currentUserCoefDiff =
                        (a.participants.filter(p => p.user.id === this.rootStore.userStore.user?.id)[0] ? 1 : 0) -
                        (b.participants.filter(p => p.user.id === this.rootStore.userStore.user?.id)[0] ? 1 : 0)

                    if (currentUserCoefDiff === 0) {
                        const colorCoefDiff =
                            (a.color === 'red' ? 2 : a.color === 'orange' ? 1 : 0) -
                            (b.color === 'red' ? 2 : a.color === 'orange' ? 1 : 0)

                        if (colorCoefDiff < 0) {
                            return 1
                        } else {
                            return -1
                        }

                    } else if (currentUserCoefDiff < 0) {
                        return 1
                    } else {
                        return -1
                    }
                }

                return 0
            })
    }

    @computed get clientGoalsFilteredClassic() {
        return this.clientGoalsArray.filter(x => x.type === ClientGoalType.classic)
    }

    @computed get clientGoalsFilteredPayment() {
        return this.clientGoalsArray.filter(x => x.type === ClientGoalType.payment)
    }

    @computed get clientGoalsFilteredClientRequests() {
        return this.clientGoalsArray.filter(x => x.type === ClientGoalType.clientRequest)
    }

    @computed get sortedComments() {
        if (!this.clientGoal) return []

        return this.clientGoal.comments.slice().reverse()
    }

    startHubConnection = async () => {
        if (this.hubConnection?.state !== 'Connected') {
            this.hubConnection!
                .start()
                .then(() => {
                    console.log(this.hubConnection!.state)
                    this.setHubConnectionStatusColor('green')
                    // toast.success('Hub uspešno konektovan', { autoClose: 1000 })
                })
                .catch((error) => {
                    console.log('Error establishing connection: ', error)
                    this.setHubConnectionStatusColor('red')
                    // toast.error('Hub neuspešno konektovan', { autoClose: 1000 })
                    setTimeout(() => {
                        // toast.warning('Pokušaj rekonekcije na hub', { autoClose: 1000 })
                        // this.setHubConnectionStatusColor('yellow')
                        this.startHubConnection(/*clientGoalId*/)
                    }, 5000);
                })
        }
    }

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @action setFirstFilter = (filter: 'all' | 'current' | 'met' | 'failed' | 'currentPartlyMet') => {
        this.firstFilter = filter
        // this.clearPredicate()
        if (filter === 'current') {
            this.setPredicate(this.secondFilter, 'true')
        } else if (filter !== 'all') {
            this.setPredicate(filter, 'true')
        }
    }

    @action setSecondFilter = (filter: 'currentAll' | 'currentBasic' | 'currentPartlyMet' | 'currentExpired') => {
        this.secondFilter = filter
        // this.clearPredicate()
        this.setPredicate(filter, 'true')
    }

    @action setPredicateFromFilter = (commit?: boolean) => {
        if (this.firstFilter === 'current') {
            this.setPredicate(this.secondFilter, 'true')
        } else {
            this.setPredicate(this.firstFilter, 'true')
        }

        if (commit) {
            this.loadClientGoals()
        }
    }

    @action setEditingCommentId = (id: string | null) => this.editingCommentId = id

    @action createHubConnection = (/*clientGoalId: string*/) => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_CLIENT_GOAL_CHAT_URL!, {
                accessTokenFactory: () => this.rootStore.commonStore.token!
            })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection?.onclose((error) => {
            this.setHubConnectionStatusColor('red')
            // toast.error('Konekcija sa Hub-om izgubljena', { autoClose: 1000 })
            if (error) {
                setTimeout(() => {
                    // this.setHubConnectionStatusColor('yellow')
                    this.startHubConnection(/*clientGoalId*/)
                }, 5000)
            } else {
                this.setHubConnectionStatusColor(undefined)
            }
        })

        this.startHubConnection()

        this.hubConnection.onreconnected(() => {
            this.setHubConnectionStatusColor('green')
            console.log('Reconnected')
        })

        this.hubConnection.onreconnecting(() => {
            // this.setHubConnectionStatusColor('yellow')
            console.log('Reconnecting')
        })

        this.hubConnection.on('ReceiveClientGoalComment', (clientGoalComment: IClientGoalComment) => {
            runInAction(() => {
                this.clientGoal?.comments.push(clientGoalComment)

                const clientGoalFormRegistry = this.clientGoalsRegistry.get(clientGoalComment.clientGoalId) ?? null


                if (clientGoalFormRegistry) {
                    this.clientGoalsRegistry.set(clientGoalComment.clientGoalId, {
                        ...clientGoalFormRegistry,
                        comments: [
                            ...clientGoalFormRegistry.comments,
                            clientGoalComment
                        ]
                    })
                }
            })
        })

        this.hubConnection.on('ReceiveUpdatedClientGoalComment', (clientGoalComment: IClientGoalComment) => {
            runInAction(() => {
                if (this.clientGoal) {
                    this.clientGoal.comments = this.clientGoal?.comments.map(comment => comment.id === clientGoalComment.id ? clientGoalComment : comment)
                }

                const clientGoalFormRegistry = this.clientGoalsRegistry.get(clientGoalComment.clientGoalId) ?? null

                if (clientGoalFormRegistry) {
                    this.clientGoalsRegistry.set(clientGoalComment.clientGoalId, {
                        ...clientGoalFormRegistry,
                        comments: clientGoalFormRegistry.comments.map(comment => comment.id === clientGoalComment.id ? clientGoalComment : comment)
                    })
                }
            })
        })

        this.hubConnection.on('ReceiveDeletedClientGoalComment', (payload: { clientGoalId: string, commentId: string }) => {
            runInAction(() => {
                if (this.clientGoal) {
                    this.clientGoal.comments = this.clientGoal?.comments.filter(comment => comment.id !== payload.commentId)
                }

                const clientGoalFromRegistry = this.clientGoalsRegistry.get(payload.clientGoalId) ?? null
                if (clientGoalFromRegistry) {
                    this.clientGoalsRegistry.set(payload.clientGoalId,
                        {
                            ...clientGoalFromRegistry,
                            comments: clientGoalFromRegistry.comments.filter(comment => comment.id !== payload.commentId)
                        })
                }
            })
        })
    }

    @action stopHubConnection = () => {
        if (this.hubConnection) {
            this.hubConnection!.stop()
                .then(() => console.log('Connection stopped'))
                .catch((error) => console.log(error))
        }
    }

    getCommentators = (clientGoal: IClientGoal): IUser[] => {
        if (!clientGoal) {
            return []
        }

        return _.uniqBy(clientGoal.comments.map(comment => comment.user), user => user.id)
    }

    @action loadClientGoal = async (id: string) => {
        this.loading = true
        const clientGoal = await agent.ClientGoals.details(id)
        try {
            runInAction(() => {
                this.clientGoal = clientGoal
            })
            return clientGoal
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }

    @action loadClientGoals = async () => {
        const requestId = uuid()
        this.lastRequestId = requestId
        this.loading = true
        try {
            const clientGoals = await agent.ClientGoals.list(this.axiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.clientGoalsRegistry.clear()
                    this.loadClientGoalsToRegistry(clientGoals)
                }
            })
        } catch (error) {
            console.log(error)

            const type: ClientGoalType = this.predicate.get('type') ? Number(this.predicate.get('type')) : ClientGoalType.classic
            const errorMessage = type === ClientGoalType.payment
                ? 'Greška prilikom pribavljanja naplata'
                : 'Greška prilikom pribavljanja ciljeva'
            toast.error(errorMessage)
            this.clientGoalsRegistry.clear()
        } finally {
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.loading = false
                }
            })
        }
    }

    @action createClientGoal = async (clientGoalFormValues: IClientGoalFormValues) => {
        this.submitting = true
        try {
            await agent.ClientGoals.create(clientGoalFormValues)
            runInAction(() => {
                // this.clientGoalsRegistry.set(newClientGoal.id, new ClientGoalWithFlags(newClientGoal))
                // history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientGoals`)
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action setClientGoalFlagIfGoalIsInRegistry = (flag: 'updating' | 'uploading' | 'deleting', id: string, value: boolean) => {
        const clientGoalFromRegistry = this.clientGoalsRegistry.get(id)
        if (clientGoalFromRegistry) {

            switch (flag) {
                case 'updating':
                    this.clientGoalsRegistry.set(id, {
                        ...clientGoalFromRegistry,
                        flags: {
                            ...clientGoalFromRegistry.flags!,
                            updating: value
                        }
                    })
                    break
                case 'uploading':
                    this.clientGoalsRegistry.set(id, {
                        ...clientGoalFromRegistry,
                        flags: {
                            ...clientGoalFromRegistry.flags!,
                            uploading: value
                        }

                    })
                    break
                case 'deleting':
                    this.clientGoalsRegistry.set(id, {
                        ...clientGoalFromRegistry,
                        flags: {
                            ...clientGoalFromRegistry.flags!,
                            deleting: value
                        }

                    })
                    break
            }
        }
    }

    @action setClientGoalFlagIfCurrent = (flag: 'updating' | 'uploading' | 'deleting', id: string, value: boolean) => {
        if (this.clientGoal?.id === id) {

            switch (flag) {
                case 'updating':
                    this.clientGoal = {
                        ...this.clientGoal,
                        flags: {
                            ...this.clientGoal.flags!,
                            updating: value
                        }
                    }
                    break
                case 'uploading':
                    this.clientGoal = {
                        ...this.clientGoal,
                        flags: {
                            ...this.clientGoal.flags!,
                            updating: value
                        }
                    }
                    break
                case 'deleting':
                    this.clientGoal = {
                        ...this.clientGoal,
                        flags: {
                            ...this.clientGoal.flags!,
                            deleting: value
                        }
                    }
                    break
            }
        }
    }

    @action setFlagIfCurrentAndIfInRegistry = (flag: 'updating' | 'uploading' | 'deleting', id: string, value: boolean) => {
        this.setClientGoalFlagIfCurrent(flag, id, value)
        this.setClientGoalFlagIfGoalIsInRegistry(flag, id, value)
    }

    @action editClientGoal = async (clientGoalFormValues: IClientGoalFormValues) => {
        this.submitting = true
        this.setFlagIfCurrentAndIfInRegistry('updating', clientGoalFormValues.id, true)
        try {
            await agent.ClientGoals.edit(clientGoalFormValues)
            const updatedClientGoal = await agent.ClientGoals.details(clientGoalFormValues.id)
            runInAction(() => {
                this.clientGoal = updatedClientGoal
                this.clientGoalsRegistry.set(updatedClientGoal.id, updatedClientGoal)
                // history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clientGoals/${updatedClientGoal.id}`) // cant do this here because use edit function in client goals list to update participants
            })
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
                this.setFlagIfCurrentAndIfInRegistry('updating', clientGoalFormValues.id, false)
            })
        }
    }

    @action deleteClientGoal = async (id: string) => {
        this.setFlagIfCurrentAndIfInRegistry('deleting', id, true)
        try {
            await agent.ClientGoals.delete(id)
            runInAction(() => {
                this.clientGoalsRegistry.delete(id)
                if (this.clientGoal?.id === id) {
                    this.clientGoal = null
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.setFlagIfCurrentAndIfInRegistry('deleting', id, false)
            })
        }
    }

    @action addComment = async (id: string, values: any) => {
        values.clientGoalId = id
        try {
            await this.hubConnection!.invoke('SendComment', values)
        } catch (error) {
            console.log(error)
        }
    }

    @action editComment = async (clientGoalId: string, commentId: string, values: any) => {
        values.clientGoalId = clientGoalId
        values.id = commentId
        try {
            await this.hubConnection!.invoke('EditComment', values)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingCommentId = null
            })
        }
    }

    @action deleteComment = async (id: string) => {
        const request = {
            id
        }
        try {
            await this.hubConnection!.invoke('DeleteComment', request)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingCommentId = null
            })
        }
    }

    @action loadClientGoalsToRegistry = (goals: IClientGoal[]) => {
        this.clientGoalsRegistry.clear()
        goals.forEach(goal => {
            this.clientGoalsRegistry.set(goal.id, new ClientGoal(goal))
        })
    }

    @action setClientGoalStatus = async (metFormValues: { id: string, status: ClientGoalStatus, statusNote: string }) => {
        this.metingId = metFormValues.id
        try {
            await agent.ClientGoals.setStatus(metFormValues)
            runInAction(() => {
                if (this.clientGoal && this.clientGoal.id === metFormValues.id) {
                    this.clientGoal.status = metFormValues.status
                    this.clientGoal.statusNote = (metFormValues.status === ClientGoalStatus.partlyMet || metFormValues.status === ClientGoalStatus.failed) ?
                        metFormValues.statusNote : ''
                }
                const goalInRegistry = this.clientGoalsRegistry.get(metFormValues.id) ?? null
                if (goalInRegistry) {
                    this.clientGoalsRegistry.set(metFormValues.id, {
                        ...goalInRegistry,
                        status: metFormValues.status,
                        statusNote: (metFormValues.status === ClientGoalStatus.partlyMet || metFormValues.status === ClientGoalStatus.failed) ?
                            metFormValues.statusNote : ''
                    })
                }
            })
        } catch (error) {
            console.log(error)
            toast.error('Greška prilikom ažuriranja statusa')
        } finally {
            runInAction(() => {
                this.metingId = null
            })
        }
    }

    @action loadClientGoalsCategories = async (local: boolean = false) => {
        this.loadingClientGoalsCategories = true
        try {
            let categories = (!local || !this.clientGoalsCategories) ?
                await agent.ClientGoals.categories() :
                this.clientGoalsCategories

            runInAction(() => {
                this.clientGoalsCategories = categories
            })

            return categories
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.loadingClientGoalsCategories = false
            })
        }
    }

    @action updateDate = async (id: string, dateType: 'completed' | 'planed', date: string | null) => {
        this.setFlagIfCurrentAndIfInRegistry('updating', id, true)
        try {
            if (dateType === 'completed') {
                await agent.ClientGoals.updateCompletedDate(id, date)
            } else {
                await agent.ClientGoals.updatePlanedDate(id, date)
            }
            runInAction(() => {
                if (this.clientGoal?.id === id) {
                    if (dateType === 'completed') {
                        this.clientGoal.completedDate = date ? new Date(date) : null
                    } else {
                        this.clientGoal.date = date ? new Date(date) : null
                    }
                }
                let clientGoalInRegistry = this.clientGoalsRegistry.get(id)
                if (clientGoalInRegistry) {
                    if (dateType === 'completed') {
                        this.clientGoalsRegistry.set(id, {
                            ...clientGoalInRegistry,
                            completedDate: date ? new Date(date) : null
                        })
                    } else {
                        this.clientGoalsRegistry.set(id, {
                            ...clientGoalInRegistry,
                            date: date ? new Date(date) : null
                        })
                    }
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.setFlagIfCurrentAndIfInRegistry('updating', id, false)
            })
        }
    }

    @action addDocument = async (clientGoalDocumentFormValues: IClientGoalDocumentFormValues) => {
        this.submittingDocument = true
        this.setFlagIfCurrentAndIfInRegistry('uploading', clientGoalDocumentFormValues.clientGoalId, true)
        try {
            await agent.ClientGoalDocuments.create(clientGoalDocumentFormValues)
            runInAction(() => {
                    const newClientGoalDocument: IClientGoalDocument = {
                        id: clientGoalDocumentFormValues.id,
                        clientGoalId: clientGoalDocumentFormValues.clientGoalId,
                        name: clientGoalDocumentFormValues.name
                    }
                    if (this.clientGoal) {
                        this.clientGoal.documents.push(newClientGoalDocument)
                    }

                    const clientGoalFromRegistry = this.clientGoalsRegistry.get(clientGoalDocumentFormValues.clientGoalId) ?? null
                    if (clientGoalFromRegistry) {
                        this.clientGoalsRegistry.set(clientGoalDocumentFormValues.clientGoalId,
                            {
                                ...clientGoalFromRegistry,
                                documents: [
                                    ...clientGoalFromRegistry.documents,
                                    newClientGoalDocument
                                ]
                            })
                    }
                }
            )
        } catch
            (error) {
            console.log(error)
            throw error
        } finally {
            runInAction(() => {
                this.submittingDocument = false
                this.setFlagIfCurrentAndIfInRegistry('uploading', clientGoalDocumentFormValues.clientGoalId, false)
            })
        }
    }

    @action getDocument = async (id: string) => {
        this.downloadingDocumentId = id
        try {
            const file = await agent.ClientGoalDocuments.getFile(id)
            window.open(URL.createObjectURL(file))
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.downloadingDocumentId = ''
            })
        }
    }

    @action deleteDocument = async (clientGoalDocument: IClientGoalDocument) => {
        this.documentDeletingId = clientGoalDocument.id
        try {
            await agent.ClientGoalDocuments.delete(clientGoalDocument.id)
            runInAction(() => {
                if (this.clientGoal) {
                    this.clientGoal.documents = this.clientGoal.documents.filter(document => document.id !== clientGoalDocument.id)
                }

                const clientGoal = this.clientGoalsRegistry.get(clientGoalDocument.clientGoalId)
                if (clientGoal) {
                    this.clientGoalsRegistry.set(clientGoalDocument.clientGoalId, {
                        ...clientGoal,
                        documents: clientGoal.documents.filter(x => x.id !== clientGoalDocument.id)
                    })
                }

            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.documentDeletingId = ''
            })
        }
    }

}
